import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import ProductGroupAssigningAvailableComponent from "../../../components/ProductGroupAssigningAvailableComponent/ProductGroupAssigningAvailableComponent";

export default function ProductGroupAvailable(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [id_value, SerIdValue] = useState(useParams());

  useEffect(() => {
    console.log("ProductGroupAvailable On mount :", props);
    console.log("ProductGroupAvailable context :", context);
  }, []);

  return (
    <div>
        <ProductGroupAssigningAvailableComponent
          type={"available"}  title={"Product Group Available / Unavailable"} is_menu_open={OutletContext}
        /> 
    </div>
  );
}
