import React, { useState, useEffect } from "react";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import { Link, useParams } from "react-router-dom";
import { api } from "../../../utils/Api";

let myModal: any;

export default function ModalCancelOrder(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [order_data,SetOrderData]= useState([]);

  useEffect(() => {
    console.log("ModalCancelOrder On mount :", props);
    let myModal1: any = document.getElementById("CancelorderModal");
    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModalCancelOrder On mount :", props);
      SetOrderData(props.data)
      myModal = new bootstrap.Modal(
        document.getElementById("CancelorderModal"),
        {}
      );
      myModal.show();
    } else {
      console.log("hide on modal when changge false");
      myModal = new bootstrap.Modal(
        document.getElementById("CancelorderModal"),
        {}
      );
      myModal.hide();
    }
  }, [props.open]);

  function close_choose_shipment() {
    myModal.hide();
  }

  return (
    // <div
    //   className="modal fade"
    //   id="CancelorderModal"
    //   tabIndex={-1}
    //   aria-labelledby="ModalLabel"
    //   aria-hidden="true"
    // >
    //   <div className="modal">
    //     <div className="modal-dialog">
    //       <div className="modal-content">
    //         <div className="modal-header">
    //           <h6 className="modal-title">Modal title</h6>
    //           <button
    //             type="button"
    //             className="btn-close"
    //             data-bs-dismiss="modal"
    //             aria-label="Close"
    //           ></button>
    //         </div>
    //         <div className="modal-body">
    //           <p>Modal body text goes here.</p>
    //         </div>
    //         <div className="modal-footer">
    //           <button
    //             type="button"
    //             className="btn btn-secondary"
    //             data-bs-dismiss="modal"
    //           >
    //             Close
    //           </button>
    //           <button type="button" className="btn btn-primary">
    //             Save changes
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div
      className="modal fade"
      id="CancelorderModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Cancel Order
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          {order_data[0]?.cancel_eligible ? (
            <div className="modal-body">Are you sure want to Cancel Order?</div>
          ) : (
            <div className="modal-body">
              This order is not eligible for cancel
            </div>
          )}
          <div className="modal-footer">
            {order_data[0]?.cancel_eligible ? (
              <button
                type="button"
                className="btn btn-danger-outline"
                onClick={() => {
                  SetCloseData((prevalue) => {
                    prevalue.action = "save";
                    props.close(prevalue);
                    myModal.hide();
                    return { ...prevalue };
                  });
                }}
              >
                Ok
              </button>
            ) : null}

            <button
              type="button"
              className="btn btn-light-outline"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
