import getWindow from './getWindow';
import getOffset from './getOffset';
export default (function (node, client) {
  var win = getWindow(node);

  if (win) {
    return win.innerWidth;
  }

  if (client) {
    return node.clientWidth;
  }

  var offset = getOffset(node);
  return offset ? offset.width : 0;
});