import { useState, useEffect } from "react";

const useIntersection = (element:any, rootMargin:any) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        let current_element = element.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setState(entry.isIntersecting);
                    observer.unobserve(current_element);
                }
            },
            {
                rootMargin
            }
        );

        current_element && observer.observe(current_element);

        return () => {
            if(current_element){
                observer.unobserve(current_element);
            }
        };
    }, [element, rootMargin]);

    return isVisible;
};

export default useIntersection;
