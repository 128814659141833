import React, { useState, useEffect, useContext } from "react";

import { createContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import ModalProducts from "./ModalProducts";
import ModalDeleteCollections from "./ModalDeleteCollections";
import ModalCreateCollection from "./ModalCreateCollection";
import Skeleton from "react-loading-skeleton";

// import CollectionProducts from "./CollectionProducts";
import {
  Feather,
  FontAwesome,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
// import ModalVariants from "./ModalVariants";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import toast from "react-hot-toast";
import { AppConfig } from "../../../config";
import ModalOrderCollection from "./ModalOrderCollection";

function valuetext(value) {
  return `${value}°C`;
}

const Name = createContext();

export { Name };

export default function Collections(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [collection_data, SetCollectionData] = useState([]);
  const [width, SetWidth] = useState(innerWidth);
  const [id_value, SerIdValue] = useState(useParams());
  const [is_loading, SetIsLoading] = useState(false);

  const [edit_user_modal, SetEditUserModal] = useState({
    is_open: false,
    data: {},
    index: "",
    type: "",
  });
  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });
  const [modal_new_custom, SetModalNewCustom] = useState({
    is_open: false,
    data: "",
    type: "",
  });
  const [modal_change_order, SetModalChangeOrder] = useState({
    is_open: false,
    data: [],
  });

  useEffect(() => {
    console.log("Collection on mount:", props);
    getCollection();
  }, []);

  async function getCollection() {
    SetIsLoading(true);
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let response: any = await api("/brand/collections", pass_data);
    console.log("/brand/collections response :", response.response);
    if (response.status_code == 200) {
      let collection_data_value = response.response.data;
      SetCollectionData(collection_data_value);
      SetIsLoading(false);
    }
  }

  return (
    <div className="">
      <div
        className={OutletContext ? "head-bar sticky-sm py-3" : "head-bar py-3"}
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="row align-items-center">
          <div className="col-5">
            <h6 className="fs-5  mb-0">Collections</h6>
          </div>
          <div className="col-7 px-0 text-end d-flex ">
            <div className="ms-auto">
              <div className="d-flex">
                <button
                  className="btn bg-prime text-white btn-radius btn-sm"
                  onClick={() => {
                    console.log("Franchise new");
                    SetModalNewCustom((prevValue: any) => {
                      prevValue.is_open = true;
                      prevValue.type = "create";
                      prevValue.data = "";
                      return { ...prevValue };
                    });
                  }}
                >
                  Create New
                </button>
                <button
                  className="btn bg-prime text-white btn-radius ms-2 btn-sm"
                  onClick={() => {
                    // SetChangeOrder(true);
                    SetModalChangeOrder((prevalue) => {
                      prevalue.is_open = true;
                      prevalue.data = collection_data;
                      return { ...prevalue };
                    });
                  }}
                >
                  Change Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          OutletContext ? "mt-3 px-md-3 px-sm-0" : "mt-3 px-md-3 px-sm-0"
        }
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="mt-3 mb-5">
          {is_loading ? (
            <div className="row">
              {Array.from({ length: 9 }).map((_, i) => (
                <div className="col-md-6 col-lg-4 md-ps-0 sm-px-3" key={i}>
                  <div className="card px-2 py-1 mb-3">
                    <div className="px-0">
                      <div className="px-1">
                        <div className="d-flex align-items-center">
                          <Skeleton width={14} height={14} />
                          <p className="ms-2 text-black fs-7 fw-bold">
                            <Skeleton width={100} />
                          </p>
                          <div className="d-flex align-items-center ms-auto">
                            <div className="cursor me-4">
                              <Skeleton width={14} height={14} />
                            </div>
                            <div className="me-4 cursor">
                              <Skeleton width={14} height={14} />
                            </div>
                            <div className="cursor">
                              <Skeleton width={14} height={14} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-1">
                        <div className="w-100">
                          <div className="row align-items-center border-bottom pb-2"></div>
                          <div className="mt-3">
                            {/* {Array.from({ length: 3 }).map((_, j) => (
                            <div className="mb-2 border-bottom" key={j}>
                              <div className="">
                                <div className="d-flex">
                                  <p className="text-black ms-2 cursor">
                                    <Skeleton width={80} />
                                  </p>
                                  <div className="d-flex align-items-center ms-auto">
                                    <div className="cursor me-4">
                                      <Skeleton width={14} height={14} />
                                    </div>
                                    <div className="me-4 cursor">
                                      <Skeleton width={14} height={14} />
                                    </div>
                                    <div className="cursor">
                                      <Skeleton width={14} height={14} />
                                    </div>
                                  </div>
                                </div>
                               
                              </div>
                            </div>
                          ))} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : collection_data.length > 0 ? (
            <div className="row">
              {collection_data?.map((val: any, i: any) => (
                <div className="col-md-6 col-lg-4 md-ps-0 sm-px-3">
                  <div className="card px-2 py-1 mb-3">
                    <Accordion className="px-0">
                      <AccordionSummary
                        expandIcon={
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "43fd67bad621bf93fac936e25b16c7aa.svg"
                            }
                            className="w-10px"
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="px-1"
                      >
                        {/* <div className="cursor d-flex align-items-center"
                          onClick={(event) => {
                            let set_data = {
                              value: val,
                              index: i,
                            };
                            SetEditUserModal({
                              is_open: true,
                              type: "edit",
                              data: set_data,
                            });

                            console.log("Edit");
                            // sample_fun(anchor, false, event);
                          }}
                        > */}
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "f9a03c6f583664c3e640505fedcad881.svg"
                          }
                          className="w-14px"
                        />
                        {/* <Ionicons name="bookmark-outline" size={16} color="#3f41c3" /> */}
                        <p className="ms-2 text-black fs-7 fw-bold">
                          {val.name}
                        </p>
                        {/* </div> */}
                        <div className="d-flex align-items-center ms-auto">
                          {val.children && val.children.length > 0 && (
                            <div
                              className="cursor me-4"
                              onClick={(e) => {
                                e.stopPropagation();
                                SetModalChangeOrder((prevalue) => {
                                  prevalue.is_open = true;
                                  prevalue.data = val.children;
                                  return { ...prevalue };
                                });
                              }}
                            >
                              <img
                                src={
                                  AppConfig.CDN_Image_url +
                                  "ce6098f415b7a98e5cac9e3bf5c7a13d.png"
                                }
                                className="w-14px"
                              />
                            </div>
                          )}

                          <div
                            className="cursor me-4"
                            onClick={(e) => {
                              e.stopPropagation();
                              console.log("Franchise new");
                              SetModalNewCustom((prevValue: any) => {
                                prevValue.is_open = true;
                                prevValue.type = "create";
                                prevValue.data = val.id;

                                return { ...prevValue };
                              });
                            }}
                          >
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "7767d0d4cacda6a7c1cee478a6cf447c.svg"
                              }
                              className="w-14px"
                            />

                            {/* <MaterialIcons name="add" size={19} color="#777" /> */}
                          </div>

                          <div className="me-4 cursor">
                            {/* <Link to={"/brand/" + id_value.brand_id + "/add_collection_products/" + ele.id}> */}
                            <Link
                              to={
                                "/brand/" +
                                id_value.brand_id +
                                "/collection_products/" +
                                val.id
                              }
                            >
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "6fd715db81080ca47877f07418286924.svg"
                                }
                                className="w-14px"
                              />
                              {/* <Feather name="link" size={16} color="#777" /> */}
                            </Link>
                          </div>

                          <div
                            className="cursor "
                            onClick={(e) => {
                              e.stopPropagation();
                              SetopenModal({
                                is_open: true,
                                data: {
                                  id: val.id,
                                  index: i,
                                  type: "brand",
                                  status: "active",
                                },
                              });
                            }}
                          >
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "dd66cdf0b0620c92e93086bff94ee11e.svg"
                              }
                              className="w-14px"
                            />
                            {/* <MaterialIcons
                                name="delete-outline"
                                size={18}
                                color="#777"
                              /> */}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className="px-1">
                        <div className="w-100">
                          <div className="row align-items-center border-bottom pb-2">
                            {/* 
                            <div className="col-4 center ps-1"
                              onClick={() => {
                                console.log("Franchise new");
                                SetModalNewCustom((prevValue: any) => {
                                  prevValue.is_open = true;
                                  prevValue.type = "create";
                                  prevValue.data = val.id;

                                  return { ...prevValue };
                                });
                              }}>
                              <MaterialIcons name="add" size={16} color="#3f41c3" />
                              <p className="text-prime ps-1">Add</p>
                            </div> */}

                            {/* <Link to={"/brand/" + id_value.brand_id + "/add_collection_products/" + val.id}> */}

                            {/* <div className="col-4 ps-2 cursor">
                              <Link to={"/brand/" + id_value.brand_id + "/collection_products/" + val.id}>
                                <div className="center" >
                                  <Feather name="link" size={16} color="#3f41c3" />
                                  <p className="text-prime ps-1">Products</p>
                                </div>
                              </Link>
                            </div>

                            <div className="col-4 center"
                              onClick={() => {
                                SetopenModal({
                                  is_open: true,
                                  data: {
                                    id: val.id,
                                    index: i,
                                    type: "brand",
                                    status: "active",
                                  },
                                });
                              }}>
                              <MaterialIcons name="delete-outline" size={18} color="#3f41c3" />
                              <p className="text-prime ps-1">Delete</p>
                            </div> */}
                          </div>
                          <div className=" mt-3">
                            {val.children?.map((ele: any, j: any) => (
                              <div className="mb-2 border-bottom" key={j}>
                                <div className="">
                                  <div className="d-flex">
                                    <p
                                      className="text-black ms-2 cursor"
                                      onClick={(event) => {
                                        let set_data = {
                                          value: ele,
                                          index: ele.i,
                                        };
                                        // SetEditUserModal({
                                        //   is_open: true,
                                        //   type: "edit",
                                        //   data: set_data,
                                        // });

                                        console.log("Edit");
                                        // sample_fun(anchor, false, event);
                                      }}
                                    >
                                      {"  "}
                                      {ele.name}
                                    </p>

                                    <div className="d-flex align-items-center ms-auto">
                                      {ele.children &&
                                        ele.children.length > 0 && (
                                          <div
                                            className="cursor me-4"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              SetModalChangeOrder(
                                                (prevalue) => {
                                                  prevalue.is_open = true;
                                                  prevalue.data = ele.children;
                                                  return { ...prevalue };
                                                }
                                              );
                                            }}
                                          >
                                            <img
                                              src={
                                                AppConfig.CDN_Image_url +
                                                "ce6098f415b7a98e5cac9e3bf5c7a13d.png"
                                              }
                                              className="w-14px"
                                            />
                                          </div>
                                        )}

                                      <div
                                        className="cursor me-4"
                                        onClick={() => {
                                          console.log("Franchise new");
                                          SetModalNewCustom(
                                            (prevValue: any) => {
                                              prevValue.is_open = true;
                                              prevValue.type = "create";
                                              prevValue.data = ele.id;

                                              return { ...prevValue };
                                            }
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            AppConfig.CDN_Media_url +
                                            "7767d0d4cacda6a7c1cee478a6cf447c.svg"
                                          }
                                          className="w-14px"
                                        />
                                        {/* <MaterialIcons
                                            name="add"
                                            size={19}
                                            color="#777"
                                          /> */}
                                      </div>

                                      <div className="me-4 cursor">
                                        {/* <Link to={"/brand/" + id_value.brand_id + "/add_collection_products/" + ele.id}> */}
                                        <Link
                                          to={
                                            "/brand/" +
                                            id_value.brand_id +
                                            "/collection_products/" +
                                            ele.id
                                          }
                                        >
                                          <img
                                            src={
                                              AppConfig.CDN_Media_url +
                                              "4d82a40234cebc7bd8420761265da96c.svg"
                                            }
                                            className="w-14px"
                                          />
                                          {/* <Feather
                                              name="link"
                                              size={16}
                                              color="#777"
                                            /> */}
                                        </Link>
                                      </div>

                                      <div
                                        className="cursor "
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          SetopenModal({
                                            is_open: true,
                                            data: {
                                              id: ele.id,
                                              index: i,
                                              type: "brand",
                                              status: "active",
                                            },
                                          });
                                        }}
                                      >
                                        <img
                                          src={
                                            AppConfig.CDN_Media_url +
                                            "dd66cdf0b0620c92e93086bff94ee11e.svg"
                                          }
                                          className="w-14px"
                                        />
                                        {/* <MaterialIcons
                                            name="delete-outline"
                                            size={18}
                                            color="#777"
                                          /> */}
                                      </div>
                                    </div>
                                  </div>

                                  <div className=" mt-3">
                                    {ele.children?.map((item, j) => (
                                      <div className="border-top">
                                        <div className="d-flex mb-3 mt-2">
                                          <p
                                            className="text-darkgray ms-3"
                                            onClick={(event) => {
                                              let set_data = {
                                                value: item,
                                                index: item.i,
                                              };
                                              // SetEditUserModal({
                                              //   is_open: true,
                                              //   type: "edit",
                                              //   data: set_data,
                                              // });

                                              console.log("Edit");
                                              // sample_fun(anchor, false, event);
                                            }}
                                          >
                                            {"  "}
                                            {item.name}
                                          </p>
                                          <div className="d-flex align-items-center ms-auto">
                                            <div className="cursor me-4">
                                              {/* <Link to={"/brand/" + id_value.brand_id + "/add_collection_products/" + item.id}>
                                               */}
                                              <Link
                                                to={
                                                  "/brand/" +
                                                  id_value.brand_id +
                                                  "/collection_products/" +
                                                  item.id
                                                }
                                              >
                                                <img
                                                  src={
                                                    AppConfig.CDN_Media_url +
                                                    "4d82a40234cebc7bd8420761265da96c.svg"
                                                  }
                                                  className="w-14px"
                                                />

                                                {/* <Feather
                                                    name="link"
                                                    size={16}
                                                    color="#777"
                                                  /> */}
                                              </Link>
                                            </div>

                                            <div
                                              className=" cursor "
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                SetopenModal({
                                                  is_open: true,
                                                  data: {
                                                    id: item.id,
                                                    index: i,
                                                    type: "brand",
                                                    status: "active",
                                                  },
                                                });
                                              }}
                                            >
                                              <img
                                                src={
                                                  AppConfig.CDN_Media_url +
                                                  "dd66cdf0b0620c92e93086bff94ee11e.svg"
                                                }
                                                className="w-14px"
                                              />

                                              {/* <MaterialIcons
                                                  name="delete-outline"
                                                  size={18}
                                                  color="#777"
                                                /> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center">
              <img
                src={require("../../../assets/icon/empty.png")}
                className="empty-img pb-2"
              />
              <p className="v-small mb-0">No Data, Please Add Collections</p>
              <button
                className="btn btn-custom-outline btn-radius px-3 btn-sm"
                onClick={() => {
                  console.log("Franchise new");
                  SetModalNewCustom((prevValue: any) => {
                    prevValue.is_open = true;
                    prevValue.type = "create";
                    prevValue.data = "";
                    return { ...prevValue };
                  });
                }}
              >
                Create New
              </button>
            </div>
          )}
        </div>

        <ModalProducts
          open={edit_user_modal.is_open}
          data={edit_user_modal.data}
          type={edit_user_modal.type}
          index={edit_user_modal.index}
          close={(data: any) => {
            console.log("Edit Outlet Modal On Close :", data);
            SetEditUserModal({
              is_open: false,
              data: {},
              type: "",
              index: "",
            });
          }}
        />

        <ModalDeleteCollections
          open={openModal.is_open}
          data={openModal.data}
          close={(data: any) => {
            // console.log("Franchise modl on close :", data);
            SetopenModal((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              get_account();
            }
          }}
        />

        <ModalCreateCollection
          open={modal_new_custom.is_open}
          type={modal_new_custom.type}
          data={modal_new_custom.data}
          collection_data={collection_data}
          close={(data: any) => {
            console.log("Franchise modl on close :", data);
            SetModalNewCustom((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              get_account();
              toast.success("Collection created");
            }
          }}
        />

        <ModalOrderCollection
          open={modal_change_order.is_open}
          data={modal_change_order.data}
          close={(data: any) => {
            SetModalChangeOrder((prevalue) => {
              prevalue.is_open = false;
              prevalue.data = [];
              return { ...prevalue };
            });
            if (data.action == "save") {
              // SetCollectionData(data.data);
              getCollection();
            }
          }}
        />
      </div>
    </div>
  );
}
