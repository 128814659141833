import React, { useState, useEffect, useContext, useSetState } from 'react'
import DataContext from '../../utils/Context/DataContext'
import { Link, useParams } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { Ionicons, MaterialIcons } from '@expo/vector-icons'
import Slider from '@material-ui/core/Slider'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { api } from '../../utils/Api'

const animatedComponents = makeAnimated()

export default function AssignAvailableFilterComponent(props: any) {
  const context = useContext(DataContext)
  const [id_value, SetIdValue] = useState(useParams())
  const [filter_data, SetFilterData] = useState({
    page_no: 1,
    next_page: false,
    type: { label: 'outlet', value: 'outlet' },
    type_option: [
      { label: 'outlet', value: 'outlet' },
      { label: 'channel', value: 'channel' },
    ],
    view: { label: 'Active', value: 'active' },
    view_option: [
      { label: 'New', value: 'new' },
      { label: 'Active', value: 'active' },
      { label: 'Unavailable', value: 'unavailable' },
      { label: 'Paused', value: 'paused' },
    ],
    outlets: [],
    channels: [],
    custom_channels: [],
    count: { label: 'All', value: 'all' },
    count_option: [
      { label: 'All', value: 'all' },
      { label: '10', value: '10' },
      { label: '20', value: '20' },
      { label: '30', value: '30' },
    ],
    filter_products: {
      own_products: false,
      others_products: false,
    },
    search: '',
    sort: { label: 'asc', value: 'asc' },
    sort_option: [
      { label: 'asc', value: 'asc' },
      { label: 'desc', value: 'desc' },
    ],
    filter_brand: [],
    min_mrp: '',
    max_mrp: '',
    filter_categories: [],
  });
  const [width, SetWidth] = useState(innerWidth);

  useEffect(() => {
    console.log('AssignAvailableFilterComponent On mount :', props)
  }, [])

  const handleChange = (panel) => (event, isExpanded) => {
    // setExpanded(isExpanded ? panel : false)
    console.log('handleChange isExpanded :', isExpanded)
    console.log('handleChange event :', event)
  }

  return (
    <div className="">
      <div className="pt-3">
        {/* own/other product */}
        <div className=" mb-4">
          <label className='text-black form-label mb-3 fw-bold '>Select brand type</label>
          <label className="form-label d-flex align-items-center mb-3 cursor">
            <input type="checkbox" className="form-check-input me-2" checked={props.filter_data.filter_products.own_products} onChange={(e) => {
              let send_data = {
                key: 'filter_products',
                value: {
                  own_products: e.target.checked,
                  others_products: props.filter_data.filter_products.others_products
                }
              }
              props.onChange(send_data)
            }} />
            <p className='small'>Own Brand Products</p>
          </label>
          <label className="form-label d-flex align-items-center mb-3 cursor">
            <input type="checkbox" className="form-check-input me-2" checked={props.filter_data.filter_products.others_products} onChange={(e) => {
              let send_data = {
                key: 'filter_products',
                value: {
                  own_products: props.filter_data.filter_products.own_products,
                  others_products: e.target.checked
                }
              }
              props.onChange(send_data)
            }} />
            <p className='small'>Other Brand Products</p>
          </label>
        </div>

        {/* Sort */}
        <div className="mb-3 pb-3">
          <label className='text-black form-label fw-bold'>Sort</label>
          <Select
            components={animatedComponents}
            className="basic-select"
            classNamePrefix="Sort"
            placeholder="Select Sorting"
            id="sort"
            Group
            value={props.filter_data.sort}
            options={props.filter_data.sort_option}
            onChange={(e, action) => {
              console.log('Brand on change e :', e)
              console.log('Brand on change action :', action)
              let send_data = {
                key: 'sort',
                value: e,
              }
              props.onChange(send_data)
            }}
          />
        </div>

        {/* Brand */}
        {/* <div className="col-md-6 mb-3">
          <label className='text-black form-label'>Brand</label>
          <Select
            components={animatedComponents}
            className="basic-select"
            classNamePrefix="select Brand"
            placeholder="Select Brand"
            id="brand"
            isMulti
            Group
            value={props.filter_data.filter_brand}
            options={props.filter_data.filter_brand_options}
            onChange={(e, action) => {
              console.log('Brand on change e :', e)
              console.log('Brand on change action :', action)
              let send_data = {
                key: 'filter_brand',
                value: e,
              }
              props.onChange(send_data)
            }}
          />
        </div> */}

        {/* outlets */}
        {/* <div className="col-md-6 mb-3">
          <label className='text-black form-label'>Outlet</label>
          <Select
            components={animatedComponents}
            className="basic-select"
            classNamePrefix="select Outlet"
            placeholder="Select Outlet"
            id="outlet"
            isMulti
            Group
            value={props.filter_data.outlets}
            options={props.filter_data.outlets_options}
            onChange={(e, action) => {
              console.log('Outlet on change e :', e)
              console.log('Outlet on change action :', action)
              let send_data = {
                key: 'outlets',
                value: e,
              }
              props.onChange(send_data)
            }}
          />
        </div> */}

        {/* channels */}
        {/* <div className="col-md-6 mb-3">
          <label className='text-black form-label'>Channels</label>
          <Select
            components={animatedComponents}
            className="basic-select"
            classNamePrefix="select Channels"
            placeholder="Select Channels"
            id="channel"
            isMulti
            Group
            value={props.filter_data.channels}
            options={props.filter_data.channels_options}
            onChange={(e, action) => {
              console.log('Channels on change e :', e)
              console.log('Channels on change action :', action)
              let send_data = {
                key: 'channels',
                value: e,
              }
              props.onChange(send_data)
            }}
          />
        </div> */}

        {/* custom_channels */}
        {/* <div className="col-md-6 mb-3">
          <label className='text-black form-label'>Custom Channels</label>
          <Select
            components={animatedComponents}
            className="basic-select"
            classNamePrefix="select Custom Channels"
            placeholder="Select Custom Channels"
            id="custom_channel"
            isMulti
            Group
            value={props.filter_data.custom_channels}
            options={props.filter_data.custom_channels_options}
            onChange={(e, action) => {
              console.log('Custom Channels on change e :', e)
              console.log('Custom Channels on change action :', action)
              let send_data = {
                key: 'custom_channels',
                value: e,
              }
              props.onChange(send_data)
            }}
          />
        </div> */}

        {/* filter_categories */}
        {/* <div className="col-md-6 mb-3">
          <label className='text-black form-label'>Categories</label>
          <Select
            components={animatedComponents}
            className="basic-select"
            classNamePrefix="select Categories"
            placeholder="Select Categories"
            id="categories"
            isMulti
            Group
            value={props.filter_data.filter_categories}
            options={props.filter_data.filter_categories_options}
            onChange={(e, action) => {
              console.log('Categories on change e :', e)
              console.log('Categories on change action :', action)
              let send_data = {
                key: 'filter_categories',
                value: e,
              }
              props.onChange(send_data)
            }}
          />
        </div> */}

        {/* min/max */}
        {/* <div className="mt-3 mb-3 col-md-6">
          <div className="row">
            <div className="col-md-6">
              <label id="range-slider" className='text-black form-label'>
                Mininum Maximum Price
              </label>
              <div className="d-flex align-items-center">
                <Slider
                  value={[props.filter_data.min_mrp, props.filter_data.max_mrp]}
                  onChange={handleChange}
                  step={20}
                  onClick={(e) => {
                    console.log('min max :', e)
                  }}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  min={10}
                  max={1000}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-6">
                  <label className="form-label text-black">Min Price</label>
                  <input
                    type="text"
                    className="form-control"
                    name={'min_mrp'}
                    value={props.filter_data.min_mrp}
                    onChange={(e) => {
                      let send_data = {
                        key: 'min_mrp',
                        value: e.target.value,
                      }
                      props.onChange(send_data)
                    }}
                  />
                </div>
                <div className="col-6">
                  <label className="form-label text-black">Max Price</label>
                  <input
                    type="text"
                    className="form-control"
                    name={'max_mrp'}
                    value={props.filter_data.max_mrp}
                    onChange={(e) => {
                      let send_data = {
                        key: 'max_mrp',
                        value: e.target.value,
                      }
                      props.onChange(send_data)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>



        </div> */}
      </div>


      {/* <div className={width < 786 ? "bottom-fix w-100 shadow left-0" : "md-none"}>
        <div className="row ">
          <div className="col-6 center bg-fff py-3">
            <p className="text-black fw-bold">Reset</p>
          </div>
          <div className="col-6 bg-prime center py-3"
            onClick={() => {
              props.onClose(false);
            }}>
            <p className="text-white fw-bold">Show products</p>
          </div>
        </div>
      </div> */}
    </div>
  )
}
