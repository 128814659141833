import React, { useState, useEffect } from "react";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import ModalOutletUser from "./ModalOutletUser";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ModalEditOutlet from "./ModalEditOutlet";
import DeleteAlert from "../../../components/Alert/DeleteAlert";
import { AntDesign, Entypo, FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { AppConfig } from "../../../config";

const table_config = {
  key_column: "id",
  page_size: 10,
  length_menu: [10, 20, 50],
  show_filter: true,
  show_pagination: true,
  button: {
    excel: false,
    print: false,
  },
  language: {
    loading_text: "Please be patient while data loads...",
  },
};


const table_header = [
  {
    name: "User Name",
    width: "20",
  },
  {
    name: "Roles",
    width: "10",
  },
  {
    name: "Custom Roles",
    width: "20",
  },
  {
    name: "Created At",
    width: "20",
  },
  {
    name: "Created User",
    width: "20",
  },
  {
    name: "",
    width: "10",
  },
];


export default function BrandViewOutlet(props: any) {
  const [id_value, SerIdValue] = useState(useParams());
  const OutletContext = useOutletContext();
  const params = useParams();
  const [outlet_details, SetOutletDetails] = useState({});
  const [outlet_user_data, SetOutletUserData] = useState([]);
  const [edit_outlet_modal, SetEditOutletModal] = useState({
    is_open: false,
    data: {},
    index: "",
  });
  const [width, SetWidth] = useState(innerWidth);
  const [modal_user, SetModalUser] = useState({
    is_open: false,
    type: "",
    data: {},
  });
  const [state, setState] = React.useState({
    bottom: false,
  });
  const [selected_data, SetSelectedData] = useState({
    value: "",
    i: "",
    type: "",
  });
  const [delete_modal, SetDeleteModal] = useState({
    is_open: false,
    title: "",
    record: {},
    index: "",
  });
  const table_column = [
    { key: "id", width: 25, text: "Id", sortable: true },
    {
      key: "name",
      width: 150,
      text: "User Name",
      sortable: true,
      cell: (record: any, index: any) => {
        return record.name + "@" + record.username;
      },
    },
    {
      key: "roles",
      width: 150,
      text: "Roles",
      sortable: true,
      cell: (record: any, index: any) => {
        let data_value = "";
        if (record.roles) {
          record.roles.map((role_item: any, role_index: any) => {
            if (role_index == 0) {
              data_value = data_value.concat(role_item.name);
            } else {
              data_value = data_value.concat(",", role_item.name);
            }
          });
        }
        return data_value;
      },
    },
    {
      key: "custom_roles",
      width: 150,
      text: "Custom Roles",
      sortable: true,
      cell: (record: any, index: any) => {
        let data_value = "";
        if (record.custom_roles) {
          record.custom_roles.map((role_item: any, role_index: any) => {
            if (role_index == 0) {
              data_value = data_value.concat(role_item.name);
            } else {
              data_value = data_value.concat(",", role_item.name);
            }
          });
        }

        return data_value;
      },
    },
    { key: "created_at", width: 150, text: "Created At", sortable: true },
    {
      key: "created_user",
      width: 150,
      text: "Created User",
      sortable: true,
      cell: (record: any, index: any) => {
        return record.created_user + "@" + record.created_username;
      },
    },
    {
      key: "action",
      text: "Action",
      width: 250,
      className: "text-center",
      align: "center",
      cell: (record: any, index: any) => {
        return (
          <div className="d-flex justify-content-center">
            {/* //Edit */}
            <div
              onClick={() => {
                let set_data = {
                  value: record,
                  index: index,
                };
                SetModalUser({
                  is_open: true,
                  type: "edit",
                  data: set_data,
                });
                console.log("Edit");
              }}
              style={{ marginRight: "5px" }}
            >
              <i
                className="fas fa-pen pe-4 cursor"
                style={{ color: "gray" }}
              ></i>
            </div>
            {/* //Delete */}
            <div
              onClick={() => {
                remove_user(record, index);
                console.log("delete record:", record);
              }}
            >
              <i
                className="fas fa-trash pe-4 cursor"
                style={{ color: "gray" }}
              ></i>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    console.log("BrandViewOutlet On mount :", props);
  }, []);

  useEffect(() => {
    if (params.tab_value == "details") {
      console.log("Tab is on details");
      get_outlet_details();
    } else if (params.tab_value == "users") {
      console.log("Tab is on users");
      get_outlet_user_details();
    }
  }, [params]);

  async function get_outlet_details() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
    };
    let data = await api("/brand/outlet_details", pass_data);
    console.log("outlet_details response :", data);
    if (data.status_code == 200) {
      SetOutletDetails(data.response.outlets[0]);
    }
  }

  async function get_outlet_user_details() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
    };
    let data = await api("/brand/outlet_users", pass_data);
    console.log("outlet_users response :", data);
    if (data.status_code == 200) {
      SetOutletUserData(data.response.users);
    }
  }

  async function remove_user(record: any, index: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
      post: {
        user_id: record.id,
      },
    };

    let response = await api("/brand/remove_outlet_user", pass_data);
    console.log("remove_outlet_user response :", response);
    if (response.status_code == 200) {
      let data = outlet_user_data;
      data.splice(index, 1);
      console.log("Sliced Data :", data);
      SetOutletUserData([...data]);
    }
  }

  function sample_fun(anchor: any, open: any, event: any) {
    console.log("sample_fun anchor :", anchor);
    console.log("sample_fun open :", open);
    console.log("sample_fun event :", event);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      console.log("return");
      return;
    }
    console.log("toggle else");

    setState({ ...state, [anchor]: open });
    // toggleDrawer("bottom", true);
  }

  const list = (anchor: any) => (
    <div
      className="mt-3"
    // className={ anchor === 'top' || anchor === 'bottom'}
    // role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="px-1">
        <div className="d-flex justify-content-center">
          <div className="w-25 px-4">
            <hr className="h-3" />
          </div>
        </div>
        <ul>
          <li
            className="p-2 pb-3 cursor align-center"
            onClick={(event) => {
              let set_data = {
                value: selected_data.value,
                index: selected_data.i,
              };
              SetModalUser({
                is_open: true,
                type: "view",
                data: set_data,
              });
              sample_fun(anchor, false, event);
              console.log("selected Data: ", selected_data);
            }}
          >
            <Ionicons name="ios-eye-outline" size={16} color="#666" />
            &nbsp;
            View
          </li>
          <li
            className="p-2 pb-3 cursor align-center"
            onClick={(event) => {
              let set_data = {
                value: selected_data.value,
                index: selected_data.i,
              };
              SetModalUser({
                is_open: true,
                type: "edit",
                data: set_data,
              });
              console.log("Edit");
              sample_fun(anchor, false, event);
            }}
          >
            <AntDesign name="edit" size={16} color="#666" />
            &nbsp;
            Edit
          </li>
          <li
            className="p-2 pb-3 cursor align-center"
            onClick={(event) => {
              remove_user(selected_data.value, selected_data.i);
              sample_fun(anchor, false, event);
            }}
          >
            <MaterialCommunityIcons name="delete-outline" size={16} color="#666" />
            &nbsp;
            Delete
          </li>
        </ul>
      </div>
    </div>
  );

  async function delete_outlet(data: any, index: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        outlet_id: id_value.outlet_id,
      },
    };
    console.log("pass_data :", pass_data);
    let response = await api("/brand/delete_outlet", pass_data);
    console.log("/brand/delete_outlet response :", response);
    if (response.status_code == 200) {
      window.history.back();
      // let old_outlet_data = outlet_data;
      // old_outlet_data.splice(index, 1);
      // SetOutletData([...old_outlet_data]);
      // get_outlets();
    }
  }

  return (
    <div>
      <div className="bg-fff p-3 head-bar sticky-sm"
        style={OutletContext == false ? { width: width } : { width: "100%" }}>
        <div className="row align-items-center pb-2">
          <div className="col-md-5 col-6">
            <h6 className="mb-0 text-dark fw-bolder">Outlets</h6>
          </div>
          <div className="col-md-7 col-6 justify-content-end d-flex align-items-center ms-auto">
            <button
              className="btn bg-prime text-white btn-radius btn-sm px-3"
              onClick={() => {
                // SetIsDrawerOpen(true);
                console.log("Add users");
                SetModalUser({
                  is_open: true,
                  type: "add",
                  data: {},
                });
              }}
            >
              Add Users
            </button>
          </div>
        </div>
        <nav className="pt-1 sticky-tabs">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <Link
              to={
                "/brand/" +
                id_value.brand_id +
                "/view_outlet/" +
                id_value.outlet_id +
                "/details"
              }
            >
              <div
                className={
                  params.tab_value == "details"
                    ? "nav-link active cursor"
                    : "nav-link cursor"
                }
                id="details-tab"
                data-bs-toggle="tab"
                data-bs-target="#details"
                role="tab"
                aria-controls="details"
                aria-selected="true"
              >
                Details
              </div>
            </Link>
            <Link
              to={
                "/brand/" +
                id_value.brand_id +
                "/view_outlet/" +
                id_value.outlet_id +
                "/users"
              }
            >
              <div
                className={
                  params.tab_value == "users"
                    ? "nav-link active cursor"
                    : "nav-link cursor"
                }
                id="users-tab"
                data-bs-toggle="tab"
                data-bs-target="#users"
                role="tab"
                aria-controls="users"
                aria-selected="false"
              >
                Users
              </div>
            </Link>
          </div>
        </nav>
      </div>
      {/* ----------------------------------------------------------------------------------(show) */}
      <div className="container tabs-after-container">
        <div className="tab-content">
          {/* Details tab */}
          <div
            className={
              params.tab_value == "details"
                ? "tab-pane fade show active p-2"
                : "tab-pane fade p-2"
            }
            id="details"
            role="tabpanel"
            aria-labelledby="details-tab"
          >
            <div className="card p-3">
              <div className="row pt-2">
                <div className="col-md-6">
                  <h6 className="  mb-3">{outlet_details.name}</h6>
                </div>
                <div className="col-md-6 text-end-md">
                  <div className="d-flex align-items-center pe-3">
                    <img src={AppConfig.CDN_Media_url+"b55712e875061ec71f20129eab8b8dd9.svg"} className="w-14px" />
                    {/* <AntDesign name="clockcircleo" size={12} color="#777" /> */}
                    <p className="v-small ps-2">{outlet_details.created_at}</p>
                  </div>
                </div>

                <div className="col-md-6 mt-2">
                  <div className="d-flex align-items-center mb-2">
                    <img src={AppConfig.CDN_Media_url+"526f2ca9679fcb7d95f223e249a05bc2.svg"} className="w-14px" />
                    {/* <Ionicons name="mail-outline" size={16} color="#777" /> */}
                    <p className="ps-2 ">{outlet_details.email}</p>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <img src={AppConfig.CDN_Media_url+"8d8e3098e56fd62ddcb04f16efeb35c9.svg"} className="w-14px" />
                    {/* <Ionicons name="ios-location-outline" size={16} color="#777" /> */}
                    <p className="ps-2">{outlet_details.address}</p>
                  </div>
                </div>
                <div className="col-md-6 sm-left text-end mt-2">
                  <div className="d-flex justify-content-end sm-left mt-3">
                    <div className="d-flex align-items-center pe-3">
                      {/* <i className="fas fa-mobile-alt pe-2"></i> */}
                    <img src={AppConfig.CDN_Media_url+"a1efa10ac862390f15c065b442ca7c5c.svg"} className="w-14px" />
                      {/* <AntDesign name="mobile1" size={12} color="#777" /> */}
                      <p className="ps-2">{outlet_details.mobile}</p>
                    </div>
                    <div className="d-flex align-items-center pe-3">
                    <img src={AppConfig.CDN_Media_url+"916836b8359b4e0f50322ec7cf2d9519.svg"} className="w-14px" />
                      {/* <FontAwesome name="whatsapp" size={14} color="#777" /> */}
                      {/* <i className="fab fa-whatsapp pe-2"></i> */}
                      <p className="ps-2">{outlet_details.whatsapp}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-center-end ms-auto mt-3">
                <button
                  className="btn btn-secondary-outline btn-sm btn-radius px-3"
                  onClick={() => {
                    SetDeleteModal((prevValue) => {
                      prevValue.is_open = true;
                      prevValue.title = "Outlet";
                      prevValue.record = {};
                      prevValue.index = 0;
                      return { ...prevValue };
                    });
                  }}
                >
                  Delete
                </button>
                <div className="ms-3">
                  <button
                    className="btn bg-prime text-white btn-sm px-3 btn-radius"
                    onClick={() => {
                      SetEditOutletModal({
                        is_open: true,
                        data: {
                          address: outlet_details.address,
                          email: outlet_details.email,
                          id: id_value.outlet_id,
                          mobile: outlet_details.mobile,
                          name: outlet_details.name,
                          website: outlet_details.website,
                          whatsapp: outlet_details.whatsapp,
                        },
                        index: 0,
                      });
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* //Users tab*/}
          <div
            className={
              params.tab_value == "users"
                ? "tab-pane fade show active mt-3"
                : "tab-pane fade mt-3"
            }
            id="users"
            role="tabpanel"
            aria-labelledby="users-tab"
          >
            {outlet_user_data.length != 0 ? (
              <div className="p-3 card">
                {/* <div className="row ">
                  <div className="col-md-8 pb-2">
                    <div className="d-flex align-items-center">
                      <p className="mb-0 pe-2">Show</p>
                      <select
                        className="form-select flex-basis-20"
                        aria-label="Default select example"
                      >
                        <option selected value={10}>
                          10
                        </option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                      </select>
                      <p className="mb-0 ps-2">records per page</p>
                    </div>
                  </div>
                  <div className="col-md-4 pb-2 text-end">
                    <input
                      type="text"
                      className="form-control"
                      id="staticEmail"
                      placeholder="Search in records..."
                    />
                  </div>
                </div> */}
                <table className="table table-hover">
                  {/* <thead className="table-headers">
     {table_header.map((head, t_index) => (
       <div
         className={"custom-table-header flex-basis-" + head.width}
         key={t_index}
       >
         <p>{head.name}</p>
       </div>
     ))}
   </thead> */}
                  <tbody>

                    <div className="mt-3 d-lg-block d-md-none">
                      <div className="custom-table-row ">
                        <div className="custom-table-cell flex-basis-20">
                          <span className="mobile-column-name">
                            User Name
                          </span>
                        </div>
                        <div className="custom-table-cell flex-basis-20">
                          <span className="mobile-column-name">Roles</span>
                        </div>
                        <div className="custom-table-cell flex-basis-20">
                          <span className="mobile-column-name">
                            Custom Roles
                          </span>
                        </div>
                        <div className="custom-table-cell flex-basis-15">
                          <span className="mobile-column-name">
                            Created At
                          </span>
                        </div>
                        <div className="custom-table-cell flex-basis-20">
                          <span className="mobile-column-name">
                            Created User
                          </span>
                        </div>
                        <div className="custom-table-cell flex-basis-5 action-cell">
                          <span className="mobile-column-name">
                            Action
                          </span>
                        </div>
                      </div>
                    </div>

                    {outlet_user_data.map((data, o_index) => (
                      <div key={o_index}>
                        <div className="d-lg-block d-md-none">

                          <div className="custom-table-row border-bottom pb-2">

                            <div className="custom-table-cell flex-basis-20">
                              <div className="center-wrapper">
                                <p className="mb-0 text-darkgray ">{data.name}</p>
                                <small className="extra-small">@{data.username}</small>
                              </div>
                            </div>


                            <div className="custom-table-cell flex-basis-20">
                              {data.hasOwnProperty("roles")
                                ? data.roles.map((role_item, role_index) => (
                                  <div
                                    className="center-wrapper"
                                    key={role_index}
                                  >
                                    <p className="mb-0">{role_item.name}</p>
                                  </div>
                                ))
                                : null}
                            </div>

                            <div className="custom-table-cell flex-basis-20">
                              {data.hasOwnProperty("custom_roles")
                                ? data.custom_roles.map(
                                  (role_item, role_index) => (
                                    <div
                                      className="center-wrapper"
                                      key={role_index}
                                    >
                                      <p>{role_item.name}</p>
                                    </div>
                                  )
                                )
                                : null}
                            </div>

                            <div className="custom-table-cell flex-basis-15">
                              <div className="center-wrapper">
                                <p className="extra-small text-gray">{data.created_at}</p>
                              </div>
                            </div>

                            <div className="custom-table-cell flex-basis-20">
                              <div className="center-wrapper">
                                <p>{data.created_user}</p>
                              </div>
                            </div>
                            <div className="custom-table-cell flex-basis-5 action-cell">
                              <div className="center-wrapper cursor">
                                <div className="dropdown">
                                  <div
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                        <img src={AppConfig.CDN_Media_url+"956436fe3d3dba3b05f75e85b4bb6f41.svg"} className="w-14px" />
                                    {/* <Entypo name="dots-three-horizontal" size={16} color="#666" /> */}
                                  </div>
                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li className="dropdown-item py-2">
                                      <div
                                        className="cursor"
                                        onClick={() => {
                                          let set_data = {
                                            value: data,
                                            index: o_index,
                                          };
                                          SetModalUser({
                                            is_open: true,
                                            type: "view",
                                            data: set_data,
                                          });
                                          console.log("view :", o_index);
                                        }}
                                        style={{ marginRight: "5px" }}
                                      >
                                        <img src={AppConfig.CDN_Media_url+"0b05ea91b3f08234e5b8fae8a9c9dab6.svg"} className="w-14px" />
                                        {/* <Ionicons name="ios-eye-outline" size={16} color="#666" /> */}
                                        &nbsp; View
                                      </div>
                                    </li>
                                    {/* Edit */}
                                    <li className="dropdown-item py-2">
                                      <div
                                        className="cursor"
                                        onClick={() => {
                                          let set_data = {
                                            value: data,
                                            index: o_index,
                                          };
                                          SetModalUser({
                                            is_open: true,
                                            type: "edit",
                                            data: set_data,
                                          });
                                          console.log("Edit");
                                        }}
                                      >
                                        <img src={AppConfig.CDN_Media_url+"81a04e1294fb73a5ed341bae293944aa.svg"} className="w-14px" />
                                        {/* <AntDesign name="edit" size={16} color="#666" /> */}
                                        &nbsp; Edit
                                      </div>
                                    </li>
                                    {/* Delete */}
                                    <li className="dropdown-item py-2">
                                      <div
                                        className="cursor"
                                        onClick={() => {
                                          remove_user(data, o_index);
                                        }}
                                      >
                                        <img src={AppConfig.CDN_Media_url+"91918a3a8275a3ae8160c61ce8280d57.svg"} className="w-14px" />
                                        {/* <MaterialCommunityIcons name="delete-outline" size={16} color="#666" /> */}
                                        &nbsp; Delete
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="d-lg-none d-sm-block">

                          <div
                            className="pb-2 border-bottom"
                            onClick={(event) => {
                              sample_fun("bottom", true, event);
                              SetSelectedData({
                                value: data,
                                i: o_index,
                                type: "active",
                              });
                            }}
                          >
                            <div className="">
                              <h6 className="mb-1">{data.name}</h6>
                              <small>@{data.username}</small>
                            </div>

                            {/* {data.roles.map((role, role_index) => (
               <div className="col-6 text-end" key={role_index}>
                 <p>{role.name}</p>
               </div>
             ))} */}
                          </div>
                        </div>
                      </div>
                    ))}

                  </tbody>
                </table>
              </div>
            ) : (
              <div className="card p-3 mt-3 text-center">
              <img
                    src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img pb-2"
                  />
                <p className="mt-2">Oops no Users! Add some users.</p>
              </div>
            )}

          </div>
        </div>

        <DeleteAlert
          open={delete_modal.is_open}
          title={delete_modal.title}
          record={delete_modal.record}
          index={delete_modal.index}
          close={(data) => {
            SetDeleteModal((prevValue) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });

            if (data.action === "delete") {
              console.log("delete Outlet : ", data);
              delete_outlet(data.value, data.index);
              // delete_service(data.value, data.index);
            }
          }}
        />

        <ModalEditOutlet
          open={edit_outlet_modal.is_open}
          data={edit_outlet_modal.data}
          index={edit_outlet_modal.index}
          close={(data) => {
            console.log("Edit Outlet Modal On Close :", data);
            SetEditOutletModal({
              is_open: false,
              data: {},
              index: "",
            });
            if (data.action == "edit") {
              get_outlet_details();
              // SetOutletData((prevValue) => {
              //   prevValue[data.index] = data.value;
              //   return [...prevValue];
              // });
            }
          }}
        />

        <ModalOutletUser
          open={modal_user.is_open}
          type={modal_user.type}
          data={modal_user.data}
          close={(data) => {
            console.log(" Outlet User Modal On Close :", data);
            SetModalUser({
              is_open: false,
              type: "",
              data: {},
            });
            if (data.action == "save") {
              SetOutletUserData((prevValue) => {
                console.log("prevValue :", prevValue);
                if (prevValue == undefined) {
                  prevValue = [];
                  prevValue.push(data.value);
                } else {
                  prevValue.push(data.value);
                }
                return [...prevValue];
              });
              // SetOutletUserData(data.value);
            } else if (data.action == "edit") {
              SetOutletUserData((prevValue) => {
                prevValue[data.index] = data.value;
                return [...prevValue];
              });
            }
          }}
        />
      </div>

      {/* {["left", "right", "top", "bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            // onClose={toggleDrawer(anchor, false)}
            // onOpen={toggleDrawer(anchor, true)}
            onClose={(event) => {
              sample_fun(anchor, false, event);
            }}
            onOpen={(event) => {
              sample_fun(anchor, true, event);
            }}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))} */}
    </div>
  );
}
