import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { api } from "../../../utils/Api";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import ModalImportRoles from "./ModalImportRoles";
import { omit } from "lodash";

// const nodes = [
//   {
//     value: "brand",
//     label: "Brand",
//     children: [
//       { value: "BrandDashboard", label: "Dashboard" },

//       {
//         value: "BrandOutlets",
//         label: "Outlets",
//         children: [
//           { value: "OutletList", label: "Outlet List" },
//           { value: "CreateOutlet", label: "Create Outlet" },
//           { value: "EditOutlet", label: "Edit Outlet" },
//           { value: "DeleteOutlet", label: "Delete Outlet" },
//         ],
//       },

//       {
//         value: "UserRoles",
//         label: "User Roles",
//         children: [
//           { value: "UserRolesList", label: "User Roles List" },
//           { value: "CreateUserRoles", label: "Create User Roles" },
//           { value: "EditUserRoles", label: "Edit User Roles" },
//           { value: "DeleteUserRoles", label: "Delete User Roles" },
//         ],
//       },
//     ],
//   },

//   {
//     value: "outlet",
//     label: "Outlet",
//     children: [
//       { value: "OutletDashboard", label: "Dashboard" },
//       {
//         value: "Services",
//         label: "Services",
//         children: [
//           { value: "ServiceList", label: "Services List" },
//           { value: "CreateService", label: "Create Service" },
//           { value: "EditService", label: "Edit Service" },
//           { value: "DeleteService", label: "Delete Service" },
//         ],
//       },
//       {
//         value: "Events",
//         label: "Events",
//         children: [
//           { value: "EventList", label: "Events List" },
//           { value: "CreateEvent", label: "Create Event" },
//           { value: "EditEvent", label: "Edit Event" },
//           { value: "DeleteEvent", label: "Delete Event" },
//         ],
//       },
//       {
//         value: "Company",
//         label: "Company",
//         children: [
//           { value: "CompanyList", label: "Company List" },
//           { value: "CreateCompany", label: "Create Company" },
//           { value: "EditCompany", label: "Edit Company" },
//           { value: "DeleteCompany", label: "Delete Company" },
//         ],
//       },
//     ],
//   },
// ];

export default function FranchiseUserRolesAdd(props: any) {
  const [checkedNode, SetCheckedNode]: any = useState([]);
  const [expandedNode, SetExpandedNode] = useState(["brand", "outlet"]);

  const [id_value, SerIdValue] = useState(useParams());
  const [role_type, SetRoleType] = useState("");
  const [page_type, SetPageType] = useState("");

  const [page_data, SetPageData] = useState([]);
  const [org_page_data, SetOrgPageData] = useState([]);
  const [imported_page_data, SetImportedPageData] = useState([]);
  const [role_name, SetRoleName] = useState("");
  const [role_data, SetRoleData] = useState([]);
  const [org_role_data, SetOrgRoleData] = useState([]);
  const [import_role_modal, SetImportRoleModal] = useState({
    is_open: false,
    data: {},
  });
  const [error, SetError] = useState(false);
  const navigate = useNavigate();
  const parms: any = useParams();

  useEffect(() => {
    console.log("BrandUserRoles On mount :", props);
    console.log("id_value :", id_value);
    if (
      id_value.role_type != "brand" &&
      id_value.role_type != "outlet" &&
      id_value.role_type != "franchise"
    ) {
      navigate("/brand/" + id_value.brand_id + "/userrole");
    } else {
      SetRoleType(id_value.role_type);
    }
    if (
      id_value.page_type != "add" &&
      id_value.page_type != "edit" &&
      id_value.page_type != "view"
    ) {
      navigate("/brand/" + id_value.brand_id + "/userrole");
    } else {
      SetPageType(id_value.page_type);
    }
    if (id_value.page_type != "add") {
      get_role();
    } else {
      get_init();
    }
  }, []);

  useEffect(() => {
    SetRoleType(parms.role_type);
    if (parms.role_type == "brand") {
      SetPageData(org_page_data);
      SetRoleData(org_role_data);
    } else if (parms.role_type == "outlet") {
      org_page_data.map((ele, index) => {
        if (ele.value == "Outlet") {
          SetPageData(() => {
            let value_data = [];
            value_data.push(ele);
            return value_data;
          });
        }
      });
      org_role_data.map((ele: any, index: any) => {
        if (ele.label == "Outlet") {
          SetRoleData(() => {
            let value_data = [];
            value_data.push(ele);
            return value_data;
          });
        }
      });
    }
  }, [parms]);

  useEffect(() => {
    console.log("Useeffet role name on change :", role_name);
  }, [role_name]);
  // ----------------------------------------------------------------------(Api Call)
  // ----------------------------------------------------------------------------------------------------------------------(Get Role)
  async function get_role() {
    console.log("get Role data");
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        role_id: id_value.role_id,
      },
    };
    let data: any = await api("/brand/get_custom_role_pages", pass_data);
    console.log("get_custom_role_pages response :", data);
    if (data.status_code == 200) {
      SetRoleName(data.response.name);
      SetPageData(data.response.pages);
      SetOrgPageData(data.response.pages);
      process_imported_page_data(data.response.pages);
    }
  }
  // ----------------------------------------------------------------------------------------------------------------------(Get Init)
  async function get_init() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };

    let data: any = await api("/brand/create_userrole_init", pass_data);
    console.log("create_userrole_init response :", data);

    if (data.status_code == 200) {
      SetOrgPageData(data.response.pages);
      SetExpandedNode(getNodeIds(data.response.pages));
      let role_value = data.response.roles;
      let set_role_value: any = [];
      role_value.map((ele: any, index: any) => {
        let value: any = {};
        value.label = ele.label;
        value.options = ele.roles;
        set_role_value.push(value);
      });
      SetOrgRoleData(set_role_value);
      console.log("set_role_value :", set_role_value);

      if (parms.role_type == "brand") {
        SetPageData(data.response.pages);
        SetRoleData(set_role_value);
      } else if (parms.role_type == "outlet") {
        data.response.pages.map((ele: any, index: any) => {
          if (ele.value == "Outlet") {
            SetPageData(() => {
              let value_data = [];
              value_data.push(ele);
              return value_data;
            });
          }
        });
        set_role_value.map((ele: any, index: any) => {
          if (ele.label == "Outlet") {
            SetRoleData(() => {
              let value_data = [];
              value_data.push(ele);
              return value_data;
            });
          }
        });
      } else if (parms.role_type == "franchise") {
        data.response.pages.map((ele: any, index: any) => {
          if (ele.value == "Franchise") {
            SetPageData(() => {
              let value_data = [];
              value_data.push(ele);
              return value_data;
            });
          }
        });
        set_role_value.map((ele: any, index: any) => {
          if (ele.label == "Franchise") {
            SetRoleData(() => {
              let value_data = [];
              value_data.push(ele);
              return value_data;
            });
          }
        });
      }
    }
  }

  function import_role_modal_on_close(close_data: any) {
    console.log("ImportRoleModal on close :", close_data);
    let set_data = {
      is_open: false,
      data: [],
    };

    SetImportRoleModal(set_data);
    if (close_data.action == "save") {
      console.log("role_value close_data :", close_data.role_value);
      console.log("page_value close_data :", close_data.page_value);
      console.log("role_data :", role_data);

      SetImportedPageData(close_data.page_value);
      SetRoleName(close_data.role_value.label);
      process_imported_page_data(close_data.page_value);
    }
  }

  function process_imported_page_data(data: any) {
    console.log("process_imported_page_data data :", data);
    SetExpandedNode(getNodeIds(data));
    SetPageData(data);
    let checked_value: any = [];
    data.map((ele: any, index: any) => {
      console.log("ele :", ele);
      if (ele.checked) {
        checked_value.push(ele.value);
      }
      if (ele.hasOwnProperty("children")) {
        ele.children.map((ele_child: any, ele_child_index: any) => {
          if (ele_child.checked) {
            checked_value.push(ele_child.value);
          }
          if (ele_child.hasOwnProperty("children")) {
            ele_child.children.map((child: any, child_index: any) => {
              if (child.checked) {
                checked_value.push(child.value);
              }
            });
          }
        });
      }
    });

    console.log("checked_value :", checked_value);
    SetCheckedNode(checked_value);
  }

  function getNodeIds(nodes: any) {
    let ids: any = [];
    console.log("nodes :", nodes);
    if (nodes.hasOwnProperty("children")) {
      nodes.forEach(({ value, children }: any) => {
        ids = [...ids, value, ...getNodeIds(children)];
      });
      return ids;
    } else {
      nodes.forEach(({ value, children }: any) => {
        ids = [...ids, value];
      });
      return ids;
    }
  }

  function on_page_check(target_data: any) {
    SetError(false);
    console.log("on_page_check target_data :", target_data);
    let old_page_data: any = page_data;
    if (target_data.checked) {
      console.log("On Check");
      if (target_data.treeDepth == 0) {
        console.log(
          "target_data.treeDepth == 0 :",
          old_page_data[target_data.index]
        );
        old_page_data.map((ele: any, ele_index: any) => {
          if (ele_index == target_data.index) {
            console.log("ele : ", ele);
            ele.checked = true;
            ele.children.map((ele_child: any, ele_child_index: any) => {
              ele_child.checked = true;
              if (ele_child.hasOwnProperty("children")) {
                ele_child.children.map((child: any, child_index: any) => {
                  child.checked = true;
                });
              }
            });
          }
        });
        console.log("old_page_data :", old_page_data);
      }
      //
      if (target_data.treeDepth == 1) {
        let parent_index = find_top_parent_index(target_data.parent.value);
        console.log(
          "target_data.treeDepth == 1 :",
          old_page_data[parent_index].children[target_data.index]
        );
        old_page_data[parent_index].children.map(
          (ele_child: any, ele_child_index: any) => {
            if (target_data.index == ele_child_index) {
              ele_child.checked = true;
              if (ele_child.hasOwnProperty("children")) {
                ele_child.children.map((child, child_index) => {
                  child.checked = true;
                });
              }
            }
          }
        );

        let all_checked: any = old_page_data[parent_index].children.every(
          (p) => p.checked == true
        );
        console.log("all_checked :", all_checked);
        if (all_checked) {
          old_page_data[parent_index].checked = true;
        } else {
          old_page_data[parent_index].checked = false;
        }
        console.log("old_page_data :", old_page_data);
      }
      //
      if (target_data.treeDepth == 2) {
        let top_parent_index = find_top_parent_index(
          target_data.parent.parent_value
        );
        let parent_index = find_parent_index(
          target_data.parent.value,
          top_parent_index
        );
        console.log(
          "target_data.treeDepth == 2 :",
          old_page_data[top_parent_index].children[parent_index].children[
            target_data.index
          ]
        );
        old_page_data[top_parent_index].children[parent_index].children.map(
          (child: any, child_index: any) => {
            if (target_data.index == child_index) {
              child.checked = true;
            }
          }
        );
        let all_checked = old_page_data[top_parent_index].children[
          parent_index
        ].children.every((p) => p.checked == true);

        if (all_checked) {
          old_page_data[top_parent_index].children[parent_index].checked = true;
        } else {
          old_page_data[top_parent_index].children[parent_index].checked =
            false;
        }

        let all_checked1 = old_page_data[top_parent_index].children.every(
          (p) => p.checked == true
        );

        if (all_checked1) {
          old_page_data[top_parent_index].checked = true;
        } else {
          old_page_data[top_parent_index].checked = false;
        }
        console.log("old_page_data :", old_page_data);
      }
    } else {
      console.log("On UnCheck");
      if (target_data.treeDepth == 0) {
        console.log(
          "target_data.treeDepth == 0 :",
          old_page_data[target_data.index]
        );
        old_page_data.map((ele, ele_index) => {
          if (ele_index == target_data.index) {
            console.log("ele : ", ele);
            ele.checked = false;
            ele.children.map((ele_child, ele_child_index) => {
              ele_child.checked = false;
              if (ele_child.hasOwnProperty("children")) {
                ele_child.children.map((child, child_index) => {
                  child.checked = false;
                });
              }
            });
          }
        });
        console.log("old_page_data :", old_page_data);
      }
      //
      if (target_data.treeDepth == 1) {
        let parent_index = find_top_parent_index(target_data.parent.value);
        console.log(
          "target_data.treeDepth == 1 :",
          old_page_data[parent_index].children[target_data.index]
        );
        old_page_data[parent_index].children.map(
          (ele_child, ele_child_index) => {
            if (target_data.index == ele_child_index) {
              ele_child.checked = false;
              if (ele_child.hasOwnProperty("children")) {
                ele_child.children.map((child, child_index) => {
                  child.checked = false;
                });
              }
            }
          }
        );
        let all_checked = old_page_data[parent_index].children.every(
          (p: any) => p.checked == true
        );
        console.log("all_checked :", all_checked);
        if (all_checked) {
          old_page_data[parent_index].checked = true;
        } else {
          old_page_data[parent_index].checked = false;
        }
        console.log("old_page_data :", old_page_data);
      }
      //
      if (target_data.treeDepth == 2) {
        let top_parent_index = find_top_parent_index(
          target_data.parent.parent_value
        );

        let parent_index = find_parent_index(
          target_data.parent.value,
          top_parent_index
        );
        console.log(
          "target_data.treeDepth == 2 :",
          old_page_data[top_parent_index].children[parent_index].children[
            target_data.index
          ]
        );
        old_page_data[top_parent_index].children[parent_index].children.map(
          (child: any, child_index: any) => {
            if (target_data.index == child_index) {
              child.checked = false;
            }
          }
        );
        let all_checked = old_page_data[top_parent_index].children[
          parent_index
        ].children.every((p: any) => p.checked == true);

        if (all_checked) {
          old_page_data[top_parent_index].children[parent_index].checked = true;
        } else {
          old_page_data[top_parent_index].children[parent_index].checked =
            false;
        }

        let all_checked1 = old_page_data[top_parent_index].children.every(
          (p) => p.checked == true
        );

        if (all_checked1) {
          old_page_data[top_parent_index].checked = true;
        } else {
          old_page_data[top_parent_index].checked = false;
        }
        console.log("old_page_data :", old_page_data);
      }
    }
    SetPageData(old_page_data);
  }

  function find_top_parent_index(find_value: any) {
    let index = page_data.findIndex((p) => p.value == find_value);
    return index;
  }

  function find_parent_index(find_value: any, parent_index: any) {
    let index = page_data[parent_index].children.findIndex(
      (p: any) => p.value == find_value
    );
    return index;
  }

  async function create_user_role() {
    if (role_name !== "" && checkedNode !== "") {
      let pass_data = {
        get: {
          brand: id_value.brand_id,
        },
        post: {
          name: role_name,
          type:
            parms.role_type == "brand"
              ? 1
              : parms.role_type == "franchise"
              ? 2
              : 3,
          pages: page_data,
        },
      };
      console.log("Pass data :", pass_data);
      let data: any = await api("/brand/create_user_role", pass_data);
      console.log("create_user_role response :", data);
      if (data.status_code == 201) {
        console.log("201 created response :", data.response);
        navigate("/brand/" + id_value.brand_id + "/userrole");
      }
    } else {
      SetError(true);
    }
  }

  function get_header() {
    if (page_type == "add") {
      if (role_type == "brand") {
        return "Create Brand User Role";
      } else if (role_type == "franchise") {
        return "Create franchise User Role";
      } else if (role_type == "outlet") {
        return "Create outlet User Role";
      }
    } else if (page_type == "edit") {
      if (role_type == "brand") {
        return "Edit Brand User Role";
      } else if (role_type == "franchise") {
        return "Edit franchise User Role";
      } else if (role_type == "outlet") {
        return "Edit outlet User Role";
      }
    } else if (page_type == "view") {
      if (role_type == "brand") {
        return "View Brand User Role";
      } else if (role_type == "franchise") {
        return "View franchise User Role";
      } else if (role_type == "outlet") {
        return "View outlet User Role";
      }
    }
  }
  // ----------------------------------------------------------------------------------------------------------------------(edit_user_role)
  async function edit_user_role() {
    if (role_name !== "" && checkedNode !== "") {
      let pass_data = {
        get: {
          brand: id_value.brand_id,
        },
        post: {
          id: id_value.role_id,
          name: role_name,
          // type: parms.role_type == "brand" ? 1 : 2,
          pages: page_data,
        },
      };
      console.log("Pass data :", pass_data);
      let data = await api("/brand/edit_user_role", pass_data);
      console.log("edit_user_role response :", data);
    } else {
      SetError(true);
    }
  }

  return (
    <div>
      <div className="bg-fff py-3 px-4">
        <div className="d-flex align-items-center pb-2">
          <div className="d-flex">
            <Link to={"/brand/" + id_value.brand_id + "/userrole"} className="">
              <img
                src={require("../../../assets/icon/svg/arrow-left.svg")}
                className="me-2"
              />
            </Link>
            <h6 className="mb-0 text-dark fw-bolder">{get_header()}</h6>
          </div>
          {id_value.page_type == "add" ? (
            <div className="ms-auto cursor">
              <div
                className="btn-light-outline d-flex align-items-center text-dark cursor py-1 px-2 "
                onClick={() => {
                  let set_data = {
                    is_open: true,
                    data: role_data,
                  };
                  SetImportRoleModal(set_data);
                }}
              >
                <img
                  src={require("../../../assets/icon/svg/plus1.svg")}
                  className=" pe-2"
                />{" "}
                Import
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="container-fuild py-3">
        <div className="card p-4">
          <div className="row align-items-center">
            <div className="col-md-8 mb-3">
              <label className="mb-2">Role Name</label>
              <input
                type="text"
                name="role"
                className="form-control"
                placeholder="Your Role Name"
                value={role_name}
                required
                disabled={
                  page_type == "add" || page_type == "edit" ? false : true
                }
                onChange={(e) => {
                  SetRoleName(e.target.value);
                  if (e.target.value.length != 0) {
                    SetError(false);
                  }
                }}
              />
              {error == true ? (
                <small className="text-red">Enter role name</small>
              ) : null}
            </div>
            <div className="col-md-4 ps-md-4 mb-3 d-flex">
              <label className="me-3 mb-2 ">Role Type :</label>
              {/* {page_type == "add" ? (
                <div>
                  <div className="md-radio md-radio-inline">
                    <Link
                      to={
                        "/brand/" +
                        id_value.brand_id +
                        "/userrole/" +
                        id_value.page_type +
                        "/brand/" +
                        id_value.role_id
                      }
                    >
                      <input
                        id="radiobrand"
                        type="radio"
                        name="type"
                        value="brand"
                        disabled={true}
                        checked={role_type == "brand" ? true : false}
                      />
                      <label htmlFor="radiobrand">Brand</label>
                    </Link>
                  </div>
                  <div className="md-radio md-radio-inline">
                    <Link
                      to={
                        "/brand/" +
                        id_value.brand_id +
                        "/userrole/" +
                        id_value.page_type +
                        "/outlet/" +
                        id_value.role_id
                      }
                    >
                      <input
                        id="radiooutlet"
                        type="radio"
                        name="type"
                        value="outlet"
                        disabled={true}
                        checked={role_type == "outlet" ? true : false}
                      />
                      <label htmlFor="radiooutlet">Outlet</label>
                    </Link>
                  </div>
                </div>
              ) : (
                <div>
                  <p>{role_type == "brand" ? "Brand" : "Outlet"} </p>
                </div>
              )} */}
              <p className="text-dark fs-6 fw-bolder">
                {role_type == "brand"
                  ? "Brand"
                  : role_type == "franchise"
                  ? "Franchise"
                  : "Outlet"}{" "}
              </p>
            </div>
            {/* --------------------------------------------------------------------------------------------------------------------------(Premission)*/}
            <div className="col-lg-12">
              <label className="mb-3 mt-2 text-dark fw-bold">
                Permission Pages &amp; Actions
              </label>
              <CheckboxTree
                //   nodes={nodes}
                nodes={page_data}
                checked={checkedNode}
                expanded={expandedNode}
                onCheck={(checked, targetNode) => {
                  console.log("Checked :", checked);
                  SetCheckedNode(checked);
                  on_page_check(targetNode);
                }}
                disabled={
                  page_type == "add" || page_type == "edit" ? false : true
                }
                onClick={(targetNode) => {
                  console.log("onClick targetNode :", targetNode);
                }}
                onExpand={(expanded) => {
                  // console.log("expanded :", expanded);
                  SetExpandedNode(expanded);
                }}
                icons={{
                  check: <i className="fas fa-check-square" />,
                  uncheck: <span className="far fa-square" />,
                  halfCheck: <span className="fas fa-minus-square" />,
                  expandClose: <span className="fas fa-plus" />,
                  expandOpen: <span className="fas fa-minus" />,
                  expandAll: <span className="fas fa-minus-square" />,
                  collapseAll: <span className="fas fa-minus-square" />,
                  parentClose: <span className="fas fa-folder" />,
                  parentOpen: <span className="fas fa-folder-open" />,
                  leaf: <span className="fas fa-file" />,
                }}
              />

              {error == true ? (
                <small className="text-red">Select any one</small>
              ) : null}
            </div>
          </div>
          <hr />
          {id_value.page_type != "view" ? (
            <div className="row pt-2">
              <div className="col-6">
                {/* <Link
                  to={"/brand/" + id_value.brand_id + "/userrole"}
                  className="btn btn-light-outline px-3 ms-auto"
                >
                  Cancel
                </Link> */}
              </div>
              <div className="col-6 text-end">
                {id_value.page_type == "add" ? (
                  <button
                    className="btn btn-light-outline px-3"
                    onClick={() => {
                      create_user_role();
                    }}
                  >
                    {/* <i className="fa fa-check"></i>  */}
                    Create
                  </button>
                ) : (
                  <button
                    className="btn btn-light-outline px-3"
                    onClick={() => {
                      edit_user_role();
                    }}
                  >
                    {/* <i className="fa fa-check"></i>  */}
                    Update
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <ModalImportRoles
        open={import_role_modal.is_open}
        data={import_role_modal.data}
        close={(data: any) => {
          import_role_modal_on_close(data);
        }}
      />
    </div>
  );
}
