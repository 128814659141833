import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { api } from "../../../utils/Api";
import { Link, useParams, useOutletContext } from "react-router-dom";
import ModalCreateOutlet from "./ModalCreateOutlet";
import ModalEditOutlet from "./ModalEditOutlet";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import DeleteAlert from "../../../components/Alert/DeleteAlert";
import ModalDeleteOutlet from "./ModalDeleteOutlet";

import { AntDesign, Entypo, Feather, FontAwesome, Fontisto, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { AppConfig } from "../../../config";

const table_config = {
  key_column: "id",
  page_size: 10,
  length_menu: [10, 20, 50],
  show_filter: true,
  show_pagination: true,
  button: {
    excel: false,
    print: false,
  },
  language: {
    loading_text: "Please be patient while data loads...",
  },
};

const table_header = [
  {
    name: "Name",
    width: "30",
  },
  {
    name: "Address",
    width: "30",
  },
  {
    name: "Mobile Number",
    width: "30",
  },
  {
    name: "",
    width: "10",
  },
];

export default function FranchiseOutlet(props: any) {
  const OutletContext = useOutletContext()
  const context = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());
  const [franchise_id, SetFranchiseId] = useState();
  const [width, SetWidth] = useState(innerWidth);
  const [outlet_data, SetOutletData] = useState([]);
  const [modal_outlet, SetModalOutlet] = useState({
    is_open: false,
    data: {},
  });
  const [edit_outlet_modal, SetEditOutletModal] = useState({
    is_open: false,
    data: {},
    index: "",
  });
  const [state, setState] = React.useState({
    bottom: false,
  });
  const [selected_data, SetSelectedData] = useState({
    value: "",
    i: "",
  });
  // const [delete_modal, SetDeleteModal] = useState({
  //   is_open: false,
  //   title: "",
  //   record: {},
  //   index: "",
  // });
  const [deleteModal, SetDeleteModal] = useState({
    is_open: false,
    id: "",
  });
  const [create_outlet_modal, SetCreateOutletModal] = useState({
    is_open: false,
    data: {},
    type: "",
  });

  function sample_fun(anchor, open, event) {
    console.log("sample_fun anchor :", anchor);
    console.log("sample_fun open :", open);
    console.log("sample_fun event :", event);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      console.log("return");
      return;
    }
    console.log("toggle else");

    setState({ ...state, [anchor]: open });
    // toggleDrawer("bottom", true);
  }

  const list = (anchor) => (
    <div
      className="mt-3"
    // className={ anchor === 'top' || anchor === 'bottom'}
    // role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div>
        <div className="d-flex justify-content-center">
          <div className="w-25 px-4">
            <hr className="h-3" />
          </div>
        </div>
        <ul>
          {/* view */}
          <li className="p-2 pb-3 cursor align-center">
            <Link
              className="cursor text-dark"
              to={
                "/brand/" +
                id_value.brand_id +
                "/view_outlet/" +
                selected_data.value.id +
                "/details"
              }
            >
              <img
                src={require("../../../assets/icon/svg/eye.svg")}
                className="action_menu_img cursor"
              />
              &nbsp; View
            </Link>
          </li>
          {/* Edit */}
          {/* <li
            className="p-2 pb-3 cursor align-center"
            onClick={() => {
              SetEditOutletModal({
                is_open: true,
                data: selected_data.value,
                index: selected_data.i,
              });
              console.log("Edit");
              sample_fun(anchor, false, event);
            }}
          >
            <img
              src={require("../../../assets/icon/svg/edit-3.svg")}
              className="me-2 action_menu_img cursor"
            />
            Edit
          </li> */}
          {/* Delete */}
          {/* <li
            className="p-2 pb-3 cursor align-center"
            onClick={(event) => {
              delete_outlet(selected_data.i, selected_data.value);
              sample_fun(anchor, false, event);
            }}
          >
            <img
              src={require("../../../assets/icon/svg/trash-2.svg")}
              className="me-2 action_menu_img cursor"
            />
            Delete
          </li> */}
        </ul>
      </div>
    </div>
  );

  useEffect(() => {
    console.log("AdminOutlet On mount :", props);
    console.log("AdminOutlet context :", context);
    get_outlets();
  }, []);

  async function get_outlets() {
    SetFranchiseId(id_value.franchise_id);
    console.log(franchise_id);
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        franchise: id_value.franchise_id,
      },
    };

    if (props.hasOwnProperty("franchise_id")) {
      pass_data.get.franchise = props.franchise_id;
    }

    let response = await api("/brand/outlets", pass_data);
    console.log("/brand/outlets response :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("outlets")) {
        SetOutletData(response.response.outlets);
      } else {
        SetOutletData([]);
      }
    }
  }
  console.log(franchise_id);

  async function delete_outlet(data: any, index: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,

      },
      post: {
        outlet_id: data.id,
      },
    };
    let response = await api("/brand/delete_outlet", pass_data);
    console.log("/brand/delete_outlet response :", response);
    if (response.status_code == 200) {
      let old_outlet_data = outlet_data;
      old_outlet_data.splice(index, 1);
      SetOutletData([...old_outlet_data]);
      // get_outlets();
    }
  }

  return (

    <div>

      <div className={OutletContext ? "head-bar table-head-bar  sticky-sm" : "head-bar  sticky-sm table-head-bar-sm"}
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="row align-items-center">
          <div className="col-md-5 col-9  mb-sm-1 mb-md-0">
            <h6 className="mb-0">Outlets</h6>
          </div>

          <div className="d-sm-block d-md-none text-center col-3 mb-sm-1 mb-md-0">
            <div
              className="btn btn-custom-outline btn-radius px-3 py-1 text-white"
              onClick={() => {
                console.log("Outlet Add");
                SetCreateOutletModal((oldValues) => ({
                  ...oldValues,
                  is_open: true,
                }));
              }}
            >
              Add
            </div>
          </div>

          <div className="col-md-4 col-9 ">
            <div className="row px-md-3 md-none">
              <div className="input-icons form-group">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search products" />
                <div className="cursor ps-2 border-left">
                  <Ionicons name="md-search" size={18} color="#663fc3" />
                </div>
              </div>
            </div>
          </div>

          <div className="text-center col-md-1 col-3 px-1">
            <select className="form-control btn-radius" defaultValue="10">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>


          <div className="d-sm-none d-md-block text-center col-md-2 ">
            <div
              className="btn btn-custom-outline btn-radius px-4 btn-sm text-white"
              onClick={() => {
                console.log("Outlet Add");
                SetCreateOutletModal((oldValues) => ({
                  ...oldValues,
                  is_open: true,
                }));
              }}
            >
              Add
            </div>
          </div>


        </div>
      </div>


      <div className={OutletContext ? "table-headpage80 px-md-3 px-sm-0" : "table-page px-md-3 px-sm-0"}
        style={OutletContext == false ? { width: width } : {}}>


        <div className="row px-0">
          {outlet_data.length > 0 ? (
            outlet_data?.map((data: any, o_index: any) => (
              <div className="col-md-6 col-lg-4 px-md-2 mb-3">
                <div className="card p-3">
                  <div className="row p-0">
                    <div className="col-10">
                      <div className="d-flex align-items-center">
                        {data.image != null ? (
                          <img
                            src={"https://cdn.1superapp.com/images/320/" + data.image}
                            className="outlet-image"
                          />
                        ) : (

                          <img
                            src={require("../../../assets/icon/empty.png")}
                            className="outlet-image"
                          />
                        )}
                        <h6 className="text-black mb-0 oneline ms-2">{data.name}</h6>
                      </div>
                    </div>

                    <div className="col-2 center cursor p-0">
                      <div className="dropdown menu cursor">
                        <div className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                          <Entypo name="dots-three-vertical" size={14} color="#777" />
                        </div>

                        <ul className="dropdown-menu  dropdown-menu-start">

                          <li className="mb-2 cursor"
                            onClick={(event) => {
                              let set_data = {
                                value: data,

                              };
                              SetEditOutletModal({
                                is_open: true,
                                index: o_index,

                                data: {
                                  name: data.name,
                                  address: data.address,
                                  id: data.id,
                                  mobile: data.mobile,
                                  email: data.email,
                                  website: data.website,
                                  whatsapp: data.whatsapp,
                                  country_id: data.country_id,
                                  country_name: data.country_name,
                                  state_id: data.state_id,
                                  state_name: data.state_name,
                                  door_no: data.door_no,
                                  city: data.city,
                                  pincode: data.pincode,
                                },
                              });
                              console.log("Edit");
                            }}

                          ><div className="dropdown-item d-flex align-items-center">
                              <AntDesign name="edit" size={18} color="#555" />
                              <p className="ps-2 text-black">Edit</p>
                            </div>
                          </li>


                          <li className="cursor"
                            onClick={() => {
                              SetDeleteModal({
                                is_open: true,
                                id: data.id,
                              });
                            }}
                          >
                            <div className="dropdown-item d-flex align-items-center">
                              <MaterialIcons name="delete-outline" size={18} color="#555" />
                              <p className="ps-2 text-black">Delete</p>
                            </div>
                          </li>

                        </ul>
                      </div>
                    </div>


                    <div className="pt-2">
                      <img className="outlet-map-img"
                        src={"https://maps.google.com/maps/api/staticmap?center=" + data.latitude + "," + data.longitude + "&markers=size:mid%7Ccolor:red%7C" + data.latitude + "," + data.longitude + "zoom=150&size=800x200&sensor=false&key="+AppConfig.google_map_api}
                      />
                    </div>

                    <div className="pt-2">
                      <p className="text-black fw-bold">Address Details</p>
                      {/* <Ionicons name="location-outline" size={14} color="#777" /> */}
                      <div className="ps-1 py-2">
                        <p className="mb-1 text-darkgray v-small">{data.address}, {data.state_name}, {data.country_name} - {data.pincode}</p>
                      </div>
                    </div>

                    <div className="pt-1">
                      <p className="text-black fw-bold">Contact Details</p>

                      <div className="d-flex align-items-center pt-2">
                        <Feather name="phone-call" size={13} color="#777" />
                        <p className="ps-2 text-gray small">
                          {data.mobile}
                        </p>
                      </div>

                      <div className="d-flex align-items-center pt-2">
                        <FontAwesome name="whatsapp" size={14} color="#777" />
                        <p className="ps-2 text-gray small">
                          {data.whatsapp}
                        </p>
                      </div>

                      <div className="d-flex align-items-center pt-2">
                        <Ionicons name="md-mail-outline" size={14} color="#777" />
                        <p className="ps-2 text-gray small">
                          {data.email}
                        </p>
                      </div>


                      <div className="d-flex align-items-center pt-2">
                        <Fontisto name="world-o" size={14} color="#777" />
                        <p className="ps-2 small text-gray">
                          {data.website}
                        </p>
                      </div>

                    </div>

                    {/* <div className="col-6">
                        <div className="d-flex align-items-center pt-2">
                          <Feather name="phone-call" size={14} color="#777" />
                          <p className="ps-2 text-black fw-bold">
                            {data.mobile}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 ">
                        <div className="end pt-2">
                          <FontAwesome name="whatsapp" size={16} color="#777" />
                          <p className="ps-2 text-black fw-bold">
                            {data.whatsapp}
                          </p>
                        </div>
                      </div>

                      <div className="d-flex align-items-center pt-2">
                        <Ionicons name="md-mail-outline" size={16} color="#777" />
                        <p className="ps-2 text-gray">
                          {data.email}
                        </p>
                      </div>

                      <div className="d-flex align-items-center pt-2">
                        <Fontisto name="world-o" size={15} color="#777" />
                        <p className="ps-2 small text-gray">
                          {data.website}
                        </p>
                      </div> */}

                    <div className="pt-3 end ">
                      <p className="text-black fw-bold small">Created by</p>
                      <div className="ms-auto">
                        <p className="text-gray small">{data.created_user}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">
              <img
                src={require("../../../assets/icon/empty.png")}
                className="w-30 pb-2"
              />
              <p className="v-small mb-0">No Data, Please Add Outlet</p>
            </div>
          )}



          {/* <ModalCreateOutlet
            open={edit_outlet_modal.is_open}
            franchise_id={props.hasOwnProperty('franchise_id') ? props.franchise_id : null}
            close={(data) => {
              console.log("Create Outlet Modal On Close :", data);
              SetCreateOutletModal((oldValues) => ({
                ...oldValues,
                is_open: false,
              }));
              if (data.action == "save") {
                SetOutletData((prevValue) => {
                  console.log("prevValue :", prevValue);
                  if (prevValue == undefined) {
                    prevValue = [];
                    prevValue.push(data.value);
                  } else {
                    prevValue.push(data.value);
                  }
                  return [...prevValue];
                });
              }
            }}
          />  */}

          {/* <ModalEditOutlet
            open={edit_outlet_modal.is_open}
            data={edit_outlet_modal.data}
            index={edit_outlet_modal.index}
            close={(data:any) => {
              console.log("Edit Outlet Modal On Close :", data);
              SetEditOutletModal({
                is_open: false,
                data: {},
                index: "",
              });
              if (data.action == "edit") {
                SetOutletData((prevValue) => {
                  prevValue[data.index] = data.value;
                  return [...prevValue];
                });
              }
            }}
          />  */}
        </div>
      </div>


      {/* <DeleteAlert
        open={delete_modal.is_open}
        title={delete_modal.title}
        record={delete_modal.record}
        index={delete_modal.index}
        close={(data:any) => {
          SetDeleteModal((prevValue) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });

          if (data.action === "delete") {
            console.log("delete Outlet : ", data);
            delete_outlet(data.value, data.index);
            // delete_service(data.value, data.index);
          }
        }}
      /> */}



      <ModalDeleteOutlet
        open={deleteModal.is_open}
        id={deleteModal.id}
        close={(data: any) => {
          // console.log("Franchise modl on close :", data);
          SetDeleteModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            get_outlets();
          }
        }}
      />




      <ModalCreateOutlet
        open={create_outlet_modal.is_open}
        franchise_id={
          props.hasOwnProperty("franchise_id") ? props.franchise_id : null
        }
        close={(data: any) => {
          console.log("Create Outlet Modal On Close :", data);
          SetCreateOutletModal((oldValues) => ({
            ...oldValues,
            is_open: false,
          }));
          if (data.action == "save") {
            get_outlets();
            // SetOutletData((prevValue) => {
            //   console.log("prevValue :", prevValue);
            //   if (prevValue == undefined) {
            //     prevValue = [];
            //     prevValue.push(data.value);
            //   } else {
            //     prevValue.push(data.value);
            //   }
            //   return [...prevValue];
            // });

          }
        }}
      />
      <ModalEditOutlet
        open={edit_outlet_modal.is_open}
        data={edit_outlet_modal.data}
        index={edit_outlet_modal.index}
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          SetEditOutletModal({
            is_open: false,
            data: {},
            index: "",
          });
          if (data.action == "edit") {
            SetOutletData((prevValue) => {
              prevValue[data.index] = data.value;
              return [...prevValue];
            });
          }
        }}
      />

      {
        ["left", "right", "top", "bottom"].map((anchor) => (
          <React.Fragment key={anchor}>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              // onClose={toggleDrawer(anchor, false)}
              // onOpen={toggleDrawer(anchor, true)}
              onClose={(event) => {
                sample_fun(anchor, false, event);
              }}
              onOpen={(event) => {
                sample_fun(anchor, true, event);
              }}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))
      }
    </div >
  );
}
