import React from 'react'
import { AppConfig } from '../../config'

function NoAccessPage() {
  return (
    <div className="card p-3 text-center">
    <img
       src={
         AppConfig.CDN_Image_url +
         "e38cbe608dba40c81ec58d266c09055f.png"
       }
       className="empty-img"
     />
     <p className="v-small mb-2">You Don't Have Access To This Page!</p>
   </div>
  )
}

export default NoAccessPage