import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import "./BrandSideMenu.css";
import DataContext from "../../utils/Context/DataContext";
import {
  AntDesign,
  Entypo,
  FontAwesome,
  FontAwesome5,
  Fontisto,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";

import { useTranslation } from "react-i18next";
import LocalizationContext from "../../utils/Context/LocalizationContext";
import { AppConfig } from "../../config";
import { CupertinoPane } from "cupertino-pane";
const menu_initial_value = [
  {
    id: 1,
    name: "Dashboard",
    path: "dashboard",
    // img: <MaterialIcons name="dashboard" size={18} color="gray" />
    // require("../../assets/icon/svg/grid.svg"),
  },
  {
    id: 2,
    name: "Franchise",
    path: "franchise",
    // img: <Entypo name="shop" size={18} color="gray" />
    // require("../../assets/icon/svg/command.svg"),
  },
  {
    id: 3,
    name: "Outlets",
    path: "outlet",
    // img: <Entypo name="flow-tree" size={18} color="gray" />
    // require("../../assets/icon/svg/user-check.svg"),
  },

  {
    id: 5,
    name: "Products",
    path: "products",
    // img: <Fontisto name="shopping-bag" size={18} color="gray" />
    // require("../../assets/icon/svg/pie-chart.svg"),
  },
  {
    id: 6,
    name: "Gallery",
    path: "image_gallery",
    // img: <Fontisto name="shopping-bag" size={18} color="gray" />
    // require("../../assets/icon/svg/pie-chart.svg"),
  },
  {
    id: 13,
    name: "Product Group",
    path: "product_group",
    // img: <Fontisto name="shopping-bag" size={18} color="gray" />
    // require("../../assets/icon/svg/pie-chart.svg"),
  },
  {
    id: 7,
    name: "General Settings",
    path: "settings",
    // img:
    // <FontAwesome name="cog" size={18} color="gray" />
    // require("../../assets/icon/svg/calendar.svg"),
  },
  {
    id: 7,
    name: "user_roles",
    path: "userrole",
    // img:
    // <FontAwesome5 name="user-cog" size={18} color="gray" />,
    // require("../../assets/icon/svg/settings.svg"),
  },
  {
    id: 8,
    name: "users",
    path: "users",
    // img:
    // <FontAwesome5 name="user-friends" size={18} color="gray" />,
    // require("../../assets/icon/svg/users.svg"),
  },
  {
    id: 9,
    name: "Assign Product Group",
    // name: "assign_product",
    path: "product_group_assign",
    // img:
    // <FontAwesome5 name="user-friends" size={18} color="gray" />,
    // require("../../assets/icon/svg/users.svg"),
  },
  {
    id: 10,
    name: "Product Group Available",
    // name: "product_available",
    path: "product_group_available",
    // img:
    // <FontAwesome5 name="user-friends" size={18} color="gray" />,
    // require("../../assets/icon/svg/users.svg"),
  },

  {
    id: 11,
    name: "Product Group Pricing",
    // name: "product_pricing",
    path: "product_group_price",
    // img:
    // <FontAwesome5 name="user-friends" size={18} color="gray" />,
    // require("../../assets/icon/svg/users.svg"),
  },

  {
    id: 12,
    name: "Assign Product",
    path: "product_assign",
  },

  {
    id: 13,
    name: "Product Available",
    path: "product_available",
  },

  {
    id: 14,
    name: "Product Pricing",
    // name: "product_pricing",
    path: "product_price",
    // img:
    // <FontAwesome5 name="user-friends" size={18} color="gray" />,
    // require("../../assets/icon/svg/users.svg"),
  },
  {
    id: 14,
    name: "Addon Group List",
    path: "addon_list",
    // img:
    // <FontAwesome5 name="user-friends" size={18} color="gray" />,
    // require("../../assets/icon/svg/users.svg"),
  },
  {
    id: 15,
    name: "Addon Item List",
    path: "addon_item_list",
    // img:
    // <FontAwesome5 name="user-friends" size={18} color="gray" />,
    // require("../../assets/icon/svg/users.svg"),
  },
  {
    id: 16,
    name: "Manage Channels",
    path: "manage_channels",
    // img:
    // <FontAwesome5 name="user-friends" size={18} color="gray" />,
    // require("../../assets/icon/svg/users.svg"),
  },
  {
    id: 17,
    name: "Collections",
    path: "collections",
    // img:
    // <FontAwesome5 name="user-friends" size={18} color="gray" />,
    // require("../../assets/icon/svg/users.svg"),
  },

  {
    id: 18,
    name: "Create / Edit Products",
    path: "create_product",
    // img:
    // <FontAwesome5 name="user-friends" size={18} color="gray" />,
    // require("../../assets/icon/svg/users.svg"),
  },
  // {
  //   id: 6,
  //   name: "SAMPLE DROPDOWN",
  //   is_open: true,
  //   children: [
  //     {
  //       id: 6.1,
  //       name: "DROPDOWN1",
  //       path: "dp1",
  //     },
  //     {
  //       id: 6.2,
  //       name: "DROPDOWN2",
  //       path: "dp2",
  //     },
  //   ],
  // },
];

const menu_initial_value1 = [
  {
    id: 1,
    name: "Dashboard",
    path: "dashboard",
    // img: <MaterialIcons name="dashboard" size={18} color="gray" />
    // require("../../assets/icon/svg/grid.svg"),
  },
  // Manage Products
  {
    id: 9,
    name: "Manage Products",
    path: "manage_products",
  },
  // product
  {
    id: 4,
    name: "Products",
    is_open: true,
    children: [
      {
        id: 4.1,
        name: "Products List",
        path: "products",
      },
      {
        id: 4.2,
        name: "Assign Product",
        path: "product_assign",
      },
      {
        id: 4.3,
        name: "Product Available",
        path: "product_available",
      },
      {
        id: 4.4,
        name: "Product Pricing",
        path: "product_price",
      },
    ],
  },
  // product group
  {
    id: 5,
    name: "Product Groups",
    is_open: true,
    children: [
      {
        id: 5.1,
        name: "Product Groups List",
        path: "product_group",
      },
      {
        id: 5.2,
        name: "Assign Product Group",
        path: "product_group_assign",
      },
      {
        id: 5.3,
        name: "Product Group Available",
        path: "product_group_available",
      },
      {
        id: 5.4,
        name: "Product Group Pricing",
        path: "product_group_price",
      },
    ],
  },
  // Addon Group
  {
    id: 6,
    name: "Addon Groups",
    is_open: true,
    children: [
      {
        id: 6.1,
        name: "Addon Groups List",
        path: "addon_list",
      },
      {
        id: 6.2,
        name: "Addon Group Available",
        path: "addon_group_available",
      },
    ],
  },
  // Addon Items
  {
    id: 7,
    name: "Addon Items",
    is_open: true,
    children: [
      {
        id: 7.1,
        name: "Addon Item Available",
        path: "addon_item_list",
      },
    ],
  },
  // Manage Chanels
  {
    id: 8,
    name: "Manage Channels",
    path: "manage_channels",
    // img:
    // <FontAwesome5 name="user-friends" size={18} color="gray" />,
    // require("../../assets/icon/svg/users.svg"),
  },
  // Collections
  {
    id: 9,
    name: "Collections",
    path: "collections",
    // img:
    // <FontAwesome5 name="user-friends" size={18} color="gray" />,
    // require("../../assets/icon/svg/users.svg"),
  },
  {
    id: 10,
    name: "Qr Code",
    path: "qr_code",
  },
  // {
  //   id: 11,
  //   name: "users",
  //   path: "users",
  //   // img:
  //   // <FontAwesome5 name="user-friends" size={18} color="gray" />,
  //   // require("../../assets/icon/svg/users.svg"),
  // },
  // // {
  // //   id: 12,
  // //   name: "Settings",
  // //   path: "settings",
  // //   // img:
  // //   // <FontAwesome name="cog" size={18} color="gray" />
  // //   // require("../../assets/icon/svg/calendar.svg"),
  // // },
  // {
  //   id: 13,
  //   name: "Create / Edit Products",
  //   path: "create_product",
  // },
  // create edit product
  {
    id: 13,
    name: "Create / Edit Products",
    path: "create_product",
  },
  // {
  //   id: 14,
  //   name: "Inventory",
  //   img: require("../../assets/icon/svg/grid.svg"),
  //   is_open: true,
  //   children: [
  //     {
  //       id: 14.1,
  //       name: "Products",
  //       path: "products",
  //       img: require("../../assets/icon/svg/grid.svg"),
  //     },
  //     {
  //       id: 14.2,
  //       name: "Addons",
  //       path: "addons",
  //     },
  //   ],
  // },
  // product timing
  {
    id: 14,
    name: "Product Timing",
    path: "product_timing",
  },
  // product group timing
  {
    id: 15,
    name: "Product Group Timing",
    path: "product_group_timing",
  },

  {
    id: 16,
    name: "Settlement",
    path: "settlement",
  },
  // {
  //   id: 15,
  //   name: "Product Timing",
  //   path: "product_timing",
  // },
  // settings
  {
    id: 2,
    name: "Settings",
    is_open: true,
    children: [
      {
        id: 12,
        name: "General",
        path: "settings",
        // img:
        // <FontAwesome name="cog" size={18} color="gray" />
        // require("../../assets/icon/svg/calendar.svg"),
      },
      {
        id: 2.1,
        name: "Franchise",
        path: "franchise",
      },
      {
        id: 2.2,
        name: "Outlets",
        path: "outlet",
      },
      {
        id: 11,
        name: "users",
        path: "users",
        // img:
        // <FontAwesome5 name="user-friends" size={18} color="gray" />,
        // require("../../assets/icon/svg/users.svg"),
      },
      // {
      //   id: 12,
      //   name: "Settings",
      //   path: "settings",
      //   // img:
      //   // <FontAwesome name="cog" size={18} color="gray" />
      //   // require("../../assets/icon/svg/calendar.svg"),
      // },
      {
        id: 10,
        name: "user_roles",
        path: "userrole",
        // img:
        // <FontAwesome5 name="user-cog" size={18} color="gray" />,
        // require("../../assets/icon/svg/settings.svg"),
      },
    ],
  },
];

let url_location: any = window.location.host;
let splited_url = url_location.split(".");

export default function BrandSideMenu(props: any) {
  // const { t } = useTranslation();
  // const { t } = useContext(LocalizationContext);

  const context: any = useContext(DataContext);

  const [is_active, SetIsActive] = useState(props.active);
  const [menu_items, SetMenuItems] = useState(menu_initial_value1);
  const [user_data, SetUserData] = useState({});
  const [id_value, SerIdValue] = useState(useParams());
  // const [view_data, SetViewData] = useState({});
  const [width, SetWidth] = React.useState(innerWidth);
  const [view_data, SetViewData] = useState({});
  const [current_brand, SetCurrentBrand]: any = useState({});

  useEffect(() => {
    console.log("BrandSideMenu On mount :", props);
    console.log("AdminSideMenu On context :", context.t());
    console.log("BrandSideMenu On id_value :", localStorage.view_data);

    // SetUserData(context.app_data.user_data);
    // let user_value = localStorage.getItem("user_data");
    // SetUserData(JSON.parse(user_value));
    // let view_value = localStorage.getItem("view_data");
    // SetViewData(JSON.parse(view_value));
    let view_value = localStorage.getItem("view_data");
    SetViewData(JSON.parse(view_value));
  }, []);

  useEffect(() => {
    console.log("BrandSideMenu - Current Brand Updated:", context.app_data.current_brand);
    if (context.app_data?.current_brand?.brand_id) {
      SetCurrentBrand(context.app_data.current_brand);
    }
  }, [context.app_data.current_brand]);
  useEffect(() => {
    console.log("AdminSideMenu On props.active :", props);
    SetIsActive(props.active);
  }, [props.active]);

  function dropdown_action(m_item: any, m_index: any) {
    let items = menu_items;
    items[m_index].is_open = !m_item.is_open;
    SetMenuItems([...items]);
  }

  function dropdown_close() {
    let items = menu_items;
    items.map((item) => {
      if (item.hasOwnProperty("is_open")) {
        item.is_open = false;
      }
    });
    SetMenuItems([...items]);
  }

  return (
    <div className=" pb-5">
      <div className={is_active ? "sidebar active " : "sidebar"}>
        <div className={is_active ? "sidebar-w" : ""}>
          <div className="navbar-profile pt-sm-0 pt-md-3 py-3">
            <div className="d-sm-block d-md-none ">
              {/* <i className="fas fa-arrow-left px-3 py-2 text-white"></i> */}
              <div className="d-flex align-items-center pt-1">
                {/* <div className="pt-3 px-2 cursor">
                  <img src={AppConfig.CDN_Media_url+"f5cd7ea8e098cca1fc505dafb3c5f165.svg"} className="type-img-big" />
                </div> */}
                {/* <AntDesign name="logout" size={17} color="#777" /> */}

                {/* <div
                  className="pt-3 px-2 cursor ms-auto"
                  onClick={() => {
                    if (width < 786) {
                      props.menu_click(false);
                    }
                  }}
                >
                   <img
                  src={
                    AppConfig.CDN_Media_url +
                    "5f1c84a5b1601dde2c5c53b5fab5c150.svg"
                  }
                  className="type-img-big"
                />
            
                </div> */}
                {/* <Ionicons name="ios-close" size={20} color="#777" /> */}
              </div>
            </div>
            <div className="p-2 img-radius">
              {context?.app_data?.current_brand?.brand_profile && (
                <img src={context?.app_data?.current_brand?.brand_profile} className="prof-img" />
              )}
            </div>
            <h6 className="mb-0 pt-2">
              {context?.app_data?.current_brand?.brand_name && context?.app_data?.current_brand?.brand_name}
            </h6>
            <p className="pt-2 v-small">Version {AppConfig.version_number}</p>
          </div>
          <div className="sidebar-sticky pb-7 bg-fff">
            <ul>
              {menu_items.map((m_item, m_index) =>
                m_item.hasOwnProperty("children") ? (
                  <li className="sidebar-dropdown" key={m_index}>
                    <a
                      className={
                        m_item.is_open
                          ? "sidebar-dropdown-btn active d-flex align-items-center"
                          : "sidebar-dropdown-btn d-flex align-items-center"
                      }
                      role="button"
                      onClick={() => {
                        dropdown_action(m_item, m_index);
                      }}
                    >
                      {/* <i className="fas fa-cogs"></i>  */}
                      {context.t(m_item.name)}
                      <div className="ms-auto">
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "43fd67bad621bf93fac936e25b16c7aa.svg"
                          }
                          className="w-10px"
                        />
                        {/* <MaterialIcons
                          name="keyboard-arrow-down"
                          size={18}
                          color={m_item.is_open ? "#663fc3" : "gray"}
                        /> */}
                      </div>
                      {/* <i className="fas fa-chevron-down sidebar-dropdown-arrow"></i> */}
                    </a>
                    <div className="sidebar-dropdown-menu">
                      <ul>
                        {m_item.children.map((m_c_item, m_c_index) => (
                          <li key={m_c_index}>
                            <NavLink
                              className="link-loader"
                              activeclassname="link-active"
                              to={m_c_item.path}
                              onClick={() => {
                                // dropdown_close();
                                if (width < 786) {
                                  props.menu_click(false);
                                }
                              }}
                            >
                              {context.t(m_c_item.name)}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ) : (
                  <li key={m_index}>
                    {m_item.name != "Create / Edit Products" ? (
                      <NavLink
                        className="link-loader"
                        activeclassname="link-active"
                        to={m_item.path}
                        onClick={() => {
                          // dropdown_close();
                          if (m_item.name == "Create / Edit Products") {
                            if (AppConfig.environment == "Development") {
                              window.location.href =
                                "https://1sdev.products.1superapp.com/seller/" +
                                id_value.brand_id +
                                "/dashboard";
                            } else {
                              window.location.href =
                                "https://products.1superapp.com/seller/" +
                                id_value.brand_id +
                                "/dashboard";
                            }
                          }
                          if (width < 786) {
                            props.menu_click(false);
                          }
                        }}
                      >
                        {/* <img src={m_item.img} className="icon-img" />{" "} */}
                        {context.t(m_item.name)}
                      </NavLink>
                    ) : (
                      <a
                        className="link-loader"
                        href={
                          splited_url[0] == "1sdev"
                            ? "https://1sdev.products.1superapp.com/seller/" +
                              id_value.brand_id +
                              "/dashboard"
                            : "https://products.1superapp.com/seller/" +
                              id_value.brand_id +
                              "/dashboard"
                          // AppConfig.environment == "Development"
                          //   ? "https://1sdev.products.1superapp.com/seller/" +
                          //     id_value.brand_id +
                          //     "/dashboard"
                          //   : "https://products.1superapp.com/seller/" +
                          //     id_value.brand_id +
                          //     "/dashboard"
                        }
                        onClick={() => {}}
                      >
                        {context.t(m_item.name)}
                      </a>
                    )}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
