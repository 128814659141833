import React, { useState, useEffect, useContext } from "react";
// import ReactApexChart from "react-apexcharts";
// import Chart from "react-apexcharts";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext, Outlet } from "react-router-dom";
import { api } from "../../../utils/Api";
import toast from "react-hot-toast";
import { mobileApp } from "../../../utils/MobileApp";
let url_location: any = window.location.host;
let splited_url = url_location.split(".");

import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import Skeleton from "react-loading-skeleton";
import { AppConfig } from "../../../config";
import ModalChannelAvailable from "../../Brands/ManageChannels/ModalChannelAvailable";
import Statistics from "./Statistics";
import ModalChannelForceAvailable from "../../Brands/ManageChannels/ModalChannelForceAvailable";
import { Card } from "@material-ui/core";

export default function OutletDashboard(props: any) {
  const context: any = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [id_value, SerIdValue] = useState(useParams());
  const [outlet_data, SetOutletData] = useState([]);
  const [series, SetSeries] = useState([55, 44, 11]);
  const [notifiacation, SetNotification] = useState();
  const [is_loading, SetIsLoading] = useState(false);
  const [modal_channel_avail, SetModalChannelAvail] = useState({
    is_open: false,
    data: "",
    next_slot: "",
    type: "",
  });
  const [modal_channel_force_avail, SetModalChannelForceAvail] = useState({
    is_open: false,
    data: "",
    next_slot: "",
    type: "",
  });
  const [show_skeleton, SetShowSkeleton] = useState(true);
  const [value, SetValue] = useState({
    labels: ["Total", "delivered", "Pending"],
    chart: {
      width: 380,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      colors: ["#F44336", "#E91E63", "#9C27B0"],
    },
    markers: {
      colors: ["#F44336", "#E91E63", "#9C27B0"],
    },
    colors: [
      function ({ value, seriesIndex, w }) {
        if (value < 55) {
          return "#7E36AF";
        } else {
          return "#D9534F";
        }
      },
      function ({ value, seriesIndex, w }) {
        if (value < 111) {
          return "#E91E63";
        } else {
          return "#9C27B0";
        }
      },
    ],
    legend: {
      formatter: function (val: any, opts: any) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },
    title: {
      text: "Project Details",
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });
  const [lineseries, SetLineSeries] = useState([
    {
      name: "Sales",
      data: [5000, 8000, 10000, 25000, 40000, 50000, 100000],
    },
  ]);
  const [lineoptions, SetLineOptions] = useState({
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Salary Details",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      // max: 100,
      count: 12,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#0267ff"],
        // shadeIntensity: 1,
        type: "horizontal",
        // opacityFrom: 1,
        // opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    stroke: {
      curve: "smooth",
    },

    markers: {
      size: 0,
    },
  });
  const [width, SetWidth] = useState(innerWidth);
  const [selected_id, SetSelectedId] = useState("");

  useEffect(() => {
    console.log("OutletDashboard On mount :", props);

    console.log("OutletDashboard context :", context);
    document.addEventListener("message", putLocationFromMobile);
    if (context.app_data.pages.hasOwnProperty("15")) {
      if (context.app_data.pages[15]) {
        get_dashborad();
      } else {
        SetIsLoading(false);
      }
    } else {
      SetIsLoading(true);
    }
    // SetNotification(context.app_data.notification)
    // console.log("hi"+context.app_data.notification)
    // get_outlet_init();
  }, [context.app_data.pages[15]]);
  useEffect(() => {
    SetNotification(context.app_data.notification);
  }, [context.app_data.notification]);

  function check_availibility(index: any) {
    if (outlet_data[index].switch == "off") {
      if (
        outlet_data[index]?.force_available?.value == 2 &&
        outlet_data[index]?.unavailable?.next_slot_timestamp &&
        outlet_data[index].outlet_timing_current == false
      ) {
        SetModalChannelForceAvail((prevValue: any) => {
          prevValue.is_open = true;
          prevValue.type = "dashboard";
          prevValue.data = outlet_data[index].id;
          prevValue.next_slot = outlet_data[index].unavailable;

          return { ...prevValue };
        });
      } else {
        update_channel_available(outlet_data[index].id, { value: 1 });
      }
    } else {
      SetModalChannelAvail((prevValue: any) => {
        prevValue.is_open = true;
        prevValue.type = "dashboard";
        prevValue.data = outlet_data[index].id;
        prevValue.next_slot = outlet_data[index].unavailable;

        return { ...prevValue };
      });
    }

    // if (outlet_data[index].unavailable.value != 1) {
    // SetOutlettData((prevValue) => {
    //   console.log(prevValue[index]);
    //   // console.log(prevValue[index].channels[i].checked);

    //   prevValue[index].updated=true,

    //   prevValue[index].unavailable.value = 1;

    //   return [...prevValue];
    // });

    //   update_channel_available(outlet_data[index].id, { value: 1 });
    // } else {

    //   if(outlet_data[index].force_available.value == 2 && outlet_data[index].switch == 'off'){
    //     SetModalChannelForceAvail((prevValue: any) => {
    //       prevValue.is_open = true;
    //       prevValue.type = "dashboard";
    //       prevValue.data = outlet_data[index].id;
    //       prevValue.next_slot = outlet_data[index].unavailable;

    //       return { ...prevValue };
    //     });
    //   } else {
    //     SetModalChannelAvail((prevValue: any) => {
    //       prevValue.is_open = true;
    //       prevValue.type = "dashboard";
    //       prevValue.data = outlet_data[index].id;
    //       prevValue.next_slot = outlet_data[index].unavailable;

    //       return { ...prevValue };
    //     });
    //   }

    // SetOutlettData((prevValue) => {
    //   console.log(prevValue[index]);
    //   // console.log(prevValue[index].channels[i].checked);

    //   prevValue[index].updated=true,
    //   prevValue[index].channels[i].updated=true,
    //   prevValue[index].channels[i].unavailable.value = 1;

    //   return [...prevValue];
    // });
    // }

    // SetOutlettData((prevValue) => {
    //   console.log(prevValue[index]);
    //   // console.log(prevValue[index].channels[i].checked);

    //   prevValue[index].updated=true,
    //   prevValue[index].channels[i].updated=true,
    //   prevValue[index].channels[i].unavailable.value = 1;

    //   return [...prevValue];
    // });
  }
  // SubmitForm();

  async function get_dashborad() {
    SetIsLoading(true);
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        // company_id: id_value.company_id,
      },
    };
    let data: any = await api("/outlet/get_channels_v2", pass_data);
    console.log("Outlet dashboard response :", data.response.data);
    if (data.status_code == 200) {
      SetOutletData(data.response.data);
      SetIsLoading(false);
      SetShowSkeleton(false);
      console.log("Statistic response 1:", outlet_data);
      console.log("Statistic response 2:", data.response.statistics);
    }
  }

  // async function get_outlet_init() {
  //   let pass_data = {
  //     get: {
  //       brand: id_value.brand_id,
  //       outlet: id_value.outlet_id,
  //       // company_id: id_value.company_id,
  //     },
  //   };
  //   let data = await api("/outlet/init", pass_data);
  //   console.log("Outlet dashboard response :", data?.response?.accounts?.order_notification);
  //   SetNotification(data?.response?.accounts?.order_notification);
  //   // SetOutlettData(data.response.data);

  //   // console.log("Statistic response 1:", outlet_data);
  //   // console.log("Statistic response 2:", data.response.statistics);
  // }

  function title_generate(title: any) {
    let replaced_data = title.replaceAll("_", " ");
    var splitStr = replaced_data.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  async function noti_on(val: any) {
    {
      val == 1 ? SetNotification(true) : SetNotification(false);
    }
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        // company_id: id_value.company_id,
      },
      post: {
        notification: val,
      },
    };

    let response: any = await api("/outlet/order_notifications", pass_data);
    console.log("/outlet/order_notifications :", response);
    if (response.response.data.order_notification == true) {
      SetNotification(true);

      mobileApp({
        function: "ecomselleracceptorders",
        app_type: "react_web",
      });

      context.SetAppData((prevValue: any) => {
        prevValue.notification = true;
        return { ...prevValue };
      });
      if (!outlet_data[0]?.unavailable) {
        console.log("notification_on");

        // mobileApp({
        //   function: "ecomselleracceptorders",
        //   app_type: "react_web",
        // });
      } else {
        mobileApp({
          function: "refreshappservices",
          app_type: "react_web",
        });
      }
    } else {
      SetNotification(false);
      context.SetAppData((prevValue: any) => {
        prevValue.notification = false;
        return { ...prevValue };
      });
      mobileApp({
        function: "refreshappservices",
        app_type: "react_web",
      });
    }
    // if (response.status_code == 200) {
    //   if (response.response.status) {
    //     get_dashborad();
    //     if (type == 1) {
    //       toast.success("Product now available");
    //     } else {
    //       toast.success("Product now Unavailable");
    //       mobileApp({
    //         function: "refreshringnotifications",
    //         app_type: "react_web",
    //       });
    //     }
    //   } else {
    //       toast.error("update_channel_available status false",);
    //   }
    // }
  }

  function putLocationFromMobile(message: any) {
    if (message.data.function == "ecomselleracceptorders") {
      if (message.data.status == true) {
        // SetSelectedId((prevalue: any) => {
        //   // update_channel_available(prevalue, 1);
        //   update_channel_available(prevalue, { value: 1 });
        //   return prevalue;
        // });
      } else {
        toast.error("Permission Deined");
      }
    }
  }

  async function update_channel_available(id: any, val: any) {
    console.log(id);

    console.log(val);

    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        // company_id: id_value.company_id,
      },
      post: {
        channel_id: id,
        status: val,
      },
    };

    let response: any = await api(
      "/outlet/update_channel_available_v2",
      pass_data
    );
    console.log("/outlet/update_channel_available :", response);

    if (response.status_code == 200) {
      if (response.response.status) {
        get_dashborad();
        toast.success("Status Updated", {
          style: {
            border: "none",
            padding: "10px",
            color: "green",
            zIndex: "999",
            marginBottom: "60px",
          },
          iconTheme: {
            primary: "green",
            secondary: "#FFFAEE",
          },
        });
        if (val.value == 1) {
          // toast.success("Outlet now available", {
          //   style: {
          //     border: "none",
          //     padding: "10px",
          //     color: "green",
          //     zIndex: "999",
          //     marginBottom: "60px",
          //   },
          //   iconTheme: {
          //     primary: "green",
          //     secondary: "#FFFAEE",
          //   },
          // });
        } else {
          // toast.success("Outlet now Unavailable", {
          //   style: {
          //     border: "none",
          //     padding: "10px",
          //     color: "#713200",
          //     zIndex: "999",
          //     marginBottom: "60px",
          //   },
          //   iconTheme: {
          //     primary: "#713200",
          //     secondary: "#FFFAEE",
          //   },
          // });
          mobileApp({
            function: "refreshringnotifications",
            app_type: "react_web",
          });
        }
      } else {
        toast.error("Unable to Update Status", {
          style: {
            border: "none",
            padding: "10px",
            color: "red",
            zIndex: "999",
            marginBottom: "60px",
          },
          iconTheme: {
            primary: "red",
            secondary: "#FFFAEE",
          },
        });
      }
    }
  }

  const handleToast = () => {
    toast.error("Contact Brand Administrator to enable sales channel");
  };

  const handleOpenNewWindow = () => {
    window.open(
      splited_url[0] == "1sdev"
        ? "https://1sdev.sellers.1superapp.com/brand/" +
            id_value.brand_id +
            "/manage_channels"
        : "https://sellers.1superapp.com/brand/" +
            id_value.brand_id +
            "/manage_channels",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="mb-5">
      <div
        className={OutletContext ? "head-bar sticky-sm" : "head-bar  sticky-sm"}
        style={width < 780 ? { width: width } : {}}
      >
        {console.log("hi" + context.app_data.notification)}
        <div className="d-flex align-items-center mb-1">
          <h6 className="mb-0">Outlet Dashboard</h6>

          {/* <button ></button> */}
          {context.app_data.pages[36] && (
            <div className="ms-auto">
              {
                !notifiacation ? (
                  <div
                    onClick={() => {
                      noti_on(1);
                    }}
                  >
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "d126724437d745e7eff5a8d5e0e9954d.svg"
                      }
                      className="w-22px"
                    />
                  </div>
                ) : (
                  // <MaterialCommunityIcons name="bell-off-outline" size={18} color="gray"

                  // ></MaterialCommunityIcons>
                  <div
                    className=""
                    onClick={() => {
                      noti_on(2);
                    }}
                  >
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "2f66edad8ac9c8587e4218a816108aac.svg"
                      }
                      className="w-22px"
                    />
                  </div>
                )

                // <MaterialCommunityIcons name="bell-outline" size={18} color="#3f41c3"
                //   ></MaterialCommunityIcons>
              }
            </div>
          )}
        </div>

        {/* <Link
          to={
            "/outlet/" +
            id_value.brand_id +
            "/" +
            id_value.outlet_id +
            "/order_request/all"
          }
        >
          <button className="btn btn-light-outline ms-auto">
            Order Request
          </button>
        </Link> */}
      </div>

      <div className="pt-3 pb-2 px-md-2 row">
        {console.log(context.app_data.pages[15])}
        {/* {conte} */}

        {
          is_loading
            ? // skeleton
              [1, 2].map((ele) => (
                <div className="col-md-6 col-lg-4">
                  <div className="card p-3 mb-2">
                    <div className="d-flex align-items-center">
                      <div className="">
                        <Skeleton height={10} width={160} />
                        <div className="mt-2">
                          <Skeleton height={10} width={60} />
                        </div>
                      </div>
                      <div className="ms-auto">
                        <Skeleton height={16} width={40} borderRadius={20} />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : context.app_data.pages[15] &&
              (outlet_data?.length > 0
                ? outlet_data.map((ele: any, i) => (
                    <div
                      className="col-md-6 col-lg-4 mb-2 font-noto-sans"
                      key={i}
                    >
                      <div className="card p-3 h-100">
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="text-dark fw-bold mb-2 fs-14">
                              {ele.name}
                            </div>
                            {ele.switch === "on" ? (
                              <div className="badge-online">Online</div>
                            ) : (
                              <div className="badge-offline">Offline</div>
                            )}
                          </div>
                          <label className="pure-material-switch mb-0 mt-1 ms-auto">
                            <input
                              className="matching-group-switch"
                              type="checkbox"
                              checked={ele.switch === "on"}
                              onChange={(e) => {
                                console.log("switch :", e.target.value);
                                SetSelectedId(ele.id);
                                check_availibility(i);
                              }}
                            />
                            <span></span>
                          </label>
                        </div>
                        {ele?.unavailable_message != "" && (
                          <div className="mt-3 border-top pt-3 text-muted fs-12 px-1">
                            {ele?.unavailable_message}
                          </div>
                        )}
                        {/* {(ele.unavailable.value !== 1 && ele.unavailable.value !== 2 && ele.force_available.value === 2) && (
                                <div className="mt-3 border-top pt-3 text-muted fs-12 px-1">
                                    Currently not accepting orders, it will turn-on automatically at 
                                    {ele.force_available.on_timestamp < ele.unavailable.next_slot_timestamp ? 
                                        ele.force_available.on_time : 
                                        ele.unavailable.next_slot
                                    }
                                </div>
                            )}
                            {ele.force_available.value === 4 && (
                                <div className="mt-3 border-top pt-3 text-muted fs-12 px-1">
                                    Currently accepting orders beyond schedule, it will continuously accept orders along with upcoming schedule
                                </div>
                            )}
                            {(ele.force_available.value !== 2 && ele.force_available.time) && (
                                <div className="mt-3 border-top pt-3 text-muted fs-12 px-1">
                                    Currently accepting orders beyond schedule, it will turn-off automatically at {ele.force_available.time}
                                </div>
                            )}
                            {ele.force_available.value === 1 && (
                                <div className="mt-3 border-top pt-3 text-muted fs-12 px-1">
                                    Currently accepting orders beyond schedule
                                </div>
                            )} */}
                      </div>
                    </div>
                  ))
                : context.app_data.pages.hasOwnProperty("35") &&
                  (context.app_data.pages[35] ? (
                    <div
                      className="card p-3 h-100"
                      onClick={handleOpenNewWindow}
                      style={{ cursor: "pointer" }}
                    >
                      Enable sales channel(s) for this Outlet
                    </div>
                  ) : (
                    <div
                      className="card p-3 h-100"
                      onClick={handleToast}
                      style={{ cursor: "pointer" }}
                    >
                      Enable sales channel(s) for this Outlet
                    </div>
                  )))
          //  : (
          //     toast.error("Contact Brand Administrator to enable sales channel")
          // )
        }
      </div>
      {/* {context.app_data.pages[15] || context.app_data.pages[20] || context.app_data.pages[21] &&  */}
      <Statistics />
      {/* }  */}

      {/* <div className="table-headpage80 px-md-3 px-sm-0"
          style={width < 786 ? (
            OutletContext == false ? { width: width } : {}
          ) : (
            OutletContext == false ? { width: width, paddingTop: "3rem" } : {}
          )} >


          
            </div>  */}
      <ModalChannelAvailable
        open={modal_channel_avail.is_open}
        data={modal_channel_avail.data}
        next_slot={modal_channel_avail.next_slot}
        type={modal_channel_avail.type}
        // type={modal_new_custom.type}
        close={(data: any) => {
          console.log("Franchise modl on close :", data);
          SetModalChannelAvail((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            if (data.type == "dashboard") {
              update_channel_available(data.data, data.value);
            }

            // get_custom_channels();
          }
        }}
      />
      <ModalChannelForceAvailable
        open={modal_channel_force_avail.is_open}
        data={modal_channel_force_avail.data}
        next_slot={modal_channel_force_avail.next_slot}
        type={modal_channel_force_avail.type}
        // type={modal_new_custom.type}
        close={(data: any) => {
          SetModalChannelForceAvail((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            if (data.type == "dashboard") {
              update_channel_available(data.data, data.value);
            }

            // get_custom_channels();
          }
        }}
      />
    </div>
  );
}
