import getWindow from './getWindow';
import getOffset from './getOffset';
export default (function (node, client) {
  var win = getWindow(node);

  if (win) {
    return win.innerHeight;
  }

  return client ? node.clientHeight : getOffset(node).height;
});