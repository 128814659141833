import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { api } from "../../../utils/Api";
import { Link, useParams, useOutletContext } from "react-router-dom";
import ModalCreateCollection from "./ModalCreateCollection";
import { Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { use } from "i18next";


export default function OutletCollection(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [grid_view, SetGridView] = useState(false);
  const [list_view, SetListView] = useState(true);
  const [id_value, SerIdValue] = useState(useParams());
  const [modal_collection, SetModalCollection] = useState({
    is_open: false,
    data: "",
    type: ""
  });
  const [width, SetWidth] = useState(innerWidth);


  useEffect(() => {
    console.log("Collection On mount :", props);
    console.log("Collection context :", context);
  }, []);


  return (
    <div className="">
      <div className={OutletContext ? "head-bar  sticky-sm" : "head-bar  sticky-sm"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className=" row align-items-center">
          <div className="col-md-4 col-7 sm-mb-2">
            <h6 className="mb-0">Collection</h6>
          </div>
          <div className="col-5 ps-0 d-sm-block d-md-none text-end sm-mb-2">
            <button className="btn btn-custom-outline px-3 btn-sm"
              onClick={() => {
                console.log("Sector new");
                SetModalCollection((prevValue: any) => {
                  prevValue.is_open = true;
                  prevValue.type = "create";
                  return { ...prevValue }
                })
              }}
            >Create New</button>
          </div>
          {/* <div className="col-md-1 col-3">
            <div className="d-flex">
              <div className="px-2 cursor" onClick={() => {
                SetListView(true);
                SetGridView(false);
              }}>
                <MaterialCommunityIcons name="format-list-text" size={20} color={list_view ? "#056aff" : "gray"} />
              </div>
              <div className="px-2 cursor" onClick={() => {
                SetListView(false);
                SetGridView(true);
              }}>
                <MaterialCommunityIcons name="view-grid-outline" size={20} color={grid_view ? "#056aff" : "gray"} />
              </div>
            </div>
          </div> */}
          <div className="col-md-5 col-7 ">
            <div className="row px-md-3">
              <div className="input-icons form-group">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search products"
                />
                <div className="cursor ps-2 border-left">
                  <Ionicons name="md-search" size={18} color="#663fc3" />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center col-md-1 col-2 p-0">
            <select className="form-control btn-radius" defaultValue="10">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
          <div className="d-sm-none d-md-block text-center col-md-2 ">
            <button
              className="btn btn-custom-outline px-3 btn-sm"
              onClick={() => {
                console.log("Sector new");
                SetModalCollection((prevValue: any) => {
                  prevValue.is_open = true;
                  prevValue.type = "create";
                  return { ...prevValue }
                })
              }}
            >
              Create New
            </button>
          </div>
        </div>
      </div>

      <div className={OutletContext ? "mt-3 px-md-3 px-sm-0" : "mt-3 mt-sm-3 mt-md-0 px-md-3 px-sm-0 mb-5"}
        style={OutletContext == false ? { width: width } : {}} >
        {/* {list_view ? (
          <div className="mt-4 bg-fff p-3">
            <Link to={"/brand/" + id_value.brand_id + "/viewOutletCollection"}>
              <div className="p-2 row list border-bottom">
                <div className="col-md-4">
                  <h6 className="mb-1">Collection 1</h6>
                  <small>Collection type</small>
                </div>
                <div className="col-md-3">
                  <p className="mb-0">Samayasangali, Pallipalayam, Namakkal, Tamilnadu - 638008</p>
                </div>
                <div className="col-md-3">
                  <small className="text-dark">GST</small>
                  <p className="mb-0">ACV4565F565E</p>
                </div>
                <div className="col-md-2">
                  <p className="mb-0">9638527410</p>
                </div>
              </div>
            </Link>
            <Link to={"/brand/" + id_value.brand_id + "/viewOutletCollection"}>
              <div className="p-2 row list  border-bottom">
                <div className="col-md-4">
                  <h6 className="mb-1">Collection 2</h6>
                  <small>Collection type</small>
                </div>
                <div className="col-md-3">
                  <p className="mb-0">Pallipalayam, Namakkal, Tamilnadu</p>
                </div>
                <div className="col-md-3">
                  <small className="text-dark">GST</small>
                  <p className="mb-0">ACV4565F565E</p>
                </div>
                <div className="col-md-2">
                  <p className="mb-0">9638527410</p>
                </div>
              </div>
            </Link>

            <div className="pagination:container text-end mt-4">
              <div className="pagination:number arrow">
                <MaterialIcons name="keyboard-arrow-left" size={24} color="black" />
              </div>

              <div className="pagination:number">
                1
              </div>
              <div className="pagination:number">
                2
              </div>

              <div className="pagination:number pagination:active">
                3
              </div>

              <div className="pagination:number">
                ..
              </div>

              <div className="pagination:number">
                20
              </div>

              <div className="pagination:number arrow">
                <MaterialIcons name="keyboard-arrow-right" size={24} color="black" />
              </div>
            </div>

          </div>
        ) : (null)} */}



        {/* {grid_view ? ( */}
          <div className="mt-4">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Link to={"/brand/" + id_value.brand_id + "/viewOutletCollection"}>
                <div className="card p-3">
                  <h6 className="mb-1">Channels 1</h6>
                  <p><small>Channels type</small></p>
                  <p className="text-darkgray my-2">GST : ACV4565F565E</p>

                  <div className="d-flex align-item-center my-1">
                    <Ionicons name="md-location-sharp" size={16} color="gray" />
                    <p className="ms-1  ">Pallipalayam, Namakkal, Tamilnadu</p>
                  </div>
                  <div className="d-flex align-item-center my-1">
                    <Ionicons name="call" size={13} color="gray" />
                    <p className="ms-1">9638527410</p>
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
        {/* ) : (null)} */}
      </div>


      <ModalCreateCollection
        open={modal_collection.is_open}
        type={modal_collection.type}
        close={(data: any) => {
          console.log("ModalCreateCollection on close :", data)
          SetModalCollection((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.type = "create";
            return { ...prevValue }
          })
        }}
      />

    </div>


  );
}
