import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../../utils/Api";
import toast from "react-hot-toast";
import { AppConfig } from "../../../config";

const outlet_initial_value = {
  name: "",
};

let myModal = {};

export default function ModalOrderCollection(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    data : [],
  });
  const [collection_list, SetCollectionList] = useState([]);
  const [id_value, SerIdValue] = useState(useParams());

  useEffect(() => {
    console.log("ModalOrderCollection On mount :", props);
    let myModal1: HTMLElement | null = document.getElementById(
      "ModalOrderCollection"
    );
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModalOrderCollection On mount :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("ModalOrderCollection"),
        {}
      );
      SetCollectionList(props.data);
      myModal.show();
    }
  }, [props.open]);

  const moveUp = (index: any) => {
    if (index === 0) return; // Can't move the first item up
    SetCollectionList((prev) => {
      const updated = [...prev];
      [updated[index - 1], updated[index]] = [
        updated[index],
        updated[index - 1],
      ];
      return updated;
    });
  };

  const moveDown = (index: any) => {
    if (index === collection_list.length - 1) return; // Can't move the last item down
    SetCollectionList((prev) => {
      const updated = [...prev];
      [updated[index], updated[index + 1]] = [
        updated[index + 1],
        updated[index],
      ];
      return updated;
    });
  };

  async function save_order_change(get_data: any) {
    console.log("change_order_collection_list :", collection_list);
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        view_order: get_data.view_order_data,
      },
    };
    console.log("pass_data :", pass_data);
    let response: any = await api(
      "/brand/change_collection_view_order",
      pass_data
    );
    console.log(
      "/brand/change_collection_view_order response :",
      response.response
    );
    if (response.status_code == 200) {
      let collection_data_value = response.response.data;
      // let collection_data_value1 = response.response.data;
      // SetCollectionData(collection_data_value);
      // collection_data_value1.map((ele: any) => {
      //   ele.checked = false;
      // });
      // SetChangeOrderCollectionList(collection_data_value1);
      SetCollectionList((prevalue)=>{
        
        const filteredArray = collection_data_value.filter(item2 => prevalue.some(item1 => item1.id === item2.id));
        const filteredAndOrderedArray = filteredArray
        .filter(item => item.view_order !== 0)
        .sort((a, b) => a.view_order - b.view_order);
        console.log("SetCollectionList :",prevalue)
        console.log("collection_data_value response :",collection_data_value)
        console.log("filteredAndOrderedArray :",filteredAndOrderedArray)

        const idList = filteredAndOrderedArray.map(item => item.id);
        const matchedItems = prevalue.filter(item => idList.includes(item.id));

        console.log("matchedItems :",matchedItems)

        let item = close_data;
        item.action = "save";
        item.data = matchedItems;
        SetCloseData(item);
        myModal.hide();
        return [...prevalue]
      })
    }
  }

  return (
    <div
      className="modal fade"
      id="ModalOrderCollection"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      // data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Change Collection Order
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>

          <div className="mt-3 p-3">
            <div className="row">
              {collection_list?.map((val: any, i: any) => (
                <div className="col-md-12 col-lg-12 md-ps-0 sm-px-3">
                  <div className="card mb-3">
                    <div className="d-flex p-3 align-items-center">
                      <p className="ms-2 text-black fs-7 fw-bold">{val.name}</p>
                      <div className="ms-auto">
                        {i != 0 && (
                          <button
                            className="btn btn-white btn-radius btn-sm me-2"
                            onClick={() => {
                              console.log("Up");
                              moveUp(i);
                            }}
                          >
                            {/* Up */}
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "97107c2e91a53a7d0f0f48fd5ef3ae72.svg"
                              }
                              className="w-14px"
                            />
                          </button>
                        )}

                        {i != collection_list.length - 1 && (
                          <button
                            className="btn btn-white btn-radius btn-sm me-2"
                            onClick={() => {
                              console.log("Down");
                              moveDown(i);
                            }}
                          >
                            {/* Down */}
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "2e7dca010109a098afb7bcc4a2f66f28.svg"
                              }
                              className="w-14px"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm btn-radius px-3"
              onClick={(event) => {
                let send_data: any = {};
                let view_order_data : any = [];
                SetCollectionList((prevalue) => {
                  prevalue.map((ele: any, i: any) => {
                    let push_data = {
                      id: ele.id,
                      view_order: i + 1,
                    };
                    view_order_data.push(push_data)
                  });
                  send_data.view_order_data = view_order_data;
                  save_order_change(send_data);
                  return [...prevalue];
                });
              }}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm btn-radius px-3"
              onClick={(event) => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
