import getWindow from './getWindow';
export default (function (node, val) {
  var win = getWindow(node);
  var left = node.scrollLeft;
  var top = 0;

  if (win) {
    left = win.pageXOffset;
    top = win.pageYOffset;
  }

  if (val !== undefined) {
    if (win) {
      win.scrollTo(val, top);
    } else {
      node.scrollLeft = val;
    }
  }

  return left;
});