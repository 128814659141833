import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import Skeleton from "react-loading-skeleton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { AppConfig } from "../../../config";
import VisibilitySensor from "react-visibility-sensor";
import { AppHelper } from "../../../utils/Helper";
import * as Scroll from "react-scroll";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import ManageProductsComponent from "../../../components/ManageProductsComponent/ManageProductsComponent";

export default function OutletManageProducts(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [collection_data, SetCollectionData] = useState([]);
  const [width, SetWidth] = useState(innerWidth);
  const [id_value, SerIdValue] = useState(useParams());
  const [outlet_list, SetOutletList]: any = useState([]);
  const [channel_list, SetChannelList]: any = useState([]);
  const [selected_outlet, SetSelectedOutlet]: any = useState({});
  const [selected_channel, SetSelectedChannel]: any = useState({});
  const [main_data, SetMainData]: any = useState([]);
  const [is_loading, SetIsLoading] = useState(true);

  useEffect(() => {
    console.log("Collections1 on mount:", props);
    get_outlets_and_channels();
  }, []);

  async function get_outlets_and_channels() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let response: any = await api("/brand/outlet_channel_list", pass_data);
    console.log("/brand/outlet_channel_list response :", response.response);
    if (response.status_code == 200) {
      let outlet_data: any = response.response.outlets;
      outlet_data.map((ele: any, index: any) => {
        ele.lable = ele.name;
        ele.value = ele.id;
        if (id_value.outlet_id == ele.id) {
          SetSelectedOutlet(ele);
          ele.channels.map((c_ele: any, c_index: any) => {
            c_ele.lable = c_ele.name;
            c_ele.value = c_ele.id;
            if (c_index == 0) {
              SetSelectedChannel(c_ele);
            }
          });
          SetChannelList(ele.channels);
        }
      });
      SetOutletList(outlet_data);
      get_collections();
    }
  }

  async function get_collections() {
    SetIsLoading(true);
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        direct: 1,
      },
    };
    let response: any = await api("/brand/collections", pass_data);
    console.log("/brand/collections response :", response.response);
    if (response.status_code == 200) {
      process_main_data(response.response.data);
    }
  }

  function process_main_data(get_data: any) {
    console.log(get_data)
    let collection_value = get_data;
    let save_data: any = [];
    collection_value.map((ele: any) => {
      let push_data = {
        collection_id: ele.id,
        collection_parent_id: ele.parent_id,
        collection_name: ele.name,
        have_produts: false,
        products_loading: false,
        products: [],
        is_viewed: false,
        show_skeleton: true,
      };
      // save_data[ele.name] = push_data;
      save_data.push(push_data);
    });
    // save_data["More"] = {
    //   collection_id: 0,
    //   collection_parent_id: 0,
    //   collection_name: "more",
    //   have_produts: false,
    //   products_loading: false,
    //   products: [],
    //   is_viewed: false,
    //   show_skeleton: true,
    // };
    save_data.push({
      collection_id: 0,
      collection_parent_id: 0,
      collection_name: "More",
      have_produts: false,
      products_loading: false,
      products: [],
      is_viewed: false,
      show_skeleton: true,
    });
    console.log("save_data : ", save_data);
    console.log("save_data length: ", Object.keys(save_data).length);
    SetMainData(save_data);
    SetIsLoading(false);
  }

  return (
    <div className="">
      <div
        className={OutletContext ? "head-bar sticky-sm py-3" : "head-bar py-3"}
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="row align-items-center">
          <div className="col-md-5">
            <h6 className="fs-5  mb-0">Manage Products</h6>
          </div>
          {/* create new */}
          {/* <div className="col-7 px-0 text-end ">
            <button
              className="btn bg-prime text-white btn-radius px-3 btn-sm"
              onClick={() => {
                console.log("Franchise new");
                SetModalNewCustom((prevValue: any) => {
                  prevValue.is_open = true;
                  prevValue.type = "create";
                  prevValue.data = "";
                  return { ...prevValue };
                });
              }}
            >
              Create New
            </button>
          </div> */}
          {/* outlet / channel */}
          <div className=" col-md-7 px-0 pt-sm-2 pt-md-0">
            <div className="row">
              <div className="col-6 pe-1 ps-0 ">
                <div className="select-wrap">
                  <label className="item-center">Outlet</label>
                  <select
                    data-show-content="true"
                    className="form-control product-timing-location"
                    id="exampleFormControlSelect1"
                    value={selected_outlet.id}
                    disabled={true}
                    onChange={(e) => {
                      console.log("on change :", e.target.value);
                      let value_data = e.target.value;
                      SetOutletList((prevalue: any) => {
                        prevalue.map((ele: any, index: any) => {
                          if (ele.id == value_data) {
                            SetSelectedOutlet(ele);
                            ele.channels.map((c_ele: any, c_index: any) => {
                              if (c_index == 0) {
                                SetSelectedChannel(c_ele);
                              }
                            });
                            SetChannelList(ele.channels);
                          }
                        });
                        return [...prevalue];
                      });
                      get_collections();
                    }}
                  >
                    {outlet_list?.map((ele: any, i: any) => (
                      <option key={i} value={ele?.id}>
                        {ele?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-6 ps-1 pe-0">
                <div className="select-wrap">
                  <label className="item-center">Channel</label>
                  <select
                    data-show-content="true"
                    className="form-control product-timing-location"
                    id="exampleFormControlSelect1"
                    value={selected_channel.id}
                    onChange={(e) => {
                      console.log("on change :", e.target.value);
                      let value_data = e.target.value;
                      SetChannelList((prevalue: any) => {
                        prevalue.map((ele: any) => {
                          if (ele.id == value_data) {
                            SetSelectedChannel(ele);
                          }
                        });
                        return [...prevalue];
                      });
                      get_collections();
                    }}
                  >
                    {channel_list?.map((ele: any, i: any) => (
                      <option key={i} value={ele?.id}>
                        {ele?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          OutletContext ? "mt-3 px-md-3 px-sm-0" : "mt-3 px-md-3 px-sm-0"
        }
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="mt-3 mb-5">
          {is_loading ? (
            // skeleton
            <div className="col-md-6">
              {Array.from({ length: 9 }).map((_, i) => (
                <div className="row md-ps-0 sm-px-3" key={i}>
                  <div className="card px-2 py-1 mb-3">
                    <div className="px-0">
                      <div className="px-1">
                        <div className="d-flex align-items-center mt-3">
                          <p className="ms-2 text-black fs-7 fw-bold">
                            <Skeleton width={100} />
                          </p>
                          <div className="d-flex align-items-center ms-auto">
                            <div className="cursor me-4">
                              <Skeleton width={14} height={14} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-1">
                        <div className="w-100">
                          <div className="mt-3 row">
                            {[1, 2, 3, 4, 5].map((sk_ele) => (
                              <div className="mt-3 col-12">
                                <Skeleton width={"100%"} height={80} />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : Object.keys(main_data).length > 0 ? (
            <ManageProductsComponent
              selected_outlet={selected_outlet}
              selected_channel={selected_channel}
              main_data={main_data}
            />
          ) : (
            <div className="text-center">
              <img
                src={require("../../../assets/icon/empty.png")}
                className="empty-img pb-2"
              />
              <p className="v-small mb-0">No Data, Please Add Collections</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
