import React, { useState, useEffect } from "react";
// import bootstrap from "bootstrap/dist/js/bootstrap";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
import { api } from "../../utils/Api";
// import { omit } from "lodash";
import { useParams } from "react-router-dom";

// const animatedComponents = makeAnimated();

const initial_value = {
  name: "",
  is_org: false,
  selected_org: {},
};

let myModal = {};

export default function ModalCreateBrand(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [save_data, SetSaveData] = useState(initial_value);
  // const [brand_id, SetBrandId] = useState(7);
  // const [outlet_id, SetOutletId] = useState(13);
  const [id_value, SerIdValue] = useState(useParams());

  const [organization_data, SetOrganizationData] = useState([]);

  // useEffect(() => {
  //   console.log("ModalCreateBrand On mount :", props);
  //   let myModal1 = document.getElementById("CreateBrandModal");
  //   myModal1.addEventListener("hidden.bs.modal", function (event) {
  //     console.log("event :", event);
  //     props.close(close_data);
  //   });
  // }, []);

  // useEffect(() => {
  //   if (props.open) {
  //     console.log("ModalCreateBrand On mount :", props);

  //     myModal = new bootstrap.Modal(
  //       document.getElementById("CreateBrandModal"),
  //       {}
  //     );
  //     myModal.show();
  //     get_init();
  //   }
  // }, [props.open]);

  // async function get_init() {
  //   let response = await api("/account/signup_init");
  //   console.log("signup_init :", response);
  //   SetOrganizationData(response.response.organization);
  // }

  // async function create_brand() {
  //   console.log("create_brand");
  //   let post_value = {
  //     name: save_data.name,
  //   };
  //   if (save_data.is_org) {
  //     post_value.organization = save_data.selected_org.value;
  //   }
  //   let pass_data = {
  //     post: post_value,
  //   };
  //   console.log("pass_data :", pass_data);
  //   let response = await api("/account/signup", pass_data);
  //   console.log("/account/signup post response :", response);
  //   if (response.status_code == 201) {
  //     let item = close_data;
  //     item.action = "save";
  //     item.value = response.response.id;
  //     console.log("Close data : ", item);
  //     SetCloseData(item);
  //     SetSaveData(initial_value);
  //     myModal.hide();
  //   }
  // }

  return (
    <div
      className="modal fade"
      id="CreateBrandModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Create Brand
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              // onClick={() => {
              //   let item = close_data;
              //   item.action = "close";
              //   SetCloseData(item);
              //   myModal.hide();
              // }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="mt-3">
              {/* <form className="row g-3">
                <div className="col-12">
                  <label className="form-label">Brand Name</label>
                  <input
                    type="text"
                    className="form-control"
                    minLength={5}
                    required
                    value={save_data.name}
                    onChange={(e) => {
                      SetSaveData((oldValues) => ({
                        ...oldValues,
                        name: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="form-check me-3">
                  <label className="form-check-label">
                    <input
                      className="form-check"
                      type="checkbox"
                      checked={save_data.is_org}
                      id="flexCheck"
                      onChange={(e) => {
                        SetSaveData((oldValues) => ({
                          ...oldValues,
                          is_org: !oldValues.is_org,
                        }));
                      }}
                    />
                    <p>Is Organization Brand</p>
                  </label>
                </div>

                {save_data.is_org ? (
                  <div className="col-12">
                    <label className="form-label">Organization</label>
                    <Select
                      components={animatedComponents}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={organization_data}
                      onChange={(e) => {
                        SetSaveData((oldValues) => ({
                          ...oldValues,
                          selected_org: e,
                        }));
                      }}
                    />
                  </div>
                ) : null}
              </form> */}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              // onClick={() => {
              //   let item = close_data;
              //   item.action = "close";
              //   SetCloseData(item);
              //   myModal.hide();
              // }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              // onClick={(event) => {
              //   console.log("CreateBrandModal on Save");
              //   create_brand();
              // }}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
