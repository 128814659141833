import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// import bootstrap from "bootstrap/dist/js/bootstrap";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import { omit } from "lodash";
import { useWizard, Wizard } from "react-use-wizard";
import ModalLocation from "./ModalLocation";
import ModalAddGallery from "../Gallery/ModalAddGallery";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import toast from "react-hot-toast";
import { AppHelper } from "../../../utils/Helper";

import LocationPicker from "../../../components/LocationPicker/LocationPicker";

const animatedComponents = makeAnimated();

const outlet_initial_value = {
  id: "",
  name: "",
  address: "",
  map_address: "",
  mobile: "",
  whatsapp: "",
  email: "",
  website: "",
  country_name: "",
  country: "",

  state: "",
  state_name: "",

  door_no: "",
  city: "",
  pincode: "",
  lat: "",
  lng: "",
  image_url: "",
  image: "",
  banner_image_url: "",
  banner_image: "",
  approx_preparation_time: "",
  cod: "0",
  latitude: "",
  longitude: "",
};

let myModal: any = {};

export default function EditBrandOutlet(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [outlet_data, SetOutletData] = useState(outlet_initial_value);
  const [errors, setErrors]: any = useState({});
  const [countries_options, SetCountriesOptions] = useState([]);
  const [states, SetStates] = useState([]);
  const [location_data, SetLocationData] = useState([]);
  const [data, SetData] = useState([]);

  // const [mapModal, SetMapModal] = useState({
  //   is_open: false,
  //   data: {},
  //   // id: "",
  // });

  const [modal_banner, SetModalBanner] = useState({
    is_open: false,
    selected_banner: [],
  });

  const [modal_image, SetModalImage] = useState({
    is_open: false,
    selected_images: [],
  });

  // const [mapModal, SetMapModal] = useState({
  //   is_open: false,
  //   latitude: "",
  //   longitude: "",
  //   map_address: "",
  //   // id: "",
  // });

  const [map_modal, SetMapModal] = useState({
    is_open: false,
    data: {},
    from: "",
    latitude: "",
    longitude: "",
    address:"",
  });

  useEffect(() => {
    console.log("ModalEditOutlet On mount :", props);
    console.log("ModalEditOutlet On mount :", id_value);

    get_details();

    get_init();
    get_states();

    // let myModal1 : any = document.getElementById("EditOutletModal");
    // myModal1.addEventListener("hidden.bs.modal", function (event : any) {
    //   console.log("event :", event);
    //   SetOutletData(outlet_initial_value);
    //   props.close(close_data);
    // });
  }, []);

  // useEffect(() => {
  //   if (props.open) {
  //     console.log("ModalEditOutlet On mount :", props);

  //     myModal = new bootstrap.Modal(
  //       document.getElementById("EditOutletModal"),
  //       {}
  //     );

  //     let set_value = {
  //       id: props.data.id,
  //       name: props.data.name,
  //       address: props.data.address,
  //       mobile: props.data.mobile,
  //       whatsapp: props.data.whatsapp,
  //       email: props.data.email,
  //       website: props.data.website,
  //       country_name: props.data.country_name,
  //       country: props.data.country_id,

  //       state: props.data.state_id,
  //       state_name: props.data.state_name,

  //       door_no: props.data.door_no,
  //       city: props.data.city,
  //       pincode : props.data.pincode,
  //     };
  //     SetOutletData(set_value);

  //     myModal.show();

  //     get_init();
  //     get_states();

  //     // get_states();
  //   }
  // }, [props.open]);
  // {console.log(myModal)}
  function outlet_value_on_change(name: any, value: any) {
    validate(name, value);
    SetOutletData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  async function edit_outlet() {
    console.log("edit_outlet data :", outlet_data);
    if (outlet_data.name !== "") {
      let post_value = {
        name: outlet_data.name,

        mobile: outlet_data.mobile,
        whatsapp: outlet_data.whatsapp,
        email: outlet_data.email,
        website: outlet_data.website,

        country: outlet_data.country,

        state: outlet_data.state ? outlet_data.state : "",
        door_no: outlet_data.door_no,
        city: outlet_data.city,
        pincode: outlet_data.pincode,
        image: outlet_data.image ? outlet_data.image : "",
        banner_image: outlet_data.banner_image ? outlet_data.banner_image : "",
        latitude: outlet_data.latitude,
        longitude: outlet_data.longitude,
        address: outlet_data.address,
        map_address: outlet_data?.map_address,
        approx_preparation_time: outlet_data.approx_preparation_time,
        cod: outlet_data.cod,
      };

      // {
      //   map_modal.latitude &&
      //     ((post_value.latitude = map_modal.latitude),
      //     (post_value.longitude = map_modal.longitude));
      // }

      let pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: outlet_data.id,
        },
        post: post_value,
      };
      console.log("pass_data :", pass_data);

      let data_res : any = await api("/brand/edit_outlet", pass_data);

      console.log("edit_outlet response :", data_res.response);
      console.log("edit_outlet response :", data_res.status_code);
      if (data_res.status_code == 200) {
        toast.success("Outlet Updated");
      }
    }
    // if (data_res.status_code == 201) {
    //   let item = close_data;
    //   item.action = "save";
    //   let outlet_value = {
    //     id: data_res.response.id,
    //     name: outlet_data.name,
    //     address: outlet_data.address,
    //     mobile: outlet_data.mobile,
    //   };
    //   item.value = outlet_value;
    //   console.log("Close data : ", item);
    //   SetCloseData(item);
    //   SetOutletData(outlet_initial_value);
    //   myModal.hide();
    // }
  }

  async function get_init() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
    };

    if (props.franchise_id != null) {
      pass_data.get.franchise = props.franchise_id;
    }

    let data_res = await api("/brand/create_outlet_init", pass_data);
    console.log("get_outlet_init response :", data_res.response);

    if (data_res.response.hasOwnProperty("countries")) {
      SetCountriesOptions(data_res.response.countries);
    }
  }

  async function get_details() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
    };

    let data_res = await api("/brand/outlet_details", pass_data);
console.log(data_res)
    SetData(data_res.response);
    console.log("get_outlet_init response :", data_res.response?.outlets[0]);
    let data = data_res.response?.outlets[0];
    console.log(data);

    let set_value = {
      id: data.id,
      name: data.name,
      address: data.address,
      mobile: data.mobile,
      whatsapp: data.whatsapp,
      email: data.email,
      website: data.website,
      country_name: data.country_name,
      country: data.country_id,
      lat: data.latitude,
      lng: data.longitude,
      map_address: data.map_address ? data.map_address : "",
      cod: data.cod,

      state: data.state_id,
      state_name: data.state_name,

      door_no: data.door_no,
      city: data.city,
      pincode: data.pincode,
      image_url: data.image,
      image: data.image_id,
      banner_image_url: data.banner_image,
      banner_image: data.banner_image_id,
      approx_preparation_time: data.approx_preparation_time,
      latitude: data.latitude,
      longitude: data.longitude,
    };
    SetOutletData(set_value);
    SetMapModal((prevalue:any)=>{
      prevalue.is_open = false;
      prevalue.data = {};
      prevalue.from = "";
      prevalue.latitude = data.latitude;
      prevalue.longitude = data.longitude;
      prevalue.address = data.map_address;
      return {...prevalue}
    })
    // if (data_res.response.hasOwnProperty("countries")) {
    //   SetCountriesOptions(data_res.response.countries);
    // }
  }

  function validate(name: any, value: any) {
    //A function to validate each input values
    console.log("name :", name);
    console.log("value  :", value);
    switch (name) {
      case "name":
        if (value.length <= 2) {
          // we will set the error state

          setErrors({
            ...errors,
            name: "Outlet name atleast have 3 letters",
          });
        } else {
          let newObj = omit(errors, "name");
          setErrors(newObj);
        }
        break;

      case "address":
        if (value.length <= 0) {
          setErrors({
            ...errors,
            address: "Enter valid address",
          });
        } else {
          let newObj = omit(errors, "address");
          setErrors(newObj);
        }
        break;

      case "mobile":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            mobile: "Enter valid mobile number",
          });
        } else {
          let newObj = omit(errors, "mobile");
          setErrors(newObj);
        }
        break;

      case "whatsapp":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            whatsapp: "Enter valid whatsapp number",
          });
        } else {
          let newObj = omit(errors, "whatsapp");
          setErrors(newObj);
        }
        break;

      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "Enter email not vaild",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      case "website":
        if (
          !new RegExp(
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            website: "Enter website not vaild",
          });
        } else {
          let newObj = omit(errors, "website");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  }

  async function get_states() {
    let pass_data = {
      get: {
        country_id: 99,
      },
    };
    let response = await api("/init/get_states", pass_data);
    console.log("/brand/settings response :", response);
    if (response.status_code == 200) {
      SetStates(response.response.date);
    }
  }

  function onImgSelect() {
    SetModalImage((prevValue: any) => {
      prevValue.is_open = !prevValue.is_open;
      // prevValue.selected_images = data.selected_img;
      return { ...prevValue };
    });
  }

  function onBnrSelect() {
    SetModalBanner((prevValue: any) => {
      prevValue.is_open = !prevValue.is_open;
      // prevValue.selected_images = data.selected_img;
      return { ...prevValue };
    });
  }

  return (
    <div>
      <div className="head-bar px-3 sticky-sm w-100">
        <div className="d-flex align-items-center">
          <div
            className="cursor px-3"
            onClick={() => {
              window.history.back();
            }}
          >
            <Ionicons name="arrow-back" size={18} color="black" />
          </div>
          <h6 className="mb-0 ">Edit Outlet</h6>
        </div>
      </div>

      <div
        className="p-2 mt-3"
        // id="CreateOutletModal"
        // tabIndex={-1}
        // aria-labelledby="ModalLabel"
        // aria-hidden="true"
        // data-bs-backdrop="static"
      >
        <div className="p-3 bg-fff border-radius pb-5">
          <div className="row">
            <div className="col-12 col-md-6 mt-2">
              <label className="form-label">Outlet Name</label>
              <input
                type="text"
                className="form-control"
                value={outlet_data.name}
                onChange={(e) => {
                  outlet_value_on_change("name", e.target.value);
                }}
              />
              {errors.name && <small className="text-red">{errors.name}</small>}
            </div>

            {/* Door No */}
            <div className="col-12 col-md-6 mt-2">
              <label className="form-label" htmlFor="name">
                Door No
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={outlet_data.door_no}
                onChange={(e) => {
                  outlet_value_on_change("door_no", e.target.value);
                  // SetSaveError(false);
                }}
              />
              {/* {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null} */}
            </div>

            <div className="col-12 col-md-8 mt-2">
              <label className="form-label">Address</label>
              <textarea
                rows={2}
                className="form-control"
                value={outlet_data.address}
                onChange={(e) => {
                  outlet_value_on_change("address", e.target.value);
                }}
              ></textarea>
              {errors.address && (
                <small className="text-red">{errors.address}</small>
              )}
            </div>

            {/* City*/}
            <div className="col-12 col-md-4 mt-2">
              <label className="form-label" htmlFor="name">
                City
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={outlet_data.city}
                onChange={(e) => {
                  outlet_value_on_change("city", e.target.value);
                  // SetSaveError(false);
                }}
              />
              {/* {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null} */}
            </div>

            {/* Countries */}
            <div className="col-12 col-md-4 mt-2">
              <label className="form-label" htmlFor="selected_roles">
                Country
              </label>
              <select
                className="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
                onChange={(e) => {
                  outlet_value_on_change("country", e.target.value);
                }}
              >
                <option selected>{outlet_data.country_name}</option>

                {countries_options.map((ele: any) => (
                  <option value={ele.id}>{ele.nicename}</option>
                ))}
              </select>
            </div>

            {/* States */}
            {outlet_data.country == "99" && (
              <div className="col-md-5 mt-2 md-ps-0 sm-p-0">
                <label className="form-label" htmlFor="selected_roles">
                  State
                </label>

                <select
                  className="form-select form-select-lg mb-3"
                  aria-label=".form-select-lg example"
                  onChange={(e) => {
                    outlet_value_on_change("state", e.target.value);
                  }}
                >
                  <option selected>{outlet_data.state_name}</option>

                  {states.map((ele: any) => (
                    <option value={ele.id}>{ele.nicename}</option>
                  ))}
                </select>
              </div>
            )}

            {/* pincode */}
            <div className="col-12 col-md-3 mt-2">
              <label className="form-label" htmlFor="name">
                Pincode
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={outlet_data.pincode}
                onChange={(e) => {
                  outlet_value_on_change("pincode", e.target.value);
                  // SetSaveError(false);
                }}
              />
              {/* {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null} */}
            </div>

            <div className="col-12 col-md-9 mt-3">
              <label className="form-label">Map Location</label>
              <input
                // value={outlet_data.address}
                className="form-control"
                // onClick={() => {
                //             let set_data = {
                //               lat: outlet_data.lat,
                //               lng: outlet_data.lng,

                //       };
                //   SetMapModal({
                //     is_open: true,
                //     data : {set_data},
                //   });
                // }}
                value={outlet_data.map_address}
                onClick={() => {
                  // SetMapModal({
                  //   is_open: true,
                  //   latitude: outlet_data.lat,
                  //   longitude: outlet_data.lng,
                  //   map_address: outlet_data.map_address,
                  // });

                  SetMapModal((prevalue) => {
                    prevalue.is_open = true;
                    prevalue.from = "edit"
                    prevalue.latitude = map_modal.latitude;
                    prevalue.longitude = map_modal.longitude;
                    return { ...prevalue };
                  });
                }}
              ></input>
              {errors.address && (
                <small className="text-red">{errors.address}</small>
              )}
            </div>
            <div className="col-12 col-md-6 mt-2">
              <label className="form-label">Mobile Number</label>
              {console.log(outlet_data)}
              <input
                type="text"
                className="form-control"
                value={outlet_data.mobile}
                onChange={(e) => {
                  if (e.target.value.length == 11) return false;
                  let val = e.target.value.slice(0, 10);
                  outlet_value_on_change("mobile", val);
                }}
              />
              {errors.mobile && (
                <small className="text-red">{errors.mobile}</small>
              )}
            </div>
            <div className="col-12 col-md-6 mt-2">
              <label className="form-label">Whatsapp Number</label>
              <input
                type="text"
                className="form-control"
                value={outlet_data.whatsapp}
                onChange={(e) => {
                  if (e.target.value.length == 11) return false;
                  let val = e.target.value.slice(0, 10);
                  outlet_value_on_change("whatsapp", val);
                }}
              />
              {errors.whatsapp && (
                <small className="text-red">{errors.whatsapp}</small>
              )}
            </div>
            <div className="col-12 col-md-6 mt-2">
              <label className="form-label">Email</label>
              <input
                type="text"
                className="form-control"
                value={outlet_data.email}
                onChange={(e) => {
                  outlet_value_on_change("email", e.target.value);
                }}
              />
              {errors.email && (
                <small className="text-red">{errors.email}</small>
              )}
            </div>
            <div className="col-12 col-md-6 mt-2 mb-3">
              <label className="form-label">Website</label>
              <input
                type="text"
                className="form-control"
                value={outlet_data.website}
                onChange={(e) => {
                  outlet_value_on_change("website", e.target.value);
                }}
              />
              {errors.website && (
                <small className="text-red">{errors.website}</small>
              )}
            </div>
            <hr />
            {/* Add Image */}
            <div className="col-12 col-md-6 mt-2">
              <label className="form-label"> Outlet Logo </label>

              {/* Image */}
              <div
                className="center px-sm-0 px-md-2 w-100"
                // className={
                //   outlet_data.image?.length > 0
                //     ? "col-md-6 px-sm-0 px-md-2"
                //     : "col-md-6 center px-sm-0 px-md-2"
                // }
              >
                {outlet_data.image?.length > 0 ? (
                  <div className=" p-1">
                    {/* <div className="row py-3 px-2 overflow-img-scroll "> */}
                    {/* {outlet_data?.image.map(
                              (img_src, img_index) => ( */}
                    <div className="p-1">
                      <div className="card">
                        <img
                          src={AppHelper.imageSrc(
                            data.image_base_url,
                            outlet_data.image_url,
                            { format: "webp", width: 100 }
                          )}
                          className="cover_img radius"
                        />
                      </div>
                    </div>
                    {/* )
                            )} */}
                    {/* </div> */}
                    <div className="row">
                    <div className="col-6">
                    <div
                      className="center cursor p-2 mt-3 bg-fff btn-radius border"
                      onClick={() => {
                        onImgSelect(outlet_data.image);
                      }}
                    >
                      <MaterialCommunityIcons
                        name="image-plus"
                        size={18}
                        color="gray"
                      />
                      <p className="ps-2 mb-0 text-darkgray ">
                      Update Logo
                      </p>
                    </div>
                    </div>

                    <div className="col-6">
                  <div
                      className="center cursor p-2 bg-danger border mt-3 radius"
                      onClick={() => {
                     
                        outlet_value_on_change("image","")
                        outlet_value_on_change("image_url","")

                      }}
                    >
                     
                      {/* <MaterialCommunityIcons
                        name="image-plus"
                        size={18}
                        color="gray"
                      /> */}
                      <p className="ps-2 mb-0  fw-bold " style={{ color: 'white' }}>
                        Remove
                      </p>
                    </div>
                  </div>
                  </div>
                  </div>

                ) : (
                  <div
                    className="center cursor p-2 w-100"
                    onClick={() => {
                      onImgSelect(modal_image.selected_images);
                    }}
                  >
                    <div>
                      <div className="d-flex align-items-center w-100 px-3 py-1 bg-fff btn-radius border">
                        <MaterialCommunityIcons
                          name="image-plus"
                          size={22}
                          color="gray"
                        />
                        <p className="ps-2 mb-0 text-darkgray fs-6">
                          Add / Select Images
                        </p>
                      </div>
                      <div className="mt-3 text-center">
                        <small className="">
                          <ul className="ps-0">
                            <li className="pb-1">
                              Add / Select your product images
                            </li>
                            <li className="pb-1">
                              No image file larger then 5MB
                            </li>
                          </ul>
                        </small>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        outlet_value_on_change("website", e.target.value);
                      }}
                    /> */}
              {errors.website && (
                <small className="text-red">{errors.website}</small>
              )}
            </div>

            {/* Banner Image */}
            <div className="col-12 col-md-6 mt-2">
              <label className="form-label">Banner Image</label>

              {/* Image */}
              <div
                className="center px-sm-0 px-md-2"
                // className={
                //   outlet_data.banner_image?.length > 0
                //     ? "col-md-6 px-sm-0 px-md-2"
                //     : "col-md-6 center px-sm-0 px-md-2"
                // }
              >
                {outlet_data.banner_image?.length > 0 ? (
                  <div className=" p-1">
                    <div className="p-1">
                      <div className="card">
                        <img
                          src={AppHelper.imageSrc(
                            data.image_base_url,
                            outlet_data.banner_image_url,
                            { format: "webp", width: 100 }
                          )}
                          className="cover_img radius"
                        />
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-6">
                    <div
                      className="center cursor p-2 mt-3 bg-fff btn-radius border"
                      onClick={() => {
                        onBnrSelect(outlet_data.selected_banner);
                      }}
                    >
                      <MaterialCommunityIcons
                        name="image-plus"
                        size={18}
                        color="gray"
                      />
                      <p className="ps-2 mb-0 text-darkgray ">
                        Update Banner
                      </p>
                    </div>
                    </div>

                    <div className="col-6">
                  <div
                      className="center cursor p-2 bg-danger border mt-3 radius"
                      onClick={() => {
                       
                        
                        outlet_value_on_change("banner_image","")
                        outlet_value_on_change("banner_image_url","")
                      }}
                    >
                     
                
                      <p className="ps-2 mb-0  fw-bold " style={{ color: 'white' }}>
                        Remove
                      </p>
                    </div>
                  </div>
                  </div>

                  </div>
                ) : (
                  <div
                    className="center cursor p-2 w-100"
                    onClick={() => {
                      onBnrSelect(modal_banner.selected_banner);
                    }}
                  >
                    <div>
                      <div className="d-flex align-items-center w-100 px-3 py-1 bg-fff btn-radius border">
                        <MaterialCommunityIcons
                          name="image-plus"
                          size={20}
                          color="gray"
                        />
                        <p className="ps-2 mb-0 text-darkgray w-100 fs-6">
                          Add / Select Banners
                        </p>
                      </div>
                      <div className="mt-3 text-center">
                        <small className="">
                          <ul className="ps-0">
                            <li className="pb-1">
                              Add / Select your product images
                            </li>
                            <li className="pb-1">
                              No image file larger then 5MB
                            </li>
                          </ul>
                        </small>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        outlet_value_on_change("website", e.target.value);
                      }}
                    /> */}
              {errors.website && (
                <small className="text-red">{errors.website}</small>
              )}
            </div>
            <hr />

            {/* cod */}
            <div className="col-12 col-md-6 my-3">
              <label className="form-label d-flex align-items-center mb-3 cursor">
                <input
                  type="checkbox"
                  className="form-check-input me-2"
                  name={"filter_category"}
                  value={outlet_data.cod}
                  checked={outlet_data.cod == "1" ? true : false}
                  onChange={(event) => {
                    outlet_value_on_change(
                      "cod",
                      outlet_data.cod == "1" ? "0" : "1"
                    );
                  }}
                />
                <p className="capitalize mb-0">Allow Cash On Delivery Order</p>
              </label>
            </div>

            {/* prep time */}
            <div className="col-12 col-md-3 mt-3">
              <label className="form-label" htmlFor="name">
                Approx Preperation Time
              </label>

              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  id="name"
                  value={outlet_data.approx_preparation_time}
                  onChange={(e) => {
                    outlet_value_on_change(
                      "approx_preparation_time",
                      e.target.value
                    );
                    // SetSaveError(false);
                  }}
                />
                <span className="input-group-text bg-primary">Min</span>
              </div>
              {errors.name && <small className="text-red">{errors.name}</small>}
            </div>
          </div>
          <div>
            <div className="my-4 end">
              <button
                type="button"
                className="btn bg-prime text-white btn-radius px-4 fw-bold"
                onClick={() => {
                  edit_outlet();
                }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
        {/* {console.log(mapModal.data)} */}

        {/* <ModalLocation
          open={mapModal.is_open}
          // id={deleteModal.id}
          location={mapModal.data}
          close={(data: any) => {
            // console.log("Franchise modl on close :", data);
            SetMapModal((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              // get_outlets();
            }
          }}
        /> */}

        {/* <ModalLocation
          open={mapModal.is_open}
          latitude={mapModal.latitude}
          longitude={mapModal.longitude}
          map_address={mapModal.map_address}
          close={(data: any) => {
            console.log("Franchise modl on close :", data);

            SetOutletData((oldValues) => ({
              ...oldValues,
              map_address: data.address,
            }));
            SetMapModal((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              // get_outlets();
              SetMapModal((prevValue: any) => {
                prevValue.latitude = data.latitude;
                prevValue.longitude = data.longitude;
                prevValue.map_address = data.address;

                return { ...prevValue };
              });
            }
          }}
        /> */}
{countries_options.length>0  &&
        <LocationPicker
          open={map_modal.is_open}
          from={map_modal.from}
          // latitude={mapModal.latitude}
          // longitude={mapModal.longitude}
          lat={map_modal.latitude}
          long={map_modal.longitude}
          address={map_modal.address}
          picked_lat_long={{
            lat: map_modal.latitude,
            long: map_modal.longitude,
          }}
          picked_address_value={map_modal.address}
          close={(data: any) => {
            console.log("Location picker on close :", data);
            SetMapModal((prevalue) => {
              prevalue.is_open = false;
              return { ...prevalue };
            });

            // latitude: "",
            // longitude: "",

            // if (data.action == "save") {
            //   SetLocationPickerData(data.value);
            //   SetShortAddressValue(data.value.short_address_value);
            //   if (data.from == "add_address") {
            //     SetAddAddress((prevalue: any) => {
            //       prevalue.latitude = data.value.lat;
            //       prevalue.longitude = data.value.lang;
            //       prevalue.map_address = data.value.full_address_value;
            //       prevalue.short_map_address = data.value.short_address_value;
            //       return { ...prevalue };
            //     });
            //     validate_add_address();
            //   }
            // }

            // SetOutletData((oldValues) => ({
            //   ...oldValues,
            //   map_address: data.value.full_address_value,

            // }));

            // SetMapModal((prevValue: any) => {
            //   prevValue.is_open = false;
            //   return { ...prevValue };

            // });
            if (data.action == "save") {
              console.log(data.value)
              SetOutletData((oldValues) => ({
                ...oldValues,
                map_address: data.value.full_address_value,
                latitude: data.value.lat,
                longitude: data.value.lang,
              }));
              // get_outlets();
              SetMapModal((prevValue: any) => {
                // prevValue.latitude = data.latitude;
                // prevValue.longitude = data.longitude;
                prevValue.latitude = data.value.lat;
                prevValue.longitude = data.value.lang;
                prevValue.address = data.value.full_address_value;
                return { ...prevValue };
              });
            }
          }}
        />
        }
        {modal_image.is_open ? (
          <ModalAddGallery
            open={modal_image.is_open}
            type={modal_image.type}
            data={modal_image.data}
            selected={modal_image.selected_images}
            from={"create_outlet"}
            close={(data: any) => {
              console.log("ModalAddGallery on close :", data);
              SetModalImage((prevValue: any) => {
                prevValue.is_open = false;
                return { ...prevValue };
              });

              if (data.action == "save") {
                SetOutletData((prevValue: any) => {
                  prevValue.image_url = data.value[0].url;
                  prevValue.image = data.value[0].id;

                  return { ...prevValue };
                });
              }
            }}
          />
        ) : null}

        {modal_banner.is_open ? (
          <ModalAddGallery
            open={modal_banner.is_open}
            type={modal_banner.type}
            data={modal_banner.data}
            selected={modal_banner.selected_banner}
            from={"create_banner"}
            close={(data: any) => {
              console.log("ModalAddGallery on close :", data);
              SetModalBanner((prevValue: any) => {
                prevValue.is_open = false;
                return { ...prevValue };
              });

              if (data.action == "save") {
                SetOutletData((prevValue: any) => {
                  prevValue.banner_image_url = data.value[0].url;
                  prevValue.banner_image = data.value[0].id;
                  return { ...prevValue };
                });
              }
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
