import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { AppConfig } from "../../../config";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";

const initial_value = {
  selected_files: [],
};
export default function SimpleProductAddingUi(props: any) {
  const fileInputRef = React.createRef();
  const [save_data, SetSaveData] = useState(initial_value);
  const [single_product_active, setSingleProductActive] = useState(true);
  const [recommend, SetRecommend] = useState(false);
  const diet_options = [
    { label: "Vegetairan", value: "1" },
    { label: "Non Vegetaian", value: "2" },
    { label: "Eggiterian", value: "3" },
    { label: "Vegan", value: "4" },
  ];

  const sector_options = [
    { label: "Food", value: "1" },
    { label: "Grocery", value: "2" },
    { label: "Pickup & Drop", value: "3" },
    { label: "Near by Store", value: "4" },
  ];

  const category_options = [
    { label: "Dosa", value: "1" },
    { label: "Meals", value: "2" },
    { label: "Gravy", value: "3" },
    { label: "Biryani", value: "4" },
  ];

  const inventory_options = [
    { label: "Yes", value: "1" },
    { label: "No", value: "1" },
  ];

  const inventory_type_options = [
    { label: "Batch Number", value: "1" },
    { label: "MRP", value: "1" },
  ];

  const country_options = [{ label: "India", value: "1" }];

  const state_options = [{ label: "Tamilnadu", value: "1" }];

  useEffect(() => {
    SetSaveData({
      selected_files: [],
    });
  }, []);

  function fileListToArray(list, value) {
    const old_value = value;
    const array = [];
    for (var i = 0; i < list.length; i++) {
      let data = {};
      data.file = list.item(i);
      data.url = URL.createObjectURL(list.item(i));
      data.upload_percentage = 0;
      old_value.push(data);
    }
    return old_value;
  }

  function onFilesAdded(evt) {
    if (props.disabled) return;
    const files = evt.target.files;

    let old_value = save_data.selected_files;
    const array = fileListToArray(files, old_value);
    SetSaveData((prevValue: any) => {
      prevValue.selected_files = array;
      return { ...prevValue };
    });
  }

  function openFileDialog() {
    if (props.disabled) return;
    fileInputRef.current.click();
  }

  const BootstrapSelect = ({
    optionName,
    options,
    placeholder,
    isMultiSelect = true,
  }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

    const handleOptionClick = (option) => {
      console.log("selected option: ", optionName);
      if (isMultiSelect) {
        // For multi-select, add the option only if it's not already selected
        if (
          !selectedOptions.some((selected) => selected.value === option.value)
        ) {
          setSelectedOptions([...selectedOptions, option]);
        }
      } else {
        // For single-select, replace the selected option
        setSelectedOptions([option]);
        setDropdownOpen(false); // Close dropdown after selecting an option
      }
    };

    const handleRemoveOption = (option) => {
      setSelectedOptions(
        selectedOptions.filter((selected) => selected.value !== option.value)
      );
    };

    const handleSearch = (e) => setSearchQuery(e.target.value);

    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <div className="dropdown">
        <div
          className="form-control radius-10 outside d-flex align-items-center custom-select"
          onClick={toggleDropdown}
          style={{ cursor: "pointer" }}
        >
          {selectedOptions.length > 0 ? (
            isMultiSelect ? (
              // Multi-select: Show selected items as badges
              selectedOptions.map((option) => (
                <span key={option.value} className="badge bg-primary me-1">
                  {option.label}
                  <button
                    type="button"
                    className="btn-close btn-close-white ms-2"
                    aria-label="Remove"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveOption(option);
                    }}
                  ></button>
                </span>
              ))
            ) : (
              <div>
                {/* Single-select: Show selected item as plain text */}
                <span className="select-label">
                  {selectedOptions[0]?.label}
                </span>
                <span className="floating-custom-label">{optionName}</span>
              </div>
            )
          ) : (
            <span className="floating-label-outside">{optionName}</span>
            // <span className="text-muted">{placeholder}</span>
          )}

          <div className="ms-auto">
            <img
              src={
                AppConfig.CDN_Media_url + "b1b871802f6048caa74336bf068322cb.svg"
              }
              className="w-10px"
            />
          </div>
        </div>

        {isDropdownOpen && (
          <ul
            className="dropdown-menu show pt-2 mt-2"
            style={{ width: "100%", padding: "0" }}
          >
            {/* <li className="p-2">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
              />
            </li> */}
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li key={option.value} className="py-1">
                  <button
                    className="dropdown-item"
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.label}
                  </button>
                </li>
              ))
            ) : (
              <li className="dropdown-item text-muted">No options found</li>
            )}
          </ul>
        )}
      </div>
    );
  };

  return (
    <div className="mt-md-5 custom-container">
      <Header />
      <div className="mb-5">
        <div className="mt-3 bg-fff shadow p-3">
          <div className="position-relative mt-4">
            <div className="d-flex w-100 border radius-6">
              <input
                type="text"
                className="outside w-90per border-none bg-none"
                // placeholder="Enter brand name"
              />
              <div className="center w-10per">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "b4bea0a7fdf01fb10dc6b969a6eb5dc7.svg"
                  }
                  className="w-20px"
                />
              </div>
            </div>
            <span className="floating-label-outside">Brand Name *</span>
          </div>

          <div className="d-flex align-items-center w-100">
            <div className="position-relative mt-4 w-90">
              <div className="d-flex w-100 border radius-6">
                <input
                  type="text"
                  className="outside w-80per border-none bg-none"
                  // placeholder="Enter outlet name"
                />
                <div className="center w-20per">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "b4bea0a7fdf01fb10dc6b969a6eb5dc7.svg"
                    }
                    className="w-20px"
                  />

                  <div className="ms-2 p-2 ">
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "b1b871802f6048caa74336bf068322cb.svg"
                      }
                      className="w-14px"
                    />
                  </div>
                </div>
              </div>
              <span className="floating-label-outside">Outlet Name *</span>
            </div>
            <div className="ms-2 mt-3 w-10per">
              <div className="locate-me mt-2 center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "7767d0d4cacda6a7c1cee478a6cf447c.svg"
                  }
                  className="w-16px"
                />
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center w-100">
            <div className="position-relative mt-4 w-90">
              <input type="text" className="outside" />
              <span className="floating-label-outside">
                Outlet Map Location
              </span>
            </div>
            <div className="ms-2 mt-3 w-10per">
              <div className="locate-me mt-2 center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "0d86a456fb776908ed0c78d70df47ddb.svg"
                  }
                  className="w-20"
                />
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center w-100">
            <div className="position-relative mt-4 w-90">
              <div className="d-flex w-100 border radius-6">
                <input
                  type="text"
                  className="outside w-80per border-none bg-none"
                  // placeholder="Enter outlet name"
                />
                <div className="center w-20per">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "b4bea0a7fdf01fb10dc6b969a6eb5dc7.svg"
                    }
                    className="w-20px"
                  />

                  <div className="ms-2 p-2 ">
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "b1b871802f6048caa74336bf068322cb.svg"
                      }
                      className="w-14px"
                    />
                  </div>
                </div>
              </div>
              <span className="floating-label-outside">Channel Name *</span>
            </div>
            <div className="ms-2 mt-3 w-10per">
              <div className="locate-me mt-2 center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "7767d0d4cacda6a7c1cee478a6cf447c.svg"
                  }
                  className="w-16px"
                />
              </div>
            </div>
          </div>

          <div className="position-relative mt-4 w-100">
            <div className="outside w-100">
              <input
                ref={fileInputRef}
                className="outside FileInputNone"
                type="file"
                multiple
                onChange={(evt) => {
                  onFilesAdded(evt);
                }}
              />
              <div className="border radius-10 p-3 w-100">
                <div className="d-flex align-items-center">
                  {save_data.selected_files.map((element, index) => (
                    <div className="border radius-6 pre-img-size me-2">
                      <img src={element[index]} className="w-20" />
                      <div
                        className="cursor text-center text-red over-img-close"
                        onClick={() => {
                          SetSaveData((prevValue: any) => {
                            let file_data = prevValue.selected_files;
                            file_data.splice(index, 1);
                            prevValue.selected_files = file_data;
                            return { ...prevValue };
                          });
                        }}
                      >
                        ×
                      </div>
                    </div>
                  ))}

                  <div
                    className="border radius-6 bg-gray pre-img-size center"
                    onClick={openFileDialog}
                  >
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "47b9835dc6f13fad0481e35755afecd2.svg"
                      }
                      className="w-20"
                    />
                  </div>
                </div>
              </div>

              <span className="floating-label-outside">Brand Image</span>
            </div>
          </div>

          <button className="primary-button mt-3">Create</button>
        </div>
        <div className="mt-3 bg-fff shadow">
          <div className="bg-body-color shadow px-3 py-2">
            <div className="position-relative mt-3 border radius-6">
              <div className="d-flex w-100">
                <input
                  type="text"
                  className="outside w-90per border-none bg-none bg-body-color"
                  // placeholder="Enter collection name"
                />
                <div className="center w-10per cursor">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "358cc79ee3ed14b245a3d3935e4b6e68.svg"
                    }
                    className="w-20px"
                  />
                </div>
                <span className="floating-label-outside bg-body-color">
                  Collection *
                </span>
              </div>
            </div>
          </div>

          <div className="px-3 pt-3">
            <div className="card radius-10">
              <div className="p-2 pb-1 d-flex align-items-center">
                <div className="w-70per ps-2">
                  <img
                    src={
                      AppConfig.CDN_Image_url +
                      "e301ce3af353aab421a2f76c898a439d.png"
                    }
                    className="w-10px me-2"
                  />
                  <div className="loc-head">Podi Dosa</div>
                  <div className="d-flex align-items-center">
                    <div className="loc-head pe-2 text-dark">₹40</div>
                    <div className="pop-font text-gray fs-11 strike">₹55</div>
                  </div>
                  <div className="v-small text-gray">
                    Thin dosa with home made podi topping
                  </div>
                </div>
                <div className="w-30per">
                  <img
                    src={
                      AppConfig.CDN_Image_url +
                      "62eeaf50fa0a9a609efe846632b0fc47.jpg"
                    }
                    className="added-prod-img"
                  />
                </div>
              </div>

              <div className="d-flex align-items-center p-2">
                <div className="text-center px-2 mx-auto ">
                  <div className="fs-9px text-gray pb-1">Edit</div>
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "21d3a9e5cedf31f69753210bf5015802.svg"
                    }
                    className="w-16px"
                  />
                </div>
                <div className="border-right-h-auto"></div>

                <div className="text-center px-2">
                  <div className="fs-9px text-gray pb-1">Product</div>
                  {/* <div className="d-flex align-items-center"> */}
                  <div
                    className={
                      single_product_active
                        ? "px-2 py-1 bg-light-prime radius-6 d-flex align-items-center cursor"
                        : "px-2 py-1 bg-light-gray radius-6 cursor"
                    }
                    onClick={() => {
                      setSingleProductActive(true);
                    }}
                  >
                    {single_product_active ? (
                      <div className="extra-small text-prime pe-1">Single</div>
                    ) : null}
                    <img
                      src={
                        single_product_active
                          ? AppConfig.CDN_Media_url +
                            "da324fef11da122ab61d90c58d6b7c09.svg"
                          : AppConfig.CDN_Media_url +
                            "e380f40f4e8eed611d55f334ab6093e2.svg"
                      }
                      className="w-12px"
                    />
                  </div>

                  {/*  <div
                      className={
                        !single_product_active
                          ? "px-2 py-1 bg-light-prime radius-6 d-flex align-items-center cursor"
                          : "px-2 py-1 bg-light-gray radius-6 cursor"
                      }
                      onClick={() => {
                        setSingleProductActive(false);
                      }}
                    >
                     {!single_product_active ? (
                        <div className="extra-small text-prime pe-1">Group</div>
                      ) : null}
                      <img
                        src={
                          !single_product_active
                            ? AppConfig.CDN_Media_url +
                              "f5e9967ea75273bd3393d9f5447ae865.svg"
                            : AppConfig.CDN_Media_url +
                              "e34a2edb8154bad25ecaf2f6be639662.svg"
                        }
                        className="w-12px"
                      />  
                    </div> */}
                  {/* </div> */}
                </div>

                <div className="border-right-h-auto"></div>

                <div className="text-center px-2 mx-auto">
                  <div className="fs-9px text-gray pb-2">Category</div>
                  <div className="extra-small">Dosa</div>
                </div>

                <div className="border-right-h-auto"></div>

                <div
                  className="text-center px-2  cursor"
                  onClick={() => {
                    SetRecommend(!recommend);
                  }}
                >
                  <div className="fs-9px text-gray pb-1">Recommend</div>
                  <img
                    src={
                      recommend
                        ? AppConfig.CDN_Media_url +
                          "5b999c4d7e924181c4d7b3996f1ed6a4.svg"
                        : // "b774e757a59696928862d2735b85ce74.svg"
                          // "2ada06691ce2d05ec1eda6f25653833b.svg"
                          AppConfig.CDN_Media_url +
                          "c6eb31a57426a97235e45ec31114c450.svg"
                    }
                    className="w-20px"
                  />
                </div>

                <div className="border-right-h-auto"></div>

                <div className=" text-center px-2 mx-auto">
                  <div className="fs-9px text-gray mt-1">In Stock</div>
                  <label className="in-stock-switch cursor">
                    <input type="checkbox" />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
              <div className="bg-light-gray p-2 mt-1">
                <div className="sub-text text-dark">Now item is available</div>
              </div>
            </div>
          </div>

          <div className="px-3">
            <div className="p-2 bg-fff shadow mt-3">
              <div className="position-relative mt-1">
                <BootstrapSelect
                  className="outside"
                  options={sector_options}
                  //   placeholder="Select an option..."
                  isMultiSelect={false}
                  placeholder={undefined}
                  optionName="Sector"
                />
              </div>

              <div className="position-relative mt-4">
                <BootstrapSelect
                  className="outside"
                  options={category_options}
                  //   placeholder="Select an option..."
                  isMultiSelect={false}
                  placeholder={undefined}
                  optionName="Category Name"
                />
              </div>

              <div className="position-relative mt-4">
                <input type="text" className="outside" required />
                <span className="floating-label-outside">Product Name</span>
              </div>

              <div className="position-relative mt-4">
                <input type="text" className="outside" required />
                <span className="floating-label-outside">
                  Product Description
                </span>
              </div>

              {/* Multi-Select Example */}
              {/* <BootstrapSelect options={options} placeholder="Select options..." isMultiSelect={true} /> */}

              {/* Single-Select Example */}
              <div className="position-relative mt-4">
                <BootstrapSelect
                  className="outside"
                  options={diet_options}
                  //   placeholder="Select an option..."
                  isMultiSelect={false}
                  placeholder={undefined}
                  optionName="Diet"
                />
              </div>

              <div className="position-relative mt-4 w-100">
                <div className="outside w-100">
                  <input
                    ref={fileInputRef}
                    className="outside FileInputNone"
                    type="file"
                    multiple
                    onChange={(evt) => {
                      onFilesAdded(evt);
                    }}
                  />
                  <div className="border radius-10 p-3 w-100">
                    <div className="d-flex align-items-center">
                      {save_data.selected_files.map((element, index) => (
                        <div className="border radius-6 pre-img-size me-2 position-relative">
                          <img src={element[index]} className="w-20" />
                          <div
                            className="cursor text-center text-red over-img-close"
                            onClick={() => {
                              SetSaveData((prevValue: any) => {
                                let file_data = prevValue.selected_files;
                                file_data.splice(index, 1);
                                prevValue.selected_files = file_data;
                                return { ...prevValue };
                              });
                            }}
                          >
                            *
                          </div>
                        </div>
                      ))}

                      <div
                        className="border radius-6 bg-gray pre-img-size center"
                        onClick={openFileDialog}
                      >
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "47b9835dc6f13fad0481e35755afecd2.svg"
                          }
                          className="w-20"
                        />
                      </div>
                    </div>
                  </div>

                  <span className="floating-label-outside">Product Image</span>
                </div>
              </div>

              <div className="fs-12px text-dark mt-4">Contanis</div>

              <div className="d-flex align-items-center">
                <div className="position-relative mt-4 me-1">
                  <input
                    type="text"
                    className="outside"
                    // placeholder="Enter units"
                  />
                  <span className="floating-label-outside l-7px">Packs</span>
                </div>

                <div className="position-relative mt-4 me-1">
                  <input
                    type="text"
                    className="outside"
                    // placeholder="Enter units"
                  />
                  <span className="floating-label-outside l-7px">Quantity</span>
                </div>

                <div className="position-relative mt-4 me-1">
                  <input
                    type="text"
                    className="outside"
                    // placeholder="Enter units"
                  />
                  <span className="floating-label-outside l-7px">Units</span>
                </div>
              </div>

              <div className="d-flex align-items-center w-100">
                <div className="position-relative mt-4 me-1 w-50">
                  <BootstrapSelect
                    className="outside"
                    options={country_options}
                    //   placeholder="Select an option..."
                    isMultiSelect={false}
                    placeholder={undefined}
                    optionName="Market"
                  />
                </div>

                <div className="position-relative mt-4 me-1 w-50">
                  <BootstrapSelect
                    className="outside"
                    options={state_options}
                    //   placeholder="Select an option..."
                    isMultiSelect={false}
                    placeholder={undefined}
                    optionName="Level"
                  />
                </div>
              </div>

              <div className="position-relative mt-4">
                <BootstrapSelect
                  className="outside"
                  options={inventory_options}
                  //   placeholder="Select an option..."
                  isMultiSelect={false}
                  placeholder={undefined}
                  optionName="Inventory Based"
                />
              </div>

              <div className="d-flex align-items-center w-100">
                <div className="position-relative mt-4 me-1 w-50">
                  <BootstrapSelect
                    className="outside"
                    options={inventory_type_options}
                    //   placeholder="Select an option..."
                    isMultiSelect={false}
                    placeholder={undefined}
                    optionName="Invontry Type"
                  />
                </div>

                <div className="position-relative mt-4 me-1 w-50">
                  <input
                    type="text"
                    className="outside"
                    // placeholder="Enter units"
                  />
                  <span className="floating-label-outside l-7px">
                    Batch Number
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center w-100">
              <div className="position-relative mt-4 me-1 w-50">
              <input
                    type="date"
                    className="outside"
                    // placeholder="Enter units"
                  />
                  <span className="floating-label-outside l-7px">
                    Manufature Date
                  </span>
                </div>

   <div className="position-relative mt-4 me-1 w-50">
              <input
                    type="date"
                    className="outside"
                    // placeholder="Enter units"
                  />
                  <span className="floating-label-outside l-7px">
                    Expiry Date
                  </span>
                </div>
                </div>

              <div className="fs-12px text-dark mt-4">Tax</div>

              <div className="d-flex align-items-center">
                <div className="position-relative mt-4 me-1">
                  <input
                    type="text"
                    className="outside icon-padding"
                    // placeholder="Enter units"
                  />
                  <span className="input-icon-absoulate">₹</span>
                  <span className="floating-label-outside l-7px">MRP</span>
                </div>

                <div className="position-relative mt-4 me-1 w-200px">
                  <input
                    type="text"
                    className="outside "
                    // placeholder="selling price"
                  />
                  {/* <span className="input-icon-absoulate">₹</span> */}
                  <span className="floating-label-outside custom-s l-7px px-0">
                    IGST
                  </span>
                </div>
                <div className="position-relative mt-4 me-1">
                  <input
                    type="text"
                    className="outside "
                    // placeholder="Enter MRP"
                  />
                  {/* <span className="input-icon-absoulate">₹</span> */}
                  <span className="floating-label-outside l-7px">SGST</span>
                </div>
                <div className="position-relative mt-4">
                  <input
                    type="text"
                    className="outside "
                    // placeholder="Enter MRP"
                  />
                  {/* <span className="input-icon-absoulate">₹</span> */}
                  <span className="floating-label-outside l-7px">CGST</span>
                </div>
              </div>

              <div className="fs-12px text-dark mt-4">Maximum Pricing</div>

              <div className="d-flex align-items-center">
                <div className="position-relative mt-4 me-1">
                  <input
                    type="text"
                    className="outside icon-padding"
                    // placeholder="Enter units"
                  />
                  <span className="input-icon-absoulate">₹</span>
                  <span className="floating-label-outside l-7px">
                    Selling Price
                  </span>
                </div>

                <div className="position-relative mt-4 me-1 w-200px">
                  <input
                    type="text"
                    className="outside icon-padding"
                    // placeholder="selling price"
                  />
                  <span className="input-icon-absoulate">₹</span>
                  <span className="floating-label-outside custom-s l-7px px-0">
                    IGST
                  </span>
                </div>
                <div className="position-relative mt-4 me-1">
                  <input
                    type="text"
                    className="outside icon-padding"
                    // placeholder="Enter MRP"
                  />
                  <span className="input-icon-absoulate">₹</span>
                  <span className="floating-label-outside l-7px">SGST</span>
                </div>
                <div className="position-relative mt-4">
                  <input
                    type="text"
                    className="outside icon-padding"
                    // placeholder="Enter MRP"
                  />
                  <span className="input-icon-absoulate">₹</span>
                  <span className="floating-label-outside l-7px">CGST</span>
                </div>
              </div>

              <div className="fs-12px text-dark mt-4">Pricing Without Tax</div>

              <div className="d-flex align-items-center">
                <div className="position-relative mt-4 me-2">
                  <input
                    type="text"
                    className="outside icon-padding"
                    // placeholder="Enter units"
                  />
                  <span className="input-icon-absoulate">₹</span>
                  <span className="floating-label-outside l-7px">
                    Selling Price
                  </span>
                </div>
                <div className="position-relative mt-4 me-2">
                  <input
                    type="text"
                    className="outside icon-padding"
                    // placeholder="Enter units"
                  />
                  <span className="input-icon-absoulate">₹</span>
                  <span className="floating-label-outside l-7px">
                    Offer Price
                  </span>
                </div>
                <div className="position-relative mt-4 me-2">
                  <input
                    type="text"
                    className="outside icon-padding"
                    // placeholder="Enter units"
                  />
                  <span className="input-icon-absoulate">₹</span>
                  <span className="floating-label-outside l-7px">Total</span>
                </div>
              </div>

              <div className="fs-12px text-dark mt-4">Pricing With Tax</div>

              <div className="d-flex align-items-center">
                <div className="position-relative mt-4 me-2">
                  <input
                    type="text"
                    className="outside icon-padding"
                    // placeholder="Enter units"
                  />
                  <span className="input-icon-absoulate">₹</span>
                  <span className="floating-label-outside l-7px">
                    Selling Price
                  </span>
                </div>
                <div className="position-relative mt-4 me-2">
                  <input
                    type="text"
                    className="outside icon-padding"
                    // placeholder="Enter units"
                  />
                  <span className="input-icon-absoulate">₹</span>
                  <span className="floating-label-outside l-7px">
                    Offer Price
                  </span>
                </div>
                <div className="position-relative mt-4 me-2">
                  <input
                    type="text"
                    className="outside icon-padding"
                    // placeholder="Enter units"
                  />
                  <span className="input-icon-absoulate">₹</span>
                  <span className="floating-label-outside l-7px">Total</span>
                </div>
              </div>

              {/* <div className="d-flex align-items-center mt-3 px-1">
                <div className="text-dark sub-head">Advanced Details</div>
                <div className="ms-auto">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "b1b871802f6048caa74336bf068322cb.svg"
                    }
                    className="w-10px"
                  />
                </div>
              </div> */}

              <div className="mt-5 mb-2">
                <button className="primary-button">Create Product</button>
              </div>
            </div>
          </div>

          <div className="m-3 pb-3 center">
            <div className="btn cancel-order-btn-prime">
              Add More Product in same Collection
            </div>
          </div>
        </div>

        <div className="px-3 pt-2 pb-5 mb-5">
          <div className="btn btn-bg-fff-text-prime mb-4">
            Add More Product in another Collection
          </div>
        </div>

        <div className="bottom-fixed-50px bottom-fixed-md btn text-white bg-prime1 uppercase w-100px shadow radius-none fs-13px center">
          Finish
        </div>
      </div>
      <Footer />
    </div>
  );
}
