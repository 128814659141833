
import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams } from "react-router-dom";
import { api } from "../../../utils/Api";
import ModalCreateFranchise from "./ModalCreateFranchise";
import { Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import Skeleton from "react-loading-skeleton";


export default function AddonList(props: any) {
  const context = useContext(DataContext);
  const [id_value, SerIdValue] = useState(useParams());
  const [addon_data, SetAddonData] = useState([]);
  const [group_addon_data, SetGroupedAddonData] = useState({});
  const [options, SetOptions] = useState()
  const [outlet_unavailable, SetOutletUnavailable] = useState({})
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);
  const [search_data, SetSearchData] = useState('');
  const [is_loading, SetIsLoading] = useState(false);


  useEffect(() => {
    console.log("AddonList On mount :", props);
    console.log("AddonList context :", context);
    get_addon(page_no, search_data);
  }, []);

  useEffect(() => {
    if (search_data == '') {
      get_addon(page_no, search_data)
    } else {
      const timeOutId = setTimeout(
        () => triggerItemChange(search_data),
        500,
      )
      return () => clearTimeout(timeOutId)
    }
  }, [search_data])

  function triggerItemChange(value) {
    SetPageNo((prevValue: any) => {
      prevValue = 1
      get_addon(prevValue, value)
      return prevValue
    })
  }


  async function get_addon(page_no_value, search_value) {
    SetIsLoading(true)
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        page: page_no_value,
        search: search_value,
      },
    };
    let response: any = await api("/brand/outlet_addon_group_list_v2", pass_data);
    console.log("/brand/outlet_addon_group_list_v2 response :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("data")) {
        let addon_data = response.response.data;
        var grouped = _.mapValues(_.groupBy(addon_data, 'account_name'));

        console.log("grouped :", grouped);
        SetGroupedAddonData(grouped)
    SetIsLoading(false)

        SetAddonData(addon_data);
      } else {
        SetAddonData([]);
        SetGroupedAddonData({})
      }
      if (response.response.hasOwnProperty("options")) {
        SetOptions(response.response.options)
      }
      if (response.response.hasOwnProperty("outlet_unavailable")) {
        SetOutletUnavailable(response.response.outlet_unavailable)
      }
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page)
      }

    }
  }

  function on_next() {
    SetPageNo((prevValue: any) => {
      prevValue = prevValue + 1;
      get_addon(prevValue, search_data);
      return prevValue
    })
  }

  function on_prev() {
    SetPageNo((prevValue: any) => {
      prevValue = prevValue - 1;
      get_addon(prevValue, search_data);
      return prevValue
    })
  }

  return (
    <div className="">
      {/* top */}
      <div className=" row align-items-center head-bar">
        <div className="col-md-5 col-12 sm-mb-2">
          <h6 className="  mb-1">Addon Group List</h6>
        </div>
        {/* <div className="col-5 ps-0 d-sm-block d-md-none text-end sm-mb-2">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              // console.log("Franchise new");
              // SetModalFranchise((prevValue) => {
              //   prevValue.is_open = true;
              //   prevValue.type = "create";
              //   return { ...prevValue };
              // });
            }}
          >
            Create New
          </button>
        </div> */}

        <div className="col-md-6 col-10 ">
          <div className="row px-md-3">
            <div className="input-icons form-group">

              <input
                type="text"
                className="form-control search-bar p-0"
                placeholder="Search products"
                onChange={(e) => {
                  console.log("search value :", e.target.value);
                  SetSearchData(e.target.value)
                }}
              />
              <div className="cursor ps-2 border-left">
                <Ionicons name="md-search" size={18} color="#663fc3" />
              </div>
            </div>
          </div>
        </div>
        <div className="text-center col-md-1 col-2 p-0">
          <select className="form-control btn-radius" defaultValue="10">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        {/* <div className="d-sm-none d-md-block text-center col-md-2 mb-2">
          <button
            className="btn btn-primary"
            onClick={() => {
              // console.log("Franchise new");
              // SetModalFranchise((prevValue: any) => {
              //   prevValue.is_open = true;
              //   prevValue.type = "create";
              //   return { ...prevValue };
              // });
            }}
          >
            Create New
          </button>
        </div> */}
      </div>

      <div className="px-md-3 px-sm-0">
        <div className="mt-4 bg-fff p-3">
        {is_loading ? (
          <div className="">
          <div className="">
            <h6 className="mb-3"><Skeleton width={100} /></h6>
          </div>
          <div className="row">
            {[1, 2, 3].map((a_index) => (
              <div className="col-md-6 col-lg-4 mb-3" key={a_index}>
                <div className="d-flex p-2 border radius">
                  <div className="">
                    <p className="small text-black fw-bold"><Skeleton width={100} /></p>
                    <div className="mt-2">
                      <p className="small"><Skeleton count={2} /></p>
                    </div>
                  </div>
        
                  <div className="ms-auto">
                    <Skeleton width={100} height={30} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        ):(        
          addon_data.length > 0 ? (
            // addon_data.map((a_ele: any, a_index: any) => (

            //     <div className="p-2 row list border-bottom">
            //       <div className="col-md-2">
            //         <img
            //           src={require("../../../assets/icon/empty.png")}
            //           className="br-100"
            //           width="60"
            //           alt="..."
            //         />
            //       </div>

            //       <div className="col-md-3">
            //         <p className="mb-0">{a_ele.name}</p>
            //       </div>

            //       <div className="col-md-3">
            //         <p className="mb-0">{a_ele.notes}</p>
            //       </div>

            //       <div className="col-md-4">
            //         <div className="d-flex">
            //           <Link
            //             to={
            //               "/brand/" + id_value.brand_id + "/addon_price/" + a_ele.id
            //             }
            //             key={a_index}
            //           >
            //             <button className="btn btn-sm mx-2" >View Addon Pricing</button>
            //           </Link>
            //         </div>
            //       </div>


            //     </div>
            // ))
            Object.keys(group_addon_data).map((key) => (
              <div className="">
                <div className="">
                  <h6 className="mb-3">{key}</h6>
                </div>
                <div className="row">
                  {group_addon_data[key].map((a_ele: any, a_index: any) => (
                    <div className="col-md-6 col-lg-4 mb-3">
                      <div className="d-flex p-2 border radius ">
                        <div className="">
                          <p className="small text-black fw-bold">{a_ele.name}</p>
                          <div className="mt-2">
                            <p className="small">{a_ele.notes}</p>
                          </div>
                        </div>

                        <div className="ms-auto">
                          <Link
                            to={
                              "/brand/" + id_value.brand_id + "/addon_price/" + a_ele.id
                            }
                            key={a_index}
                          >
                            <button className="btn btn-sm text-prime v-small fw-bold" >Addon Pricing</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div className="center p-3 bg-fff">
            <div>
              <img src={require("../../../assets/icon/empty.png")} className="empty-img" />
              <p className="text-center">No data found!</p>
            </div>
          </div>
          ))}

          {/* Pagination */}
          {/* <div className="pagination:container text-end mt-4">
            <div className="pagination:number arrow">
              <MaterialIcons
                name="keyboard-arrow-left"
                size={24}
                color="black"
              />
            </div>

            <div className="pagination:number">1</div>
            <div className="pagination:number">2</div>

            <div className="pagination:number pagination:active">3</div>

            <div className="pagination:number">..</div>

            <div className="pagination:number">20</div>

            <div className="pagination:number arrow">
              <MaterialIcons
                name="keyboard-arrow-right"
                size={24}
                color="black"
              />
            </div>
          </div> */}

          <div className="">
            {page_no > 1 ? (
              <button className="btn btn-primary" onClick={() => {
                console.log("Prev")
                on_prev();
              }}>Prev</button>
            ) : null}

            {next_page ? (
              <button className="btn btn-primary" onClick={() => {
                console.log("Next")
                on_next();
              }}>Next</button>
            ) : null}
          </div>

        </div>
      </div>
    </div>
  );
}
