"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Sort = _interopRequireDefault(require("@rsuite/icon-font/lib/direction/Sort"));

// Generated by script, don't edit it please.
var Sort = (0, _createSvgIcon["default"])({
  as: _Sort["default"],
  ariaLabel: 'sort',
  category: 'direction',
  displayName: 'Sort'
});
var _default = Sort;
exports["default"] = _default;
module.exports = exports.default;