import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import DataContext from "../../utils/Context/DataContext";
import { Link, useParams } from "react-router-dom";
import { Table, Column, HeaderCell, Cell, ColumnGroup } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import Slider from "@material-ui/core/Slider";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { api } from "../../utils/Api";

import AssignAvailableFilterComponent from "../AssignAvailableFilterComponent/AssignAvailableFilterComponent";
import useStickyHeader from "../../assets/js/useStickyHeader";
import toast, { Toaster } from "react-hot-toast";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import { AppConfig } from "../../config";
import InfiniteScroll from "react-infinite-scroll-component";

// import Select from 'react-select'
// import makeAnimated from 'react-select/animated'

function valuetext(value: any) {
  return `${value}°C`;
}
const animatedComponents = makeAnimated();

// const animatedComponents = makeAnimated()
const handleDragStart = (e: any) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  200: { items: 2 },
  300: { items: 3 },
  350: { items: 3 },
  548: { items: 4 },
  720: { items: 5 },
};

export default function ProductAssigningAvailableComponent(props: any) {
  const context: any = useContext(DataContext);
  const [table_loading, SetTableLoading] = useState(false);
  const [checked, SetChecked] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [width, SetWidth] = useState(innerWidth);
  const [is_loading, SetIsLoading] = useState(true);

  const [id_value, SetIdValue] = useState(useParams());
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [value, setValue] = useState(30);

  ////////////////////////////////////////////////////////////////    15/10/2022

  const [filter_data, SetFilterData] = useState({
    page_no: 1,
    next_page: false,
    type: { label: "outlet", value: "outlet" },
    type_option: [
      { label: "outlet", value: "outlet" },
      { label: "channel", value: "channel" },
    ],
    view: { label: "All", value: "all" },
    view_option: [
      { label: "All", value: "all" },
      { label: "New", value: "new" },
      { label: "Active", value: "active" },
      { label: "Unavailable", value: "unavailable" },
      { label: "Paused", value: "paused" },
    ],
    outlets: [],
    channels: [],
    custom_channels: [],
    count: { label: "All", value: "all" },
    count_option: [
      { label: "All", value: "all" },
      { label: "10", value: "10" },
      { label: "20", value: "20" },
      { label: "30", value: "30" },
    ],
    filter_products: {
      own_products: false,
      others_products: false,
    },
    search: "",
    sort: { label: "asc", value: "asc" },
    sort_option: [
      { label: "asc", value: "asc" },
      { label: "desc", value: "desc" },
    ],
    filter_brand: [],
    filter_brand_options: [],
    filter_categories: [],
    filter_categories_options: [],
    outlets_options: [],
    channels_options: [],
    custom_channels_options: [],
    min_mrp: "",
    max_mrp: "",
  });
  const [product_data, SetProductData] = useState([]);
  const [outlet_data, SetOutletData] = useState([]);
  const [outlet_data_list, SetOutletDataList] = useState([]);
  const [selected_outlet_data, SetSelectedOutletData] = useState({
    id: 0,
    name: "All",
  });
  const [filtered_outlet_data, SetFilteredOutletData] = useState([]);
  const [channel_data, SetChannelData] = useState([]);
  const [all_data, SetAllData] = useState([]);
  const [all_data_saftey, SetAllDataSaftey] = useState([]);
  const [new_checked, SetNewChecked] = useState([]);
  const [new_checked1, SetNewChecked1] = useState({});
  const [new_disabled, SetNewDisabled] = useState({
    product_group: [],
    variants: [],
    addon_groups: [],
    addon_items: [],
  });
  const [active_tab, SetActiveTab] = useState({ value: "All", open: false });
  const { tableRef, isSticky } = useStickyHeader();
  const [initial_render, SetInitialRender] = useState(true);
  const ref = useRef(null);
  const [width_ref, setWidthRef] = useState(0);
  const [height_ref, setHeightRef] = useState(0);
  const [numbers, setNumbers] = useState([]);
  const [bottom_sheet_data, SetBottomSheetData] = useState({
    is_open: false,
    data: {},
  });
  const [uncheck_radio_value, SetUncheckRadioValue] = useState({
    checked_value: "",
    other_value: "",
  });

  let custom_date_value = Date();
  const rows = 5; // Number of skeleton rows
  const columns = 2; // Number of skeleton columns
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setWidthRef(ref.current.offsetWidth);
    setHeightRef(ref.current.offsetHeight);
  }, [numbers]);

  useEffect(() => {
    SetWidth(innerWidth);
    console.log("width :", width);
  }, [props.is_menu_open]);

  useEffect(
    () => {
      console.log("ProductAssigningAvailableComponent On mount :", props);
      // if(context.app_data.pages.hasOwnProperty('14')){
      //   if(context.app_data.pages[14]){
      if (props.type == "available") {
        get_available_products(filter_data, false);
      } else {
        console.log("calling in useEffect[]");
        get_products(filter_data, false);
      }
      get_filter_data();

      function handleWindowResize() {
        setWidthRef(ref.current.offsetWidth);
        setHeightRef(ref.current.offsetHeight);
      }

      window.addEventListener("resize", handleWindowResize);

      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
      // }else{
      //   console.log("hi")
      //   navigate("/no_access");

      // }}else{
      //   SetIsLoading(true)
      // }
    },
    []
    //  [context.app_data.pages?.[14]]
  );

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  function filter_fun(anchor: any, open: any, event: any) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  }

  useEffect(() => {
    if (initial_render) {
      SetInitialRender(false);
    } else {
      if (filter_data.search == "") {
        console.log("calling in search change empty");
        if (props.type == "available") {
          get_available_products(filter_data, false);
        } else {
          get_products(filter_data, false);
        }
      } else {
        const timeOutId = setTimeout(
          () => triggerItemChange(filter_data.search),
          500
        );
        return () => clearTimeout(timeOutId);
      }
    }
  }, [filter_data.search]);

  function triggerItemChange(value: any) {
    SetFilterData((prevValue: any) => {
      prevValue.page_no = 1;
      if (props.type == "available") {
        get_available_products(prevValue, false);
      } else {
        get_products(prevValue, false);
      }
      return { ...prevValue };
    });
  }

  async function get_filter_data() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        product_categories: 1,
        product_brand: 1,
        all_outlets: 1,
        all_channels: 1,
      },
    };
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    console.log("get_filter_data pass_data :", pass_data);
    let response = await api("/init/get", pass_data);
    console.log("/init/get response :", response);
    if (response.status_code == 200) {
      SetFilterData((prevValue: any) => {
        if (response.response.hasOwnProperty("product_categories")) {
          response.response.product_categories.map((c_ele: any) => {
            c_ele.label = c_ele.name;
            c_ele.value = c_ele.id;
          });
          prevValue.filter_categories_options =
            response.response.product_categories;
        }
        if (response.response.hasOwnProperty("product_brands")) {
          prevValue.filter_brand_options = response.response.product_brands;
        }
        if (response.response.hasOwnProperty("outlets")) {
          prevValue.outlets_options = response.response.outlets;
        }
        if (response.response.hasOwnProperty("channels")) {
          let system_data = [],
            custom_data = [];
          response.response.channels.map((c_ele: any) => {
            if (c_ele.type == "system") {
              system_data.push(c_ele);
            } else {
              custom_data.push(c_ele);
            }
          });
          prevValue.channels_options = system_data;
          prevValue.custom_channels_options = custom_data;
        }
        return { ...prevValue };
      });
    }
  }

  //
  async function get_products(filter_value: any, load_more: boolean) {
    // SetIsLoading(true);
    let outlet_value = [];
    filter_value.outlets.map((o_ele) => {
      outlet_value.push(o_ele.value);
    });
    if (id_value?.outlet_id) {
      outlet_value.push(id_value.outlet_id);
    }
    let channel_value = [];
    filter_value.channels.map((c_ele) => {
      channel_value.push(c_ele.value);
    });
    let custom_channel_value = [];
    filter_value.custom_channels.map((cc_ele) => {
      custom_channel_value.push(cc_ele.value);
    });
    let filter_brand_value = [];
    filter_value.filter_brand.map((b_ele) => {
      filter_brand_value.push(b_ele.value);
    });
    let filter_categories_value = [];
    filter_value.filter_categories.map((fc_ele) => {
      filter_categories_value.push(fc_ele.value);
    });
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        page: filter_value.page_no,
        type: "channel",
        view: filter_value.view.value,
        outlets: encodeURIComponent(JSON.stringify(outlet_value)),
        channels: encodeURIComponent(channel_value),
        custom_channels: encodeURIComponent(custom_channel_value),
        // filter_count: filter_value.count.value,
        filter_products: encodeURIComponent(
          JSON.stringify(filter_value.filter_products)
        ),
        search: filter_value.search,
        sort: filter_value.sort.value,
        filter_brand: encodeURIComponent(filter_brand_value),
        filter_min_mrp: filter_value.min_mrp,
        filter_max_mrp: filter_value.max_mrp,
        filter_categories: encodeURIComponent(filter_categories_value),
      },
    };
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    console.log("get_products pass_data :", pass_data);
    let response = await api("/brand/outlet_product_variants_list", pass_data);
    console.log("/brand/outlet_product_variants_list response :", response);
    if (response.status_code == 200) {
      let res_data = response.response;
      // SetNewChecked([]);
      // SetNewDisabled({
      //   product_group: [],
      //   variants: [],
      //   addon_groups: [],
      //   addon_items: [],
      // });
      // SetNewChecked1({});
      // SetAllData(res_data);
      // SetAllDataSaftey(res_data);

      if (!load_more) {
        SetNewChecked([]);
        SetNewDisabled({
          product_group: [],
          variants: [],
          addon_groups: [],
          addon_items: [],
        });
        SetNewChecked1({});
      }
      SetAllData((prevalue: any) => {
        if (load_more) {
          // res_data.products.map((ele : any)=>{
          //   prevalue.products.push(ele);
          // })
          prevalue.outlets = res_data.outlets;
          prevalue.channels = res_data.channels;
          prevalue.outlet_system_channels = res_data.outlet_system_channels;
          prevalue.outlet_custom_channels = res_data.outlet_custom_channels;

          Object.keys(res_data.outlet_product_variants_assign).map((ele) => {
            prevalue.outlet_product_variants_assign[ele] =
              res_data.outlet_product_variants_assign[ele];
          });
          Object.keys(res_data.outlet_product_variants_available).map((ele) => {
            prevalue.outlet_product_variants_available[ele] =
              res_data.outlet_product_variants_available[ele];
          });
          Object.keys(res_data.outlet_channel_product_variants_assign).map(
            (ele) => {
              prevalue.outlet_channel_product_variants_assign[ele] =
                res_data.outlet_channel_product_variants_assign[ele];
            }
          );
          Object.keys(res_data.outlet_channel_product_variants_available).map(
            (ele) => {
              prevalue.outlet_channel_product_variants_available[ele] =
                res_data.outlet_channel_product_variants_available[ele];
            }
          );
        } else {
          prevalue = res_data;
        }
        console.log("SetAllData prevalue :", prevalue);
        return prevalue;
      });

      SetAllDataSaftey((prevalue: any) => {
        if (load_more) {
          // res_data.products.map((ele : any)=>{
          //   prevalue.products.push(ele);
          // })
          prevalue.outlets = res_data.outlets;
          prevalue.channels = res_data.channels;
          prevalue.outlet_system_channels = res_data.outlet_system_channels;
          prevalue.outlet_custom_channels = res_data.outlet_custom_channels;

          Object.keys(res_data.outlet_product_variants_assign).map((ele) => {
            prevalue.outlet_product_variants_assign[ele] =
              res_data.outlet_product_variants_assign[ele];
          });
          Object.keys(res_data.outlet_product_variants_available).map((ele) => {
            prevalue.outlet_product_variants_available[ele] =
              res_data.outlet_product_variants_available[ele];
          });
          Object.keys(res_data.outlet_channel_product_variants_assign).map(
            (ele) => {
              prevalue.outlet_channel_product_variants_assign[ele] =
                res_data.outlet_channel_product_variants_assign[ele];
            }
          );
          Object.keys(res_data.outlet_channel_product_variants_available).map(
            (ele) => {
              prevalue.outlet_channel_product_variants_available[ele] =
                res_data.outlet_channel_product_variants_available[ele];
            }
          );
        } else {
          prevalue = res_data;
        }
        return prevalue;
      });

      if (res_data.hasOwnProperty("products")) {
        if (load_more) {
          SetProductData((prevalue) => {
            res_data.products.map((ele: any) => {
              prevalue.push(ele);
            });
            console.log("on load more product data :", prevalue);
            return prevalue;
          });
        } else {
          SetProductData(res_data.products);
        }
      }

      if (res_data.hasOwnProperty("outlets")) {
        // if(load_more){
        //   SetOutletData(res_data.outlets);
        // }else{
        //   SetOutletData(res_data.outlets);
        // }
        if (!load_more) {
          let outlet_data_array: any = [];
          outlet_data_array.push({
            id: 0,
            name: "All",
          });
          res_data.outlets.map((ele: any) => {
            outlet_data_array.push(ele);
          });
          SetOutletDataList(outlet_data_array);
          SetOutletData(res_data.outlets);
          SetFilteredOutletData(res_data.outlets);
        } else {
          // let outlet_data_array: any = [];
          // outlet_data_array.push({
          //   id: 0,
          //   name: "All",
          // });
          // res_data.outlets.map((ele: any) => {
          //   outlet_data_array.push(ele);
          // });
          // SetOutletDataList(outlet_data_array);
          console.log("selected_outlet_data :",selected_outlet_data);

          if (Object.keys(selected_outlet_data).length > 0) {
            if(selected_outlet_data.name == "All"){
              SetOutletData(res_data.outlets);
            }else{
              console.log("selected_outlet_data res_data.outlets :",res_data.outlets)
              console.log("selected_outlet_data filtered_outlet_data :",filtered_outlet_data)
              const filtered = filtered_outlet_data.filter(
                (item: any) =>
                  item.name === selected_outlet_data.name
              );
              console.log("selected_outlet_data filtered :",filtered);
              SetOutletData(filtered);
            }

          } else {
            SetOutletData(res_data.outlets);
          }
          SetFilteredOutletData(res_data.outlets);
        }
      }

      if (res_data.hasOwnProperty("channels")) {
        // if(load_more){
        //   SetChannelData(res_data.channels);
        // }else{
        //   SetChannelData(res_data.channels);
        // }
        SetChannelData(res_data.channels);
      }

      SetFilterData((prevValue: any) => {
        if (res_data.hasOwnProperty("next_page")) {
          prevValue.next_page = res_data.next_page;
        }
        return { ...prevValue };
      });
      SetIsLoading(false);
      // SetNextPage(res_data.next_page)
    }
  }

  async function get_available_products(filter_value: any, load_more: boolean) {
    // SetIsLoading(true);
    let outlet_value = [];
    filter_value.outlets.map((o_ele) => {
      outlet_value.push(o_ele.value);
    });
    if (id_value?.outlet_id) {
      outlet_value.push(id_value.outlet_id);
    }
    let channel_value = [];
    filter_value.channels.map((c_ele) => {
      channel_value.push(c_ele.value);
    });
    let custom_channel_value = [];
    filter_value.custom_channels.map((cc_ele) => {
      custom_channel_value.push(cc_ele.value);
    });
    let filter_brand_value = [];
    filter_value.filter_brand.map((b_ele) => {
      filter_brand_value.push(b_ele.value);
    });
    let filter_categories_value = [];
    filter_value.filter_categories.map((fc_ele) => {
      filter_categories_value.push(fc_ele.value);
    });

    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        page: filter_value.page_no,
        type: "channel",
        view: filter_value.view.value,
        outlets: encodeURIComponent(JSON.stringify(outlet_value)),
        channels: encodeURIComponent(channel_value),
        custom_channels: encodeURIComponent(custom_channel_value),
        // filter_count: filter_value.count.value,
        filter_products: encodeURIComponent(
          JSON.stringify(filter_value.filter_products)
        ),
        search: filter_value.search,
        sort: filter_value.sort.value,
        filter_brand: encodeURIComponent(filter_value.filter_brand),
        filter_min_mrp: filter_value.min_mrp,
        filter_max_mrp: filter_value.max_mrp,
        filter_categories: encodeURIComponent(filter_value.filter_categories),
      },
    };
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    console.log("get_available_products pass_data :", pass_data);
    // let response = await api("/brand/outlet_product_variants_list", pass_data);
    let response = await api(
      "/brand/outlet_product_variants_list_v2",
      pass_data
    );
    console.log("/brand/outlet_product_variants_list response :", response);
    if (response.status_code == 200) {
      let res_data = response.response;
      console.log("res_data :", res_data);
      // SetNewChecked([]);
      // SetNewDisabled({
      //   product_group: [],
      //   variants: [],
      //   addon_groups: [],
      //   addon_items: [],
      // });
      // SetNewChecked1({});
      // SetAllData(res_data);
      // SetAllDataSaftey(res_data);

      if (!load_more) {
        SetNewChecked([]);
        SetNewDisabled({
          product_group: [],
          variants: [],
          addon_groups: [],
          addon_items: [],
        });
        SetNewChecked1({});
      }

      SetAllData((prevalue: any) => {
        if (load_more) {
          // res_data.products.map((ele : any)=>{
          //   prevalue.products.push(ele);
          // })
          prevalue.outlets = res_data.outlets;
          prevalue.channels = res_data.channels;
          prevalue.outlet_system_channels = res_data.outlet_system_channels;
          prevalue.outlet_custom_channels = res_data.outlet_custom_channels;

          Object.keys(res_data.outlet_product_variants_assign).map((ele) => {
            prevalue.outlet_product_variants_assign[ele] =
              res_data.outlet_product_variants_assign[ele];
          });
          Object.keys(res_data.outlet_product_variants_available).map((ele) => {
            prevalue.outlet_product_variants_available[ele] =
              res_data.outlet_product_variants_available[ele];
          });
          Object.keys(res_data.outlet_channel_product_variants_assign).map(
            (ele) => {
              prevalue.outlet_channel_product_variants_assign[ele] =
                res_data.outlet_channel_product_variants_assign[ele];
            }
          );
          Object.keys(res_data.outlet_channel_product_variants_available).map(
            (ele) => {
              prevalue.outlet_channel_product_variants_available[ele] =
                res_data.outlet_channel_product_variants_available[ele];
            }
          );
          Object.keys(res_data.outlet_channel_product_variants_approved).map(
            (ele) => {
              prevalue.outlet_channel_product_variants_approved[ele] =
                res_data.outlet_channel_product_variants_approved[ele];
            }
          );
        } else {
          prevalue = res_data;
        }
        console.log("SetAllData prevalue :", prevalue);
        return prevalue;
      });

      SetAllDataSaftey((prevalue: any) => {
        if (load_more) {
          // res_data.products.map((ele : any)=>{
          //   prevalue.products.push(ele);
          // })
          prevalue.outlets = res_data.outlets;
          prevalue.channels = res_data.channels;
          prevalue.outlet_system_channels = res_data.outlet_system_channels;
          prevalue.outlet_custom_channels = res_data.outlet_custom_channels;

          Object.keys(res_data.outlet_product_variants_assign).map((ele) => {
            prevalue.outlet_product_variants_assign[ele] =
              res_data.outlet_product_variants_assign[ele];
          });
          Object.keys(res_data.outlet_product_variants_available).map((ele) => {
            prevalue.outlet_product_variants_available[ele] =
              res_data.outlet_product_variants_available[ele];
          });
          Object.keys(res_data.outlet_channel_product_variants_assign).map(
            (ele) => {
              prevalue.outlet_channel_product_variants_assign[ele] =
                res_data.outlet_channel_product_variants_assign[ele];
            }
          );
          Object.keys(res_data.outlet_channel_product_variants_available).map(
            (ele) => {
              prevalue.outlet_channel_product_variants_available[ele] =
                res_data.outlet_channel_product_variants_available[ele];
            }
          );
          Object.keys(res_data.outlet_channel_product_variants_approved).map(
            (ele) => {
              prevalue.outlet_channel_product_variants_approved[ele] =
                res_data.outlet_channel_product_variants_approved[ele];
            }
          );
        } else {
          prevalue = res_data;
        }
        return prevalue;
      });

      if (res_data.hasOwnProperty("products")) {
        res_data.products.map((p_ele: any) => {
          p_ele.expanded = false;
          if (p_ele.hasOwnProperty("variants")) {
            p_ele.have_expand = p_ele.variants.length > 0 ? true : false;
            p_ele.variants.map((v_ele: any) => {
              v_ele.expanded = false;
              if (v_ele.hasOwnProperty("addon_groups")) {
                v_ele.have_expand =
                  v_ele.addon_groups.length > 0 ? true : false;
                v_ele.addon_groups.map((ag_ele: any) => {
                  ag_ele.expanded = false;
                  if (ag_ele.hasOwnProperty("addon_items")) {
                    ag_ele.have_expand =
                      ag_ele.addon_items.length > 0 ? true : false;
                    ag_ele.addon_items.map((ai_ele: any) => {
                      ai_ele.expanded = false;
                      ai_ele.have_expand = false;
                    });
                  }
                });
              }
            });
          }
        });
        if (load_more) {
          SetProductData((prevalue) => {
            res_data.products.map((ele: any) => {
              prevalue.push(ele);
            });
            return prevalue;
          });
        } else {
          SetProductData(res_data.products);
        }
        // SetProductData(res_data.products);
      }

      if (res_data.hasOwnProperty("outlets")) {
        if (!load_more) {
          let outlet_data_array: any = [];
          outlet_data_array.push({
            id: 0,
            name: "All",
          });
          res_data.outlets.map((ele: any) => {
            outlet_data_array.push(ele);
          });
          SetOutletDataList(outlet_data_array);
          SetOutletData(res_data.outlets);
          SetFilteredOutletData(res_data.outlets);
        } else {
          // let outlet_data_array: any = [];
          // outlet_data_array.push({
          //   id: 0,
          //   name: "All",
          // });
          // res_data.outlets.map((ele: any) => {
          //   outlet_data_array.push(ele);
          // });
          // SetOutletDataList(outlet_data_array);
          console.log("selected_outlet_data :",selected_outlet_data);

          if (Object.keys(selected_outlet_data).length > 0) {
            if(selected_outlet_data.name == "All"){
              SetOutletData(res_data.outlets);
            }else{
              console.log("selected_outlet_data res_data.outlets :",res_data.outlets)
              const filtered = filtered_outlet_data.filter(
                (item: any) =>
                  item.name === selected_outlet_data.name
              );
              console.log("selected_outlet_data filtered :",filtered);
              SetOutletData(filtered);
            }
          } else {
            SetOutletData(res_data.outlets);
          }
          // SetOutletData(res_data.outlets);
          SetFilteredOutletData(res_data.outlets);
        }
      }

      if (res_data.hasOwnProperty("channels")) {
        SetChannelData(res_data.channels);
      }

      SetFilterData((prevValue: any) => {
        if (res_data.hasOwnProperty("next_page")) {
          prevValue.next_page = res_data.next_page;
        }
        return { ...prevValue };
      });
      SetIsLoading(false);
    }
  }

  const OwnAccordion = (accordian_props: any) => {
    const [list_data, SetListData] = useState(accordian_props.ele);

    function is_checked(o_ele: any, c_ele: any) {
      let f1_id, f2_id;
      f1_id = o_ele.id + "_" + c_ele.id;
      if (c_ele.type == "system") {
        if (all_data.hasOwnProperty("outlet_system_channels")) {
          if (all_data.outlet_system_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_system_channels[f1_id];
          } else {
            // console.log("no value 2")
            return false;
          }
        } else {
          // console.log("no value 1")
          return false;
        }
      }
      if (c_ele.type == "custom") {
        if (all_data.hasOwnProperty("outlet_custom_channels")) {
          if (all_data.outlet_custom_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_custom_channels[f1_id];
          } else {
            // console.log("no value 4")
            return false;
          }
        } else {
          // console.log("no value 3")
          return false;
        }
      }

      let f3_id = f2_id + "_" + list_data.id;
      if (all_data.hasOwnProperty("outlet_channel_product_variants_assign")) {
        if (
          all_data.outlet_channel_product_variants_assign.hasOwnProperty(f3_id)
        ) {
          if (
            all_data.outlet_channel_product_variants_assign[f3_id] == "checked"
          ) {
            return true;
          } else if (
            all_data.outlet_channel_product_variants_assign[f3_id] == "paused"
          ) {
            return "paused";
          } else if (
            all_data.outlet_channel_product_variants_assign[f3_id] ==
            "unchecked"
          ) {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    function available_is_checked(o_ele: any, c_ele: any) {
      let f1_id, f2_id;
      f1_id = o_ele.id + "_" + c_ele.id;
      if (c_ele.type == "system") {
        if (all_data.hasOwnProperty("outlet_system_channels")) {
          if (all_data.outlet_system_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_system_channels[f1_id];
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      if (c_ele.type == "custom") {
        if (all_data.hasOwnProperty("outlet_custom_channels")) {
          if (all_data.outlet_custom_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_custom_channels[f1_id];
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      let f3_id = f2_id + "_" + list_data.id;

      if (
        all_data.outlet_channel_product_variants_available.hasOwnProperty(f3_id)
      ) {
        let return_data;
        if (
          all_data.outlet_channel_product_variants_available[f3_id].value == 1
        ) {
          return_data = true;
        } else {
          return_data = false;
        }
        return return_data;
        // return all_data.outlet_channel_product_variants_available[f3_id];
      } else {
        return false;
      }
    }

    function available_is_disabled(o_ele: any, c_ele: any) {
      let f1_id, f2_id;
      f1_id = o_ele.id + "_" + c_ele.id;
      if (c_ele.type == "system") {
        if (all_data.hasOwnProperty("outlet_system_channels")) {
          if (all_data.outlet_system_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_system_channels[f1_id];
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
      if (c_ele.type == "custom") {
        if (all_data.hasOwnProperty("outlet_custom_channels")) {
          if (all_data.outlet_custom_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_custom_channels[f1_id];
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
      let f3_id = f2_id + "_" + list_data.id;

      if (
        all_data.outlet_channel_product_variants_available.hasOwnProperty(f3_id)
      ) {
        return false;
      } else {
        return true;
      }
    }

    function assign_on_change(o_ele: any, c_ele: any, e: any) {
      let f1_id, f2_id;
      f1_id = o_ele.id + "_" + c_ele.id;
      if (c_ele.type == "system") {
        if (all_data.hasOwnProperty("outlet_system_channels")) {
          if (all_data.outlet_system_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_system_channels[f1_id];
          }
        }
      }
      if (c_ele.type == "custom") {
        if (all_data.hasOwnProperty("outlet_custom_channels")) {
          if (all_data.outlet_custom_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_custom_channels[f1_id];
          }
        }
      }

      SetAllData((prevValue: any) => {
        let f3_id = f2_id + "_" + list_data.id;
        if (e.target.checked) {
          if (
            !prevValue.outlet_channel_product_variants_assign.hasOwnProperty(
              f3_id
            )
          ) {
            let send_data = {
              product_id: list_data.id,
              outlet_id: o_ele.id,
              channel_id: c_ele.id,
            };
            accordian_props.on_new_check(send_data);
            let send_data1 = {
              key: f3_id,
              value: "checked",
            };
            accordian_props.on_new_check1(send_data1);
          }
          prevValue.outlet_channel_product_variants_assign[f3_id] = "checked";
          let send_data1 = {
            key: f3_id,
            value: "checked",
          };
          accordian_props.on_new_check1(send_data1);
        } else {
          if (
            accordian_props.new_checked.some(
              (ele) =>
                ele.product_id == list_data.id &&
                ele.outlet_id == o_ele.id &&
                ele.channel_id == c_ele.id
            )
          ) {
            let index = accordian_props.new_checked.findIndex(
              (ele) =>
                ele.product_id == list_data.id &&
                ele.outlet_id == o_ele.id &&
                ele.channel_id == c_ele.id
            );
            let send_data = {
              for: accordian_props.for,
              index: index,
            };
            accordian_props.on_new_uncheck(send_data);

            let send_data1 = {
              key: f3_id,
              value: "unchecked",
            };
            accordian_props.on_new_uncheck1(send_data1);
            delete prevValue.outlet_channel_product_variants_assign[f3_id];
          } else {
            if (
              prevValue.outlet_channel_product_variants_assign[f3_id] ==
              "checked"
            ) {
              prevValue.outlet_channel_product_variants_assign[f3_id] =
                "paused";

              let send_data1 = {
                key: f3_id,
                value: "paused",
              };
              accordian_props.on_new_pause1(send_data1);
            }
          }
        }
        return { ...prevValue };
      });
    }

    function available_on_change(o_ele: any, c_ele: any, e: any) {
      let f1_id, f2_id;
      f1_id = o_ele.id + "_" + c_ele.id;
      if (c_ele.type == "system") {
        if (all_data.hasOwnProperty("outlet_system_channels")) {
          if (all_data.outlet_system_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_system_channels[f1_id];
          }
        }
      }
      if (c_ele.type == "custom") {
        if (all_data.hasOwnProperty("outlet_custom_channels")) {
          if (all_data.outlet_custom_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_custom_channels[f1_id];
          }
        }
      }

      SetAllData((prevValue: any) => {
        if (
          prevValue.outlet_channel_product_variants_available.hasOwnProperty(
            f2_id + "_" + list_data.id
          )
        ) {
          if (e.target.checked) {
            // prevValue.outlet_channel_product_variants_available[
            //   f2_id + "_" + list_data.id
            // ] = true;
            prevValue.outlet_channel_product_variants_available[
              f2_id + "_" + list_data.id
            ] = {
              value: 1,
            };
            let send_data1 = {
              key: f2_id + "_" + list_data.id,
              value: {
                value: 1,
              },
            };
            accordian_props.on_new_check1(send_data1);
          } else {
            // prevValue.outlet_channel_product_variants_available[
            //   f2_id + "_" + list_data.id
            // ] = false;
            let send_data1 = {
              key: f2_id + "_" + list_data.id,
              value: false,
            };
            console.log("on un check");
            SetBottomSheetData((prevalue: any) => {
              prevalue.is_open = true;
              prevalue.data = send_data1;
              return { ...prevalue };
            });
            // accordian_props.on_new_check1(send_data1)
          }
        }
        return { ...prevValue };
      });
    }

    function get_uncheck_value_data(o_ele: any, c_ele: any) {
      let f1_id, f2_id;
      f1_id = o_ele.id + "_" + c_ele.id;
      if (c_ele.type == "system") {
        if (all_data.hasOwnProperty("outlet_system_channels")) {
          if (all_data.outlet_system_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_system_channels[f1_id];
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      if (c_ele.type == "custom") {
        if (all_data.hasOwnProperty("outlet_custom_channels")) {
          if (all_data.outlet_custom_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_custom_channels[f1_id];
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      let f3_id = f2_id + "_" + list_data.id;

      if (
        all_data.outlet_channel_product_variants_available.hasOwnProperty(f3_id)
      ) {
        let return_data;
        if (
          all_data.outlet_channel_product_variants_available[f3_id].value == 1
        ) {
          return_data = "";
        }
        if (
          all_data.outlet_channel_product_variants_available[f3_id].value == 2
        ) {
          return_data = "Unavailable Permanent";
        }
        if (
          all_data.outlet_channel_product_variants_available[f3_id].value == 3
        ) {
          if (
            all_data.outlet_channel_product_variants_available[
              f3_id
            ].hasOwnProperty("hours")
          ) {
            return_data =
              "Available after " +
              all_data.outlet_channel_product_variants_available[f3_id].hours +
              "Hours";
          } else {
            if (
              all_data.outlet_channel_product_variants_available[
                f3_id
              ].hasOwnProperty("time")
            ) {
              return_data =
                "Available At " +
                all_data.outlet_channel_product_variants_available[f3_id].time;
            } else {
              return_data = "";
            }
          }
        }
        if (
          all_data.outlet_channel_product_variants_available[f3_id].value == 4
        ) {
          // return_data = "Unavailable till Next Available Slot";
          if (
            all_data.outlet_channel_product_variants_available[
              f3_id
            ].hasOwnProperty("days")
          ) {
            return_data =
              "Available after " +
              all_data.outlet_channel_product_variants_available[f3_id].days +
              " Days";
          } else {
            if (
              all_data.outlet_channel_product_variants_available[
                f3_id
              ].hasOwnProperty("time")
            ) {
              return_data =
                "Available At " +
                all_data.outlet_channel_product_variants_available[f3_id].time;
            } else {
              return_data = "";
            }
          }
        }
        if (
          all_data.outlet_channel_product_variants_available[f3_id].value == 5
        ) {
          if (
            all_data.outlet_channel_product_variants_available[
              f3_id
            ].hasOwnProperty("time")
          ) {
            return_data =
              "Available At " +
              all_data.outlet_channel_product_variants_available[f3_id].time;
          } else {
            return_data = "";
          }
        }
        return return_data;
      } else {
        return "";
      }
    }

    return (
      <tr key={accordian_props.key_value} className="">
        <th className="td-prod p-0">
          <p className="text-gray fw-500 p-2 ps-3 bg-fff td-min-height ">
            {list_data.name}
          </p>
        </th>
        {accordian_props.outlet_data.map(
          (o_ele: any, o_index: any) =>
            o_ele?.checked &&
            accordian_props.channel_data.map((c_ele: any, c_index: any) => (
              <td
                className="cursor p-2  "
                key={
                  accordian_props.key_value + "_" + o_index + "_" + c_ele.name
                }
              >
                <div className="center">
                  {props.type == "assign" ? (
                    <input
                      type="checkbox"
                      className="form-check-input"
                      ref={(input) => {
                        if (input) {
                          if (props.type == "assign") {
                            let check_value = is_checked(o_ele, c_ele);
                            if (check_value == "paused") {
                              input.indeterminate = true;
                            } else {
                              input.checked = check_value;
                            }
                          } else {
                            input.checked = false;
                          }
                        }
                      }}
                      onChange={(e) => {
                        console.log("e.target.checked :", e.target.checked);
                        console.log("Outlet :", o_ele);
                        console.log("Channel :", c_ele);
                        console.log("accordian_props :", accordian_props);
                        assign_on_change(o_ele, c_ele, e);
                      }}
                    />
                  ) : (
                    <div className="text-center mt-2">
                      <div className="center  form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          ref={(input) => {
                            if (input) {
                              if (props.type == "available") {
                                let check_value = available_is_checked(
                                  o_ele,
                                  c_ele
                                );
                                let disable_value = available_is_disabled(
                                  o_ele,
                                  c_ele
                                );
                                input.checked = check_value;
                                input.disabled = disable_value;
                              } else {
                                input.checked = false;
                                input.disabled = true;
                              }
                            }
                          }}
                          onChange={(e) => {
                            console.log("e:", e);
                            console.log("e.target.checked :", e.target.checked);
                            console.log("Outlet :", o_ele);
                            console.log("Channel :", c_ele);
                            console.log("accordian_props :", accordian_props);
                            available_on_change(o_ele, c_ele, e);
                          }}
                        />
                      </div>

                      <p className="mt-2 v-small text-gray">
                        {get_uncheck_value_data(o_ele, c_ele)}
                      </p>
                    </div>
                  )}
                </div>
              </td>
            ))
        )}
      </tr>
    );
  };

  async function assign_submit() {
    console.log("assign_submit :", all_data);
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        data: {
          // outlet_product_variants: all_data.outlet_product_variants_assign,
          // outlet_channel_product_variants:
          //   all_data.outlet_channel_product_variants_assign,
          outlet_channel_product_variants: new_checked1,
        },
      },
    };
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    console.log(
      "assign_submit save_outlet_product_variants pass_data :",
      pass_data
    );
    console.log("assign_submit new checked 1 :", new_checked1);
    let response: any = await api(
      "/brand/save_outlet_product_variants",
      pass_data,
      false,
      false,
      "raw_data"
    );
    console.log("/brand/save_outlet_product_variants response :", response);
    if (response.status_code == 200) {
      toast.success("Updated Successful");
      // get_products(filter_data, false);
      SetNewChecked([]);
      SetNewDisabled({
        product_group: [],
        variants: [],
        addon_groups: [],
        addon_items: [],
      });
      SetNewChecked1({});
    }
  }

  async function available_submit() {
    console.log("available_submit all_data :", all_data);
    console.log("available_submit new_disabled :", new_checked);
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        data: {
          // outlet_product_variants_available:
          //   all_data.outlet_product_variants_available,
          // outlet_channel_product_variants_available:
          //   all_data.outlet_channel_product_variants_available,
          outlet_channel_product_variants_available: new_checked1,
        },
      },
    };
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    console.log(
      "available_submit save_outlet_product_variants pass_data :",
      pass_data
    );
    console.log("available_submit new checked 1 :", new_checked1);
    // let response = await api(
    //   "/brand/save_outlet_product_variants",
    //   pass_data,
    //   false,
    //   false,
    //   "raw_data"
    // );
    let response = await api(
      "/brand/save_outlet_product_variants_v2",
      pass_data,
      false,
      false,
      "raw_data"
    );
    // console.log("/brand/save_outlet_product_variants response :", response);
    if (response.status_code == 200) {
      toast.success("Update Successful");
      // SetNewChecked1({});
      // get_available_products(filter_data, false);
      SetNewChecked([]);
      SetNewDisabled({
        product_group: [],
        variants: [],
        addon_groups: [],
        addon_items: [],
      });
      SetNewChecked1({});
    }
  }

  function on_next() {
    SetFilterData((prevValue: any) => {
      prevValue.page_no = prevValue.page_no + 1;
      if (props.type == "assign") {
        get_products(prevValue, true);
      } else {
        get_available_products(prevValue, true);
      }
      return { ...prevValue };
    });
  }

  function on_prev() {
    SetFilterData((prevValue: any) => {
      prevValue.page_no = prevValue.page_no - 1;
      if (props.type == "assign") {
        get_products(prevValue, false);
      } else {
        get_available_products(prevValue, false);
      }
      return { ...prevValue };
    });
  }

  // const body = document.body;
  // const nav = document.querySelector(".page-header nav");
  // const menu = document.querySelector(".page-header .menu");
  // const scrollUp = "scroll-up";
  // const scrollDown = "scroll-down";
  // let lastScroll = 0;

  // window.addEventListener("scroll", () => {
  //   const currentScroll = window.pageYOffset;
  //   if (currentScroll <= 0) {
  //     body.classList.remove(scrollUp);
  //     return;
  //   }

  //   if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
  //     // down
  //     body.classList.remove(scrollUp);
  //     body.classList.add(scrollDown);
  //     // lottiePlayer.play();
  //   } else if (
  //     currentScroll < lastScroll &&
  //     body.classList.contains(scrollDown)
  //   ) {
  //     // up
  //     body.classList.remove(scrollDown);
  //     body.classList.add(scrollUp);
  //     // lottiePlayer.stop();
  //   }
  //   lastScroll = currentScroll;

  // });

  function submit_uncheck_time() {
    console.log("submit_uncheck_time");
    SetBottomSheetData((prevalue: any) => {
      let send_data = prevalue.data;
      SetAllData((all_data_prevalue: any) => {
        // all_data_prevalue.outlet_channel_product_variants_available[
        //   send_data.key
        // ] = false;
        // let new_key_value = send_data.key + "_new"
        let new_key_value = send_data.key;
        SetUncheckRadioValue((radio_prevalue: any) => {
          SetNewChecked1((n_c_prevValue: any) => {
            if (radio_prevalue.checked_value == "2 Hours") {
              all_data_prevalue.outlet_channel_product_variants_available[
                new_key_value
              ] = {
                value: 3,
                hours: 2,
              };
              n_c_prevValue[send_data.key] = {
                value: 3,
                hours: 2,
              };
            }
            if (radio_prevalue.checked_value == "4 Hours") {
              all_data_prevalue.outlet_channel_product_variants_available[
                new_key_value
              ] = {
                value: 3,
                hours: 4,
              };
              n_c_prevValue[send_data.key] = {
                value: 3,
                hours: 4,
              };
            }
            if (radio_prevalue.checked_value == "1 Day") {
              all_data_prevalue.outlet_channel_product_variants_available[
                new_key_value
              ] = {
                value: 4,
                days: 1,
              };
              n_c_prevValue[send_data.key] = {
                value: 4,
                days: 1,
              };
            }
            if (radio_prevalue.checked_value == "2 Day") {
              all_data_prevalue.outlet_channel_product_variants_available[
                new_key_value
              ] = {
                value: 4,
                days: 2,
              };
              n_c_prevValue[send_data.key] = {
                value: 4,
                days: 2,
              };
            }
            if (radio_prevalue.checked_value == "Next Available Slot") {
              all_data_prevalue.outlet_channel_product_variants_available[
                new_key_value
              ] = {
                value: 6,
              };
              n_c_prevValue[send_data.key] = {
                value: 6,
              };
            }
            if (radio_prevalue.checked_value == "Custom Date & Time") {
              all_data_prevalue.outlet_channel_product_variants_available[
                new_key_value
              ] = {
                value: 5,
                time: radio_prevalue.other_value,
              };
              n_c_prevValue[send_data.key] = {
                value: 5,
                time: radio_prevalue.other_value,
              };
            }
            if (radio_prevalue.checked_value == "I will turn it on myself") {
              all_data_prevalue.outlet_channel_product_variants_available[
                new_key_value
              ] = {
                value: 2,
              };
              n_c_prevValue[send_data.key] = {
                value: 2,
              };
            }
            return { ...n_c_prevValue };
          });
          return { ...radio_prevalue };
        });
        return { ...all_data_prevalue };
      });
      return { ...prevalue };
    });
    SetBottomSheetData((prevalue: any) => {
      prevalue.is_open = false;
      prevalue.data = {};
      return { ...prevalue };
    });
  }

  function is_submit_disabled() {
    let return_data = false;
    if (Object.keys(new_checked1).length > 0) {
      return_data = false;
    } else {
      return_data = true;
    }
    return return_data;
  }

  return (
    <div className="checktable-page">
      <Toaster position="top-center" reverseOrder={false} />
      {/* Header */}
      {/* trigger-menu-wrapper */}
      <div
        className={
          props.is_menu_open
            ? product_data.length == 0
              ? " head-bar w-100"
              : " head-bar sticky-sm"
            : "head-bar sticky-top"
        }
        style={
          props.is_menu_open == false
            ? { width: width }
            : product_data.length != 0
            ? { width: width_ref + 46 }
            : {}
        }
      >
        {/* <div className=" trigger-menu"> */}
        <div className=" d-flex align-items-center">
          <h6 className="mb-0">{props.title}</h6>
        </div>

        <div className="row align-items-cetner pt-2">
          <div className="col-md-7 p-0 mb-sm-2 mb-md-0">
            <div className="">
              {/* <AliceCarousel
                mouseTracking
                touchMoveDefaultEvents={false}
                // items={productsRestraunt}
                // paddingLeft={50}
                paddingRight={30}
                disableDotsControls
                responsive={responsive}
                disableButtonsControls
                controlsStrategy="alternate"
              > */}
              <div className="mt-1 d-flex align-items-center bg-fff ps-0 p-2 md-px-3 ">
                <div className="d-flex align-items-center scrollX">
                  {filter_data.view_option.map((options, o_index) => (
                    // <div
                    //   key={o_index}
                    //   className="text-center cursor me-2"

                    //     // const timeOutId = setTimeout(() => {
                    //     //   SetFilterData((prevValue: any) => {
                    //     //     prevValue['view'] = options
                    //     //     prevValue.page_no = 1
                    //     //     get_products(prevValue)
                    //     //     return { ...prevValue }
                    //     //   })
                    //     // }, 500)
                    //     // return () => clearTimeout(timeOutId)
                    //
                    //   onDragStart={handleDragStart}
                    // >
                    <button
                      type="button"
                      className={
                        filter_data.view.label == options.label
                          ? "btn btn-text header-tap me-1 px-3 center"
                          : "btn btn-text text-black me-1 px-3 center"
                      }
                      onClick={(e) => {
                        console.log("Brand on change e :", e);
                        console.log("Brand on change options :", options);
                        SetFilterData((prevValue: any) => {
                          prevValue.view = options;
                          prevValue.page_no = 1;
                          if (props.type == "available") {
                            get_available_products(prevValue, false);
                          } else {
                            get_products(prevValue, false);
                          }
                          return { ...prevValue };
                        });
                      }}
                    >
                      {options.label}
                    </button>
                  ))}
                </div>
              </div>
              {/* </AliceCarousel>*/}
            </div>
          </div>
          {/* search */}
          <div className="col-md-3 col-lg-4 px-2 col-10">
            <div className="row px-md-2 md-none">
              <div className="input-icons form-group">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search products"
                  value={filter_data.search}
                  onChange={(e) => {
                    console.log("search value :", e.target.value);
                    SetFilterData((prevValue: any) => {
                      prevValue.search = e.target.value;
                      return { ...prevValue };
                    });
                  }}
                />
                <div className="cursor ps-2 ">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "c241785c497fb98f5f7d4e587b3857ba.svg"
                    }
                    className="w-20px"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* filter */}
          <div className="col-2 col-md-2 col-lg-1">
            <div
              className="center cursor pt-2"
              onClick={(event) => {
                if (width < 768) {
                  filter_fun("bottom", true, event);
                } else {
                  filter_fun("right", true, event);
                }
              }}
            >
              {/* <Ionicons name="filter" size={18} color="black" /> */}
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "311ae78abd7e491e4b25539cfbf1a03f.png"
                }
                className="type-img-big"
              />
              {width > 768 ? (
                <p className="text-gray fs-7 mb-0 ps-2">Filter</p>
              ) : null}
            </div>
          </div>
        </div>
        {/* outlet filter */}
        <div className="row align-items-cetner pt-2">
          <div className="col-2 col-md-2 col-lg-1">
            <label>
              <span>Outlet List</span>
            <select
              value={selected_outlet_data.name}
              onChange={(e: any) => {
                console.log("on change e :", e.target.value);
                SetSelectedOutletData(e.target.value);
                let sel_value = outlet_data_list.filter((ele:any) => ele.name == e.target.value);
                console.log("sel_value :",sel_value)
                SetSelectedOutletData(sel_value[0]);
                if (e.target.value != "All") {
                  const filtered = filtered_outlet_data.filter(
                    (item: any) =>
                      e.target.value === "" || item.name === e.target.value
                  );
                  console.log("filtered outlet data : ", filtered);
                  SetOutletData(filtered);
                } else {
                  SetOutletData(filtered_outlet_data);
                }
              }}
            >
              {outlet_data_list.map((option: any, index: any) => {
                return <option key={index}>{option.name}</option>;
              })}
            </select>
            </label>
          </div>
        </div>
        {/* </div> */}
      </div>

      <div
        className={
          props.is_menu_open
            ? product_data.length == 0
              ? "mt-3 px-md-3 px-sm-0 w-100"
              : "mt-3 px-md-3 px-sm-0"
            : "mb-5 mt-3 px-md-3 px-sm-0 overflow-hidden"
        }
        style={
          props.is_menu_open == false
            ? { width: width }
            : product_data.length != 0
            ? { width: width_ref + 46 }
            : {}
        }
      >
        <div ref={ref} className="">
          {/* filter header */}
          {/* <div className="pt-3 d-md-block d-sm-none bg-fff  px-3">
            <p className='text-black  mb-4 fs-5'>Assigning filters </p>
            {/* view */}
          {/* <div className="col-md-6">
              <label>View</label>
              <Select
                components={animatedComponents}
                className="basic-select"
                classNamePrefix="select Brand"
                placeholder="Select Brand"
                id="brand"
                // isMulti
                Group
                value={filter_data.view}
                options={filter_data.view_option}
                onChange={(e, action) => {
                  console.log('Brand on change e :', e)
                  console.log('Brand on change action :', action)
                  const timeOutId = setTimeout(() => {
                    SetFilterData((prevValue: any) => {
                      prevValue['view'] = e
                      prevValue.page_no = 1
                      get_products(prevValue)
                      return { ...prevValue }
                    })
                  }, 500)
                  return () => clearTimeout(timeOutId)
                }}
              />
            </div> /}
            <AssignAvailableFilterComponent
              filter_data={filter_data}
              onChange={(data) => {
                // const timeOutId = setTimeout(() => {
                SetFilterData((prevValue: any) => {
                  prevValue[data.key] = data.value
                  prevValue.page_no = 1
                  get_products(prevValue)
                  return { ...prevValue }
                })
                // }, 500)
                // return () => clearTimeout(timeOutId)
              }}
            />
          </div> */}

          {/* Body */}
          <div className="p-3 w-100 bg-fff ">
            {is_loading ? (
              <div className="mt-3 table-responsive">
                <table
                  ref={tableRef}
                  className="table table-bordered border table-bordered table-condensed"
                >
                  <thead className="sticky-table-head bg-fff">
                    <tr className="th-prod">
                      <th className="bg-fff col p-0" rowSpan="2">
                        <div className="px-3 py-4">
                          <Skeleton width={100} />
                        </div>
                      </th>
                      {Array.from({ length: columns }).map((_, index) => (
                        <th className="col p-0" key={index} colSpan={columns}>
                          <div className="text-center p-2">
                            <Skeleton width={80} />
                          </div>
                        </th>
                      ))}
                    </tr>
                    <tr>
                      {Array.from({ length: columns }).map((_, o_index) =>
                        Array.from({ length: columns }).map((_, c_index) => (
                          <th className="col p-0" key={o_index + "_" + c_index}>
                            <div className="p-2 text-center">
                              <Skeleton width={50} />
                            </div>
                          </th>
                        ))
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: rows }).map((_, index) => (
                      <tr key={index}>
                        <td>
                          <Skeleton width={100} />
                        </td>
                        {Array.from({ length: columns * columns }).map(
                          (_, c_index) => (
                            <td key={c_index}>
                              <Skeleton width={80} />
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : product_data.length == 0 ? (
              <div className="center p-3 bg-fff">
                <div>
                  <img
                    src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img"
                  />
                  <p className="text-center">No data found!</p>
                </div>
              </div>
            ) : (
              <div className="mt-3 table-responsive ">
                <InfiniteScroll
                  // className="row"
                  dataLength={product_data?.length}
                  next={() => {
                    on_next();
                  }}
                  hasMore={filter_data.next_page}
                  loader={
                    <div className="d-flex align-items-center justify-content-center text-center p-5">
                      <div className="lds-dual-ring"></div>
                      <span className=" small ps-2">Just a second ...</span>
                    </div>
                  }
                >
                  <table
                    ref={tableRef}
                    className="table table-bordered border table-bordered table-condensed"
                  >
                    <thead className="sticky-table-head bg-fff">
                      <tr className="th-prod">
                        <th className="bg-fff col p-0 " rowSpan="2">
                          <div className="px-3 py-4">Product Name</div>
                        </th>

                        {outlet_data.map(
                          (o_ele: any, o_index: any) =>
                            o_ele?.checked && (
                              <th
                                className="col p-0 "
                                key={o_index + "_" + o_ele.name}
                                colSpan={channel_data.length}
                              >
                                <div className="text-center p-2   ">
                                  {o_ele.name}
                                </div>
                              </th>
                            )
                        )}
                      </tr>
                      <tr>
                        {outlet_data.map(
                          (o_ele: any, o_index: any) =>
                            o_ele?.checked &&
                            channel_data.map((c_ele: any, c_index: any) => (
                              <th
                                className="col p-0"
                                key={
                                  c_index + "_" + o_ele.name + "_" + c_ele.name
                                }
                              >
                                <div className="p-2 text-center  ">
                                  {c_ele.name}
                                </div>
                              </th>
                            ))
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {product_data.map((p_ele: any, p_index: any) => (
                        <OwnAccordion
                          key_value={p_index + "_" + p_ele.name}
                          ele={p_ele}
                          // for={"product_group"}
                          parent_id={0}
                          p_index={p_index}
                          outlet_data={outlet_data}
                          channel_data={channel_data}
                          space={"0"}
                          new_checked={new_checked}
                          on_new_check={(data: any) => {
                            SetNewChecked((prevValue) => {
                              prevValue.push(data);
                              return [...prevValue];
                            });
                          }}
                          on_new_uncheck={(data: any) => {
                            console.log("on new uncheck");
                            SetNewChecked((prevValue) => {
                              prevValue.splice(data.index, 1);
                              return [...prevValue];
                            });
                          }}
                          on_new_check1={(data: any) => {
                            console.log("on_new_check1");
                            SetNewChecked1((prevValue: any) => {
                              prevValue[data.key] = data.value;
                              return { ...prevValue };
                            });
                          }}
                          on_new_uncheck1={(data: any) => {
                            console.log("on new uncheck1");
                            SetNewChecked1((prevValue: any) => {
                              delete prevValue[data.key];
                              return { ...prevValue };
                            });
                          }}
                          on_new_pause1={(data: any) => {
                            SetNewChecked1((prevValue: any) => {
                              prevValue[data.key] = data.value;
                              console.log("on pause new 1 :", prevValue);
                              return { ...prevValue };
                            });
                          }}
                        />
                      ))}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            )}
            {/* pagination */}
            {/* <div className="d-flex align-items-center mb-5">
              {filter_data.page_no > 1 ? (
                <button
                  className="me-2 center btn btn-white btn-radius fw-bold btn-sm"
                  onClick={() => {
                    console.log("Prev");
                    on_prev();
                  }}
                >
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "3172f69196e958f3bc4edb405f97ce29.svg"
                    }
                    className="w-20px"
                  />
                  <p className="text-prime ps-1">Prev</p>
                </button>
              ) : null}

              <div className={filter_data.page_no > 1 ? "ps-3" : ""}>
                {filter_data.next_page ? (
                  <button
                    className="center btn btn-white btn-radius fw-bold btn-sm"
                    onClick={() => {
                      console.log("Next");
                      on_next();
                    }}
                  >
                    <p className="text-prime pe-1">Next</p>
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "ffb1885fc1b6450d7b6c9652189cdcb2.svg"
                      }
                      className="w-20px"
                    />
                  </button>
                ) : null}
              </div>
            </div> */}
            {/* submit */}
            <div
              style={
                props.is_menu_open == false
                  ? { width: width }
                  : product_data.length != 0
                  ? { width: width_ref }
                  : {}
              }
              className="align-items-center bg-fff p-2 bottom-sm-50px-fixed bottom-md-0px-fixed sm-w-100 md-w-fill"
            >
              {product_data.length != 0 ? (
                <div className="">
                  <button
                    className={
                      is_submit_disabled()
                        ? "btn bg-prime text-white btn-radius fw-bold py-1 px-3 btn-block footer_button"
                        : "btn bg-prime text-white btn-radius fw-bold py-1 px-3 btn-block footer_button"
                    }
                    onClick={() => {
                      console.log("submit");
                      if (Object.keys(new_checked1).length > 0) {
                        if (props.type == "assign") {
                          assign_submit();
                        } else if (props.type == "available") {
                          available_submit();
                        }
                      }
                    }}
                  >
                    Submit
                  </button>
                </div>
              ) : null}
            </div>

            {/* <div
              className="mt-3 " >
              <table
                border="1"
                className="table table-hover align-middle table-bordered" >
                <thead className="bg-fff sticky-table-headers__sticky"
                style={{position: "sticky", top: "0"}}>
                  <tr className=''>
                    <th className="bg-fff col p-0 " rowSpan="2">
                      <div className="  p-2 py-4">
                      Product Name
                      </div>
                    </th>

                    {outlet_data.map((o_ele: any, o_index: any) =>
                      <th className="col p-0 "
                        key={o_index + '_' + o_ele.name}
                        colSpan={channel_data.length}
                        
                      >
                        <div className="text-center   p-2 ">
                          {o_ele.name}
                        </div>
                      </th>
                    )}
                  </tr>
                  <tr>
                    {outlet_data.map((o_ele: any, o_index: any) =>
                      channel_data.map((c_ele: any, c_index: any) => (
                        <th className='col p-0'
                          key={c_index + '_' + o_ele.name + '_' + c_ele.name}
                        >
                          <div className="p-2 text-center -black  ">
                            {c_ele.name}
                          </div>
                        </th>
                      ))
                    )}
                  </tr>



                  {* <tr className="d-flex">
                  {outlet_data.map((o_ele: any, o_index: any) =>
                    channel_data.map((c_ele: any, c_index: any) => (
                      <th className='col-1'
                        key={c_index + '_' + o_ele.name + '_' + c_ele.name}
                      >
                        {c_ele.name}
                      </th>
                    ))
                  )}
                </tr> *}
                </thead>
                <tbody>
                  {product_data.map((p_ele: any, p_index: any) => (
                    <OwnAccordion
                      key_value={p_index + '_' + p_ele.name}
                      ele={p_ele}
                      // for={"product_group"}
                      parent_id={0}
                      p_index={p_index}
                      outlet_data={outlet_data}
                      channel_data={channel_data}
                      space={'0'}
                      new_checked={new_checked}
                      on_new_check={(data) => {
                        SetNewChecked((prevValue: any) => {
                          prevValue.push(data)
                          return [...prevValue]
                        })
                      }}
                      on_new_uncheck={(data) => {
                        SetNewChecked((prevValue: any) => {
                          prevValue.splice(data.index, 1)
                          return [...prevValue]
                        })
                      }}
                    />
                  ))}
                </tbody>
              </table>

              <div className="">
                {filter_data.page_no > 1 ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      console.log('Prev')
                      on_prev()
                    }}
                  >
                    Prev
                  </button>
                ) : null}

                {filter_data.next_page ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      console.log('Next')
                      on_next()
                    }}
                  >
                    Next
                  </button>
                ) : null}
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {bottom_sheet_data.is_open ? (
        <div className="out_of_stock_swipe">
          <SwipeableBottomSheet
            overflowHeight={0}
            overlay={true}
            style={{ zIndex: 1300 }}
            open={bottom_sheet_data.is_open}
            onClose={() => {
              SetBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                return { ...prevalue };
              });
              SetUncheckRadioValue({
                checked_value: "",
                other_value: "",
              });
            }}
            onChange={() => {
              // SetFilterOpen(false)
              SetBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                return { ...prevalue };
              });
              SetUncheckRadioValue({
                checked_value: "",
                other_value: "",
              });
            }}
          >
            <div
              className="bg-fff radius-top"
              style={{ minHeight: "500px", maxHeight: "90vh" }}
            >
              <div className="">
                <div className="w-100 center">
                  <hr className="hr-drawer" />
                </div>
                <div className="p-3">
                  <h6 className="fs-5 fw-600">Set time to make out of stock</h6>
                </div>
              </div>
              <div className="p-3">
                <div className="mb-3 row">
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"2 Hours"}
                      checked={
                        uncheck_radio_value.checked_value == "2 Hours"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        console.log("name event :", event);
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">2 Hours</p>
                  </label>

                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"4 Hours"}
                      checked={
                        uncheck_radio_value.checked_value == "4 Hours"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">4 Hours</p>
                  </label>

                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"1 Day"}
                      checked={
                        uncheck_radio_value.checked_value == "1 Day"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">1 Day</p>
                  </label>

                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"2 Day"}
                      checked={
                        uncheck_radio_value.checked_value == "2 Day"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">2 Days </p>
                  </label>

                  <label
                    // className="form-label d-flex align-items-center mb-3 cursor"
                    className={
                      // checked == false
                      all_data?.outlet_channel_product_variants_available[
                        bottom_sheet_data.data.key
                      ].hasOwnProperty("next_slot")
                        ? "form-label d-flex align-items-center mb-3 cursor"
                        : "form-label d-flex align-items-center mb-3 cursor border-light-gray"
                    }
                  >
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"Next Available Slot"}
                      disabled={
                        all_data?.outlet_channel_product_variants_available[
                          bottom_sheet_data.data.key
                        ].hasOwnProperty("next_slot")
                          ? false
                          : true
                      }
                      checked={
                        uncheck_radio_value.checked_value ==
                        "Next Available Slot"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p
                      className={
                        // checked == false
                        all_data?.outlet_channel_product_variants_available[
                          bottom_sheet_data.data.key
                        ].hasOwnProperty("next_slot")
                          ? "capitalize"
                          : "capitalize text-light-gray"
                      }
                    >
                      {all_data?.outlet_channel_product_variants_available[
                        bottom_sheet_data.data.key
                      ].hasOwnProperty("next_slot")
                        ? "Next Available Slot At " +
                          all_data?.outlet_channel_product_variants_available[
                            bottom_sheet_data.data.key
                          ].next_slot
                        : "Next Available Slot"}
                    </p>
                  </label>

                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"Custom Date & Time"}
                      // value={ele.id}
                      checked={
                        uncheck_radio_value.checked_value ==
                        "Custom Date & Time"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">Custom Date & Time</p>
                  </label>

                  {uncheck_radio_value.checked_value == "Custom Date & Time" ? (
                    <div className="px-0 w-50">
                      <input
                        type="datetime-local"
                        className="me-2 form-control"
                        min={new Date().toJSON().slice(0, 10)}
                        onChange={(event: any) => {
                          console.log("date event :", event);
                          let value = event.target.value;
                          let formattedDate = value.split("T");
                          console.log("formattedDate :", formattedDate);
                          var timeSplit = formattedDate[1].split(":");
                          var hours = parseInt(timeSplit[0]);
                          var minutes = parseInt(timeSplit[1]);

                          // Determine AM or PM
                          var period = hours >= 12 ? "PM" : "AM";

                          // Convert hours to 12-hour format
                          hours = hours % 12;
                          hours = hours ? hours : 12; // 0 should be 12

                          // Add leading zero to minutes if needed
                          minutes = minutes < 10 ? "0" + minutes : minutes;

                          // Return the formatted time
                          let time_value = hours + ":" + minutes + " " + period;
                          console.log("time_value :", time_value);
                          let other_value = formattedDate[0] + " " + time_value;
                          console.log("other_value :", other_value);
                          SetUncheckRadioValue((preavlue: any) => {
                            // preavlue.checked_value = event.target.name;
                            preavlue.other_value = other_value;
                            return { ...preavlue };
                          });
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="d-flex ">
                  <small className="text-black fw-bold">Manualy turn on</small>
                </div>
                <div className="my-3">
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"I will turn it on myself"}
                      // value={ele.id}
                      checked={
                        uncheck_radio_value.checked_value ==
                        "I will turn it on myself"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">I will turn it on myself</p>
                  </label>
                </div>
              </div>
              <div className="p-3">
                <div className="end">
                  <button
                    className="btn btn-primary btn-lg btn-block"
                    onClick={() => {
                      submit_uncheck_time();
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </SwipeableBottomSheet>
        </div>
      ) : null}

      {/* Filter */}
      {["left", "right", "top", "bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={(event) => {
              filter_fun(anchor, false, event);
            }}
            onOpen={(event) => {
              filter_fun(anchor, true, event);
            }}
          >
            <div className="overflowX-hidden">
              <div className="row sticky-top bg-fff shadow-bottom p-3 align-items-center">
                <div className="col-10">
                  <p className="text-dark fw-600 mb-0 fs-6">Filters</p>
                </div>
                <div
                  className="col-2 ps-0 pe-1 text-end cursor"
                  onClick={(event) => {
                    filter_fun(anchor, false, event);
                  }}
                >
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "8555c06ac82c76f24547be05700308af.svg"
                    }
                    className="w-20px"
                  />
                </div>
              </div>
              <div className="p-3 pb-5">
                <AssignAvailableFilterComponent
                  filter_data={filter_data}
                  onChange={(data) => {
                    const timeOutId = setTimeout(() => {
                      SetFilterData((prevValue: any) => {
                        prevValue[data.key] = data.value;
                        prevValue.page_no = 1;
                        if (props.type == "available") {
                          get_available_products(prevValue, false);
                        } else {
                          get_products(prevValue, false);
                        }
                        return { ...prevValue };
                      });
                    }, 500);
                    return () => clearTimeout(timeOutId);
                  }}
                />
              </div>
              <div
                className={
                  width < 786
                    ? "row bottom-fix left-0 right-0 shadow"
                    : "row bottom-fix w-30 shadow right-0"
                }
              >
                <div className="col-6 center bg-fff p-3">
                  <p className="text-black fw-bold mb-0 fs-7">Reset</p>
                </div>
                <div
                  className="col-6 bg-prime center p-3"
                  onClick={(event) => {
                    filter_fun(anchor, false, event);
                  }}
                >
                  <p className="text-white fw-bold mb-0 fs-7">Show products</p>
                </div>
              </div>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
