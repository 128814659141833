/**
 * Bind `target` event `eventName`'s callback `listener`.
 */
export default function on(target, eventType, listener, options) {
  if (options === void 0) {
    options = false;
  }

  target.addEventListener(eventType, listener, options);
  return {
    off: function off() {
      target.removeEventListener(eventType, listener, options);
    }
  };
}