import React, {
  useState,
  useEffect,
  useContext,
  Component,
  useMemo,
} from "react";
import DataContext from "../../utils/Context/DataContext";
import { Link, useParams } from "react-router-dom";
import { Table, Column, HeaderCell, Cell, ColumnGroup } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Select from "react-select";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import { MaterialIcons, AntDesign, Ionicons } from "@expo/vector-icons";
import Slider from "@material-ui/core/Slider";
import { api } from "../../utils/Api";

function valuetext(value) {
  return `${value}°C`;
}

const pricingOptions = [
  { value: "outlet", label: "Outlet" },
  { value: "channel", label: "Channel" },
];

const priceType = [
  { value: "margin", label: "Margin Price" },
  { value: "buy", label: "Buying Price" },
  { value: "commission", label: "Commission Price" },
];

// let selected_field_option = { label: "Sellers Price", value: "sellers_price" };

const OutletCell = ({
  rowData,
  checkedkeys,
  onChange,
  onBtnClick,
  currencies_option,
  selected_field_option,
  sellers_price_on_change,
  seller_price_value,
  key,
  ...props
}) => (
  // outlet_index={index}
  // channel_index={cc_index}
  // channel_type="custom"
  // from="channel"
  <Cell {...props} style={{ padding: 0 }} key={key} dataKey={key}>
    <div className="p-2 d-flex align-items-center" key={key}>
      {rowData.hasOwnProperty("is_price") ? (
        rowData.is_price == true ? (
          <>
            {/* sellers_price */}
            {selected_field_option.value == "sellers_price" ? (
              <input
                type="text"
                key={key}
                onClick={() => {
                  console.log("sellers_price rowData :", rowData);
                }}
                className="w-100 form-control"
                placeholder="Enter Selling Price"
                value={seller_price_value(rowData, props, event)}
                // value={
                //   rowData.name == "Non Mrp Pricing"
                //     ? rowData.hasOwnProperty("outlets")
                //       ? props.from == "outlet"
                //         ? rowData.outlets[props.outlet_index].non_mrp_pricing
                //             .sellers_price
                //         : props.channel_type != null
                //         ? props.channel_type == "default"
                //           ? rowData.outlets[props.outlet_index].channels[
                //               props.channel_index
                //             ].non_mrp_pricing.sellers_price
                //           : rowData.outlets[props.outlet_index].custom_channels[
                //               props.channel_index
                //             ].non_mrp_pricing.sellers_price
                //         : ""
                //       : ""
                //     : ""
                // }
                // value={
                //   rowData.name == "Non Mrp Pricing"
                //     ? rowData.outlets &&
                //       rowData.outlets[props.outlet_index].non_mrp_pricing
                //         .sellers_price
                //     : null
                // }
                // value={
                //   rowData.is_price_value &&
                //   rowData.is_price_value.sellers_price
                // }
                // value={get_sell_price(rowData, props, price_for)}
                // rowData.is_price_value.sellers_price
                onChange={(event) => {
                  // onChange(rowData, props, event);
                  event.preventDefault();
                  console.log("props.outlet_index :", props.outlet_index);
                  console.log("props :", props);
                  console.log("rowData :", rowData);

                  sellers_price_on_change(rowData, props, event);
                }}
              />
            ) : null}
            {/* selling_type */}
            {selected_field_option.value == "selling_type" ? (
              <div className="">
                <label className="">
                  <input type="radio" onChange={(event) => {}} />
                  <span className="">Variable Selling Price</span>
                </label>
                <label className="">
                  <input type="radio" onChange={(event) => {}} />
                  <span className="">Fixed Selling Price</span>
                </label>
              </div>
            ) : null}
            {/* min_selling_price */}
            {selected_field_option.value == "min_selling_price" ? (
              <input
                type="text"
                className="w-100 form-control"
                placeholder="Enter Min Selling Price"
                onChange={(event) => {
                  // onChange(rowData, props, event);
                }}
              />
            ) : null}
            {/* max_selling_price */}
            {selected_field_option.value == "max_selling_price" ? (
              <input
                type="text"
                className="w-100 form-control"
                placeholder="Enter Max Selling Price"
                onChange={(event) => {
                  // onChange(rowData, props, event);
                }}
              />
            ) : null}
            {/* fixed_selling_price */}
            {selected_field_option.value == "fixed_selling_price" ? (
              <input
                type="text"
                className="w-100 form-control"
                placeholder="Enter Fixed Selling Price"
                onChange={(event) => {
                  // onChange(rowData, props, event);
                }}
              />
            ) : null}
            {/* currency_id */}
            {selected_field_option.value == "currency_id" ? (
              <Select
                className="Price"
                options={currencies_option}
                onChange={(e) => {
                  console.log("currency :", e);
                }}
              />
            ) : null}
            {/* info */}
            <div
              className="ps-2 cursor"
              onClick={(event) => {
                onBtnClick(rowData, props, event);
              }}
            >
              <AntDesign name="infocirlceo" size={15} color="gray" />
            </div>
          </>
        ) : null
      ) : null}
    </div>
  </Cell>
);

function get_group_variant(parent_object, from) {
  let variant_object, variant_unique_id, group_object, group_unique_id;
  if (from == "Non Mrp Pricing") {
    variant_object = parent_object;
    variant_unique_id = parent_object.unique_id;
    group_object =
      variant_object[Object.getOwnPropertySymbols(variant_object)[0]];
    group_unique_id = group_object.unique_id;
    let return_value = {
      variant_object: variant_object,
      variant_unique_id: variant_unique_id,
      group_object: group_object,
      group_unique_id: group_unique_id,
    };
    return return_value;
  } else if (from == "Mrp Pricing") {
    variant_object =
      parent_object[Object.getOwnPropertySymbols(parent_object)[0]];
    variant_unique_id = variant_object.unique_id;
    group_object =
      variant_object[Object.getOwnPropertySymbols(variant_object)[0]];
    group_unique_id = group_object.unique_id;
    let return_value = {
      variant_object: variant_object,
      variant_unique_id: variant_unique_id,
      group_object: group_object,
      group_unique_id: group_unique_id,
    };
    return return_value;
  }
}

export default function ProductPricingComponent(props: any) {
  const context = useContext(DataContext);
  const [table_loading, SetTableLoading] = useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [width, SetWidth] = React.useState(innerWidth);
  const [id_value, SetIdValue] = useState(useParams());

  const [hover, setHover] = React.useState(true);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [side_drawer, SetSideDrawer] = React.useState({
    type: "",
  });
  const [value, setValue] = React.useState(30);
  const [price_for, SetPriceFor] = useState(pricingOptions[0]);

  ////////////////////////////////////////////////////////////////    10/06/2022
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);

  const [view_option, SetViewOption] = useState([
    { label: "New", value: "new" },
    { label: "Active", value: "active" },
    { label: "Unavailable", value: "unavailable" },
    { label: "Paused", value: "paused" },
  ]);
  const [selected_view_option, SetSelectedViewOption] = useState({});
  const [count_option, SetCountOption] = useState([
    { label: "10", value: "10" },
    { label: "20", value: "30" },
    { label: "30", value: "30" },
  ]);
  const [selected_count_option, SetSelectedCountOption] = useState({});
  const [filter_products_value, SetFilterProductsValue] = useState({
    own_products: false,
    others_products: false,
  });
  const [search_value, SetSearchValue] = useState("");
  const [sort_option, SetSortOption] = useState([
    { label: "Ascending order", value: "asce" },
    { label: "Descending order", value: "desc" },
  ]);
  const [selected_sort_option, SetSelectedSortOption] = useState({});
  const [product_brands_option, SetProductBrandsOption] = useState([]);
  const [selected_brand, SetSelectedBrand] = useState([]);
  const [product_categories_option, SetProductCategoriesOption] = useState([]);
  const [selected_category, SetSelectedCategory] = useState([]);
  const [filter_mrp, SetFilterMrp] = useState({
    min_mrp: "",
    max_mrp: "",
  });

  const [product_data, SetProductData] = useState([]);
  const [product_data1, SetProductData1] = useState([]);
  const [outlets, SetOutlet] = useState([]);
  const [size_value, SetSizeValue] = useState([]);
  const [image_base_url, SetImageBaseUrl] = useState("");
  const [selling_type_option, SetSellingTypeOption] = useState([]);
  // const [selected_selling_type, SetSelectedSellingType] = useState({});
  const [channel_option, SetChannelOption] = useState([]);
  const [outlet_option, SetOutletOption] = useState([]);
  const [field_option, SetFieldOption] = useState([
    { label: "Sellers Price", value: "sellers_price" },
    { label: "Selling Type", value: "selling_type" },
    { label: "Min Selling Price", value: "min_selling_price" },
    { label: "Max Selling Price", value: "max_selling_price" },
    { label: "Fixed Selling Price", value: "fixed_selling_price" },
    { label: "Currency", value: "currency_id" },
  ]);
  const [selected_field_option, SetSelectedFieldOption] = useState(
    field_option[0]
  );

  const [currencies_option, SetCurrenciesOption] = useState([]);

  useEffect(() => {
    console.log("Pricing Product On mount :", props);
    console.log("Pricing Product context :", context);
    get_pricing_products();
  }, []);

  async function get_pricing_products() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        page: page_no,
        view:
          Object.keys(selected_view_option).length > 0
            ? selected_view_option.value
            : "",
        filter_count:
          Object.keys(selected_count_option).length > 0
            ? selected_count_option.value
            : "10",
        filter_products: encodeURIComponent(
          JSON.stringify(filter_products_value)
        ),
        search: search_value,
        sort:
          Object.keys(selected_sort_option).length > 0
            ? selected_sort_option.value
            : "desc",
        filter_brand: encodeURIComponent(selected_brand),
        filter_min_mrp: filter_mrp.min_mrp,
        filter_max_mrp: filter_mrp.max_mrp,
        filter_categories: encodeURIComponent(selected_category),
      },
    };

    console.log("get_pricing_products pass_data :", pass_data);
    let response = await api("/brand/outlet_product_pricing_list", pass_data);
    console.log("/brand/outlet_product_pricing_list response :", response);

    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("data")) {
        SetProductData(response.response.data);
        SetProductData1(response.response.data);

        SetTableLoading(false);
      }
      if (response.response.hasOwnProperty("options")) {
        if (response.response.options.hasOwnProperty("outlets")) {
          let outlet_value = response.response.options.outlets;
          let product_data = response.response.data;
          let channel_option_value = [];
          let outlet_option_value = [];
          outlet_value.map((ele: any) => {
            ele.checked = true;
            let out_obj_value = ele;
            out_obj_value.label = out_obj_value.name;
            out_obj_value.value = out_obj_value.id;
            outlet_option_value.push(out_obj_value);
            let obj_value = {
              label: ele.name,
              options: [],
            };
            ele.channels.map((c_ele: any) => {
              c_ele.label = c_ele.name;
              c_ele.value = c_ele.channel_id;
              c_ele.checked = true;
              obj_value.options.push(c_ele);
            });
            ele.custom_channels.map((cc_ele: any) => {
              cc_ele.label = cc_ele.name;
              cc_ele.value = cc_ele.channel_id;
              cc_ele.checked = true;
              obj_value.options.push(cc_ele);
            });
            channel_option_value.push(obj_value);
          });
          SetOutlet(outlet_value);
          SetChannelOption(channel_option_value);
          SetOutletOption(outlet_option_value);
        }
        if (response.response.options.hasOwnProperty("sizes")) {
          SetSizeValue(response.response.options.sizes);
        }
        if (response.response.options.hasOwnProperty("image_base_url")) {
          SetImageBaseUrl(response.response.options.image_base_url);
        }
        if (response.response.options.hasOwnProperty("selling_type")) {
          let selling_type_value = response.response.options.selling_type;
          selling_type_value.map((ele) => {
            ele.label = ele.name;
            ele.value = ele.id;
          });
          SetSellingTypeOption(selling_type_value);
        }
        if (response.response.options.hasOwnProperty("currencies")) {
          SetCurrenciesOption(response.response.options.currencies);
        }
      }
      if (response.response.hasOwnProperty("filter")) {
        if (response.response.filter.hasOwnProperty("product_brands")) {
          SetProductBrandsOption(response.response.filter.product_brands);
        }
        if (response.response.filter.hasOwnProperty("product_categories")) {
          SetProductCategoriesOption(
            response.response.filter.product_categories
          );
        }
      }
      if (response.response.hasOwnProperty("next_page")) {
        if (response.response.next_page) {
          SetNextPage(true);
          SetPageNo((prevValue: any) => {
            prevValue = prevValue + 1;
            return prevValue;
          });
        } else {
          SetNextPage(false);
        }
      }
    }
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function getData() {
    return product_data;
  }

  function handleCheck(value, checked) {
    console.log("handleCheck value :", value);
    console.log("handleCheck checked :", checked);
  }

  function sample_function(rowData, propsData, event) {
    if (event.target.checked) {
      // on check event
      let child_id = [];
      child_id.push(rowData.id);

      if (have_parent(rowData)) {
        child_id.push(rowData._parent.id);
        if (have_parent(rowData._parent)) {
          child_id.push(rowData._parent._parent.id);
          if (have_parent(rowData._parent._parent)) {
            child_id.push(rowData._parent._parent._parent.id);
          }
        }
      }

      if (have_child(rowData)) {
        rowData.children.map((ele, index) => {
          child_id.push(ele.id);
          if (have_child(ele)) {
            ele.children.map((child, c_index) => {
              child_id.push(child.id);
              if (have_child(child)) {
                child.children.map((c_ele, c_e_index) => {
                  child_id.push(c_ele.id);
                });
              }
            });
          }
        });
      }

      console.log("child_ids :", child_id);

      let old_outlets_value = outlets;
      let o_i = propsData.outlet_index;
      let c_i = propsData.channel_index;
      let v1_value = old_outlets_value[o_i].channels[c_i].checkedKeys;

      child_id.map((child, c_index) => {
        let find_value = v1_value.some((item) => item == child);
        if (!find_value) {
          old_outlets_value[propsData.outlet_index].channels[
            propsData.channel_index
          ].checkedKeys.push(child);
        }
      });

      SetOutlet(old_outlets_value);
      // this.setState({ outlets: old_outlets_value }, () => {
      console.log("outlets :", outlets);
      // });
    } else {
      console.log("uncheck event row_data :", rowData);

      let child_id = [];
      let old_outlets_value = outlets;
      let o_i = propsData.outlet_index;
      let c_i = propsData.channel_index;
      let v1_value = old_outlets_value[o_i].channels[c_i].checkedKeys;

      child_id.push(rowData.id);

      if (have_parent(rowData)) {
        let v1 = [];
        rowData._parent.children.map((ele, index) => {
          if (ele.id != rowData.id) {
            v1.push(ele.id);
          }
        });
        v1.map((ele, index) => {
          v1[index] = v1_value.some((item) => item == ele);
        });
        if (v1.every((item) => item === false)) {
          child_id.push(rowData._parent.id);
          if (have_parent(rowData._parent)) {
            let v1 = [];
            rowData._parent._parent.children.map((ele, index) => {
              if (ele.id != rowData._parent.id) {
                v1.push(ele.id);
              }
            });
            v1.map((ele, index) => {
              v1[index] = v1_value.some((item) => item == ele);
            });
            if (v1.every((item) => item === false)) {
              child_id.push(rowData._parent._parent.id);
              if (have_parent(rowData._parent._parent)) {
                child_id.push(rowData._parent._parent._parent.id);
              }
            }
          }
        }
      }

      if (have_child(rowData)) {
        rowData.children.map((ele, index) => {
          child_id.push(ele.id);
          if (have_child(ele)) {
            ele.children.map((child, c_index) => {
              child_id.push(child.id);
              if (have_child(child)) {
                child.children.map((c_ele, c_e_index) => {
                  child_id.push(c_ele.id);
                });
              }
            });
          }
        });
      }

      console.log("child_id :", child_id);

      let to_save_data = v1_value.filter((v1) => {
        return (
          child_id.filter((child) => {
            return v1 == child;
          }).length == 0
        );
      });

      console.log("to_save_data :", to_save_data);
      old_outlets_value[o_i].channels[c_i].checkedKeys = to_save_data;
      SetOutlet(old_outlets_value);
      console.log("outlets :", outlets);
    }
  }

  function have_child(data) {
    if (data.hasOwnProperty("children")) {
      return true;
    } else {
      return false;
    }
  }

  function have_parent(data) {
    if (data.hasOwnProperty("_parent")) {
      if (data._parent != null) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function slide_drawer(anchor: any, open: any, event: any) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  }

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  function get_selected_value(from: any) {
    if (from == "outlet") {
      let return_value = [];
      outlet_option.map((ele: any) => {
        if (ele.checked) {
          return_value.push(ele);
        }
      });
      return return_value;
    }
    if (from == "channel") {
      let return_value = [];
      channel_option.map((ele: any) => {
        ele.options.map((o_ele: any) => {
          if (o_ele.checked) {
            return_value.push(o_ele);
          }
        });
      });
      return return_value;
    }
  }

  function on_change_seller_price(
    rowData,
    props,
    event,
    for_data,
    channel_data
  ) {
    // console.log("sellers_price on rowData ", rowData);
    // console.log("sellers_price on props ", props);
    // console.log("sellers_price on event ", event);
    console.log("sellers_price on for_data ", for_data);
    console.log("sellers_price on channel_data ", channel_data);

    // // console.log("sellers_price on change key ", key);

    let parent_object = rowData[Object.getOwnPropertySymbols(rowData)[0]];

    if (rowData.name == "Non Mrp Pricing") {
      let { variant_object, variant_unique_id, group_object, group_unique_id } =
        get_group_variant(parent_object, "Non Mrp Pricing");

      SetProductData((prevValue: any) => {
        prevValue.map((ele: any, index: any) => {
          if (ele.unique_id == group_unique_id) {
            ele.children.map((c_ele, c_index) => {
              if (c_ele.unique_id == variant_unique_id) {
                c_ele.children.map((cc_ele: any, cc_index) => {
                  if (cc_ele.unique_id == rowData.unique_id) {
                    cc_ele.outlets = c_ele.outlets;
                    cc_ele.outlets.map((o_ele: any, o_index: any) => {
                      if (o_index == props.outlet_index) {
                        if (for_data.value == "outlet") {
                          o_ele.non_mrp_pricing.sellers_price =
                            event.target.value;
                        } else {
                          console.log("channel_data :", channel_data);
                          if (channel_data.default_channel) {
                            o_ele.channels.map(
                              (dch_ele: any, dch_index: any) => {
                                if (dch_index == channel_data.channel_index) {
                                  dch_ele.non_mrp_pricing.sellers_price =
                                    event.target.value;
                                }
                              }
                            );
                          } else {
                            o_ele.custom_channels.map(
                              (cch_ele: any, cch_index: any) => {
                                if (cch_index == channel_data.channel_index) {
                                  cch_ele.non_mrp_pricing.sellers_price =
                                    event.target.value;
                                }
                              }
                            );
                          }
                        }
                      }
                    });
                  }
                });

                c_ele.outlets.map((o_ele: any, o_index: any) => {
                  if (o_index == props.outlet_index) {
                    if (for_data.value == "outlet") {
                      o_ele.non_mrp_pricing.sellers_price = event.target.value;
                    } else {
                      console.log("channel_data :", channel_data);
                      if (channel_data.default_channel) {
                        o_ele.channels.map((dch_ele: any, dch_index: any) => {
                          if (dch_index == channel_data.channel_index) {
                            dch_ele.non_mrp_pricing.sellers_price =
                              event.target.value;
                          }
                        });
                      } else {
                        o_ele.custom_channels.map(
                          (cch_ele: any, cch_index: any) => {
                            if (cch_index == channel_data.channel_index) {
                              cch_ele.non_mrp_pricing.sellers_price =
                                event.target.value;
                            }
                          }
                        );
                      }
                    }
                  }
                });
                //
              }
            });
          }
        });
        return [...prevValue];
      });
    } else {
      if (parent_object.name == "Mrp Pricing") {
        let {
          variant_object,
          variant_unique_id,
          group_object,
          group_unique_id,
        } = get_group_variant(parent_object, "Mrp Pricing");

        console.log("Mrp Pricing variant_object :", variant_object);
        console.log("Mrp Pricing group_object :", group_object);

        // SetProductData((prevValue: any) => {
        //   prevValue.map((ele: any, index: any) => {
        //     if (ele.unique_id == group_unique_id) {
        //       ele.children.map((c_ele, c_index) => {
        //         console.log("c_ele" + c_index + " :", c_ele);

        //         if (c_ele.unique_id == variant_unique_id) {
        //           c_ele.children.map((cc_ele: any, cc_index) => {
        //             console.log("cc_ele" + cc_index + " :", cc_ele);
        //             if (cc_ele.name == "Mrp Pricing") {
        //               cc_ele.children.map((ccc_ele: any, ccc_index: any) => {
        //                 if (ccc_ele.unique_id == rowData.unique_id) {
        //                   console.log("ccc_ele :", ccc_ele);
        //                   ccc_ele.outlets = c_ele.outlets;
        //                   ccc_ele.outlets.map((o_ele: any, o_index: any) => {
        //                     if (o_index == props.outlet_index) {
        //                       if (for_data.value == "outlet") {
        //                         o_ele.mrp_pricing.map(
        //                           (oo_ele: any, oo_index: any) => {
        //                             if (oo_ele.unique_id == rowData.unique_id) {
        //                               if (!oo_ele.hasOwnProperty("price")) {
        //                                 oo_ele.price = {};
        //                               }
        //                               oo_ele.price.sellers_price =
        //                                 event.target.value;
        //                             }
        //                           }
        //                         );
        //                       } else {
        //                         console.log("channel_data :", channel_data);
        //                         if (channel_data.default_channel) {
        //                           o_ele.channels.map(
        //                             (dch_ele: any, dch_index: any) => {
        //                               if (
        //                                 dch_index == channel_data.channel_index
        //                               ) {
        //                                 dch_ele.mrp_pricing.map(
        //                                   (dchc_ele: any, dchc_index: any) => {
        //                                     if (
        //                                       dchc_ele.unique_id ==
        //                                       rowData.unique_id
        //                                     ) {
        //                                       if (
        //                                         !dchc_ele.hasOwnProperty(
        //                                           "price"
        //                                         )
        //                                       ) {
        //                                         dchc_ele.price = {};
        //                                       }
        //                                       dchc_ele.price.sellers_price =
        //                                         event.target.value;
        //                                     }
        //                                   }
        //                                 );
        //                               }
        //                             }
        //                           );
        //                         } else {
        //                           o_ele.custom_channels.map(
        //                             (cch_ele: any, cch_index: any) => {
        //                               if (
        //                                 cch_index == channel_data.channel_index
        //                               ) {
        //                                 cch_ele.mrp_pricing.map(
        //                                   (cchc_ele: any, cchc_index: any) => {
        //                                     if (
        //                                       cchc_ele.unique_id ==
        //                                       rowData.unique_id
        //                                     ) {
        //                                       if (
        //                                         !cchc_ele.hasOwnProperty(
        //                                           "price"
        //                                         )
        //                                       ) {
        //                                         cchc_ele.price = {};
        //                                       }
        //                                       cchc_ele.price.sellers_price =
        //                                         event.target.value;
        //                                     }
        //                                   }
        //                                 );

        //                                 // cch_ele.non_mrp_pricing.sellers_price =
        //                                 //   event.target.value;
        //                               }
        //                             }
        //                           );
        //                         }
        //                       }
        //                     }
        //                   });
        //                 }
        //               });
        //             }
        //           });

        //           c_ele.outlets.map((o_ele: any, o_index: any) => {
        //             if (o_index == props.outlet_index) {
        //               if (for_data.value == "outlet") {
        //                 o_ele.mrp_pricing.map((oo_ele: any, oo_index: any) => {
        //                   if (oo_ele.unique_id == rowData.unique_id) {
        //                     if (!oo_ele.hasOwnProperty("price")) {
        //                       oo_ele.price = {};
        //                     }
        //                     oo_ele.price.sellers_price = event.target.value;
        //                   }
        //                 });
        //                 // o_ele.non_mrp_pricing.sellers_price =
        //                 //   event.target.value;
        //               } else {
        //                 console.log("channel_data :", channel_data);
        //                 if (channel_data.default_channel) {
        //                   // o_ele.channels.map((dch_ele: any, dch_index: any) => {
        //                   //   if (dch_index == channel_data.channel_index) {
        //                   //     dch_ele.non_mrp_pricing.sellers_price =
        //                   //       event.target.value;
        //                   //   }
        //                   // });

        //                   o_ele.channels.map((dch_ele: any, dch_index: any) => {
        //                     if (dch_index == channel_data.channel_index) {
        //                       dch_ele.mrp_pricing.map(
        //                         (dchc_ele: any, dchc_index: any) => {
        //                           if (dchc_ele.unique_id == rowData.unique_id) {
        //                             if (!dchc_ele.hasOwnProperty("price")) {
        //                               dchc_ele.price = {};
        //                             }
        //                             dchc_ele.price.sellers_price =
        //                               event.target.value;
        //                           }
        //                         }
        //                       );
        //                     }
        //                   });
        //                 } else {
        //                   // o_ele.custom_channels.map(
        //                   //   (cch_ele: any, cch_index: any) => {
        //                   //     if (cch_index == channel_data.channel_index) {
        //                   //       cch_ele.non_mrp_pricing.sellers_price =
        //                   //         event.target.value;
        //                   //     }
        //                   //   }
        //                   // );

        //                   o_ele.custom_channels.map(
        //                     (cch_ele: any, cch_index: any) => {
        //                       if (cch_index == channel_data.channel_index) {
        //                         cch_ele.mrp_pricing.map(
        //                           (cchc_ele: any, cchc_index: any) => {
        //                             if (
        //                               cchc_ele.unique_id == rowData.unique_id
        //                             ) {
        //                               if (!cchc_ele.hasOwnProperty("price")) {
        //                                 cchc_ele.price = {};
        //                               }
        //                               cchc_ele.price.sellers_price =
        //                                 event.target.value;
        //                             }
        //                           }
        //                         );
        //                       }
        //                     }
        //                   );
        //                 }
        //               }
        //             }
        //           });
        //           //
        //         }
        //       });
        //     }
        //   });
        //   return [...prevValue];
        // });
      }
    }
  }

  function get_seller_price_value(rowData, props, event) {
    if (rowData.name == "Non Mrp Pricing") {
      if (rowData.hasOwnProperty("outlets")) {
        if (props.from == "outlet") {
          if (
            rowData.outlets[props.outlet_index].non_mrp_pricing.hasOwnProperty(
              "sellers_price"
            )
          ) {
            return rowData.outlets[props.outlet_index].non_mrp_pricing
              .sellers_price;
          } else {
            return "";
          }
        } else if (props.channel_type != null) {
          if (props.channel_type == "default") {
            if (
              rowData.outlets[props.outlet_index].channels[
                props.channel_index
              ].non_mrp_pricing.hasOwnProperty("sellers_price")
            ) {
              return rowData.outlets[props.outlet_index].channels[
                props.channel_index
              ].non_mrp_pricing.sellers_price;
            } else {
              return "";
            }
          } else {
            if (
              rowData.outlets[props.outlet_index].custom_channels[
                props.channel_index
              ].non_mrp_pricing.hasOwnProperty("sellers_price")
            ) {
              return rowData.outlets[props.outlet_index].custom_channels[
                props.channel_index
              ].non_mrp_pricing.sellers_price;
            } else {
              return "";
            }
          }
        }
      }
    } else {
      if (rowData.hasOwnProperty("outlets")) {
        if (props.from == "outlet") {
          rowData.outlets[props.outlet_index].mrp_pricing.map(
            (ele: any, index: any) => {
              if (ele.unique_id == rowData.unique_id) {
                if (
                  rowData.outlets[props.outlet_index].mrp_pricing[
                    index
                  ].hasOwnProperty("price")
                ) {
                  if (
                    rowData.outlets[props.outlet_index].mrp_pricing[
                      index
                    ].price.hasOwnProperty("sellers_price")
                  ) {
                    rowData.outlets[props.outlet_index].mrp_pricing[index].price
                      .sellers_price;
                  } else {
                    return "";
                  }
                } else {
                  return "";
                }
              }
            }
          );
          // if (
          //   rowData.outlets[props.outlet_index].non_mrp_pricing.hasOwnProperty(
          //     "sellers_price"
          //   )
          // ) {
          //   return rowData.outlets[props.outlet_index].non_mrp_pricing
          //     .sellers_price;
          // } else {
          //   return "";
          // }
        } else if (props.channel_type != null) {
          if (props.channel_type == "default") {
            rowData.outlets[props.outlet_index].mrp_pricing.map(
              (ele: any, index: any) => {
                if (ele.unique_id == rowData.unique_id) {
                  if (
                    rowData.outlets[props.outlet_index].channels[
                      props.channel_index
                    ].mrp_pricing[index].hasOwnProperty("price")
                  ) {
                    if (
                      rowData.outlets[props.outlet_index].channels[
                        props.channel_index
                      ].mrp_pricing[index].price.hasOwnProperty("sellers_price")
                    ) {
                      rowData.outlets[props.outlet_index].channels[
                        props.channel_index
                      ].mrp_pricing[index].price.sellers_price;
                    } else {
                      return "";
                    }
                  } else {
                    return "";
                  }
                }
              }
            );
            // if (
            //   rowData.outlets[props.outlet_index].channels[
            //     props.channel_index
            //   ].non_mrp_pricing.hasOwnProperty("sellers_price")
            // ) {
            //   return rowData.outlets[props.outlet_index].channels[
            //     props.channel_index
            //   ].non_mrp_pricing.sellers_price;
            // } else {
            //   return "";
            // }
          } else {
            rowData.outlets[props.outlet_index].mrp_pricing.map(
              (ele: any, index: any) => {
                if (ele.unique_id == rowData.unique_id) {
                  if (
                    rowData.outlets[props.outlet_index].custom_channels[
                      props.channel_index
                    ].mrp_pricing[index].hasOwnProperty("price")
                  ) {
                    if (
                      rowData.outlets[props.outlet_index].custom_channels[
                        props.channel_index
                      ].mrp_pricing[index].price.hasOwnProperty("sellers_price")
                    ) {
                      rowData.outlets[props.outlet_index].custom_channels[
                        props.channel_index
                      ].mrp_pricing[index].price.sellers_price;
                    } else {
                      return "";
                    }
                  } else {
                    return "";
                  }
                }
              }
            );
            // if (
            //   rowData.outlets[props.outlet_index].custom_channels[
            //     props.channel_index
            //   ].non_mrp_pricing.hasOwnProperty("sellers_price")
            // ) {
            //   return rowData.outlets[props.outlet_index].custom_channels[
            //     props.channel_index
            //   ].non_mrp_pricing.sellers_price;
            // } else {
            //   return "";
            // }
          }
        }
      }
    }
    // return value;

    //   rowData.name == "Non Mrp Pricing"
    //     ? rowData.hasOwnProperty("outlets")
    //       ? props.from == "outlet"
    //         ? rowData.outlets[props.outlet_index].non_mrp_pricing
    //             .sellers_price
    //         : props.channel_type != null
    //         ? props.channel_type == "default"
    //           ? rowData.outlets[props.outlet_index].channels[
    //               props.channel_index
    //             ].non_mrp_pricing.sellers_price
    //           : rowData.outlets[props.outlet_index].custom_channels[
    //               props.channel_index
    //             ].non_mrp_pricing.sellers_price
    //         : ""
    //       : ""
    //     : ""
  }

  return (
    <div>
      <div className="p-3 ">
        <div className="bg-fff p-3">
          <div className="">
            <button
              className="btn btn-primary"
              onClick={() => {
                console.log("outlets :", outlets);
                console.log("product_data :", product_data);
              }}
            >
              Check
            </button>
          </div>
          {/* Top Filter */}
          <div className="px-2 pb-3 row align-items-center">
            {/* Set price to */}
            <div className="col-md-6 mb-2">
              <div className="row align-items-center">
                <div className="col-md-3 pe-0 sm-mb-2">
                  <label className="form-label mb-0">Set price to</label>
                </div>
                <div className="col-md-8">
                  <Select
                    options={pricingOptions}
                    defaultValue={pricingOptions[0]}
                    value={price_for}
                    onChange={(e) => {
                      console.log("selected_type :", e.value);
                      SetPriceFor(e);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Select Field */}
            <div className="col-md-6 mb-2">
              <div className="row align-items-center">
                <div className="col-md-4 pe-0 sm-mb-2">
                  <label className="form-label mb-0">Select Field</label>
                </div>
                <div className="col-md-8">
                  <Select
                    className="Price"
                    options={field_option}
                    value={selected_field_option}
                    onChange={(e) => {
                      console.log("selling Field :", e.value);
                      // SetSelectedSellingType(e);
                      SetSelectedFieldOption(e);
                      // selected_field_option = e;
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Select Outlets/Channels */}
            <div className="col-md-7 mb-2">
              <div className="row align-items-center">
                <div className="col-md-3 pe-0 sm-mb-2">
                  <label className="form-label mb-0">
                    {price_for.value == "outlet"
                      ? "Select Outlet"
                      : "Select Channels"}
                  </label>
                </div>
                <div className="col-md-9 p-0">
                  <Select
                    className="Price"
                    options={
                      price_for.value == "outlet"
                        ? outlet_option
                        : channel_option
                    }
                    value={get_selected_value(price_for.value)}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selected_data) => {
                      console.log("selected_channels :", selected_data);
                      if (price_for.value == "outlet") {
                        if (selected_data.length > 0) {
                          let product_data_value = product_data;
                          SetProductData([]);
                          SetOutletOption((prevValue: any) => {
                            prevValue.map((ele: any, index: any) => {
                              let have_data = selected_data.some(
                                (value) => value.id == ele.id
                              );
                              if (have_data) {
                                ele.checked = true;
                              } else {
                                ele.checked = false;
                              }
                            });
                            return [...prevValue];
                          });
                          SetOutlet((prevValue: any) => {
                            prevValue.map((ele: any, index: any) => {
                              let have_data = selected_data.some(
                                (value) => value.id == ele.id
                              );
                              if (have_data) {
                                ele.checked = true;
                              } else {
                                ele.checked = false;
                              }
                            });
                            console.log("outlets prevValue:", prevValue);

                            return [...prevValue];
                          });
                          SetProductData(product_data_value);

                          // SetOutlet((prevValue: any) => {
                          //   prevValue.map((ele: any, index: any) => {
                          //     let have_data = selected_data.some(
                          //       (value) => value.id == ele.id
                          //     );
                          //     if (have_data) {
                          //       ele.checked = true;
                          //     } else {
                          //       ele.checked = false;
                          //     }
                          //   });
                          //   return [...prevValue];
                          // });
                        } else {
                          SetOutletOption((prevValue: any) => {
                            prevValue.map((ele: any, index: any) => {
                              ele.checked = false;
                            });
                            return [...prevValue];
                          });
                        }
                      }

                      if (price_for.value == "channel") {
                        console.log("channel_option :", channel_option);
                        console.log("outlets :", outlets);

                        SetChannelOption((prevValue: any) => {
                          prevValue.map((ele: any, index: any) => {
                            ele.options.map((o_ele: any, o_index: any) => {
                              let have_data = selected_data.some(
                                (value) => value.channel_id == o_ele.channel_id
                              );
                              if (have_data) {
                                o_ele.checked = true;
                              } else {
                                o_ele.checked = false;
                              }
                            });
                          });
                          return [...prevValue];
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Filter */}
            <div className="col-md-5 my-2">
              <div
                className="end cursor"
                onClick={(event) => {
                  if (width < 768) {
                    slide_drawer("bottom", true, event);
                    SetSideDrawer({ type: "filter" });
                  } else {
                    slide_drawer("right", true, event);
                    SetSideDrawer({ type: "filter" });
                  }
                }}
              >
                <Ionicons name="filter" size={18} color="black" />
                <h6 className=" mb-0 ps-2">Filter</h6>
              </div>
            </div>
          </div>
          {!table_loading ? (
            <Table
              bordered
              cellBordered
              headerHeight={80}
              // data={getData()}
              data={product_data}
              // data={useMemo(() => product_data, [product_data])}
              loading={table_loading}
              height={800}
              hover={hover}
              rowKey="unique_id"
              isTree
              // defaultExpandAllRows
              virtualized
              onRowClick={(data) => {
                // console.log("onRowClick :", data);
              }}
              shouldUpdateScroll={true}
            >
              <Column width={300} align="left">
                <HeaderCell align="center">Products</HeaderCell>
                <Cell dataKey="name" />
              </Column>

              {outlets.map((ele, index) =>
                price_for.value == "channel" ? (
                  <ColumnGroup
                    header={ele.name}
                    align="center"
                    key={index}
                    className="center"
                  >
                    <>
                      {/* Default Channels */}
                      {ele.channels.length > 0 ? (
                        ele.channels.map((c_ele, c_index) => (
                          <>
                            {c_ele.checked ? (
                              <Column
                                width={200}
                                align="center"
                                key={c_index}
                                outlet_index={index}
                                channel_index={c_index}
                                channel_type="default"
                                from="channel"
                              >
                                <HeaderCell>
                                  <div style={{ lineHeight: "40px" }}>
                                    {c_ele.name}
                                  </div>
                                </HeaderCell>
                                <OutletCell
                                  key={c_ele.unique_id}
                                  checkedkeys={c_ele.checkedKeys}
                                  onChange={(rowData, propsData, event) => {
                                    //   sample_function(rowData, propsData, event);
                                    console.log("Onchange Outlet cell");
                                  }}
                                  onBtnClick={(rowData, propsData, event) => {
                                    console.log("rowData :", rowData);
                                    console.log("propsData :", propsData);
                                    console.log("event :", event);

                                    if (width < 768) {
                                      slide_drawer("bottom", true, event);
                                      SetSideDrawer({ type: "information" });
                                    } else {
                                      slide_drawer("right", true, event);
                                      SetSideDrawer({ type: "information" });
                                    }
                                  }}
                                  currencies_option={currencies_option}
                                  selected_field_option={selected_field_option}
                                  sellers_price_on_change={(
                                    rowData,
                                    props,
                                    event
                                  ) => {
                                    let channel_data = {
                                      default_channel: true,
                                      channel_index: c_index,
                                    };
                                    on_change_seller_price(
                                      rowData,
                                      props,
                                      event,
                                      price_for,
                                      channel_data
                                    );
                                  }}
                                  seller_price_value={(
                                    rowData,
                                    props,
                                    event
                                  ) => {
                                    return get_seller_price_value(
                                      rowData,
                                      props,
                                      event
                                    );
                                  }}
                                />
                              </Column>
                            ) : null}
                          </>
                        ))
                      ) : (
                        // <Column width={200}>
                        //   <HeaderCell>
                        //     <div style={{ lineHeight: "40px" }}>
                        //       No Channels
                        //     </div>
                        //   </HeaderCell>
                        //   <Cell></Cell>
                        // </Column>
                        <>{null}</>
                      )}
                      {/* Custom Channels */}
                      {ele.custom_channels.length > 0 ? (
                        ele.custom_channels.map((cc_ele, cc_index) => (
                          <>
                            {cc_ele.checked ? (
                              <Column
                                width={200}
                                align="center"
                                key={cc_index}
                                outlet_index={index}
                                channel_index={cc_index}
                                channel_type="custom"
                                from="channel"
                              >
                                <HeaderCell>
                                  <div style={{ lineHeight: "40px" }}>
                                    {cc_ele.name}
                                  </div>
                                </HeaderCell>
                                <OutletCell
                                  key={cc_ele.unique_id}
                                  checkedkeys={cc_ele.checkedKeys}
                                  onChange={(rowData, propsData, event) => {
                                    //   sample_function(rowData, propsData, event);
                                    console.log("Onchange Outlet cell");
                                  }}
                                  onBtnClick={(rowData, propsData, event) => {
                                    console.log("rowData :", rowData);
                                    console.log("propsData :", propsData);
                                    console.log("event :", event);
                                    if (width < 768) {
                                      slide_drawer("bottom", true, event);
                                      SetSideDrawer({ type: "information" });
                                    } else {
                                      slide_drawer("right", true, event);
                                      SetSideDrawer({ type: "information" });
                                    }
                                  }}
                                  currencies_option={currencies_option}
                                  selected_field_option={selected_field_option}
                                  sellers_price_on_change={(
                                    rowData,
                                    props,
                                    event
                                  ) => {
                                    let channel_data = {
                                      default_channel: false,
                                      channel_index: cc_index,
                                    };
                                    on_change_seller_price(
                                      rowData,
                                      props,
                                      event,
                                      price_for,
                                      channel_data
                                    );
                                  }}
                                  seller_price_value={(
                                    rowData,
                                    props,
                                    event
                                  ) => {
                                    return get_seller_price_value(
                                      rowData,
                                      props,
                                      event
                                    );
                                  }}
                                />
                              </Column>
                            ) : null}
                          </>
                        ))
                      ) : (
                        // <Column width={200}>
                        //   <HeaderCell>
                        //     <div style={{ lineHeight: "40px" }}>
                        //       No Custom Channels
                        //     </div>
                        //   </HeaderCell>
                        //   <Cell></Cell>
                        // </Column>
                        <>{null}</>
                      )}
                    </>
                  </ColumnGroup>
                ) : (
                  // price_for.value == "outlet"
                  <>
                    {ele.checked ? (
                      <Column
                        width={250}
                        align="center"
                        key={index}
                        outlet_index={index}
                        channel_index={null}
                        channel_type={null}
                        from="outlet"
                      >
                        <HeaderCell>
                          <div style={{ lineHeight: "40px" }}>{ele.name}</div>
                        </HeaderCell>

                        <OutletCell
                          key={ele.unique_id}
                          onChange={(rowData, propsData, event) => {
                            console.log("Onchange Outlet cell");
                          }}
                          onBtnClick={(rowData, propsData, event) => {
                            console.log("rowData :", rowData);
                            console.log("propsData :", propsData);
                            console.log("event :", event);
                            let view_type;
                            if (width < 768) {
                              view_type = "bottom";
                            } else {
                              view_type = "right";
                            }
                            slide_drawer(view_type, true, event);
                            SetSideDrawer({ type: "information" });
                          }}
                          currencies_option={currencies_option}
                          selected_field_option={selected_field_option}
                          sellers_price_on_change={(rowData, props, event) => {
                            on_change_seller_price(
                              rowData,
                              props,
                              event,
                              price_for,
                              {}
                            );
                          }}
                          seller_price_value={(rowData, props, event) => {
                            return get_seller_price_value(
                              rowData,
                              props,
                              event
                            );
                          }}
                        />
                      </Column>
                    ) : null}
                  </>
                )
              )}
            </Table>
          ) : null}
        </div>
      </div>

      {["left", "right", "top", "bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={(event) => {
              slide_drawer(anchor, false, event);
            }}
            onOpen={(event) => {
              slide_drawer(anchor, true, event);
            }}
          >
            {side_drawer.type == "information" ? (
              <div className="">
                <div className="mt-3 scrollbar-none">
                  <div
                    className="w-100 mb-4 cursor px-3 end"
                    onClick={(event) => {
                      slide_drawer(anchor, false, event);
                    }}
                  >
                    <Ionicons name="close" size={19} color="gray" />
                    <p className="mb-0 ps-1">Close</p>
                  </div>

                  <Accordion>
                    <AccordionSummary
                      aria-controls="details-content"
                      id="details-header"
                      aria-label="Expand"
                      className="align-items-center"
                    >
                      <div className="col-3">
                        <img
                          src={require("../../assets/img/mob.png")}
                          className="w-100 product-img"
                        />
                      </div>
                      <div className="col-9">
                        <div className="d-flex">
                          <div>
                            <Typography className="text-dark mb-1">
                              Oppo A9 2020
                            </Typography>
                            <Typography className="mb-1">
                              Mobile shop
                            </Typography>
                            <h6 className="text-dark mb-2">₹18000</h6>
                          </div>
                          <div className="ms-auto">
                            <MaterialIcons
                              name="keyboard-arrow-down"
                              size={20}
                              color="gray"
                              className={
                                expanded === true
                                  ? "MuiAccordionSummary-expandIcon.Mui-expanded MuiAccordionSummary-expandIcon"
                                  : "MuiAccordionSummary-expandIcon"
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <div className="row w-100 align-items-center">
                            <p className="mb-2 col-5">HSN Code : </p>
                            <p className="mb-2 text-dark text-end col-7">
                              123456789
                            </p>
                          </div>
                          <div className="row w-100 align-items-center">
                            <p className="mb-2 col-7">Tax % : </p>
                            <div className="col-5 p-0 mb-2">
                              <div className="input-group ">
                                <input type="text" className="form-control" />
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  %
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row w-100 align-items-center">
                            <p className="mb-2 col-6">Commission % : </p>
                            <p className="mb-2 text-dark text-end col-6">30%</p>
                          </div>
                        </div>
                      </div>
                    </AccordionSummary>

                    <AccordionDetails>
                      {/* <Typography className="row w-100 p-0">
                        <div className="row w-100 align-items-center p-0">
                          <p className="mb-2 col-10">Fixed Platform Fee : </p>
                          <p className="mb-2 text-dark text-end col-2 ps-0">
                            ₹20
                          </p>
                        </div>
                        <div className="row w-100 align-items-center p-0">
                          <p className="mb-2 col-10">GST % of Commission : </p>

                          <p className="mb-2 text-dark text-end col-2 ps-0">
                            5%
                          </p>
                        </div>
                        <div className="row w-100 align-items-center p-0">
                          <p className="mb-2 col-10">GST % for Fixed P.F : </p>
                          <p className="mb-2 text-dark text-end col-2 ps-0">
                            5%
                          </p>
                        </div>
                      </Typography> */}
                      <p>Hai...</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                {/* Save Button */}
                <div className="pt-5 mt-2">
                  <div
                    className="text-center p-3 cursor bottom bg-primary text-white"
                    onClick={(event) => {
                      slide_drawer(anchor, false, event);
                    }}
                  >
                    Save
                  </div>
                </div>
              </div>
            ) : null}

            {/* {side_drawer.type == "information" ? (
              <div>
                <div className="mt-3 scrollbar-none">
                  <div
                    className="w-100 mb-4 cursor px-3 end"
                    onClick={(event) => {
                      slide_drawer(anchor, false, event);
                    }}
                  >
                    <Ionicons name="close" size={19} color="gray" />
                    <p className="mb-0 ps-1">Close</p>
                  </div>

                  <Accordion>
                    <AccordionSummary
                      aria-controls="details-content"
                      id="details-header"
                      aria-label="Expand"
                      className="align-items-center"
                    >
                      <div className="col-3">
                        <img
                          src={require("../../assets/img/mob.png")}
                          className="w-100 product-img"
                        />
                      </div>
                      <div className="col-9">
                        <div className="d-flex">
                          <div>
                            <Typography className="text-dark mb-1">
                              Oppo A9 2020
                            </Typography>
                            <Typography className="mb-1">
                              Mobile shop
                            </Typography>
                            <h6 className="text-dark mb-2">₹18000</h6>
                          </div>
                          <div className="ms-auto">
                            <MaterialIcons
                              name="keyboard-arrow-down"
                              size={20}
                              color="gray"
                              className={
                                expanded === true
                                  ? "MuiAccordionSummary-expandIcon.Mui-expanded MuiAccordionSummary-expandIcon"
                                  : "MuiAccordionSummary-expandIcon"
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <div className="row w-100 align-items-center">
                            <p className="mb-2 col-5">HSN Code : </p>
                            <p className="mb-2 text-dark text-end col-7">
                              123456789
                            </p>
                          </div>
                          <div className="row w-100 align-items-center">
                            <p className="mb-2 col-7">Tax % : </p>
                            <div className="col-5 p-0 mb-2">
                              <div className="input-group ">
                                <input type="text" className="form-control" />
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  %
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row w-100 align-items-center">
                            <p className="mb-2 col-6">Commission % : </p>
                            <p className="mb-2 text-dark text-end col-6">30%</p>
                          </div>
                        </div>
                      </div>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Typography className="row w-100 p-0">
                        <div className="row w-100 align-items-center p-0">
                          <p className="mb-2 col-10">Fixed Platform Fee : </p>
                          <p className="mb-2 text-dark text-end col-2 ps-0">
                            ₹20
                          </p>
                        </div>
                        <div className="row w-100 align-items-center p-0">
                          <p className="mb-2 col-10">GST % of Commission : </p>

                          <p className="mb-2 text-dark text-end col-2 ps-0">
                            5%
                          </p>
                        </div>
                        <div className="row w-100 align-items-center p-0">
                          <p className="mb-2 col-10">GST % for Fixed P.F : </p>
                          <p className="mb-2 text-dark text-end col-2 ps-0">
                            5%
                          </p>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <div className="p-3">
                    <label className="form-label d-flex align-items-center mb-2">
                      <span className="w-70">Cost of the product</span>
                      <div className="input-group w-30">
                        <span className="input-group-text" id="basic-addon1">
                          ₹
                        </span>
                        <input type="text" className="form-control" />
                      </div>
                    </label>

                    <div className="mt-2">
                      <Typography id="range-slider">Margin</Typography>
                      <div className="d-flex align-items-center">
                        <div className="w-70 pe-3">
                          <Slider
                            value={typeof value === "number" ? value : 0}
                            onChange={handleSliderChange}
                            aria-labelledby="input-slider"
                            valueLabelDisplay="auto"
                          />
                        </div>

                        <div className="input-group w-30">
                          <span className="input-group-text" id="basic-addon1">
                            ₹
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={value}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-check mt-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="ownPrice"
                      />
                      <label className="form-check-label" htmlFor="ownPrice">
                        Sell on their Own Price
                      </label>
                    </div>
                    <div className="form-check mt-2 d-flex align-items-center">
                      <div className="w-70">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="myPrice"
                          checked
                        />
                        <label className="form-check-label" htmlFor="myPrice">
                          Sell with My Price
                        </label>
                      </div>
                      <div className="input-group w-30">
                        <span className="input-group-text" id="basic-addon1">
                          ₹
                        </span>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={
                        <MaterialIcons
                          name="keyboard-arrow-down"
                          size={20}
                          color="gray"
                          className="MuiAccordionSummary-expandIcon"
                        />
                      }
                      aria-controls="expense-content"
                      id="expense-header"
                      aria-label="Expand"
                      className="align-items-center"
                    >
                      <Typography className="text-dark mb-1">
                        Platform Expenses :{" "}
                      </Typography>
                      <h6 className="ps-2 text-end">₹36.75</h6>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Typography className="row w-100 p-0">
                        <div className="row w-100 align-items-center p-0">
                          <p className="mb-2 col-10">Commission with Tax : </p>
                          <p className="mb-2 text-dark text-end col-2 p-0">
                            ₹15.75
                          </p>
                        </div>
                        <div className="row w-100 align-items-center p-0">
                          <p className="mb-2 col-10">FPF with Tax : </p>
                          <p className="mb-2 text-dark text-end col-2 p-0">
                            ₹21.00
                          </p>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <div className="px-3  row w-100 align-items-center">
                    <p className="mb-2 col-10">Tax 10% :</p>
                    <p className="mb-2 text-dark text-end col-2 p-0">₹18.60</p>
                  </div>

                  <div className="px-3 py-2">
                    <div className="row w-100 align-items-center p-0 mt-2">
                      <p className="mb-2 col-8">Product price with Tax : </p>
                      <p className="mb-2 text-dark text-end col-4 p-0 fw-bold">
                        ₹205.35
                      </p>
                    </div>
                    <div className="row w-100 align-items-center p-0 mt-2">
                      <p className="mb-2 col-8">Profit : </p>
                      <p className="mb-2 text-dark text-end col-4 p-0 fw-bold">
                        ₹50.35
                      </p>
                    </div>
                  </div>

                </div>
                <div className="pt-5 mt-2">
                  <div
                    className="text-center p-3 cursor bottom bg-primary text-white"
                    onClick={(event) => {
                      slide_drawer(anchor, false, event);
                    }}
                  >
                    Save
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="bg-fff p-3">
                  <div className="d-flex ">
                    <h6>Filter</h6>
                    <div
                      className="ms-auto cursor"
                      onClick={() => {
                        slide_drawer(anchor, false, event);
                      }}
                    >
                      <small>Clear</small>
                    </div>
                  </div>
                  <div className="my-3">
                    <label className="form-label d-flex align-items-center">
                      <input type="checkbox" className="form-check me-2" />
                      <small>Own Brand Products</small>
                    </label>
                    <label className="form-label d-flex align-items-center">
                      <input type="checkbox" className="form-check me-2" />
                      <small>Other Brand Products</small>
                    </label>
                  </div>

                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="acc-head-id">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#acc-id"
                          aria-expanded="false"
                          aria-controls="acc-id"
                        >
                          Brand
                        </button>
                      </h2>
                      <div
                        id="acc-id"
                        className="accordion-collapse collapse "
                        aria-labelledby="acc-head-id"
                      >
                        <div className="accordion-body">
                          <input
                            type="text"
                            placeholder="Search..."
                            className="form-control"
                          />
                          <div className="p-2">
                            <label className="form-label mt-3 d-flex align-items-center">
                              <input type="checkbox" className="me-2" />
                              Polo
                            </label>
                            <label className="form-label mt-3 d-flex align-items-center">
                              <input type="checkbox" className="me-2" />
                              Polo
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <Typography id="range-slider" gutterBottom>
                      Mininum Maximum Price
                    </Typography>
                    <div className="d-flex align-items-center">
                      <Slider
                        value={value}
                        onChange={handleChange}
                        onClick={() => {
                          console.log("min max :", value);
                        }}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={valuetext}
                      />
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <label className="form-label">Min Price</label>
                        <input
                          type="text"
                          className="form-control"
                          value={value[0]}
                          onChange={(e) => {
                            console.log("Min Price :", e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <label className="form-label">Max Price</label>
                        <input
                          type="text"
                          className="form-control"
                          value={value[1]}
                          onChange={(e) => {
                            console.log("Max Price :", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-5 mt-2">
                  <div
                    className="text-center p-3 cursor bottom bg-primary text-white"
                    onClick={(event) => {
                      slide_drawer(anchor, false, event);
                    }}
                  >
                    Done
                  </div>
                </div>
              </div>
            )} */}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
