import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import DataContext from "../../utils/Context/DataContext";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useTranslation } from "react-i18next";

import en from "../../utils/locales/en.json";
import ta from "../../utils/locales/ta.json";
import LocalizationContext from "../../utils/Context/LocalizationContext";

import { api } from "../../utils/Api";
import Cookies from "js-cookie";
import { AntDesign, Entypo, Ionicons, MaterialIcons } from "@expo/vector-icons";
import { AppConfig } from "../../config";

const animatedComponents = makeAnimated();

export default function Header(props) {
  const context = useContext(DataContext);
  const [selected_language, SetSelectedLanguage] = useState({
    label: "English",
    value: "en",
  });
  const language_option = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "Tamil",
      value: "ta",
    },
  ];
  const [width, SetWidth] = React.useState(innerWidth);

  useEffect(() => {
    console.log("Header On mount :", props);
    console.log("Header context :", context);
  }, []);

  async function get_locales(lang_value: any) {
    let pass_data = {
      get: {
        lang: lang_value.value,
      },
    };
    let response = await api("/locales", pass_data);
    console.log("locales :", response);

    if (response.response.hasOwnProperty("data")) {
      context.SetAppData((prevValue) => {
        prevValue.selected_language = lang_value;
        prevValue.language_data = response.response.data;
        return { ...prevValue };
      });
    }
  }

  return (
    <div>
      <nav className="navbar navbar md-fixed-top  navbar-light navbar-custom "
       style={width < 780 ? { width: width } : {}}>
        <div className="row w-100 align-items-center">
          <div className="col-md-6 ps-0">
            <div className="d-flex align-items-center py-2 pe-1">
              {width > 720 ? (
                <div
                  className="p-2 me-1 cursor"
                  onClick={() => {
                    // if (props.menu_click(true)) {
                    //   props.menu_click(false);
                    //   console.log("menu close");
                    // } else {
                    //   props.menu_click(true);
                    // }
                    props.menu_click(!props.is_menu_open);
                  }}
                >
                  <img src={AppConfig.CDN_Media_url+"ab053c20532b220988eef003e0328fe5.svg"} className="w-25px" />

                  {/* <MaterialIcons name="menu" size={20} color="#444" /> */}
                </div>
              ) : null}

              {/* <img
                src={require("../../assets/img/shopping-cart.png")}
                className="logo-img"
              /> */}
              <Link to={'/'} className="d-flex align-items-center" >
                <img
                  src={AppConfig.CDN_Image_url+"2344617f5bc696eacff9e3b5df613b58.png"}
                  className="logo-img"
                />
                <h6 className="mb-0 ps-2 capitalize text-dark fw-600">{context.t("Sellers")}</h6>
              </Link>
            </div>
          </div>

          <div className="col-md-6 pe-0 d-sm-none d-md-block text-end">
            <div className="sidebar-options dropdown">
              <div className="lang-dropdown pe-3">
                <div
                  className="d-flex align-items-center border btn-radius ps-2 pe-1"
                  data-bs-toggle="tooltip"
                  title="Language"
                >
                  <Entypo name="language" size={14} color="#777" />
                  <Select
                    components={animatedComponents}
                    placeholder="Select Language"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={context.app_data.language_list}
                    value={context.app_data.selected_language}
                    onChange={(e) => {
                      console.log("Header Select :", e);
                      SetSelectedLanguage(e);
                      Cookies.set("lang", e.value);
                      get_locales(e);

                      // let language = e.value;
                      // i18n.changeLanguage(language);
                      // context.SetAppData((prevValue) => {
                      //   prevValue.language_data = e;
                      //   return { ...prevValue };
                      // });
                    }}
                  />
                </div>
              </div>
              {/* <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  console.log("context :", context);
                }}
              >
                Check context
              </button> */}
              <Link to="/" className="p-0 mt-0">
                <div
                  className="pe-3 cursor"
                  data-bs-toggle="tooltip"
                  title="Home"
                >
                    <img
                                  className="menu-icon"
                                  src={AppConfig.CDN_Media_url+"59d6d8f1cc6d6a28308067c422e9af1a.svg"}
                                />
                          
                  {/* <AntDesign name="home" size={20} color="#777" /> */}
                </div>
              </Link>

              <div
                className="pe-3 cursor"
                data-bs-toggle="tooltip"
                title="Notifications"
              >
                  <img
                    src={AppConfig.CDN_Media_url+"a34614fba3e984731c1b0b96576c11e5.svg"}
                    className="menu-icon"
                  />
               
                {/* <Ionicons
                  name="ios-notifications-outline"
                  size={22}
                  color="#777"
                /> */}
              </div>

              {/* <Link to="" className="p-0 mt-0">
                <div
                  className="pe-3 cursor"
                  data-bs-toggle="tooltip"
                  title="Logout"
                >
                  <img src={AppConfig.CDN_Image_url+"b768514a88b9a901a98ea3bb85c71049.png"} className="" />
                </div>
              </Link> */}
              <div
                className="nav-notification p-3 dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <div className="nav-notification-content">
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
