import React, { useState, useEffect } from "react";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import ModalDeleteUserRole from "./ModalDeleteUserRole";
import ShortNotification from "../../../components/Toast/ShortNotification";
import Franchise from './../Franchise/Franchise';
import { AntDesign, Entypo, Feather, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { AppConfig } from "../../../config";
import Skeleton from "react-loading-skeleton";




export default function BrandUserRole(props: any) {
  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });
  const OutletContext = useOutletContext();
  const [openToast, SetOpenToast] = useState(false);
  const [toastData, SetToastData] = useState({ message: "", style: "" });
  const [width, SetWidth] = useState(innerWidth);
  const [id_value, SerIdValue] = useState(useParams());
  const [brand_active_roles, SetBrandActiveRoles] = useState([]);
  const [franchies_active_roles, SetFranchiesActiveRoles] = useState([]);
  const [outlet_active_roles, SetOutletActiveRoles] = useState([]);
  const [is_loading, SetIsLoading] = useState(false);

  useEffect(() => {
    console.log("AdminUserRoles On mount :", props);
    get_custom_roles();
  }, []);

  function ModalOnClose(data: any) {
    SetopenModal({
      is_open: false,
      data: {},
    });
    // SetToastData({ message: "User Role Deleted", style: "" });
    // SetOpenToast(true);
    if (data.action == "delete") {
      console.log("on confirm delete :", data.value);
      delete_role(
        data.value.element,
        data.value.index,
        data.value.type,
        data.value.status
      );
    }
  }

  function ToastOnClose(data: any) {
    SetOpenToast(false);
  }
  //


  async function get_custom_roles() {
    SetIsLoading(true)
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let data: any = await api("/brand/get_custom_roles", pass_data);
    console.log("get_custom_roles response :", data);
    if (data.status_code == 200) {
      data.response.roles.map((role_element: any, role_index: any) => {
        if (role_element.name == "Brand") {
          if (role_element.hasOwnProperty("roles")) {
            SetBrandActiveRoles(role_element.roles);
          } else {
            SetBrandActiveRoles([]);
          }
        } else if (role_element.name == "Outlet") {
          if (role_element.hasOwnProperty("roles")) {
            SetOutletActiveRoles(role_element.roles);
          } else {
            SetOutletActiveRoles([]);
          }
        } else if (role_element.name == "Franchise") {
          if (role_element.hasOwnProperty("roles")) {
            SetFranchiesActiveRoles(role_element.roles);
          } else {
            SetFranchiesActiveRoles([]);
          }
        }
      });
    SetIsLoading(false)

    }
  }

  // function view_role(ele, index, type, status) {
  //   console.log("View Role");
  //   console.log("View Role ele :", ele);
  //   console.log("View Role index :", index);
  //   console.log("View Role type :", type);
  //   console.log("View Role status :", status);
  // }
  // function edit_role(ele, index, type, status) {
  //   console.log("Edit Role");
  //   console.log("Edit Role ele :", ele);
  //   console.log("Edit Role index :", index);
  //   console.log("Edit Role type :", type);
  //   console.log("Edit Role status :", status);
  // }
  // async function delete_role(ele, index, type, status) {
  //   console.log("Delete Role");
  //   let pass_data = {
  //     get: {
  //       brand: id_value.brand_id,
  //     },
  //     post: {
  //       role_id: ele.id,
  //     },
  //   };
  //   let data = await api("/brand/delete_custom_user_role", pass_data);
  //   console.log("delete_custom_user_role response :", data);
  //   if (data.status_code == 200) {
  //     get_custom_roles();
  //   }
  // }

  return (
    <div>
      <div className={OutletContext ? "head-bar sticky-sm" : "head-bar  sticky-sm"}
        style={OutletContext == false ? { width: width } : {}} >
        <h6 className="mb-1 text-dark fw-bolder">User Roles</h6>
        <p className="mb-0 v-small">Brands & Outlets</p>
      </div>
      <div className={OutletContext ? "mt-3 px-md-3 px-sm-0 mb-5" : "mt-3 px-md-3 px-sm-0 mb-5"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="row">
          <div className="col-lg-4 mb-3">
            <div className="bg-fff box-3 p-3 br-3">
              <div className="d-flex align-items-center mb-3">
                <h6 className="mb-0">Brand User Roles</h6>
                <div className="cursor py-1 px-2 ms-auto">
                  <Link
                    to="add/brand/new">
                    <div className="bg-prime btn-radius cursor py-1 px-3 ms-auto">
                      {/* <Entypo name="plus" size={18} color="#663fc3" /> */}
                      <p className="text-white fw-bold">Add</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div>
              {is_loading?
               <div>
               {Array.from({ length: 6 }).map((_, index) => (
                 <div key={index} className="p-2 row list border-bottom">
                   <div className="col-6">
                     <div className="mb-1">
                       <Skeleton width={150} />
                     </div>
                   </div>
                   <div className="col-6 d-flex align-items-center justify-content-end">
                     <div className="cursor p-2">
                       <Skeleton circle={true} width={20} height={20} />
                     </div>
                     <div className="cursor p-2">
                       <Skeleton circle={true} width={14} height={14} />
                     </div>
                     <div className="cursor p-2">
                       <Skeleton circle={true} width={14} height={14} />
                     </div>
                   </div>
                 </div>
               ))}
             </div>
                : brand_active_roles.length > 0 ? (
                  brand_active_roles.map((ele: any, index: any) => (

                    <div className="p-2 row list border-bottom">
                      <div className="col-6">
                        <div className="mb-1">{ele.name}</div>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-end">
                        <Link
                          className="cursor p-2"
                          to={
                            "/brand/" +
                            id_value.brand_id +
                            "/userrole/view/brand/" +
                            ele.id
                          }
                        >
                          <img src={AppConfig.CDN_Media_url+"dea875fb200efe26145c4ccc2a582fb0.svg"} className="w-20px" />
                          {/* <AntDesign name="eyeo" size={18} color="#777" /> */}
                        </Link>

                        <Link
                          className="cursor p-2"
                          to={
                            "/brand/" +
                            id_value.brand_id +
                            "/userrole/edit/brand/" +
                            ele.id
                          }
                        >
                          <img src={AppConfig.CDN_Media_url+"ab30fda22ff8a0d7814600c1a192acd6.svg"} className="w-14px" />
                          {/* <Feather name="edit" size={15} color="#777" /> */}
                        </Link>

                        <div
                          className="cursor p-2"
                          onClick={() => {
                            SetopenModal({
                              is_open: true,
                              data: {
                                element: ele,
                                index: index,
                                type: "brand",
                                status: "active",
                              },
                            });
                          }}
                        >
                          <img src={AppConfig.CDN_Media_url+"d73710484da46cc16fb3af1f3ab50cab.svg"} className="w-14px" />
                          {/* <MaterialIcons name="delete-outline" size={18} color="#777" /> */}
                        </div>
                      </div>
                    </div>


                  ))

                ) : (
                  <div className="text-center">
                     <img
                     src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img pb-2"
                    />
                    <p className="v-small mb-0">No Data, Please Add Some Roles</p>
                  </div>
                )}
              </div>
            </div>
          </div>


          <div className="col-lg-4 mb-3">
            <div className="bg-fff  box-3 p-3 br-3">
              <div className="d-flex align-items-center mb-3">
                <h6 className="mb-0">Franchise User Roles</h6>
                <div className="cursor py-1 px-2 ms-auto">
                  <Link
                    to="add/franchise/new">
                    <div className="bg-prime btn-radius cursor py-1 px-3 ms-auto">
                      {/* <Entypo name="plus" size={18} color="#663fc3" /> */}
                      <p className="text-white fw-bold">Add</p>
                    </div>
                  </Link>
                </div>
              </div>


              <div>
                {is_loading? <div>
      {Array.from({ length: 6 }).map((_, index) => (
        <div key={index} className="p-2 row list border-bottom">
          <div className="col-6">
            <div className="mb-1">
              <Skeleton width={150} />
            </div>
          </div>
          <div className="col-6 d-flex align-items-center justify-content-end">
            <div className="cursor p-2">
              <Skeleton circle={true} width={20} height={20} />
            </div>
            <div className="cursor p-2">
              <Skeleton circle={true} width={14} height={14} />
            </div>
            <div className="cursor p-2">
              <Skeleton circle={true} width={14} height={14} />
            </div>
          </div>
        </div>
      ))}
    </div>:
                brand_active_roles.length > 0 ? (

                  franchies_active_roles.map((ele: any, index: any) => (
                    <div className="p-2 row list border-bottom">

                      <div className="col-6">
                        <div className="mb-1">{ele.name}</div>
                      </div>

                      <div className="col-6 d-flex align-items-center justify-content-end">
                        <Link
                          className="cursor p-2"
                          to={
                            "/brand/" +
                            id_value.brand_id +
                            "/userrole/view/franchise/" +
                            ele.id
                          }
                        >
                          <img src={AppConfig.CDN_Media_url+"dea875fb200efe26145c4ccc2a582fb0.svg"} className="w-20px" />
                          {/* <AntDesign name="eyeo" size={18} color="#777" /> */}
                        </Link>

                        <Link
                          className="cursor p-2"
                          to={
                            "/brand/" +
                            id_value.brand_id +
                            "/userrole/edit/franchise/" +
                            ele.id
                          }
                        >
                          <img src={AppConfig.CDN_Media_url+"ab30fda22ff8a0d7814600c1a192acd6.svg"} className="w-14px" />
                          {/* <Feather name="edit" size={15} color="#777" /> */}
                        </Link>

                        <div
                          className="cursor p-2"
                          onClick={() => {
                            SetopenModal({
                              is_open: true,
                              data: {
                                element: ele,
                                index: index,
                                type: "franchise",
                                status: "active",
                              },
                            });
                          }}
                        >
                          <img src={AppConfig.CDN_Media_url+"d73710484da46cc16fb3af1f3ab50cab.svg"} className="w-14px" />
                          {/* <MaterialIcons name="delete-outline" size={18} color="#777" /> */}
                        </div>
                      </div>
                    </div>
                  ))


                ) : (
                  <div className="text-center">
                    <img
                     src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img pb-2"
                    />
                    <p className="v-small mb-0">No Data, Please Add Some Roles</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-3">
            <div className="bg-fff box-3 p-3 br-3">
              <div className="d-flex align-items-center mb-3">
                <h6 className="mb-0">Outlet User Roles</h6>
                <div className="cursor py-1 px-2 ms-auto">
                  <Link
                    to="add/outlet/new" >
                    <div className="bg-prime btn-radius cursor py-1 px-3 ms-auto">
                      {/* <Entypo name="plus" size={18} color="#663fc3" /> */}
                      <p className="text-white fw-bold">Add</p>
                    </div>
                  </Link>
                </div>
              </div>

              <div>
              {is_loading? <div>
      {Array.from({ length: 6 }).map((_, index) => (
        <div key={index} className="p-2 row list border-bottom">
          <div className="col-6">
            <div className="mb-1">
              <Skeleton width={150} />
            </div>
          </div>
          <div className="col-6 d-flex align-items-center justify-content-end">
            <div className="cursor p-2">
              <Skeleton circle={true} width={20} height={20} />
            </div>
            <div className="cursor p-2">
              <Skeleton circle={true} width={14} height={14} />
            </div>
            <div className="cursor p-2">
              <Skeleton circle={true} width={14} height={14} />
            </div>
          </div>
        </div>
      ))}
    </div>:outlet_active_roles.length > 0 ? (
                  outlet_active_roles.map((ele: any, index: any) => (
                    <div className="p-2 row list border-bottom">
                      <div className="col-6">
                        <div className="mb-1">{ele.name}</div>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-end">
                        <Link
                          className="cursor p-2"
                          to={
                            "/brand/" +
                            id_value.brand_id +
                            "/userrole/view/outlet/" +
                            ele.id
                          }
                        >
                          <img src={AppConfig.CDN_Media_url+"dea875fb200efe26145c4ccc2a582fb0.svg"} className="w-20px" />
                          {/* <AntDesign name="eyeo" size={18} color="#777" /> */}
                        </Link>

                        <Link
                          className="cursor p-2"
                          to={
                            "/brand/" +
                            id_value.brand_id +
                            "/userrole/edit/outlet/" +
                            ele.id
                          }
                        >
                          <img src={AppConfig.CDN_Media_url+"ab30fda22ff8a0d7814600c1a192acd6.svg"} className="w-14px" />
                          {/* <Feather name="edit" size={15} color="#777" /> */}
                        </Link>

                        <div
                          className="cursor p-2"
                          onClick={() => {
                            SetopenModal({
                              is_open: true,
                              data: {
                                element: ele,
                                index: index,
                                type: "outlet",
                                status: "active",
                              },
                            });
                          }}
                        >
                          <img src={AppConfig.CDN_Media_url+"d73710484da46cc16fb3af1f3ab50cab.svg"} className="w-14px" />
                          {/* <MaterialIcons name="delete-outline" size={18} color="#777" /> */}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                   <img
                     src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img pb-2"
                    />
                    <p className="v-small mb-0">No Data, Please Add Some Roles</p>
                  </div>
                )}
              </div>
            </div>


            <ModalDeleteUserRole
              open={openModal.is_open}
              data={openModal.data}
              close={ModalOnClose}
            />
            <ShortNotification
              open={openToast}
              close={ToastOnClose}
              data={toastData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
