"use strict";

exports.__esModule = true;
exports["default"] = void 0;

var _insertCss = require("insert-css");

var _prefix = require("./prefix");

var _react = require("react");

var prefix = (0, _prefix.getClassNamePrefix)(); // Generated with ../less/index.less

var styles = "." + prefix + "icon {\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  text-rendering: optimizeLegibility;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  vertical-align: middle;\n}\n." + prefix + "icon[tabindex] {\n  cursor: pointer;\n}\n." + prefix + "icon-spin {\n  -webkit-animation: icon-spin 2s infinite linear;\n          animation: icon-spin 2s infinite linear;\n}\n." + prefix + "icon-pulse {\n  -webkit-animation: icon-spin 1s infinite steps(8);\n          animation: icon-spin 1s infinite steps(8);\n}\n." + prefix + "icon-flip-horizontal {\n  -webkit-transform: scaleX(-1);\n      -ms-transform: scaleX(-1);\n          transform: scaleX(-1);\n}\n." + prefix + "icon-flip-vertical {\n  -webkit-transform: scaleY(-1);\n      -ms-transform: scaleY(-1);\n          transform: scaleY(-1);\n}\n@-webkit-keyframes icon-spin {\n  0% {\n    -webkit-transform: rotate(0deg);\n            transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(359deg);\n            transform: rotate(359deg);\n  }\n}\n@keyframes icon-spin {\n  0% {\n    -webkit-transform: rotate(0deg);\n            transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(359deg);\n            transform: rotate(359deg);\n  }\n}";
var cssInjected = false;

var useInsertStyles = function useInsertStyles(styleStr) {
  if (styleStr === void 0) {
    styleStr = styles;
  }

  (0, _react.useEffect)(function () {
    // Make sure css injected once.
    if (!cssInjected) {
      (0, _insertCss.insertCss)(styleStr, {
        prepend: true
      });
      cssInjected = true;
    }
  }, []);
};

var _default = useInsertStyles;
exports["default"] = _default;
module.exports = exports.default;