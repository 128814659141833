import React, { useState, useEffect } from "react";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import ModalDeleteUser from "./ModalDeleteUser";
import ModalCreateUsers from "../Sector/ModalCreateUsers";
import toast from "react-hot-toast";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ModalEditUsers from "./ModalEditUsers";

import { Entypo, Feather, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";


const table_header = [
  {
    name: "",
    width: "10",
  },
  {
    name: "Users",
    width: "20",
  },
  {
    name: "Email",
    width: "20",
  },
  {
    name: "Mobile",
    width: "10",
  },
  {
    name: "Whatsapp",
    width: "10",
  },
  {
    name: "Address",
    width: "20",
  },
  {
    name: "",
    width: "10",
  },
];


export default function FranchiseUsers(props: any) {
  const OutletContext = useOutletContext()
  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });
  const [width, SetWidth] = useState(innerWidth);
  const [id_value, SerIdValue] = useState(useParams());
  const [users_data, SetUsersData] = useState([]);
  const [deleted_users_data, SetDeletedUsersData] = useState([]);
  const [user_modal, SetUserModal] = useState({
    is_open: false,
    type: "add",
    data: {},
  });
  const [owner, Setowner] = useState(false);
  const [state, setState]: any = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [selected_data, SetSelectedData] = useState({
    value: "",
    i: "",
    type: "",
  });
  const [edit_user_modal, SetEditUserModal] = useState({
    is_open: false,
    data: {},
    type: "",
  });

  const [deleteModal, SetDeleteModal] = useState({
    is_open: false,
    data: {},
  });


  useEffect(() => {
    console.log("AdminUsers On mount :", props);
    get_users();
  }, []);

  function ModalOnClose(data) {
    SetopenModal({
      is_open: false,
      data: {},
    });
    if (data.action == "delete") {
      console.log("on confirm delete :", data.value);
      delete_user(data.value.element, data.value.index);
    }
  }

  async function delete_user(record: any, index: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        user_id: record.id,
      },
    };
    let response = await api("/brand/remove_brand_user", pass_data);
    console.log("remove_brand_user response :", response);
    if (response.status_code == 200) {
      // let data = users_data;
      // console.log(users_data[index]);
      // data.splice(index, 1);
      // console.log("Sliced Data :", data);
      // SetUsersData([...data]);
      get_users();
    }
  }

  async function get_users() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        franchise: id_value.franchise_id,
      },
    };
    let data = await api("/brand/brand_users", pass_data);
    console.log("brand_users response :", data);
    if (data.status_code == 200) {
      SetUsersData(data.response.brand_users);
      Setowner(data.response.owner);
      if (data.response.hasOwnProperty("deleted_brand_users")) {
        SetDeletedUsersData(data.response.deleted_brand_users);
      }
    }
  }

  async function set_owner(record: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        user_id: record.id,
      },
    };
    let data = await api("/brand/set_brand_user_owner", pass_data);
    console.log("set_brand_user_owner response :", data);
    if (data.status_code == 200) {
      SetUsersData(data.response.brand_users);
    }
  }

  async function remove_owner(record: any) {
    console.log("remove_owner record :", record);

    const count = users_data.filter((obj) => {
      if (obj.admin == true && obj.id != record.id) {
        return true;
      }
      return false;
    }).length;
    console.log("count :", count);
    if (count > 0) {
      console.log("can remove ");
      let pass_data = {
        get: {
          brand: id_value.brand_id,
        },
        post: {
          user_id: record.id,
        },
      };
      let data = await api("/brand/remove_brand_user_owner", pass_data);
      console.log("remove_brand_user_owner response :", data);
      if (data.status_code == 200) {
        SetUsersData(data.response.brand_users);
      }
    } else {
      console.log("Cannot remove ");
      toast.error("Cannot remove!");
    }
  }

  function sample_fun(anchor, open, event) {
    console.log("sample_fun anchor :", anchor);
    console.log("sample_fun open :", open);
    console.log("sample_fun event :", event);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      console.log("return");
      return;
    }
    console.log("toggle else");

    setState({ ...state, [anchor]: open });
    // toggleDrawer("bottom", true);
  }

  const list = (anchor) => (
    <div
      className="mt-3"
    // className={ anchor === 'top' || anchor === 'bottom'}
    // role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div>
        <div className="d-flex justify-content-center">
          <div className="w-25 px-4">
            <hr className="h-3" />
          </div>
        </div>
        {selected_data.type == "active" ? (
          <ul>
            <li className="p-2 pb-3 cursor align-center">
              {owner ? (
                selected_data.value.admin ? (
                  <div
                    className="cursor"
                    onClick={() => {
                      console.log("remove owner");
                      remove_owner(selected_data);
                    }}
                  >
                    <img
                      src={require("../../../assets/icon/svg/x1.svg")}
                      className="action_menu_img cursor"
                    />
                    &nbsp;
                    Remove Owner
                  </div>
                ) : (
                  <div
                    className="cursor"
                    onClick={() => {
                      console.log("set owner");
                      set_owner(selected_data);
                    }}
                  >
                    <img
                      src={require("../../../assets/icon/svg/user2.svg")}
                      className="action_menu_img cursor"
                    />
                    &nbsp;
                    Set Owner
                  </div>
                )
              ) : null}
            </li>
            <li
              className="p-2 pb-3 cursor align-center"
              onClick={(event) => {
                SetUserModal({
                  is_open: true,
                  type: "view",
                  data: {
                    value: selected_data.value,
                    index: selected_data.i,
                  },
                });
                sample_fun(anchor, false, event);
                console.log("selected Data: ", selected_data);
              }}
            >
              <img
                src={require("../../../assets/icon/svg/eye.svg")}
                className="me-2 action_menu_img cursor"
              />
              View
            </li>
            <li
              className="p-2 pb-3 cursor align-center"

              onClick={(event) => {
                let set_data = {
                  value: ele,
                  index: ele.i,
                };
                SetEditUserModal({
                  is_open: true,
                  type: "edit",
                  data: set_data,
                });
                console.log("Edit");
                // sample_fun(anchor, false, event);
              }}
              onClick={(event) => {
                SetUserModal({
                  is_open: true,
                  type: "edit",
                  data: {
                    value: selected_data.value,
                    index: selected_data.i,
                  },
                });
                sample_fun(anchor, false, event);
                console.log("selected Data: ", selected_data);
              }}
            >
              <img
                src={require("../../../assets/icon/svg/edit-3.svg")}
                className="me-2 action_menu_img cursor"
              />
              Edit
            </li>
            <li
              className="p-2 pb-3 cursor align-center"
              onClick={(event) => {
                SetopenModal({
                  is_open: true,
                  data: {
                    element: selected_data.value,
                    index: selected_data.i,
                  },
                });
                sample_fun(anchor, false, event);
              }}
            >
              <img
                src={require("../../../assets/icon/svg/trash-2.svg")}
                className="me-2 action_menu_img cursor"
              />
              Delete
            </li>
          </ul>
        ) : (
          <ul>
            <li
              className="p-2 pb-3 cursor align-center"
              onClick={(event) => {
                SetUserModal({
                  is_open: true,
                  type: "view",
                  data: {
                    value: selected_data.value,
                    index: selected_data.i,
                  },
                });
                sample_fun(anchor, false, event);
                console.log("selected Data: ", selected_data);
              }}
            >
              <img
                src={require("../../../assets/icon/svg/eye.svg")}
                className="me-2 action_menu_img cursor"
              />
              View
            </li>
          </ul>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div className={OutletContext ? "head-bar table-head-bar  sticky-sm" : "head-bar  sticky-sm table-head-bar-sm"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="row align-items-center pb-2">
          <div className="col-md-4 col-7 sm-mb-2">
            <h6 className="mb-0">Franchise Users</h6>
          </div>

          <div className="col-5 ps-0 d-sm-block d-md-none text-end sm-mb-2">
            <button
              className="btn btn-custom-outline btn-sm px-3 "
              onClick={() => {
                SetUserModal({
                  is_open: true,
                  type: "add",
                  data: {},
                });
              }}
            >
              {/* <img
                src={require("../../../assets/icon/svg/plus1.svg")}
                className="pe-2"
              />{" "} */}
              Add
            </button>
          </div>

          <div className="col-md-5 col-9 ">
            <div className="row px-md-3 md-none">
              <div className="input-icons form-group">

                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search products"

                />
                <div className="cursor ps-2 border-left">
                  <Ionicons name="md-search" size={18} color="#663fc3" />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center col-md-1 col-3 ps-sm-2 p-md-0">
            <select className="form-control btn-radius" defaultValue="10">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
          <div className="d-sm-none d-md-block text-end col-md-2">
            <button
              className="btn btn-custom-outline btn-sm px-3 "
              onClick={() => {
                SetUserModal({
                  is_open: true,
                  type: "add",
                  data: {},
                });
              }}
            >
              {/* <img
                src={require("../../../assets/icon/svg/plus1.svg")}
                className="pe-2"
              />{" "} */}
              Add
            </button>
          </div>
        </div>

        {/* <nav className="pt-1 sticky-tabs">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              className="nav-link active cursor"
              id="active-user-tab"
              data-bs-toggle="tab"
              data-bs-target="#active-user"
              role="tab"
              aria-controls="active-user"
              aria-selected="true"
            >
              Active Users
            </button>
            <button
              className="nav-link cursor"
              id="deleted-user-tab"
              data-bs-toggle="tab"
              data-bs-target="#deleted-user"
              role="tab"
              aria-controls="deleted-user"
              aria-selected="false"
            >
              Deleted Users
            </button>
          </div>
        </nav> */}
      </div>

      <div className="table-headpage80 px-md-3 px-sm-0"

        style={width < 786 ? (
          OutletContext == false ? { width: width } : {}
        ) : (
          OutletContext == false ? { width: width, paddingTop: "3rem" } : {}
        )} >
        <div className="mt-2 p-sm-2 p-md-0">
          {users_data.length > 0 ? (
            <div className="p-2 row ">
              {users_data.map((ele: any, index: any) => (
                <div className="col-md-4 mb-3">
                  <div className="card radius px-2 pt-3 pb-0">
                    <div className="position-absolute right-0 ">
                      <div className="dropdown menu ms-auto cursor px-2 user-dropdown">
                        <div
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <Entypo name="dots-three-vertical" size={16} color="#777" />
                        </div>

                        <ul className="dropdown-menu ms-auto dropdown-menu-start">
                          {owner == true &&
                            ele.loggedin_user == false &&
                            ele.admin == true ? (
                            <li
                              onClick={() => {
                                // SetRemoveOwnerModal({
                                //   is_open: true,
                                //   data: {
                                //     id: ele.id,

                                //     type: "brand",
                                //     status: "active",
                                //   },
                                // });
                              }}
                            >
                              <button className="dropdown-item py-2 mb-1 d-flex align-items-center" type="button">
                                <Feather name="user-x" size={18} color="#666" />
                                <p className="ps-3 fs-7  text-black">Remove Owner</p>
                              </button>
                            </li>
                          ) : (
                            ""
                          )}
                          {owner == true &&
                            ele.loggedin_user == false &&
                            ele.admin == false ? (
                            <li
                              onClick={() => {
                                // SetOwnerModal({
                                //   is_open: true,
                                //   data: {
                                //     id: ele.id,

                                //     type: "brand",
                                //     status: "active",
                                //   },
                                // });
                              }}
                            >
                              <button className="dropdown-item py-2 mb-1 d-flex align-items-center" type="button">
                                <Feather name="user-plus" size={18} color="#666" />
                                <p className="ps-3 fs-7  text-black">Set Owner</p>
                              </button>
                            </li>
                          ) : ("")}
                          <li

                            onClick={(event) => {
                              let set_data = {
                                value: ele,
                                index: ele.i,
                              };
                              SetEditUserModal({
                                is_open: true,
                                type: "view",
                                data: set_data,
                              });
                              console.log("Edit");
                              // sample_fun(anchor, false, event);
                            }}
                          >
                            <button className="dropdown-item py-2 mb-1 d-flex align-items-center" type="button"

                            >
                              <MaterialCommunityIcons name="eye-outline" size={18} color="#666" />
                              <p className="ps-3 fs-7 text-black">View</p>
                            </button>
                          </li>
                          {console.log(ele.custom_roles)}
                          <li
                            // onClick={() => {
                            //   console.log(ele)
                            //   SetEditUserModal({
                            //     is_open: true,
                            //     data: ele,
                            //     index: 0,
                            //   });
                            //   // console.log("Edit");
                            //   // sample_fun(anchor, false, event);
                            // }}

                            onClick={(event) => {
                              let set_data = {
                                value: ele,
                                index: ele.i,
                              };
                              SetEditUserModal({
                                is_open: true,
                                type: "edit",
                                data: set_data,
                              });
                              console.log("Edit");
                              // sample_fun(anchor, false, event);
                            }}
                          >
                            <button className="dropdown-item py-2 mb-1 d-flex align-items-center" type="button">
                              <Feather name="edit" size={16} color="#666" />
                              <p className="ps-3 fs-7  text-black">Edit</p>
                            </button>
                          </li>

                          <li
                            onClick={() => {

                              SetDeleteModal({
                                is_open: true,
                                data: {
                                  id: ele.id,

                                  type: "brand",
                                  status: "active",
                                },
                              });
                            }}
                          >
                            <button className="dropdown-item py-2 mb-1 d-flex align-items-center" type="button">
                              <MaterialCommunityIcons name="delete-outline" size={18} color="#666" />
                              <p className="ps-3 fs-7  text-black">Delete</p>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="text-center">
                      <img src={ele.profile_picture} className="franchise-user-img" />


                      <div className="pt-2">
                        <div>
                          <p className="text-black fw-bold oneline mt-1">{ele.user}</p>
                          <p className="fw-500 text-gray extra-small mt-1">@{ele.username}</p>
                        </div>
                        <div className="mt-1 mb-2">
                          {ele.admin && <span className="badge bg-prime btn-radius extra-small">Owner</span>}
                        </div>

                      </div>
                    </div>


                    <div className="border-top">
                      <Accordion className='px-0'>
                        <AccordionSummary
                          expandIcon={<MaterialIcons name="keyboard-arrow-down" size={20} color="#333" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="px-1"
                        >
                          <p className="text-black small">Contact Details</p>
                        </AccordionSummary>
                        <AccordionDetails className="px-1">
                          <div>
                            <div className=" d-flex align-items-center">
                              <Ionicons name="md-location-outline" size={15} color="#444" />
                              <p className="ms-2 v-small text-black">{ele.address}</p>
                            </div>
                            <div className="mt-3 d-flex align-items-center">
                              <Feather name="mail" size={13} color="#777" />
                              <p className="ms-2 small">{ele.email}</p>
                            </div>

                            <div className="mt-3 d-flex align-items-center">
                              <Feather name="phone-call" size={13} color="#777" />
                              <p className="ms-2 small">{ele.mobile}</p>
                            </div>
                            <div className="mt-3 d-flex align-items-center">
                              <Ionicons name="logo-whatsapp" size={13} color="#777" />
                              <p className="ms-2 small">{ele.whatsapp}</p>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>
              ))}
            </div>

          ) : (
            <div className="bg-fff p-2 mt-5 radius">
              <div className="text-center">
                <img
                  src={require("../../../assets/icon/empty.png")}
                  className="w-30 pb-2"
                />
                <p className="v-small mb-0">No Data, Please Add Users</p>
              </div>
            </div>
          )}

        </div>
      </div>


      {/* <ModalDeleteUser
        open={openModal.is_open}
        data={openModal.data}
        close={ModalOnClose}
      /> */}

      <ModalDeleteUser
        open={deleteModal.is_open}
        data={deleteModal.data}
        close={(data: any) => {
          // console.log("Franchise modl on close :", data);
          SetDeleteModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            get_users();
          }
        }}
      />

      <ModalCreateUsers
        open={user_modal.is_open}
        data={user_modal.data}
        type={user_modal.type}
        close={(data) => {
          console.log("Modal user on close :", data);
          SetUserModal({
            is_open: false,
            type: "add",
            data: {},
          });
          if (data.action == "save") {
            SetUsersData((prevValue) => {
              if (prevValue == undefined) {
                prevValue = [];
                prevValue.push(data.value);
              } else {
                prevValue.push(data.value);
              }
              return [...prevValue];
            });
          }
          if (data.action == "edit") {
            SetUsersData((prevValue) => {
              prevValue[data.index] = data.value;
              return [...prevValue];
            });
          }
        }}
      />


      <ModalEditUsers
        open={edit_user_modal.is_open}
        data={edit_user_modal.data}
        type={edit_user_modal.type}
        index={edit_user_modal.index}
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          SetEditUaerModal({
            is_open: false,
            data: {},
            type: "",
            index: "",
          });
          if (data.action == "edit") {
            get_users();
            // SetUsersData((prevValue) => {
            //   prevValue[data.index] = data.value;
            //   return [...prevValue];
            // });
          }
        }}
      />
  {/* <ModalEditUsers
        open={edit_user_modal.is_open}
        data={edit_user_modal.data}
        index={edit_user_modal.index}
        close={(data:any) => {
          console.log("Edit Outlet Modal On Close :", data);
          SetEditUaerModal({
            is_open: false,
            data: {},
            index: "",
          });
          if (data.action == "edit") {
            SetUsersData((prevValue) => {
              prevValue[data.index] = data.value;
              return [...prevValue];
            });
          }
        }}
      /> */}

  {
    ["left", "right", "top", "bottom"].map((anchor: any) => (
      <React.Fragment key={anchor}>
        <SwipeableDrawer
          anchor={anchor}
          open={state[anchor]}
          // onClose={toggleDrawer(anchor, false)}
          // onOpen={toggleDrawer(anchor, true)}
          onClose={(event) => {
            sample_fun(anchor, false, event);
          }}
          onOpen={(event) => {
            sample_fun(anchor, true, event);
          }}
        >
          {list(anchor)}
        </SwipeableDrawer>
      </React.Fragment>
    ))
  }
    </div >
  );
}
