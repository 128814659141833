import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import {
  MaterialCommunityIcons,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
// import ModalVariants from "./ModalVariants";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import SwipeableBottomSheet from '@geomatico/react-swipeable-bottom-sheet';
import { AppHelper } from "../../../utils/Helper";


function valuetext(value) {
  return `${value}°C`;
}

export default function AddCollectionProducts(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    product_name: "",
    product_id: "",
    product_checked: "",
    data: {},
  });
  const [text, setText] = useState("");
  const [type, setType] = useState("all");
  const [id_value, SerIdValue] = useState(useParams());
  const [filter_open, SetFilterOpen] = useState(false);
  const [product_data, SetProductData] = useState([]);
  const [data, SetData] = useState([]);
  const [variant, SetVariant] = useState([]);
  const [filter, SetFilter] = useState([]);
  const [timer, SetTimer] = useState(null);
  const [width, SetWidth] = useState(innerWidth);
  const [sort_open, SetSortOpen] = useState(false);
  const [min_max_value, SetMinMaxValue] = React.useState(["", ""]);
  const [brand, SetBrand] = useState([]);
  const [category, SetCategory] = useState([]);
  const [f_products, SetFproducts] = useState({
    own_products: false,
    others_products: false,
  });

  const [sort, SetSort] = useState("");
  const [collection_id, SetCollectionId] = useState("");

  const [count, SetCount] = useState(10);

  const [search, SetSearch] = useState("");
  const [page_no, SetPageNo] = useState(1);

  const [next_page, SetNextPage] = useState(false);
  const WAIT_INTERVAL = 700;
  const PRICE_WAIT_INTERVAL = 2000;

  // const [sliderWidth, SetSlideWidth] = useState(0);
  // const [offsetSliderWidht, SetOffsetSlideWidth] = useState(0);
  // const [min, SetMin] = useState(0);
  // const [max, SetMax] = useState(200);
  // const [minValueBetween, SetMinValueBetween] = useState(10);
  // const [currentMin, SetCurrentMin] = useState(55);
  // const [inputMin, SetInputMin] = useState(55);
  // const [currentMax, SetCurrentMax] = useState(100);
  // const [inputMax, SetInputMax] = useState(100);

  useEffect(() => {
    console.log("Add Product On mount :", props);
    console.log("Add Product context :", context);

    var baseUrl = window.location.href; // You can also use document.URL
    var last = baseUrl.substring(baseUrl.lastIndexOf("/") + 1, baseUrl.length);
    SetCollectionId(last);
    get_add_products(page_no, count, min_max_value, type, text, sort);
    console.log(f_products);
  }, []);

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const search_text = e.target.value;
    setText(search_text);
    console.log("Text onchange: ", search_text);
    if (search_text == "") {
      let page_no_value = 1;
      SetPageNo(page_no_value);
      get_add_products(
        page_no_value,
        count,
        min_max_value,

        type, search_text, sort
      );
    } else {
      SetTimer(
        setTimeout(() => {
          get_add_products(
            1,
            count,
            min_max_value,

            type, search_text, sort
          );
        }, WAIT_INTERVAL)
      );
    }
  }

  function countChange(e: any) {
    console.log("countChange:", e.target.value);
    SetCount(e.target.value);
    get_add_products(1, e.target.value, min_max_value, type, text, sort);
    get_add_products(1, e.target.value, min_max_value, type, text, sort);
  }

  const handleChange = (event: any, newValue: any) => {
    clearTimeout(timer);
    SetMinMaxValue(newValue);
    console.log(newValue);

    if (newValue != "") {
      SetTimer(
        setTimeout(() => {
          get_add_products(1, count, newValue, type, text, sort);
        }, PRICE_WAIT_INTERVAL)
      );
    }
    // SetMinMaxValue(newValue);

    // get_add_products(1,count);
  };

  async function get_add_products(
    page_value: any,
    count_value: any,
    f_min_max_value: any,

    type_a: any,
    search_value: any,
    sort_value: any,
  ) {

    console.log(search_value);
    SetPageNo(page_value);

    let pass_data = {};

    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,
          search: search_value,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: f_min_max_value[0],
          filter_max_mrp: f_min_max_value[1],
          sort: sort_value,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          filter_count: count_value,
          collection_id: id_value.collect_id,
          type: type_a,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: f_min_max_value[0],
          filter_max_mrp: f_min_max_value[1],
          sort: sort_value,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          filter_count: count_value,
          collection_id: id_value.collect_id,
          type: type_a,
        },
      };
    }
    let response: any = await api(
      "/brand/add_collection_variant_list",
      pass_data
    );
    console.log("/brand/add_collection_variant_list :", response);
    if (response.status_code == 200) {
      SetData(response.response);

      if (response.response.hasOwnProperty("data")) {
        SetProductData(response.response.data);
        SetFilter(response.response.filter);
        SetNextPage(response.response.next_page);
      } else {
        SetProductData([]);
      }
    }
  }

  async function add_products(e: any) {
    console.log(e.target.value);

    let pass_data = {};

    pass_data = {
      get: {
        brand: id_value.brand_id,
        collection_id: collection_id,
      },
      post: {
        variants: [e.target.value],
      },
    };

    let response: any = await api(
      "/brand/add_collection_products_v2",
      pass_data
    );
    console.log("/brand/add_collection_products_v2 :", response);
    if (response.status_code == 200) {
      get_add_products(1, count, min_max_value, type, text, sort);
    }
  }

  async function del_products(e: any) {
    console.log(e.target.value);

    let pass_data = {};

    pass_data = {
      get: {
        brand: id_value.brand_id,
        collection_id: collection_id,
      },
      post: {
        products: [e.target.value],
      },
    };

    let response: any = await api(
      "/brand/remove_collection_products_v2",
      pass_data
    );
    console.log("/brand/remove_collection_products_v2", response);
    if (response.status_code == 200) {
      get_add_products(1, count, min_max_value, type, text, sort);
    }
  }

  async function input_on_change(event) {
    console.log("input_on_change value", event.target.name);

    console.log("input_on_change value", event.target.checked);
    console.log("input_on_change value", f_products.others_products);

    let arr = [];

    if (event.target.checked == true) {
      if (event.target.name == "own products") {
        f_products.own_products = true;
      }
    } else {
      if (event.target.name == "own products") {
        f_products.own_products = false;
      }
    }

    if (event.target.checked == true) {
      if (event.target.name == "others products") {
        f_products.others_products = true;
      }
    } else {
      if (event.target.name == "others products") {
        f_products.others_products = false;
      }
    }

    if (event.target.checked == true) {
      if (event.target.name == "filter_brand") {
        brand.push(event.target.value);
      }
    } else {
      if (event.target.name == "filter_brand") {
        brand.splice(brand.indexOf(event.target.value, 1));
      }
    }

    console.log(brand);
    SetBrand(brand);

    if (event.target.checked == true) {
      if (event.target.name == "filter_category") {
        category.push(event.target.value);
      }
    } else {
      if (event.target.name == "filter_category") {
        category.splice(brand.indexOf(event.target.value, 1));
      }
    }

    get_add_products(1, count, min_max_value, type, text, sort);
  }

  function dataSort() {
    if (sort == "asc") {
      SetSort("desc");
      get_add_products(1, count, min_max_value, type, text, "desc");
    } else {
      SetSort("asc");

      get_add_products(1, count, min_max_value, type, text, "asc");
    }
  }

  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          search: text,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: min_max_value[0],
          filter_max_mrp: min_max_value[1],

          sort: sort,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          filter_count: count,
          collection_id: id_value.collect_id,
          type: type,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: min_max_value[0],
          filter_max_mrp: min_max_value[1],
          sort: sort,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          filter_count: count,
          collection_id: id_value.collect_id,
          type: type,
        },
      };
    }
    let response: any = await api("/brand/add_collection_variant_list", pass_data);
    console.log("/brand/add_collection_variant_list :", response);
    SetProductData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  return (
    <div>
      <div className={OutletContext ? "head-bar sticky-sm" : "head-bar  sticky-sm"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="row align-items-center">
          <div className="col-md-7 col-10 sm-mb-2 ">
            <div className="d-flex align-items-center">
              <div className="px-1 cursor " onClick={() => {
                window.history.back(1);
              }}>
                {/* <Link to={"/brand/" + id_value.brand_id + "/collection_products/" + id_value.collect_id}> */}
                <MaterialCommunityIcons name="arrow-left" size={20} color="black" />
                {/* </Link> */}
              </div>
              <h6 className="ps-1 mb-0">Add Products</h6>
            </div>
            <div className="ms-4 mt-1">
              <p className="ps-1 text-gray">{data?.collection?.name}</p>
            </div>
          </div>
          <div className="col-2 d-sm-block d-md-none text-end p-0 sm-mb-2">
            <button className="btn btn-text" onClick={() => {
              SetFilterOpen(true);
            }}>
              <Ionicons name="md-options" size={20} color="black" />
              <span className="position-absolute rounded-circle translate-middle badge rounded-pill bg-prime">
                2
              </span>
            </button>
          </div>
          <div className="col-md-5 md-text-end">
            <div className="d-flex align-items-center">
              <div className="tabs col-md-4 p-0">
                <div
                  className={type == "all" ? 'active-tab-view me-3 cursor' : 'tab-view me-3 cursor'}
                  onClick={() => {
                    setType("all");

                    get_add_products(
                      1,
                      count,
                      min_max_value,

                      "all",
                      text, sort
                    );
                  }}
                >
                  <p className={type == "all" ? "text-white px-2 text-center" : "px-2 text-center"}>All</p>
                </div>
                <div
                  className={type == "active" ? 'active-tab-view me-3 cursor' : 'tab-view me-3 cursor'}
                  onClick={() => {
                    setType("active");
                    get_add_products(1, count, min_max_value, "active", text, sort
                    );
                  }}
                >
                  <p className={type == "active" ? "text-white px-2 text-center" : "px-2 text-center"}>Active</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={OutletContext ? "mt-3 px-md-3 px-sm-0 mb-5" : "mt-3 px-md-3 px-sm-0 mb-5"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="pb-5">
          <div className="row p-md-3 px-sm-3 ">
            <div className="col-md-3 md-ps-0 sm-p-0 d-sm-none d-md-block">
              <div className="bg-fff radius p-3 sticky-md-50px overflowY-auto scrollw-none">
                <div className="d-flex ">
                  <h6 className='fs-5 fw-500'>Filter</h6>
                  <div className="ms-auto cursor">
                    <small className='text-black'>Clear</small>
                  </div>
                </div>
                <div className="my-3">
                  <label className="form-label d-flex align-items-center mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name={"own products"}
                      // value={ele.id}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                    <p className='capitalize'>{"own product"}</p>
                  </label>

                  <label className="form-label d-flex align-items-center mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name={"others products"}
                      // value={ele.id}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                    <p className='capitalize'>{"other product"}</p>
                  </label>
                </div>
                <div className="d-flex ">
                  <h6>Category</h6>
                </div>
                <div className="my-3">
                  {filter.product_categories?.map((ele: any) => (
                    <label className="form-label d-flex align-items-center mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        name={"filter_category"}
                        value={ele.id}
                        onChange={(event) => {
                          input_on_change(event);
                        }}
                      />
                      <p className='capitalize'>{ele.name}</p>
                    </label>
                  ))}
                </div>

                <div className="brand">
                  <Accordion className='px-0'>
                    <AccordionSummary
                      expandIcon={<MaterialIcons name="keyboard-arrow-down" size={20} color="black" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='fs-7 fw-bold text-black'>Brand</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='w-100'>
                        <input
                          type="text"
                          placeholder="Search..."
                          className="form-control btn-radius"
                        />
                        <div className="p-2">
                          {filter.product_brands?.map((ele: any) => (
                            <label className="form-label mt-3 d-flex align-items-center">
                              <input
                                type="checkbox"
                                className="me-2"
                                name={"filter_brand"}
                                value={ele.id}
                                onChange={(event) => {
                                  input_on_change(event);
                                }}
                              />
                              {ele.name}
                            </label>
                          ))}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="mt-3">
                  <Typography id="range-slider" gutterBottom>
                    Mininum Maximum Price
                  </Typography>
                  <div className="d-flex align-items-center">
                    {/* <p className="pe-3">Min</p> */}
                    <Slider
                      value={min_max_value}
                      onChange={handleChange}
                      step={20}
                      onClick={() => {
                        console.log("min max :", min_max_value);
                      }}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                      min={10}
                      max={1000000}
                    />
                    {/* <p className="px-3">Max</p> */}
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <label className="form-label">Min Price</label>
                      <input
                        type="text"
                        className="form-control"
                        name={"min_mrp"}
                        value={min_max_value[0]}
                        onChange={(event) => {
                          input_on_change(event);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-label">Max Price</label>
                      <input
                        type="text"
                        className="form-control"
                        name={"max_mrp"}
                        value={min_max_value[1]}
                        onChange={(event) => {
                          input_on_change(event);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9 sm-mt-3 px-sm-0 px-md-2">
              <div className="row bg-fff  align-items-center p-3 border-bottom">
                <div className="col-7">
                  <div className="row md-none">
                    <div className="input-icons form-group">

                      <input
                        type="text"
                        className="form-control search-bar p-0"
                        placeholder="Search products"
                        name="search"
                        defaultValue={text}
                        onChange={(e) => {
                          onTextChanged(e);
                        }}
                      />
                      <div className="cursor ps-2 border-left">
                        <Ionicons name="md-search" size={18} color="#663fc3" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <select
                    className="number-select form-select btn-radius form-control pe-0 ps-2"
                    defaultValue="10"
                    onChange={(e) => {
                      countChange(e);
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>

                    <option value="40">40</option>

                    <option value="50">50</option>
                  </select>
                </div>
                <div className="col-3 ms-auto">
                  <div
                    className="pe-3 end cursor"
                    onClick={() => {
                      dataSort();
                    }}
                  >
                    <MaterialCommunityIcons name="sort-reverse-variant" size={16} color="#555" />
                    <p className='text-darkgray ps-2 fw-bold'>Sort</p>
                  </div>
                </div>
              </div>
              <div className="row bg-fff py-3 px-sm-0 px-md-1 align-items-center border-bottom">
                {/* {console.log(product_data)} */}
                {product_data.map((ele: any) => (
                  <div className="col-md-6 px-sm-0 px-md-1">
                    <div className="p-3 border radius mb-2">
                      <label className="form-label row mb-0 cursor">
                        <div className="col-1 p-0 text-end">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={ele.checked}
                            value={ele.checked ? ele.cpid : ele.id}
                            // disabled={unavailablity_check}
                            onChange={(e) => {
                              // check_varient(e, index);
                              {
                                ele.checked ? del_products(e) : add_products(e);
                              }
                            }}
                            id="switch"
                          />

                        </div>

                        <div className="col-11">
                          <p className="mb-2 text-dark fw-bold">{ele.name}</p>
                        </div>
                      </label>
                      <div className="row">
                        <div className="col-3">
                          {ele.images ? (
                            <img
                              src={AppHelper.imageSrc(
                                data.image_base_url,
                                ele.images[0]?.url,
                                { format: "webp", width: 100 }
                              )}
                              className="prod-group-img"
                            />
                          ) : (
                            <img
                              src={require('../../../assets/img/noimage.png')}
                              alt="pic"
                              className="prod-group-img"
                            />
                          )}
                        </div>
                        <div className="col-9 p-0">
                          <p className="disc-overflow text-gray small mb-2">
                            {ele.description}
                          </p>
                          <p className="text-black small">
                            MRP :
                            <span className="text-dark fw-bold small ps-1">
                              ₹{ele.min_mrp} - ₹{ele.max_mrp}
                            </span>
                          </p>

                        </div>
                      </div>

                    </div>
                  </div>
                ))}
              </div>
              {next_page ? (
                <p
                  className="text-prime fw-bold text-center cursor my-4"
                  onClick={() => {
                    load_more();
                  }}
                >
                  Load More
                </p>
              ) : null}
            </div>
          </div>


        </div>
      </div>


      {width < 786 ? (
        <SwipeableBottomSheet overflowHeight={0} style={{ zIndex: 1300 }} open={filter_open} onClose={() => { SetFilterOpen(false) }}
          onChange={() => {
            SetFilterOpen(false)
          }} >
          <div className="bg-fff radius-top" style={{ minHeight: "500px", maxHeight: "90vh" }}>
            <div className=" drawer-fixed">
              <div className='w-100 center'>
                <hr className="hr-drawer" />
              </div>
              <div className="row align-items-center pt-2 px-3">
                <div className="col-9">
                  <h6 className='fs-5 fw-500'>Filter</h6>
                </div>
                <div className="col-3 text-end cursor">
                  <small className='text-black'>Clear</small>
                </div>
              </div>
            </div>
            <div className="p-3 pt-5">
              <div className="my-3">
                <label className="form-label d-flex align-items-center mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    name={"own products"}
                    // value={ele.id}
                    onChange={(event) => {
                      input_on_change(event);
                    }}
                  />
                  <p className='capitalize'>{"own product"}</p>
                </label>

                <label className="form-label d-flex align-items-center mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    name={"others products"}
                    // value={ele.id}
                    onChange={(event) => {
                      input_on_change(event);
                    }}
                  />
                  <p className='capitalize'>{"other product"}</p>
                </label>
              </div>
              <div className="d-flex ">
                <h6>Category</h6>
              </div>
              <div className="my-3">
                {filter.product_categories?.map((ele: any) => (
                  <label className="form-label d-flex align-items-center mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name={"filter_category"}
                      value={ele.id}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                    <p className='capitalize'>{ele.name}</p>
                  </label>
                ))}
              </div>

              <div className="brand">
                <Accordion className='px-0'>
                  <AccordionSummary
                    expandIcon={<MaterialIcons name="keyboard-arrow-down" size={20} color="black" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p className='fs-7 fw-bold text-black'>Brand</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='w-100'>
                      <input
                        type="text"
                        placeholder="Search..."
                        className="form-control btn-radius"
                      />
                      <div className="p-2">
                        {filter.product_brands?.map((ele: any) => (
                          <label className="form-label mt-3 d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="me-2"
                              name={"filter_brand"}
                              value={ele.id}
                              onChange={(event) => {
                                input_on_change(event);
                              }}
                            />
                            {ele.name}
                          </label>
                        ))}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div className="mt-3">
                <Typography id="range-slider" gutterBottom>
                  Mininum Maximum Price
                </Typography>
                <div className="d-flex align-items-center">
                  {/* <p className="pe-3">Min</p> */}
                  <Slider
                    value={min_max_value}
                    onChange={handleChange}
                    step={20}
                    onClick={() => {
                      console.log("min max :", min_max_value);
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                    min={10}
                    max={1000000}
                  />
                  {/* <p className="px-3">Max</p> */}
                </div>

                <div className="row">
                  <div className="col-6">
                    <label className="form-label">Min Price</label>
                    <input
                      type="text"
                      className="form-control"
                      name={"min_mrp"}
                      value={min_max_value[0]}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">Max Price</label>
                    <input
                      type="text"
                      className="form-control"
                      name={"max_mrp"}
                      value={min_max_value[1]}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwipeableBottomSheet>
      ) : (
        null
      )}

      {/* <ModalVariants
        open={modal_variants.is_open}
        product={modal_variants.product_name}
        id={modal_variants.product_id}
        checked={modal_variants.product_checked}
        data={modal_variants.data}
        close={(data: any) => {
          console.log("Variants modl on close :", data);
          // SetModalVariants((prevValue: any) => {
          //     prevValue.is_open = false;
          //     return { ...prevValue }
          // })
          SetModalVariants({
            is_open: false,
            product_name: "",
            product_id: "",
            product_checked: "",

            // return { ...prevValue }
            data: {},
          });

          if (data.action == "add") {
            get_add_products();
          }
        }}
      /> */}
    </div>
  );
}
