"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = useClassNames;

var _prefix = require("./prefix");

var _classnames = _interopRequireDefault(require("classnames"));

var _react = require("react");

function useClassNames(componentClassName) {
  var className = (0, _prefix.defaultClassPrefix)('icon');
  var addPrefix = (0, _react.useCallback)(function (blockClassName) {
    return (0, _prefix.prefix)(className, blockClassName);
  }, []);
  return [(0, _classnames["default"])(className, (0, _prefix.defaultClassPrefix)(componentClassName)), addPrefix];
}

module.exports = exports.default;