import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams } from "react-router-dom";
import { api } from "../../../utils/Api";
import ModalCreateFranchise from "./ModalCreateFranchise";

export default function ViewOutlet(props: any) {
  const context = useContext(DataContext);
  const [modal_franchise, SetModalFranchise] = useState({
    is_open: false,
    data: "",
    type: "",
  });
  const [id_value, SerIdValue] = useState(useParams());

  useEffect(() => {
    console.log("Outlet On mount :", props);
    console.log("Outlet context :", context);
  }, []);

  return (
    <div>
      <div className="bg-fff py-2 px-4 d-flex align-items-center">
        <div>
          <div className="d-flex align-items-center">
            <Link to={"/brand/" + id_value.brand_id + "/viewfranchise"}>
              <img
                src={require("../../../assets/icon/svg/arrow-left.svg")}
                className="cursor pe-2"
              />
            </Link>
            <h6 className="  mb-1">Outlet 1</h6>
          </div>
        </div>
        <div className="ms-auto">
          <button
            className="btn btn-primary"
            onClick={() => {
              console.log("Franchise new");
              SetModalFranchise((prevValue: any) => {
                prevValue.is_open = true;
                prevValue.type = "outlet";
                return { ...prevValue };
              });
            }}
          >
            Create New
          </button>
        </div>
      </div>

      <div className="px-3 mt-4 ">
        <div className="row">
          <div className="col-md-2">
            <div className="card p-3">
              <p className="mb-1 text-dark">Profit</p>
              <h4>16500</h4>
              <small>+5% of target</small>
            </div>
          </div>

          <div className="col-md-2">
            <div className="card p-3">
              <p className="mb-1 text-dark">Sales</p>
              <h4>16500</h4>
              <small>+5% of target</small>
            </div>
          </div>

          <div className="col-md-2">
            <div className="card p-3">
              <p className="mb-1 text-dark">Orders</p>
              <h4>16500</h4>
              <small>+5% of target</small>
            </div>
          </div>

          <div className="col-md-2">
            <div className="card p-3">
              <p className="mb-1 text-dark">Customers</p>
              <h4>16500</h4>
              <small>+5% of target</small>
            </div>
          </div>

          <div className="col-md-2">
            <div className="card p-3">
              <p className="mb-1 text-dark">Returns</p>
              <h4>16500</h4>
              <small>+5% of target</small>
            </div>
          </div>
        </div>
      </div>

      <ModalCreateFranchise
        open={modal_franchise.is_open}
        type={modal_franchise.type}
        close={(data: any) => {
          console.log("Franchise modl on close :", data);
          SetModalFranchise((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
        }}
      />
    </div>
  );
}
