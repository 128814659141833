import React, { useState, useEffect, useContext, useRef } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
import { useParams } from "react-router-dom";
import DataContext from "../../utils/Context/DataContext";
import { mobileApp } from "../../utils/MobileApp";
import "./QrStyles.css";
import QrScanner from "qr-scanner";
import QrFrame from "../../assets/svg/qr-frame.svg";

let myModal = {};

export default function ModalScanner(props: any) {
  const context: any = useContext(DataContext);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());

  const [radio_val, SetRadioVal] = useState("1");
  const [selected_outlet, SetSelectedOutlet]: any = useState({});

  // QR States
  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);

  // Result
  const [scannedResult, setScannedResult] = useState("");

  // Success
  const onScanSuccess = (result: QrScanner.ScanResult) => {
    // 🖨 Print the "result" to browser console.
    console.log("onScanSuccess", result);
    // ✅ Handle success.
    // 😎 You can do whatever you want with the scanned result.
    setScannedResult(result?.data);
  };

  // Fail
  const onScanFail = (err: string | Error) => {
    // 🖨 Print the "err" to browser console.
    console.log("onScanFail :", err);
  };

  useEffect(() => {
    console.log("ModalScanner On mount :", props);
    let myModal1 = document.getElementById("ScannerModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      setScannedResult("");
    //   if (!videoEl?.current) {
    //     scanner?.current?.stop();
    //   }
      scanner?.current?.stop();
      props.close(close_data);
    });
    // return () => {
    //   if (!videoEl?.current) {
    //     scanner?.current?.stop();
    //   }
    // };
  }, []);

  useEffect(() => {
    if (!qrOn)
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);

  useEffect(() => {
    if (props.open) {
      console.log("ModalScanner On mount :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("ScannerModal"),
        {}
      );
      myModal.show();
      SetSelectedOutlet(props.data.selected_outlet);
      if (
        context.app_data.application.platform == "android" ||
        context.app_data.application.platform == "ios"
      ) {
        Permission();
      } else {
      }
      console.log("videoEl?.current :", videoEl?.current);
      console.log("scanner?.current :", scanner?.current);
    //   if (videoEl?.current && !scanner.current) {
        // 👉 Instantiate the QR Scanner
        scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
          onDecodeError: onScanFail,
          // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
          preferredCamera: "environment",
          // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
          highlightScanRegion: true,
          // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
          highlightCodeOutline: true,
          // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
          overlay: qrBoxEl?.current || undefined,
        });

        // 🚀 Start QR Scanner
        scanner?.current
          ?.start()
          .then(() => setQrOn(true))
          .catch((err) => {
            if (err) setQrOn(false);
          });
    //   }
    }
  }, [props.open]);

  function Permission() {
    if (
      context.app_data.application.platform == "android" ||
      context.app_data.application.platform == "ios"
    ) {
      mobileApp({
        function: "opencamera",
        app_type: "react_web",
      });
    }
  }

  async function activate_qr() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        url: scannedResult,
      },
    };
    if (radio_val == "2") {
      pass_data.post.outlet_id = selected_outlet.id;
    }
    let response: any = await api("/brand/activate_qr", pass_data);
    console.log("/brand/activate_qr :", response);
    if (response.status_code == 200) {
      let response_data = response.response;
      let item = close_data;
      item.action = "save";
      item.data = {
        // qr_link : response_data[0].qr_link,
        // id : response_data[0].id,
        whole_data: response_data,
      };
      SetCloseData(item);
      myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="ScannerModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-sm-down">
        <div className="modal-content">
          {/* head */}
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              Scan Qr Code
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          {/* body */}
          <div className="modal-body">
            {scannedResult == "" ? (
              <div className="qr-reader">
                {/* QR */}
                <video ref={videoEl}></video>
                <div ref={qrBoxEl} className="qr-box">
                  {/* <img
                    src={QrFrame}
                    alt="Qr Frame"
                    width={256}
                    height={256}
                    className="qr-frame"
                  /> */}
                </div>

                {/* Show Data Result if scan is success */}
                {scannedResult && (
                  <p
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 99999,
                      color: "white",
                    }}
                  >
                    Scanned Result: {scannedResult}
                  </p>
                )}
              </div>
            ) : (
              <>
                {/* type 1 */}
                <div
                  className="form-check mb-2"
                  onClick={() => {
                    SetRadioVal("1");
                  }}
                >
                  <input
                    className="form-check-input"
                    checked={radio_val == "1"}
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    onChange={(e) => {
                      console.log("valjue 1");
                      // SetRadioVal(e.target.value);
                    }}
                    value={1}
                  />
                  <label
                    className="form-check-label text-black"
                    htmlFor="flexRadioDefault1"
                  >
                    Link Qr To Brand
                  </label>
                </div>
                {/* type 2 */}
                <div
                  className="form-check mb-2"
                  onClick={() => {
                    // SetRadioVal(e.target.value);
                    SetRadioVal("2");
                  }}
                >
                  <input
                    className="form-check-input"
                    checked={radio_val == "2"}
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    onChange={(e) => {
                      console.log("valjue 2");
                      // SetRadioVal(e.target.value);
                    }}
                    value={2}
                  />
                  <label
                    className="form-check-label text-black"
                    htmlFor="flexRadioDefault2"
                  >
                    Link Qr To Outlet
                  </label>
                </div>
                {/* outlet list */}
                {radio_val == "2" ? (
                  <div className="">
                    <label className="item-center">Outlet</label>
                    <select
                      data-show-content="true"
                      className="form-select "
                      id="exampleFormControlSelect1"
                      value={selected_outlet.id}
                      onChange={(e) => {
                        console.log("on change :", e.target.value);
                        let value_data = e.target.value;
                        props.data.outlet_list.map((ele: any, index: any) => {
                          if (ele.id == value_data) {
                            SetSelectedOutlet(ele);
                          }
                        });
                      }}
                    >
                      {props.data.outlet_list?.map((ele: any, i: any) => (
                        <option key={i} value={ele?.id}>
                          {ele?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className="modal-footer">
            {scannedResult != "" ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={(event) => {
                  console.log("ScannerModal add");
                  activate_qr();
                }}
              >
                Activate
              </button>
            ) : null}
            {/* ) : null} */}
          </div>
        </div>
      </div>
    </div>
  );
}
