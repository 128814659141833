import React, { useState, useEffect, useContext } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import DataContext from '../../utils/Context/DataContext'
import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons'

let myModal = {}

export default function ModalDelete(props: any) {
  const context = useContext(DataContext)
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const [id_value, SetIdValue] = useState(useParams())
  const [options_data, SetOptionsData] = useState({})

  useEffect(() => {
    if (props.open) {
      console.log('ModalDelete On Open :', props)
      myModal = new bootstrap.Modal(document.getElementById('DeleteModal'), {})
      myModal.show()
      if (props.have_option) {
        SetOptionsData(props.options)
      }
    }
  }, [props.open])

  useEffect(() => {
    console.log('ModalDelete On mount :', props)
    let myModal1 = document.getElementById('DeleteModal')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      props.close(close_data)
    })
  }, [])

  function onCancel() {
    let item = close_data
    item.action = 'close'
    SetCloseData(item)
    myModal.hide()
  }

  return (
    <div
      className="modal p-2"
      id="DeleteModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content radius m-2">
          {/* header */}
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              {props.data.title}
            </h6>
            {/* <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button> */}
          </div>
          {/* body */}
          <div className="modal-body">
            {/* <h6 className="mb-3  modal-title" id="ModalLabel">
              {props.data.title}
            </h6> */}

            <p className='text-dark fs-7'>{props.data.body}</p>
            {props.have_option ? (
              <div className="my-2">
                {Object.keys(options_data).length > 0 ? (
                  <div className="">
                    <label>
                      <input className='form-check-input me-2'
                        type={options_data.type}
                        checked={options_data.is_checked}
                        onChange={(e) => {
                          SetOptionsData((prevValue: any) => {
                            prevValue.is_checked = e.target.checked
                            return { ...prevValue }
                          })
                        }}
                      />
                      {options_data.title}
                    </label>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          {/* footer */}
          <div className="modal-footer">
            <div className="d-flex align-items-center w-100">
              <button
                type="button"
                className="btn btn-secondary-outline"
                onClick={() => {
                  let item = close_data
                  item.action = 'close'
                  SetCloseData(item)
                  myModal.hide()
                }}
              >
                Cancel
              </button>
              <div className="ms-auto">
                <button
                  type="button"
                  className="btn btn-primary-outline"
                  onClick={() => {
                    // onSubmit();
                    let item = close_data
                    item.action = 'save'
                    item.have_option = props.have_option
                    if (props.have_option) {
                      item.options = options_data
                    }
                    item.data = props.data.data
                    item.delete_type = props.delete_type
                    console.log('Delete send item on hide:', item)
                    SetCloseData(item)
                    myModal.hide()
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}