import React, { useState, useEffect } from "react";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import { Link, useParams, useOutletContext, Outlet } from "react-router-dom";
import { api } from "../../../utils/Api";
import { start } from "@popperjs/core";


let myModal: any;
export default function ModalTime(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value: "",
    index: "",
    data: "",
    demo_order: false
  });
  console.log(props)
  const [allow, setAllow] = useState("");
  const [desc, setDesc] = useState("")
  const [from, setFrom] = useState("")

  const [notes, setNotes] = useState([])
  const [note_id, setNoteId] = useState("")
  const [startDateShow, setStartDateShow] = useState(getFormattedDateTime(props.data.start));
  const [endDateShow, setEndDateShow] = useState(getFormattedDateTime(props.data.end));
  const [startDate, setStartDate] = useState(convertDateTime(getFormattedDateTime(props.data.start)));
  const [endDate, setEndDate] = useState(convertDateTime(getFormattedDateTime(props.data.end)));
  const [id_value, SerIdValue] = useState(useParams());

  function getFormattedDateTime(date: any) {

    // const year = date.getFullYear();
    // const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    // const day = String(date.getDate()).padStart(2, '0');
    // const hours = String(date.getHours()).padStart(2, '0');
    // const minutes = String(date.getMinutes()).padStart(2, '0');

    // return `${year}-${month}-${day}`;
    return date;
  }
  const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setDesc(searchValue);
  }

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("cart");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      setDesc("");
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log(id_value)
      setFrom(props.data.from)
      console.log("ModelDeleteUserRoles On props.open :", props);
      setStartDateShow(props.data.start)
      setEndDateShow(props.data.end)

      const convertedDateTime = convertDateTime(props.data.start);

      setStartDate(convertedDateTime)


      const convertedEndDateTime = convertDateTime(props.data.end);

      setEndDate(convertedEndDateTime)
      // setStartDate(props.data.start)
      // setEndDate(props.data.end)
      if (props.data.dm == true) {
        setAllow("on")
      } else {
        setAllow("off")

      }

      myModal = new bootstrap.Modal(
        document.getElementById("cart"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);




  async function Save() {
    let pass_data: any

    if (from == "settlement") {
      pass_data = {

        get: {

          brand: id_value.brand_id,

          outlet: props.data.outlet,
          view: "custom",
          from: startDate,
          to: endDate



        }
      };
    } else if (from == "brand") {
      pass_data = {

        get: {

          brand: id_value.brand_id,


          view: "custom",
          from: startDate,
          to: endDate



        }
      };
    } else {
      pass_data = {

        get: {

          brand: id_value.brand_id,

          outlet: id_value.outlet_id,
          view: "custom",
          from: startDate,
          to: endDate



        }
      };
    }
    console.log(props)
    let data_res: any
    console.log(props.data.from)
    if (from == "settlement") {
      // data_res = await api("/brand/settlement_statistics", pass_data);
    } else if (from == "brand") {
      data_res = await api("/brand/statistics", pass_data);
    } else {
      data_res = await api("/outlet/statistics", pass_data);
    }


    console.log(data_res)
    if (data_res?.status_code == 200) {
      let item = close_data;
      console.log(item)
      item.action = "save";
      item.index = props.data.value;
      item.data = data_res.response;
      item.demo_order = data_res.response.ecommerce_cart_response
      setDesc("");
      // item.value =  data_res.response.data.cod_allowed_all_users;
      SetCloseData(item);

      myModal.hide();
    }
  }


  const handleApplicationChange = (event: any) => {
    console.log(event.target.value)
    setNoteId(event.target.value)
  };

  const handleStartChange = (e) => {
    //  let start:any = moment(date).format('DD-MM-YYYY HH:mm:ss')
    console.log(e.target.value)
    const convertedDateTime = convertDateTime(e.target.value);
    console.log(convertedDateTime);
    setStartDateShow(e.target.value)
    setStartDate(convertedDateTime)

    //  getSuperappStatistics(start,endDate)
    //  get_order_statistics(start,endDate)
    //  get_order_price_statistics(start,endDate)

  };

  const handleEndChange = (e) => {
    const convertedDateTime = convertDateTime(e.target.value);
    setEndDateShow(e.target.value)
    setEndDate(convertedDateTime)




    // getSuperappStatistics(startDate,end)
    // get_order_statistics(startDate,end)
    // get_order_price_statistics(startDate,end)



  };

  function convertDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const year = date.getFullYear();

    // const hours = String(date.getHours()).padStart(2, '0');
    // const minutes = String(date.getMinutes()).padStart(2, '0');
    // const seconds = '00'; // Set seconds to 00

    const formattedDateTime = `${day}-${month}-${year} `;
    return formattedDateTime;
  }

  return (
    <div
      className="modal fade"
      id="cart"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Set Your Custom Date Range
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="ms-auto">
              <div className="d-flex">
                <div className="me-2">
                  From
                  <input type="date"
                    onChange={handleStartChange}
                    id="startDateTime"
                    className="form-control"
                    value={startDateShow}
                    name="startDateTime" required />

                  {/* <Datetime 
                    id="start"
                    // value={startDate}
                    onChange={handleStartChange}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    inputProps={{ placeholder: 'Select start date and time' }}
                /> */}
                </div>

                <div>

                  To
                  <input type="date"

                    id="endDateTime"
                    className="form-control"
                    value={endDateShow}
                    onChange={handleEndChange}
                    name="endDateTime" required />
                  {/*         
                <Datetime 
                    id="end"
                    value={endDateShow}
                    onChange={handleEndChange}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    inputProps={{ placeholder: 'Select end date and time' }}
                /> */}
                </div>
                {/* <button className="btn btn-primary btn-sm ms-2 h-10" 
            onClick={handleApply}
            >Apply</button> */}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"

              onClick={() => {
                if (props.from == "settlement") {
                  let item:any = close_data;
                  item.action = "save";
                  item.startDate = startDate;
                  item.endDate = endDate;
                  SetCloseData(item);

                  myModal.hide();
                } else {
                  Save();
                }
              }
                // myModal.hide();
              }
            // data-bs-dismiss="modal"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
