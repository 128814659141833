import React, { useState, useEffect } from "react";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap.min.js";

import { Link, useParams } from "react-router-dom";
import { api } from "../../../utils/Api";
import { mobileApp } from "../../../utils/MobileApp";

const outlet_initial_value = {
  channel_id: "",
};

let myModal: any;
export default function ModalRejected(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });

  console.log(props.data.id);

  const [id_value, SerIdValue] = useState(useParams());
  const [outlet_data, SetOutletData] = useState(outlet_initial_value);

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("DelUser");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelDeleteUserRoles On props.open :", props);
      console.log(id_value);

      myModal = new bootstrap.Modal(document.getElementById("DelUser"), {});
      myModal.show();
    }
  }, [props.open]);

  async function deleteUserRole() {

    let pass_data = {
      get: {
        brand: props.data.brand_id,
        outlet: props.data.outlet_id,
        order_id: props.data.id,
      },
      post: {
        status: "Reject",
      },
    };
    console.log(pass_data);
    let data_res = await api("/outlet/update_order_status", pass_data);
    console.log("/outlet/update_order_status :", data_res.response);
    if (data_res.status_code == 200) {
      const ring = {
        function: "refreshringnotifications",
        app_type: "react_web",
      };
      mobileApp(ring);
      let item = close_data;
      item.action = "delete";
      // item.value = data_res.response.data;
      console.log("Close data : ", item);
      SetCloseData(item);
      // SetOutletData(outlet_initial_value);
      myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="DelUser"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Reject Order
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">Are you sure want to reject order?</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              name="channel_id"
              value={props.data.id}
              onClick={(e) => {
                deleteUserRole();
              }}
            >
              Reject
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
