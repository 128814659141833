import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import CreatableSelect from 'react-select/creatable';
import { omit } from "lodash";
import toast, { Toaster } from 'react-hot-toast';


const animatedComponents = makeAnimated();

const inventory_initial_value = {
  product_id: "",
  type: "",
  // mrp: "",
  batch_number: "",
  batch: "",
  manufacturing_date: "",
  expiry_date: "",
  quantity_tracking: "0",
  quantity: "",
  buying_price: "",
  buying_price_currency: "1",
};

let myModal = {};

export default function ModalCreateAddonsInventory(props: any) {
  console.log(props);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());

  const [inventory_data, SetInventoryData] = useState(inventory_initial_value);
  const [user_role_options, SetUserRoleOptions] = useState({});
  const [text, setText] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [user_data, SetUserData] = useState([]);
  const [next_page, SetNextPage] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const [new_franchise_id, SetNewFranchiseId] = useState(null);
  const [modal_type, SetModalType] = useState("");
  const [dimention_unit, SetDimentionUnit] = useState([]);
  const [weight_unit, SetWeightUnit] = useState([]);
  const [variant_id, SetVariantId] = useState("");
  const [batches, SetBatches] = useState([]);
  const [currencies, SetCurrencies] = useState([]);
  const [inventory_types, SetInventoryTypes] = useState([]);
  const [mrp, SetMrp] = useState([]);

  //FranchiseData End

  useEffect(() => {
    console.log("ModalFranchiseUser On mount :", props);
    let myModal1 = document.getElementById("FranchiseUserModal1");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      SetInventoryData(inventory_initial_value);
      setText("");
      SetPageNo(1);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModalFranchiseUser On mount :", props);
      console.log("ModalFranchiseUser On mount :", props.units);

      myModal = new bootstrap.Modal(
        document.getElementById("FranchiseUserModal1"),
        {}
      );
      myModal.show();
      // SetModalType(props.type);
      // get_roles();
      // get_users(page_no);
      // if (props.type == "edit" || props.type == "view") {
      // let set_data = {
      //   gst_percent: props.data.gst_percent,
      //   hsn: props.data.hsn,
      //   package_breadth: props.data.package_breadth,
      //   package_breadth_unit: props.data.package_breadth_unit,
      //   package_breadth_unit_name: props.data.package_breadth_unit_name,
      //   package_height: props.data.package_height,
      //   package_height_unit: props.data.package_height_unit,
      //   package_height_unit_name: props.data.package_height_unit_name,
      //   package_length: props.data.package_length,
      //   package_length_unit: props.data.package_length_unit,
      //   package_length_unit_name: props.data.package_length_unit_name,
      //   package_weight: props.data.package_weight,
      //   package_weight_unit: props.data.package_weight_unit,
      //   package_weight_unit_name: props.data.package_weight_unit_name,
      // };
      // console.log("On edit franchise data :", set_data);
      // SetInventoryData(set_data);
      SetBatches(props.batches)
      SetCurrencies(props.currencies)
      SetMrp(props.mrp)
      SetInventoryTypes(props.inventory_types)
    }
    // }
  }, [props.open]);

  async function get_roles() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let data_res = await api("/brand/get_brand_roles", pass_data);
    console.log("get_franchise_roles response :", data_res.response);
    let option_data = [];
    Object.keys(data_res.response).map((key, index) => {
      let push_data = {};
      if (key == "custom_roles") {
        push_data.label = "Custom Roles";
        for (var i = 0; i < data_res.response[key].length; i++) {
          data_res.response[key][i].type = "custom_roles";
        }
        push_data.options = data_res.response[key];
        option_data.push(push_data);
      } else if (key == "roles") {
        push_data.label = "Roles";
        for (var i = 0; i < data_res.response[key].length; i++) {
          data_res.response[key][i].type = "roles";
        }
        push_data.options = data_res.response[key];
        option_data.push(push_data);
      }
    });
    console.log("Option Data :", option_data);
    SetUserRoleOptions(option_data);
    if (props.type == "edit" || props.type == "view") {
      let role_set_value = [];
      if (props.data.value.hasOwnProperty("roles")) {
        props.data.value.roles.map((role_item, role_index) => {
          role_set_value.push({
            label: role_item.name,
            value: role_item.role_id,
            type: "roles",
          });
        });
      }
      if (props.data.value.hasOwnProperty("custom_roles")) {
        props.data.value.custom_roles.map((role_item, role_index) => {
          role_set_value.push({
            label: role_item.name,
            value: role_item.role_id,
            type: "custom_roles",
          });
        });
      }
      SetInventoryData((oldValues) => ({
        ...oldValues,
        selected_roles: role_set_value,
      }));
    }
  }

  async function get_users(page_value: any, search_value = null) {
    let pass_data = {};
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          franchise: id_value.franchise_id,
          page: page_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          franchise: id_value.franchise_id,
          page: page_value,
        },
      };
    }
    let response = await api("/brand/get_users_add_franchise", pass_data);
    console.log(
      "Franchise create /brand/get_users_add_franchise response :",
      response
    );
    if (response.response.data) {
      SetUserData(response.response.data);
    }
    SetNextPage(response.response.next_page);
  }

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const value = e.target.value;
    setText(value);
    console.log("Text onchange: ", value);
    if (value == "") {
      let page_no_value = 1;
      SetPageNo(page_no_value);
      get_users(page_no_value);
    } else {
      SetTimer(
        setTimeout(() => {
          get_users(1, value);
        }, WAIT_INTERVAL)
      );
    }
  }

  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != "") {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          franchise: id_value.franchise_id,
          page: page_no_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          franchise: id_value.franchise_id,
          page: page_no_value,
        },
      };
    }
    let response = await api("/brand/get_users_add_franchise", pass_data);
    console.log("Users get_users_add_franchise response :", response);
    SetUserData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  function inventory_value_on_change(name: any, value: any) {
    console.log(name, value);
    SetInventoryData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  async function add_user() {
    console.log("add_user data :", inventory_data);
    let post_value = {
      user_id: inventory_data.user_id,
      roles: {},
      address: inventory_data.address,
      mobile: inventory_data.mobile,
      whatsapp: inventory_data.whatsapp,
      email: inventory_data.email,
    };
    let roles_value = {
      roles: [],
      custom_roles: [],
    };
    inventory_data.selected_roles.map((item, index) => {
      if (item.type == "roles") {
        roles_value.roles.push(item.value);
      } else {
        roles_value.custom_roles.push(item.value);
      }
    });
    post_value.roles = roles_value;
    console.log("post_value :", post_value);
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        franchise: id_value.franchise_id,
      },
      post: post_value,
    };
    let data_res = await api("/brand/add_franchise_user", pass_data);
    console.log("add_franchise_user response :", data_res.response);

       if (data_res.status_code == 200) {
      toast.error(
        data_res.response.message
      );
      }
    if (data_res.status_code == 201) {
      let item = close_data;
      item.action = "save";
      item.value = data_res.response.users[0];
      console.log("Close data : ", item);
      SetCloseData(item);
      SetInventoryData(inventory_initial_value);
      myModal.hide();
    }
  }

  async function update_user() {
    console.log("update_user :", inventory_data.batch);
    console.log("update_user :", inventory_data.batch_number);

    console.log("update_user :", id_value);

    let post_value = {

      addon_id: id_value.addons_id,
      type: inventory_data.type,
      // mrp: inventory_data.mrp,
      // batch_number: inventory_data.batch_number,
      // inventory_data.batch!="" && 
      // batch: inventory_data.batch,
      manufacturing_date: inventory_data.manufacturing_date,
      expiry_date: inventory_data.expiry_date,
      quantity_tracking: inventory_data.quantity_tracking,
      quantity: inventory_data.quantity,
      buying_price: inventory_data.buying_price,
      buying_price_currency: inventory_data.buying_price_currency,

    };


    if (inventory_data.type == "2") {

      if (inventory_data.batch != "") {
        post_value.batch = inventory_data.batch;

      } else {
        post_value.batch_number = inventory_data.batch_number;

      }

    }

    // inventory_data.selected_roles.map((item, index) => {
    //   if (item.type == "roles") {
    //     roles_value.roles.push(item.value);
    //   } else {
    //     roles_value.custom_roles.push(item.value);
    //   }
    // });

    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
      post: post_value,
    };
    console.log("pass_data :", pass_data);

    let data_res = await api("/outlet/create_inventory_addon", pass_data);
    console.log("/brand/brand_product_update_details:", data_res.response);
    if (data_res.status_code == 200) {
      toast.error(
        data_res.response.message
      );
      }
    
    if (data_res.status_code == 201) {
      let item = close_data;
      item.action = "create";
      // item.value = data_res.response.data[0];
      // item.index = props.data.index;
      console.log("Close data : ", item);
      SetInventoryData(inventory_initial_value);

      SetCloseData(item);
      myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="FranchiseUserModal1"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >

{/* <Toaster
  position="top-center"
  reverseOrder={false}
/> */}
      <div className="modal-dialog modal-fullscreen-sm-down">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              {/* {modal_type == "add"
                ? "Add User"
                : modal_type == "edit"
                ? "Edit User"
                : "View User"}  */}
              Create Product Inventory
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
            {/* Package Details */}
            <div>
              {userOpen ? (
                <div className="row">
                  <div className="col-12">
                    <label>Select user</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select User Name"
                      defaultValue={text}
                      onChange={(e) => {
                        onTextChanged(e);
                      }}
                    />
                  </div>

                  <h6 className="mt-3">Available Users</h6>
                  <div className="mt-2">
                    {user_data.map((item, sugIndex) => (
                      <div
                        className="cursor"
                        key={sugIndex}
                        onClick={() => {
                          if (!item.exists) {
                            SetInventoryData((oldValues) => ({
                              ...oldValues,
                              selected_user: item,
                              user_id: item.id,
                            }));
                            setUserOpen(false);
                          }

                          console.log("Select item:", item);
                        }}
                      >
                        <p>
                          <i className="fas fa-user"></i> {item.name}{" "}
                          {item.exists ? " - User Already Exists" : null}
                        </p>
                      </div>
                    ))}
                  </div>
                  {next_page ? (
                    <p
                      onClick={() => {
                        load_more();
                      }}
                    >
                      Load More
                    </p>
                  ) : null}
                </div>
              ) : (
                <div>
                  {modal_type == "view" || "edit" ? (
                    <div className="row">
                      {console.log(inventory_data)}

                      <div className="col-12 col-md-6 mb-3 px-sm-0 px-md-2">
                        <label className="form-label">Type</label>
                        {console.log(dimention_unit)}
                        <div className="input-group">
                          {/* <input
                            type="text"
                            value={inventory_data.package_breadth}
                            onChange={(e) => {
                              inventory_value_on_change("package_breadth", e.target.value);
                            }}
                            className="form-control"
                            placeholder="Recipient's username"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          /> */}
                          <select
                            className="form-select"

                            aria-label="Default select example"
                            value={inventory_data.type}
                            onChange={(e) => {
                              inventory_value_on_change(
                                "type",
                                e.target.value,
                              );
                            }}
                          >
                            {/* <option selected>{inventory_data.package_breadth_unit_name}</option> */}
                            <option value="" disabled>
  Choose type
</option>

                            {inventory_types?.map((ele: any) => (
                              <option
                                value={ele.id}

                              >
                                {ele.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-12 col-md-6 mt-2">
                        <label className="form-label">Mrp</label>
                        <div className="input-group mb-3">
                
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => {
                              inventory_value_on_change(
                                "mrp",
                                e.target.value,
                              );
                            }}
                          >
                            <option disabled selected >Choose mrp</option>
                      
                      {mrp?.map((ele: any) => (
                              <option
                                value={ele.id}
                            
                              >

                                {ele.currency_symbol + " "}
                                
                                {ele.mrp}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div> */}
                      {inventory_data.type == "2" &&
                        <div className="col-12 col-md-6 mb-3 px-sm-0 px-md-2">
                          <label className="form-label">Batch No </label>
                          <div className="input-group">
                            {/* <input
                            type="text"
                            value={inventory_data.package_length}
                            onChange={(e) => {
                              inventory_value_on_change("package_length", e.target.value);
                            }}
                            className="form-control"
                            placeholder="Recipient's username"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          /> */}


                            <CreatableSelect isClearable

                              options={batches}
                              onChange={(e, action) => {
                                console.log("e:", e)
                                console.log("action:", action)

                                e?.__isNew__ ?
                                  (inventory_value_on_change(
                                    "batch_number",
                                    e?.value,
                                  ),
                                    inventory_data.batch = "")

                                  :
                                  (inventory_value_on_change(
                                    "batch",
                                    e?.value,


                                  ),
                                    inventory_data.batch_number = ""
                                  )

                              }}
                            />


                            {/* <select
                            className="form-select w-auto"
                            aria-label="Default select example"
                            onChange={(e) => {
                              inventory_value_on_change(
                                "batch_number",
                                e.target.value,
                              );
                            }}
                          >
                            <option disabled selected > -- select batch number -- </option>

                                     {batches?.map((ele: any) => (
                              <option
                                value={ele.value}
                             className="w-auto"
                              >
                                {ele.label}
                              </option>
                            ))}
                          </select> */}
                          </div>
                        </div>
                      }
                      <div className="col-12 col-md-6 mb-3 px-sm-0 px-md-2">
                        <label className="form-label">Buying Price</label>
                        <div className="input-group  w-100 group-select">

                          <select
                            className="form-select w-30"
                            aria-label="Default select example"

                            onChange={(e) => {
                              inventory_value_on_change(
                                "buying_price_currency",
                                e.target.value,
                              );
                            }}
                          >
                            {/* <option disabled selected >Choose Currencies</option> */}

                            {currencies?.map((ele: any) => (

                              <option
                                value={ele.id}

                              >
                                {ele.symbol}
                              </option>
                            ))}
                          </select>

                          <input
                            type="text"
                            value={inventory_data.buying_price}
                            className="form-control w-70"
                            onChange={(e) => {
                              inventory_value_on_change("buying_price", e.target.value);
                            }}
                            placeholder="Buying Price"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                        </div>
                      </div>


                      <div className="col-12 col-md-6 mb-3 px-sm-0 px-md-2">
                        <label className="form-label">Manufacturing date</label>
                        <input
                          type="date"
                          value={inventory_data.manufacturing_date}
                          className="form-control"
                          readOnly={modal_type == "view" ? true : false}
                          onChange={(e) => {
                            inventory_value_on_change(
                              "manufacturing_date",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-6 mb-3 px-sm-0 px-md-2">
                        <label className="form-label">Expiry date</label>
                        <input
                          type="date"
                          value={inventory_data.expiry_date}
                          className="form-control"
                          readOnly={modal_type == "view" ? true : false}
                          onChange={(e) => {
                            inventory_value_on_change("expiry_date", e.target.value);
                          }}
                        />
                      </div>

                      {/* <div className="col-12 col-md-6 mt-2">
                        <label className="form-label">Quantity</label>
                        <input
                          type="text"
                          value={inventory_data.quantity}
                          className="form-control"
                          readOnly={modal_type == "view" ? true : false}
                          onChange={(e) => {
                            inventory_value_on_change("quantity", e.target.value);
                          }}
                        />
                      </div> */}



                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <div className="">
            {/* {userOpen ? (
              <div>
                <button
                  type="button"
                  className="btn btn-light-outline"
                  onClick={() => {
                    setUserOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                {modal_type == "add" ? (
                  <button
                    type="button"
                    className="btn btn-light-outline"
                    onClick={() => {
                      add_user();
                    }}
                  >
                    Add
                  </button>
                ) : modal_type == "edit" ? ( */}
            <button
              type="button"
              className="btn bg-prime btn-sm text-white py-3 w-100 center btn-radius modal-footer fw-600"
              onClick={() => {
                update_user();
              }}
            >
              Create
            </button>
            {/* ) : (
                  <button
                    type="button"
                    className="btn btn-light-outline"
                    onClick={(event) => {
                      console.log("Close modal", event);
                      SetCloseData(event);
                      myModal1.hide();
                    }}
                  >
                    Close
                  </button>
                )}
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
