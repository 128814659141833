import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
// import ProductAssigningComponent from "../../../components/ProductAssigningComponent/ProductAssigningComponent";
import ProductGroupAssigningAvailableComponent from "../../../components/ProductGroupAssigningAvailableComponent/ProductGroupAssigningAvailableComponent";

export default function ProductGroupAssign(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [id_value, SerIdValue] = useState(useParams());

  useEffect(() => {
    console.log("ProductGroupAssign On mount :", props);
    console.log("ProductGroupAssign context :", context);
    console.log("ProductGroupAssign OutletContext :", OutletContext);
  }, []);

  return (
    <div>
        <ProductGroupAssigningAvailableComponent
          type={"assign"} title={"Product Group Assigning"} is_menu_open={OutletContext}
        />
    </div>
  );
}
