import getWindow from './getWindow';
export default (function (node, val) {
  var win = getWindow(node);
  var top = node.scrollTop;
  var left = 0;

  if (win) {
    top = win.pageYOffset;
    left = win.pageXOffset;
  }

  if (val !== undefined) {
    if (win) {
      win.scrollTo(left, val);
    } else {
      node.scrollTop = val;
    }
  }

  return top;
});