import React, { useState, useEffect, useRef } from "react";
import bootstrap from "../../../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../../../utils/Api";
import { useParams } from "react-router-dom";
import QRCodeStyling from "qr-code-styling";

let myModal = {};

export default function ModalViewQr(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const ref = useRef(null);
  let qr_initial_data: any = {
    data: props.data.qr_link,
    width: 200,
    height: 200,
    // data: " ",
    margin: 0,
    image: "",
    dotsOptions: {
      type: "square",
      color: "#000",
      gradient: null,
    },
    dotsOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000",
        color2: "#000",
        rotation: "0",
        colorStops: [
          { offset: 0, color: "#000" },
          { offset: 1, color: "#000" },
        ],
        type: "linear",
      },
    },
    cornersSquareOptions: {
      type: "",
      color: "#000",
      gradient: null,
    },
    cornersSquareOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000",
        color2: "#000",
        rotation: "0",
        colorStops: [
          { offset: 0, color: "#000" },
          { offset: 1, color: "#000" },
        ],
        type: "linear",
      },
    },
    cornersDotOptions: {
      type: "",
      color: "#000",
      gradient: null,
    },
    cornersDotOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000",
        color2: "#000",
        rotation: "0",
        colorStops: [
          { offset: 0, color: "#000" },
          { offset: 1, color: "#000" },
        ],
        type: "linear",
      },
    },
    backgroundOptions: {
      color: "#fff",
      gradient: null,
    },
    backgroundOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
        rotation: "0",
        colorStops: [
          { offset: 0, color: "#ffffff" },
          { offset: 1, color: "#ffffff" },
        ],
        type: "linear",
      },
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.4,
      margin: 0,
      crossOrigin: "anonymous",
      // crossOrigin: "use-credentials",
    },
    qrOptions: {
      typeNumber: "0",
      mode: "Byte",
      errorCorrectionLevel: "Q",
    },
  };
  let qrCode : any;

  useEffect(() => {
    console.log("ModalViewQr On mount :", props);
    let myModal1 = document.getElementById("ViewQrModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
    // qr_initial_data.data = "";
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModalViewQr On Open :", props);
      myModal = new bootstrap.Modal(document.getElementById("ViewQrModal"), {});
      myModal.show();
    }
    // qr_initial_data.data = props.data.qr_link;
    qrCode = new QRCodeStyling(qr_initial_data);
    qrCode.append(ref.current);
    qrCode.update({data :props.data.qr_link});

    // }, [props.open]);
  }, [props]);


  const downloadQr=()=>{

    let qr_download_data: any = {
      data: props.data.qr_link,
      width: 1000,
      height: 1000,
      // data: " ",
      margin: 0,
      image: "",
      dotsOptions: {
        type: "square",
        color: "#000",
        gradient: null,
      },
      dotsOptionsHelper: {
        colorType: {
          single: true,
          gradient: false,
        },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000",
          color2: "#000",
          rotation: "0",
          colorStops: [
            { offset: 0, color: "#000" },
            { offset: 1, color: "#000" },
          ],
          type: "linear",
        },
      },
      cornersSquareOptions: {
        type: "",
        color: "#000",
        gradient: null,
      },
      cornersSquareOptionsHelper: {
        colorType: {
          single: true,
          gradient: false,
        },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000",
          color2: "#000",
          rotation: "0",
          colorStops: [
            { offset: 0, color: "#000" },
            { offset: 1, color: "#000" },
          ],
          type: "linear",
        },
      },
      cornersDotOptions: {
        type: "",
        color: "#000",
        gradient: null,
      },
      cornersDotOptionsHelper: {
        colorType: {
          single: true,
          gradient: false,
        },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000",
          color2: "#000",
          rotation: "0",
          colorStops: [
            { offset: 0, color: "#000" },
            { offset: 1, color: "#000" },
          ],
          type: "linear",
        },
      },
      backgroundOptions: {
        color: "#fff",
        gradient: null,
      },
      backgroundOptionsHelper: {
        colorType: {
          single: true,
          gradient: false,
        },
        gradient: {
          linear: true,
          radial: false,
          color1: "#ffffff",
          color2: "#ffffff",
          rotation: "0",
          colorStops: [
            { offset: 0, color: "#ffffff" },
            { offset: 1, color: "#ffffff" },
          ],
          type: "linear",
        },
      },
      imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.4,
        margin: 0,
        crossOrigin: "anonymous",
        // crossOrigin: "use-credentials",
      },
      qrOptions: {
        typeNumber: "0",
        mode: "Byte",
        errorCorrectionLevel: "Q",
      },
    };
    qrCode = new QRCodeStyling(qr_download_data);
    qrCode.download();
  }
  return (
    <div
      className="modal"
      id="ViewQrModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-fullscreen-sm-down modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header shadow-bottom">
            <h6 className="  modal-title" id="ModalLabel">
              View Qr
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>

          <div className="modal-body">
            <p>ID : {props.data.id}</p>
            <p>Qr Link : {props.data.qr_link}</p>
            
            <div ref={ref} className="text-center p-3" />

            <div className="">
              <button
                type="button"
                className="btn btn-secondary-outline btn-radius px-3 me-2"
                onClick={() => {
                  let item = close_data;
                  item.action = "close";
                  SetCloseData(item);
                  // SetSaveData(initial_value);
                  myModal.hide();
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn bg-prime text-white btn-radius px-3"
                onClick={() => {
                  console.log("download");
                  downloadQr()
                 
                  // qrCode.update({data :"1234567891011121314151617181920"});
                }}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
