import React, { useState, useEffect } from "react";
import { api } from "../../../utils/Api";

import { Link, useParams, useOutletContext } from "react-router-dom";
import ModalCreateChannels from "./ModalCreateChannels";
import ModalDeleteCustomChannel from "./ModalDeleteCustomChannel";
import RequestActivation from "./RequestActivation";
import Skeleton from "react-loading-skeleton";


import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";
import ModalChannelAvailable from "./ModalChannelAvailable";
import { AppConfig } from "../../../config";

export default function BrandSettings(props: any) {
  const OutletContext = useOutletContext();
  const [width, SetWidth] = useState(innerWidth);
  const [id_value, SerIdValue] = useState(useParams());
  const [outlet_data, SetOutlettData] = useState([]);
  const [outlet_data_static, SetOutlettDataStatic] = useState([]);
  const [userDetails, SetUserDetails] = useState({});
  const [custom_channel, SetCustomChannel] = useState([]);
  // const [avail_channel, SetAvailChannel] = useEffect([]);
  const [av_channel, SetAvailchannel] = useState([]);
  const [label, setLabel] = useState("");
  const [startBtnLoader, setStartBtnLoader]: any = useState(false);
  const [is_loading, SetIsLoading] = useState(false);
  const [is_cus_loading, SetIsCusLoading] = useState(false);


  const [modal_new_custom, SetModalNewCustom] = useState({
    is_open: false,
    data: "",
    type: "",
  });
  const [modal_channel_avail, SetModalChannelAvail] = useState({
    is_open: false,
    data: "",
    next_slot: "",
    type: "",
  });
  const [modal_franchise, SetModalFranchise] = useState({
    is_open: false,
    data: "",
    type: "",
  });
  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });
  const [requestActivationModal, setRequestActivationModal] = useState({
    is_open: false,
    data: "",
    outlet_id: "",
    channel_id: "",
  });

  useEffect(() => {
    console.log("Account Settings on mount:", props);
    console.log("ProductAssign OutletContext :", OutletContext);
    get_account();
    get_custom_channels();
  }, []);

  function formatDate(inputDate) {
    const date = new Date(inputDate);

    // Get components of the date
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Convert hours to 12-hour format and determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Format the time
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    // Format the date
    const formattedDate = `${day}-${month}-${year}`;

    // Concatenate date and time
    return `${formattedDate} ${formattedTime}`;
  }

  function check_availibility(event: any, index: any, i: any) {
    // console.log(outlet_data[index]?.channels[i])
    // ?.unavailable
    //   let avail = [
    //     {
    //         "id": outlet_data[index]?.id,
    //         "channels": [
    //             {
    //                 "id": outlet_data[index]?.channels[i]?.id,
    //                 "unavailable":false

    //                 // {
    //                 //     "value": 1
    //                 // }
    //             }
    //         ]
    //     }
    // ]
    //   SetAvailchannel(avail);
    //   if(outlet_data[index]?.channels[i]?.unavailable == false){
    //   SetModalChannelAvail((prevValue: any) => {
    //     prevValue.is_open = true;
    //     prevValue.type = "create";
    //     return { ...prevValue };
    //   });
    // }
    console.log(
      "outlet_data" + outlet_data[index].channels[i].unavailable.value
    );
    // let key_name = event.target.name;
    if (outlet_data[index].channels[i].unavailable.value != 1) {
      SetOutlettData((prevValue) => {
        console.log(prevValue[index]);
        // console.log(prevValue[index].channels[i].checked);

        (prevValue[index].updated = true),
          (prevValue[index].channels[i].updated = true),
          (prevValue[index].channels[i].unavailable.value = 1);

        return [...prevValue];
      });
    } else {
      SetModalChannelAvail((prevValue: any) => {
        prevValue.is_open = true;
        prevValue.type = "channel";
        prevValue.data = [i, index];
        prevValue.next_slot = outlet_data[index].channels[i].unavailable;

        return { ...prevValue };
      });

      // SetOutlettData((prevValue) => {
      //   console.log(prevValue[index]);
      //   // console.log(prevValue[index].channels[i].checked);

      //   prevValue[index].updated=true,
      //   prevValue[index].channels[i].updated=true,
      //   prevValue[index].channels[i].unavailable.value = 1;

      //   return [...prevValue];
      // });
    }
    // SubmitForm();
  }

  async function get_account() {
    SetIsLoading(true)
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let response = await api("/brand/outlet_channels_v2", pass_data);
    console.log("/brand/settings response :", response.response);
    if (response.status_code == 200) {
      SetOutlettData(response.response.data);
      SetOutlettDataStatic(JSON.parse(JSON.stringify(response.response.data)));
      SetUserDetails(response.response.user_details);
    SetIsLoading(false)

    }
  }

  async function SubmitRequestActivationForm(outlet, channel, name, mobile) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: outlet,
      },
      post: {
        channel_id: channel,
        name: name,
        mobile: mobile,
      },
    };
    let response = await api("/brand/request_activation_v2", pass_data);
    if (response.status_code == 200) {
      SetOutlettData(response.response.data);
      SetOutlettDataStatic(JSON.parse(JSON.stringify(response.response.data)));
      toast.success("Request Sent");
    }
  }

  async function get_custom_channels() {
    SetIsCusLoading(true)

    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let response = await api("/brand/custom_channels", pass_data);
    console.log("/brand/settings response :", response.response);
    if (response.status_code == 200) {
      SetCustomChannel(response.response.data);
      SetIsCusLoading(false)
    }
  }

  async function SubmitForm() {
    setStartBtnLoader(true);
    let arr = [];

    // outlet_data[index].channels[i].unavailable.value

    outlet_data.map((ele, i) => {
      if (ele.updated == true) {
        ele.channels.map((ch_ele, i) => {
          if (ch_ele.updated == true) {
            arr.push({
              id: ele.id,
              channels: [
                {
                  id: ch_ele.id,
                  unavailable: ch_ele.unavailable,
                },
              ],
            });
          }
        });

        ele.custom_channels.map((ch_ele, i) => {
          if (ch_ele.updated == true) {
            arr.push({
              id: ele.id,
              custom_channels: [
                {
                  id: ch_ele.id,
                  unavailable: ch_ele.unavailable,
                },
              ],
            });
          }
        });
      }
    });

    console.log("outlet_data", outlet_data);

    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        channel_data: outlet_data,
        // outlet_data,
      },
    };

    let pass_data_avail = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        channel_data: arr,
        // outlet_data,
      },
    };
    let response = await api("/brand/save_outlet_channels_v2", pass_data);
    console.log("/brand/settings response :", response.response);
    if (response.status_code == 200) {
      setStartBtnLoader(false);
      let new_response = await api(
        "/brand/outlet_channels_status_v2",
        pass_data_avail
      );
      console.log("/brand/settings response :", new_response.response);
      if (response.status_code == 200) {
        SetOutlettData(new_response.response.data);
        SetOutlettDataStatic(
          JSON.parse(JSON.stringify(response.response.data))
        );
        toast.success("Update Successful");
      }
    }
  }

  async function check_pause(event: any, index: any, i: any) {
    console.log(index);

    let key_name = event.target.name;

    SetOutlettData((prevValue) => {
      console.log(prevValue[index]);
      console.log(prevValue[index].channels[i].paused);

      // {
      //   type == "checked"
      //     &&
      prevValue[index].channels[i].paused =
        !prevValue[index].channels[i].paused;
      //  }
      {
        prevValue[index].channels[i].paused == false &&
          (prevValue[index].channels[i].checked = true);
      }

      return [...prevValue];
    });

    // SubmitForm();
  }

  async function check_channel(event: any, index: any, i: any) {
    console.log(index);

    let key_name = event.target.name;

    SetOutlettData((prevValue) => {
      console.log(prevValue[index]);
      console.log(prevValue[index].channels[i].checked);

      // {
      //   type == "checked"
      //     &&
      prevValue[index].channels[i].checked =
        !prevValue[index].channels[i].checked;
      //  }

      return [...prevValue];
    });

    // SubmitForm();
  }

  async function check_custom_pause(event: any, index: any, i: any) {
    console.log(index);

    let key_name = event.target.name;

    SetOutlettData((prevValue) => {
      console.log(prevValue[index]);
      console.log(prevValue[index].custom_channels[i].paused);

      // {
      //   type == "checked"
      //     &&
      prevValue[index].custom_channels[i].paused =
        !prevValue[index].custom_channels[i].paused;
      //  }
      {
        prevValue[index].custom_channels[i].paused == false &&
          (prevValue[index].custom_channels[i].checked = true);
      }

      return [...prevValue];
    });

    // SubmitForm();
  }

  async function check_custom_channel(event: any, index: any, i: any) {
    console.log(index);

    let key_name = event.target.name;

    SetOutlettData((prevValue) => {
      console.log(prevValue[index]);
      console.log(prevValue[index].custom_channels[i].checked);

      // {
      //   type == "checked"
      //     &&
      prevValue[index].custom_channels[i].checked =
        !prevValue[index].custom_channels[i].checked;
      //  }

      return [...prevValue];
    });

    // SubmitForm();
  }

  async function check_custom_availibility(event: any, index: any, i: any) {
    console.log(index);

    let key_name = event.target.name;
    if (outlet_data[index].custom_channels[i].unavailable.value != 1) {
      SetOutlettData((prevValue) => {
        console.log(prevValue[index]);
        // console.log(prevValue[index].channels[i].checked);

        (prevValue[index].updated = true),
          (prevValue[index].custom_channels[i].updated = true),
          (prevValue[index].custom_channels[i].unavailable.value = 1);

        return [...prevValue];
      });
    } else {
      SetModalChannelAvail((prevValue: any) => {
        prevValue.is_open = true;
        prevValue.type = "custom_channels";
        prevValue.data = [i, index];
        prevValue.next_slot = outlet_data[index].custom_channels[i].unavailable;

        return { ...prevValue };
      });
    }
    // SetOutlettData((prevValue) => {
    //   console.log(prevValue[index]);
    //   console.log(prevValue[index].custom_channels[i].checked);

    //   // {
    //   //   type == "checked"
    //   //     &&
    //   prevValue[index].custom_channels[i].unavailable =
    //     !prevValue[index].custom_channels[i].unavailable;
    //   //  }

    //   return [...prevValue];
    // });

    // SubmitForm();
  }

  {
    console.log(outlet_data);
  }

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div
        className={
          OutletContext
            ? "head-bar sticky-sm sticky-md-50px py-3"
            : "head-bar sticky-sm sticky-md-50px py-3"
        }
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="">
          <h6 className="mb-0 ms-1">Channels</h6>
        </div>
      </div>

      <div
        className={
          OutletContext ? "mt-3 px-md-3 px-sm-0" : "mt-3 px-md-3 px-sm-0"
        }
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="my-3">
          <div className="row pb-4">
            <div className="col-md-8 sm-mb-2">
              <div className="card sticky-md-130px">
                <div className="d-flex align-items-center shadow-bottom sticky-sm-50px sticky-md-100px bg-fff p-3">
                  <div>
                    <h6 className="mb-0">Manage Channels</h6>
                  </div>
                  <div className="ms-auto">
                    <button
                      className="btn bg-prime text-white btn-sm btn-radius px-3"
                      type="submit"
                      onClick={() => SubmitForm()}
                      // onSubmit={SubmitForm }
                    >
                      {startBtnLoader ? (
                        <span className="btn-loader"></span>
                      ) : (
                        <span> Submit</span>
                      )}
                    </button>
                  </div>
                </div>

                <div className="p-3">
                  <div className="row p-0 chan">

                  {is_loading ? (
                    <div className="">
                    {Array.from({ length: 3 }).map((_, i) => (
                      <div className="border-bottom pb-2 mb-2 px-0" key={i}>
                        <Accordion className="px-0">
                          <AccordionSummary
                            expandIcon={<Skeleton width={20} height={20} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="hover-row"
                          >
                            <div className="">
                              <h6 className="fw-500 text-black">
                                <Skeleton width={200} />
                              </h6>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails className="px-0 row">
                            <div className="col-md-6 px-0">
                              <div className="center w-100">
                                <div className="card radius py-3 px-2 w-100 sm-mb-2">
                                  <p className="text-black fw-bold fs-7 mb-3">Channels</p>
                                  {Array.from({ length: 3 }).map((_, j) => (
                                    <div
                                      className="mb-2 pb-2 border-bottom"
                                      key={j}
                                    >
                                      <div className="d-flex align-items-center mb-3">
                                        <p className="text-black mb-2">
                                          <Skeleton width={100} />
                                        </p>
                                      </div>
                                      <div className="row">
                                        <div className="col-6">
                                          <label className="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="form-check-input mt-0"
                                            />
                                            <p className="ps-2 text-gray">
                                              <Skeleton width={50} />
                                            </p>
                                          </label>
                                        </div>
                                        <div className="col-6">
                                          <label className="end">
                                            <p className="pe-2 text-gray">
                                              <Skeleton width={100} />
                                            </p>
                                            <div className="form-check form-switch mb-0">
                                              <Skeleton width={50} />
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 px-2">
                              <div className="center w-100">
                                <div className="card radius py-3 px-2 w-100 sm-mb-2">
                                  <p className="text-black fw-bold fs-7 mb-3">
                                    Custom Channels
                                  </p>
                                  {Array.from({ length: 3 }).map((_, j) => (
                                    <div className="mb-2 pb-2 border-bottom" key={j}>
                                      <div className="d-flex align-items-center mb-3">
                                        <p className="text-black mb-2">
                                          <Skeleton width={100} />
                                        </p>
                                      </div>
                                      <div className="row">
                                        <div className="col-6">
                                          <label className="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="form-check-input mt-0"
                                            />
                                            <p className="ps-2 text-gray">
                                              <Skeleton width={50} />
                                            </p>
                                          </label>
                                        </div>
                                        <div className="col-6">
                                          <label className="end">
                                            <p className="pe-2 text-gray">
                                              <Skeleton width={100} />
                                            </p>
                                            <div className="form-check form-switch mb-0">
                                              <Skeleton width={50} />
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    ))}
                  </div>):
                    outlet_data.length != 0 ? (
                      outlet_data?.map((val, i) => (
                        <div className="border-bottom pb-2 mb-2 px-0">
                          <Accordion className="px-0">
                            <AccordionSummary
                              expandIcon={
                                <img
                                  src={
                                    AppConfig.CDN_Media_url +
                                    "9c43a17f765cfe2641a2341221e5c79d.svg"
                                  }
                                  className="w-20px"
                                />
                                // <MaterialIcons
                                //   name="keyboard-arrow-down"
                                //   size={20}
                                //   color="black"
                                // />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="hover-row "
                            >
                              <div className="">
                                <h6 className="fw-500 text-black">
                                  {val.name}
                                </h6>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails className="px-0 row">
                              <div className="col-md-6 px-0 ">
                                <div className="center w-100">
                                  <div className="card radius py-3 px-2 w-100 sm-mb-2">
                                    <p className="text-black fw-bold fs-7 mb-3">
                                      Channels
                                    </p>
                                    {val.channels?.map((ele, j) => (
                                      <div
                                        className={
                                          j < val.channels.length - 1
                                            ? "mb-2 pb-2 border-bottom"
                                            : "mb-2 pb-2"
                                        }
                                      >
                                        <div className="d-flex align-items-center mb-3 ">
                                          <p className="text-black mb-2">
                                            {ele.name}
                                          </p>

                                          {/* <div className="ms-auto">
                                          {ele.paused &&
                                            <div className="bg-fff d-flex align-items-center border radius cursor px-2 py-1"
                                              onClick={(e) => {
                                                check_pause(e, i, j);
                                              }} >
                                              <FontAwesome name="pause" size={10} color="#3f41c3" />
                                              <p className="text-prime ms-2 v-small">Pause</p>
                                            </div>
                                          }
                                        </div> */}
                                        </div>

                                        <div className="row ">
                                          <div className="col-6">
                                            <label className="d-flex align-items-center">
                                              <input
                                                type="checkbox"
                                                className="form-check-input mt-0"
                                                checked={ele.checked}
                                                onChange={(e) => {
                                                  check_channel(e, i, j);
                                                }}
                                                // hidden={ele.paused}
                                                // id="switch"
                                              />
                                              {ele.checked ? (
                                                <p className="ps-2 text-gray ">
                                                  Enable
                                                </p>
                                              ) : (
                                                <p className="ps-2 text-gray ">
                                                  Paused
                                                </p>
                                              )}
                                            </label>
                                          </div>

                                          <div className="col-6">
                                            <label className="end ">
                                              <p className="pe-2 text-gray ">
                                                Available
                                              </p>
                                              <div className="form-check form-switch mb-0">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  checked={
                                                    ele.unavailable.value == "1"
                                                      ? true
                                                      : false
                                                  }
                                                  disabled={
                                                    !ele.checked ||
                                                    (ele.id == 2 && !ele.active)
                                                  }
                                                  hidden={ele.paused}
                                                  onChange={(e) => {
                                                    check_availibility(e, i, j);
                                                  }}
                                                  id="switch"
                                                />
                                              </div>
                                            </label>
                                            {/* {(ele?.unavailable?.time || (ele?.label && ele?.label!="I will turn on my self"))&&
ele.unavailable.value=="5"?
(let inputDate = ele.label;
const formattedDateTime = formatDate(inputDate);
console.log(formattedDateTime); 
<p>Available at {ele.label ?formattedDateTime :ele.unavailable.time}</p>

):( <p>Available at {ele.label ?ele.label :ele.unavailable.time}</p>)
                                           } */}

                                            {(ele?.unavailable?.time ||
                                              ele?.label) &&
                                              (ele.unavailable?.value == "5" ? (
                                                (() => {
                                                  console.log(ele?.label);
                                                  const inputDate =
                                                    ele?.unavailable?.time;
                                                  const formattedDateTime =
                                                    formatDate(inputDate);
                                                  console.log(
                                                    formattedDateTime
                                                  );
                                                  return (
                                                    <p>
                                                      Available at{" "}
                                                      {formattedDateTime}
                                                    </p>
                                                  );
                                                })()
                                              ) : (
                                                <p>
                                                  Available at{" "}
                                                  {ele.label
                                                    ? ele.label
                                                    : ele.unavailable.time}
                                                </p>
                                              ))}
                                          </div>
                                        </div>
                                        {ele.id == 2 && (
                                          <>
                                            {ele.active == false &&
                                              ele.activation_requested ==
                                                false && (
                                                <div className="row pt-3">
                                                  <div
                                                    className={
                                                      outlet_data_static[i]
                                                        ?.channels[j]
                                                        ?.checked == true &&
                                                      ele.checked
                                                        ? "col-12 d-flex align-items-center bg-light py-2 cursor"
                                                        : "col-12 d-flex align-items-center bg-light py-2 text-muted disabled"
                                                    }
                                                    onClick={() => {
                                                      if (
                                                        outlet_data_static[i]
                                                          ?.channels[j]
                                                          ?.checked == true &&
                                                        ele.active == false &&
                                                        ele.activation_requested ==
                                                          false &&
                                                        (ele.checked == true ||
                                                          ele.paused == true)
                                                      ) {
                                                        setRequestActivationModal(
                                                          (prevValue: any) => {
                                                            prevValue.is_open =
                                                              true;
                                                            prevValue.outlet_id =
                                                              val.id;
                                                            prevValue.channel_id =
                                                              ele.id;
                                                            return {
                                                              ...prevValue,
                                                            };
                                                          }
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    Request Activation
                                                    <i className="fas fa-arrow-right ms-auto"></i>
                                                  </div>
                                                </div>
                                              )}
                                            {(ele.checked == true ||
                                              ele.paused == true) &&
                                              ele.active == false &&
                                              ele.activation_requested ==
                                                true && (
                                                <div className="row pt-3">
                                                  <div className="col-12 d-flex align-items-center bg-light py-2">
                                                    <i className="fas fa-check-circle text-success me-2"></i>
                                                    Your application is in
                                                    processing
                                                  </div>
                                                </div>
                                              )}
                                            {ele.active == true && (
                                              <div className="row pt-3">
                                                <div className="col-12 d-flex align-items-center bg-light py-2">
                                                  <i className="fas fa-check-circle text-success me-2"></i>
                                                  Ready to sale...
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 px-2">
                                <div className="center w-100">
                                  <div className="card radius py-3 px-2 w-100 sm-mb-2">
                                    <p className="text-black fw-bold fs-7 mb-3">
                                      Custom Channels
                                    </p>
                                    {val.custom_channels?.map((ele, j) => (
                                      <div className="mb-2 pb-2 border-bottom">
                                        <div className="d-flex align-items-center mb-3 ">
                                          <p className="text-black mb-2">
                                            {ele.name}
                                          </p>

                                          <div className="ms-auto">
                                            {ele.paused && (
                                              <div
                                                className="bg-fff d-flex align-items-center border radius cursor px-2 py-1"
                                                onClick={(e) => {
                                                  check_custom_pause(e, i, j);
                                                }}
                                              >
                                                <FontAwesome
                                                  name="pause"
                                                  size={10}
                                                  color="#3f41c3"
                                                />
                                                <p className="text-prime ms-2 v-small">
                                                  Pause
                                                </p>
                                              </div>
                                            )}
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-6">
                                            <label className="d-flex align-items-center">
                                              <input
                                                type="checkbox"
                                                className="form-check-input mt-0"
                                                checked={ele.checked}
                                                onChange={(e) => {
                                                  check_custom_channel(e, i, j);
                                                }}
                                                hidden={ele.paused}
                                                // id="switch"
                                              />
                                              <p className="ps-2 text-gray ">
                                                Enable
                                              </p>
                                            </label>
                                          </div>

                                          <div className="col-6">
                                            <label className="end ">
                                              <p className="pe-2 text-gray ">
                                                Available
                                              </p>
                                              <div className="form-check form-switch mb-0">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  checked={
                                                    ele.unavailable.value == 1
                                                  }
                                                  disabled={!ele.checked}
                                                  hidden={ele.paused}
                                                  onChange={(e) => {
                                                    check_custom_availibility(
                                                      e,
                                                      i,
                                                      j
                                                    );
                                                  }}
                                                  id="switch"
                                                />
                                              </div>
                                            </label>
                                            {(ele?.unavailable?.time ||
                                              ele?.label) &&
                                              (ele.unavailable?.value == "5" ? (
                                                (() => {
                                                  console.log(ele?.label);
                                                  const inputDate =
                                                    ele?.unavailable?.time;
                                                  const formattedDateTime =
                                                    formatDate(inputDate);
                                                  console.log(
                                                    formattedDateTime
                                                  );
                                                  return (
                                                    <p>
                                                      Available at{" "}
                                                      {formattedDateTime}
                                                    </p>
                                                  );
                                                })()
                                              ) : (
                                                <p>
                                                  Available at{" "}
                                                  {ele.label
                                                    ? ele.label
                                                    : ele.unavailable.time}
                                                </p>
                                              ))}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "e38cbe608dba40c81ec58d266c09055f.png"
                          }
                          className="empty-img pb-2"
                        />
                        <p className="v-small mb-0">
                          No Data, Please Add some channels
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 sm-mb-2">
              <div className="card h-90vh sticky-md-130px">
                <div className="d-flex align-items-center mb-4 bg-fff p-3 sticky-sm-50px sticky-md-100px shadow-bottom">
                  <h6 className="  mb-0">Custom Channels</h6>
                  <button
                    className="btn bg-prime text-white btn-radius btn-sm ms-auto px-3"
                    onClick={() => {
                      console.log("Franchise new");
                      SetModalNewCustom((prevValue: any) => {
                        prevValue.is_open = true;
                        prevValue.type = "create";
                        return { ...prevValue };
                      });
                    }}
                  >
                    New
                  </button>
                </div>

                <div className="p-3">
                  {is_cus_loading?
                  <div className="">
                  {Array.from({ length: 3 }).map((_, i) => (
                    <div className="d-flex px-2 py-3" key={i}>
                      <div className="">
                        <p className="fs-7 fw-bold text-black mb-1">
                          <Skeleton width={100} />
                        </p>
                        <p className="extra-small text-gray">
                          <Skeleton width={80} />
                        </p>
                      </div>
                      <div className="ms-auto">
                        <div className="cursor px-1">
                          <Skeleton width={20} height={20} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>:
                  custom_channel.length != 0 ? (
                    custom_channel?.map((val, i) => (
                      <div className="d-flex px-2 py-3">
                        <div className="">
                          <p className="fs-7 fw-bold text-black mb-1">
                            {val.name}
                          </p>
                          <p className="extra-small text-gray">
                            {val.created_at}
                          </p>
                        </div>
                        <div className="ms-auto">
                          <div
                            className="cursor px-1"
                            onClick={() => {
                              SetopenModal({
                                is_open: true,
                                data: {
                                  id: val.id,
                                  index: i,
                                  type: "brand",
                                  status: "active",
                                },
                              });
                            }}
                          >
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "b4405b2b7046c82047b78bbacce44ea8.svg"
                              }
                              className="w-20px"
                            />
                            {/* <MaterialCommunityIcons
                              name="delete-outline"
                              size={18}
                              color="#777"
                            /> */}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">
                      <img
                        src={
                          AppConfig.CDN_Image_url +
                          "e38cbe608dba40c81ec58d266c09055f.png"
                        }
                        className="empty-img pb-2"
                      />
                      <p className="v-small mb-0">
                        No Data, Please Add some channels
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalCreateChannels
          open={modal_new_custom.is_open}
          type={modal_new_custom.type}
          close={(data: any) => {
            console.log("Franchise modl on close :", data);
            SetModalNewCustom((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              get_custom_channels();
            }
          }}
        />
        <ModalChannelAvailable
          open={modal_channel_avail.is_open}
          data={modal_channel_avail.data}
          next_slot={modal_channel_avail.next_slot}
          type={modal_channel_avail.type}
          // type={modal_new_custom.type}
          close={(data: any) => {
            console.log("Franchise modl on close :", data);
            SetModalChannelAvail((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              if (data.type == "channel") {
                SetOutlettData((prevValue) => {
                  console.log(prevValue[data.data[1]]);
                  // console.log(prevValue[index].channels[i].checked);

                  (prevValue[data.data[1]].updated = true),
                    (prevValue[data.data[1]].channels[data.data[0]].label =
                      data.label),
                    (prevValue[data.data[1]].channels[data.data[0]].updated =
                      true),
                    (prevValue[data.data[1]].channels[
                      data.data[0]
                    ].unavailable = data.value);

                  return [...prevValue];
                });
              } else {
                SetOutlettData((prevValue) => {
                  console.log(prevValue[data.data[1]]);
                  // console.log(prevValue[index].channels[i].checked);

                  (prevValue[data.data[1]].updated = true),
                    (prevValue[data.data[1]].custom_channels[
                      data.data[0]
                    ].label = data.label),
                    (prevValue[data.data[1]].custom_channels[
                      data.data[0]
                    ].updated = true),
                    (prevValue[data.data[1]].custom_channels[
                      data.data[0]
                    ].unavailable = data.value);

                  return [...prevValue];
                });
              }
              // get_custom_channels();
            }
          }}
        />
        <ModalDeleteCustomChannel
          open={openModal.is_open}
          data={openModal.data}
          close={(data: any) => {
            // console.log("Franchise modl on close :", data);
            SetopenModal((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              get_custom_channels();
            }
          }}
        />

        <RequestActivation
          open={requestActivationModal.is_open}
          outlet_id={requestActivationModal.outlet_id}
          channel_id={requestActivationModal.channel_id}
          userdetails={userDetails}
          close={(data: any) => {
            setRequestActivationModal((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              SubmitRequestActivationForm(
                requestActivationModal.outlet_id,
                requestActivationModal.channel_id,
                data.name,
                data.mobile
              );
            }
          }}
        />
      </div>
    </div>
  );
}
