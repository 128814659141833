import React, { useState, useEffect } from "react";
// import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap.min.js";
// import bootstrap from "../../../../assets/libraries/bootstrap/js/bootstrap";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";


import { Link, useParams } from "react-router-dom";
import { api } from "../../../utils/Api";

let myModalEditShip: any;

export default function ModalEditItems(props) {
  console.log(props.data);

  const [product_data, SetProductData] = useState([]);
  const [delivery_data, SetDeliveryData] = useState([]);
  const [main_product, SetMainProductData] = useState([]);

  const [order_id, SetOrderId] = useState("");

  const [item_arr, SetItemArr] = useState([]);
  const [item_arr_add, SetItemArrAdd] = useState([]);

  const [id_value, SerIdValue] = useState(useParams());
  const [status, SetStatus] = useState(false);
  const [pro_check, SetProCheck] = useState(true);

  

  const [close_data, SetCloseData] = useState({
    action: "close",
  });


  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal2 = document.getElementById("EditItemModal");
    myModal2.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelDeleteUserRoles On props.open :", props);
      SetProductData(props.data.product);
      SetDeliveryData(props.data.delivery);
      SetMainProductData(props.data.main_product);
      SetOrderId(props.id);

      myModalEditShip = new bootstrap.Modal(
        document.getElementById("EditItemModal"),
        {}
      );
      myModalEditShip.show();
    }
  }, [props.open]);

  function deleteUserRole() {
    let item: any = close_data;
    item.action = "delete";
    item.value = props.data;
    SetCloseData(item);
    myModalEditShip.hide();
  }

  function remove_input_on_change(e: any) {
    console.log(e.target.value);
    console.log(e.target.checked);
    console.log(item_arr);

    let value = e.target.value;
    let checked = e.target.checked;

    SetProCheck(!pro_check);

    if (!checked) {
      item_arr.push(value);
    } else {
      var carIndex = item_arr.indexOf(value);
      //remove car from the colors array
      item_arr.splice(carIndex, 1);
    }
    SetItemArr(item_arr);
    console.log(item_arr);
  }

  function add_input_on_change(e: any) {
    console.log(e.target.value);
    console.log(e.target.checked);

    let value = e.target.value;
    let checked = e.target.checked;

    if (checked) {
      item_arr_add.push(value);
    } else {
      var carIndex = item_arr_add.indexOf(value);
      //remove car from the colors array
      item_arr_add.splice(carIndex, 1);
    }
    SetItemArrAdd(item_arr_add);
    console.log(item_arr);
  }

  async function remove_shipment() {
    if (item_arr.length > 0) {
      let pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: id_value.outlet_id,
          delivery_id: order_id,
        },
        post: {
          products: JSON.stringify(item_arr),
        },
      };
      console.log("pass_data :", pass_data);

      let data_res = await api("/outlet/remove_delivery_products", pass_data);

      console.log("/outlet/remove_delivery_products :", data_res);
      if (data_res.status) {
        let item = close_data;
        SetStatus(true);
        item.action = "edit";
        // item.value = data_res.response.outlet_users[0];
        // item.index = props.data.index;
        // console.log("Close data : ", item);
        SetCloseData(item);
        // SetFranchiseData(franchise_initial_value);
        // myModalEditShip.hide();
        myModalEditShip.hide();

      }
    }
  }

  async function add_shipment() {
    if (item_arr_add.length > 0) {
      let pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: id_value.outlet_id,
          delivery_id: order_id,
        },
        post: {
          products: JSON.stringify(item_arr_add),
        },
      };
      console.log("pass_data :", pass_data);

      let data_res = await api("/outlet/add_delivery_products", pass_data);
      console.log("outlet/add_delivery_products :", data_res);
      if (data_res.status == true) {
        let item = close_data;
        SetStatus(true);
        const [matric_volume, SetMatricVolume] = useState(1);
        // item.action = "edit";
        // item.value = data_res.response.outlet_users[0];
        // item.index = props.data.index;
        // console.log("Close data : ", item);
        SetCloseData(item);
        // SetFranchiseData(franchise_initial_value);
        // myModalEditShip.hide();
        myModalEditShip.hide();
      }
    }
  }

  return (
    <div
      className="modal fade"
      id="EditItemModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel1"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel1">
              Edit Shipment
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {product_data?.map((ele: any, i_item: any) => (
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={ele.id}
                  id={"flexCheckDefault" + i_item}
                  checked={pro_check}
                  onChange={(e) => {
                    remove_input_on_change(e);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor={"flexCheckDefault" + i_item}
                >
                  {ele.product_name}
                </label>
              </div>
            ))}
            {/* </div> */}

            {delivery_data?.map(
              (ele_pro: any, i_item: any) =>
                ele_pro.products != product_data &&
                ele_pro.products != product_data && (
                  <div>
                    {ele_pro.products?.map((item: any, i_item: any) => (
                      // <div className="basic-list my-2">
                      //   {/* <img src={require('./assets/images/10.40c7e1f4.jpg')} alt="10.40c7e1f4" className="rounded-float-start" height="65" width="65" /> */}
                      //   <div>
                      //     <h6 className="mb-0">
                      //       {item.variant_name} x {item.quantity}
                      //     </h6>
                      //     </div>
                      //     </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={item.id}
                          id="flexCheckCheckedDisabled"
                          checked
                          disabled
                          // onChange={(e) => {
                          //   input_on_change(e);
                          // }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckCheckedDisabled"
                        >
                          {item.product_name}
                        </label>
                      </div>
                    ))}
                  </div>
                )
            )}

            {main_product?.map((ele: any, i_item: any) => (
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={ele.id}
                  id={"flexCheckDefault" + i_item}
                  onChange={(e) => {
                    add_input_on_change(e);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor={"flexCheckDefault" + i_item}
                >
                  {ele.product_name}
                </label>
              </div>
            ))}
          </div>
          <div className="modal-footer">
            {/* <Link to="/package_details"> */}
            <button
              type="button"
              className="btn btn-primary mt-2  mx-auto"
              onClick={() => {
                remove_shipment();
                add_shipment();

              }}
              // onClick={}
            >
              Edit Shipment
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
