import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import Skeleton from "react-loading-skeleton";

import {
  Entypo,
  Feather,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import {
  SwipeableDrawer,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ModalEditAdditional from "./ModalEditAdditional";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";

import Switch from "react-switch";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import toast from "react-hot-toast";
import ModalDeleteVarient from "./ModalDeleteVarient";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import InfiniteScroll from "react-infinite-scroll-component";


import { AppHelper } from "../../../utils/Helper";
import { AppConfig } from "../../../config";

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  200: { items: 2 },
  300: { items: 2 },
  350: { items: 2 },
  548: { items: 3 },
  720: { items: 5 },
};
function valuetext(value) {
  return `${value}°C`;
}

export default function Products(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });
  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    product_name: "",
    product_id: "",
    product_checked: "",
    unavailable: false,
    data: {},
    type: "",
  });
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [edit_user_modal, SetEditUserModal] = useState({
    is_open: false,
    data: {},
    units: {},
    id: "",
    index: "",
    type: "",
  });
  const [text, setText] = useState("");

  const [id_value, SerIdValue] = useState(useParams());
  const [type, setType] = useState("allall");

  const [grid_view, SetGridView] = useState(false);
  const [list_view, SetListView] = useState(true);
  const [visible_content, SetVisibleContent] = useState(false);

  const [add_product, SetAddProduct] = useState(true);
  const [filter, SetFilter] = useState([]);
  const [request_product, SetRequestProduct] = useState(false);
  const [width, SetWidth] = React.useState(window.innerWidth);
  const [product_data, SetProductData] = useState([]);
  const [option, SetOption] = useState({});
  const [units, SetUnits] = useState([]);

  const [data, SetData] = useState([]);
  const [brand, SetBrand] = useState([]);
  const [category, SetCategory] = useState([]);
  const [sort, SetSort] = useState("");
  const [f_products, SetFproducts] = useState({
    own_products: false,
    others_products: false,
  });
  const [next_page, SetNextPage] = useState(false);
  const [min_max_value, SetMinMaxValue] = React.useState(["", ""]);
  const [count, SetCount] = useState(10);
  const [page_no, SetPageNo] = useState(1);
  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const PRICE_WAIT_INTERVAL = 2000;

  const [deleteModal, SetDeleteModal] = useState({
    is_open: false,
    id: "",
  });
  const [filter_open, SetFilterOpen] = useState(false);
  const [check_available, SetCheckAvailable] = useState(false);
  const [view_option, SetViewOption] = useState([
    { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Unavailable", value: "unavailable" },
    { label: "Paused", value: "paused" },
  ]);

  async function get_products(
    page_value: any,
    count_value: any,
    f_min_max_value: any,
    type_a: any,
    search_value: any,
    sort_value: any
  ) {
    console.log(page_value)
    let pass_data = {};
    let brand_string = encodeURIComponent(JSON.stringify(brand));
    // SetPageNo(page_value);
    console.log(sort);

    if (search_value != null) {
      // brand,
      //   page,
      //   view,
      //   filter_count,
      //   filter_products,
      //   search,
      //   sort,
      //   filter_brand,
      //   filter_min_mrp,
      //   filter_max_mrp,
      //   filter_categories

      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value.page_no,
          view: type_a,
          filter_count: count_value,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          search: search_value,
          sort: sort_value,
          filter_brand: brand_string,
          filter_min_mrp: f_min_max_value[0],
          filter_max_mrp: f_min_max_value[1],
          filter_categories: encodeURIComponent(JSON.stringify(category)),
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value.page_no,
          filter_brand: brand_string,
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: f_min_max_value[0],
          filter_max_mrp: f_min_max_value[1],
          sort: sort_value,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          filter_count: count_value,
          vie: type_a,
        },
      };
    }
    let response: any = await api("/brand/brand_variants_v2", pass_data);
    console.log("/brand/brand_variants :", response);
    if (response.status_code == 200) {
      SetData(response.response);
      SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
        if (page_value.load_more) {
          SetProductData((prevValue: any) => {
            response?.response?.data?.map((ele) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetUnits(response.response.options.units);
          SetOption(response.response.filter);
          SetProductData(response.response.data);
          
        SetFilter(response.response.filter);
        }

        SetNextPage(response.response.next_page);
      } else {
        SetProductData([]);
      }
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
      // console.log(response.response.options.units);
   
      // console.log("response.response.options :", response.response.filter);

      //   if(response.response.hasOwnProperty("options")){
      //     SetOption(response.response.options);
      //   }
    }
  }

  useEffect(() => {
    console.log("Franchise On mount :", props);
    console.log("Franchise context :", context);
    console.log("ProductAssign OutletContext :", OutletContext);
    let pass_data = {
            
      // load_more: true,

      page_no: page_no,
    };
    get_products(pass_data, count, min_max_value, type, text, sort);
  }, []);

  function sample_fun(anchor, open, event) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  }

  async function input_on_change(event) {
    console.log("input_on_change value", event.target.name);

    console.log("input_on_change value", event.target.checked);
    console.log("input_on_change value", f_products.others_products);

    let arr = [];

    if (event.target.checked == true) {
      if (event.target.name == "own products") {
        f_products.own_products = true;
      }
    } else {
      if (event.target.name == "own products") {
        f_products.own_products = false;
      }
    }

    if (event.target.checked == true) {
      if (event.target.name == "others products") {
        f_products.others_products = true;
      }
    } else {
      if (event.target.name == "others products") {
        f_products.others_products = false;
      }
    }

    if (event.target.checked == true) {
      if (event.target.name == "filter_brand") {
        brand.push(event.target.value);
      }
    } else {
      if (event.target.name == "filter_brand") {
        brand.splice(brand.indexOf(event.target.value, 1));
      }
    }

    console.log(brand);
    SetBrand(brand);

    if (event.target.checked == true) {
      if (event.target.name == "filter_category") {
        category.push(event.target.value);
      }
    } else {
      if (event.target.name == "filter_category") {
        category.splice(brand.indexOf(event.target.value, 1));
      }
    }
    let pass_data = {
            
    

      page_no: 1,
    };
    SetPageNo(1);
    get_products(pass_data, count, min_max_value, type, text, sort);
  }

  function dataSort() {
    if (sort == "asc") {
      SetSort("desc"),
        // ()=> {
          SetPageNo(1);
        get_products({
            
       
    
          page_no: 1,
        }, count, min_max_value, type, text, "desc");
      // }
    }
    // if (sort == 'desc')
    else {
      SetSort("asc"),
        // ()=> {
          SetPageNo(1);
        get_products({
            
    
    
          page_no: 1,
        }, count, min_max_value, type, text, "asc");
      // }
    }

    // ()=> {
    //   get_products(1, count, min_max_value, type)
    //     }
  }
  // else {
  //   SetSort('desc')
  //   get_products(1, count, min_max_value, type)

  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          search: text,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: min_max_value[0],
          filter_max_mrp: min_max_value[1],
          filter_products: encodeURIComponent(JSON.stringify(f_products)),

          view: type,
          // sort: '',
          filter_count: count,
          sort: sort,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: min_max_value[0],
          filter_max_mrp: min_max_value[1],
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          filter_count: count,
          view: type,
          sort: sort,
        },
      };
    }
    let response: any = await api("/brand/brand_variants_v2", pass_data);
    console.log("/brand/add_brand_product_list :", response);
    SetProductData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const search_text = e.target.value;
    setText(search_text);
    console.log("Text onchange: ", search_text);
    if (search_text == "") {
      let page_no_value = 1;
      SetPageNo(page_no_value);
      SetPageNo(1);
      get_products(
        {
            
          // load_more: true,
    
          page_no: 1,
        },
        count,
        min_max_value,
        type,
        search_text,
        sort
      );
    } else {
      SetTimer(
        setTimeout(() => {
          SetPageNo(1);
          get_products({
            
          
      
            page_no: 1,
          }, count, min_max_value, type, search_text, sort);
        }, WAIT_INTERVAL)
      );
    }
  }

  function countChange(e: any) {
    console.log("countChange:", e.target.value);
    SetCount(e.target.value);
    SetPageNo(1);
    get_products({
            
    

      page_no: 1,
    }, e.target.value, min_max_value, type, text, sort);
  }

  const handleChange = (event: any, newValue: any) => {
    clearTimeout(timer);
    SetMinMaxValue(newValue);
    console.log(newValue);

    if (newValue != "") {
      SetTimer(
        setTimeout(() => {
          SetPageNo(1);
          get_products({
            
  
      
            page_no: 1,
          }, count, newValue, type, text, sort);
        }, PRICE_WAIT_INTERVAL)
      );
    }
    // SetMinMaxValue(newValue);

    // get_products(1,count);
  };

  async function Pause(e) {
    let pass_data = {};
    let arr = [];
    arr.push(e);

    pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        product_data: arr,
      },
    };

    let response: any = await api("/brand/remove_brand_variant", pass_data);
    console.log("/brand/add_brand_product_list :", response);

    if (response.status_code == 200) {
      SetPageNo(1);
      get_products({
            
     
  
        page_no: 1,
      }, count, min_max_value, type, text, sort);
      toast.success("Product is Paused");
      // SetProductData((prevValue) => {
      //   response.response.data.map((item) => {
      //     prevValue.push(item);
      //   });
      //   return [...prevValue];
      // });
      // SetNextPage(response.response.next_page);
    }
  }

  async function Enable(e) {
    let pass_data = {};
    let arr = [];
    arr.push(e);

    pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        product_data: arr,
      },
    };

    let response: any = await api("/brand/add_brand_variant", pass_data);
    console.log("/brand/add_brand_variant :", response);

    if (response.status_code == 200) {
      SetPageNo(1);
      get_products({
            
     
  
        page_no: 1,
      }, count, min_max_value, type, text, sort);
      toast.success("Product is Enabled");
      // SetProductData((prevValue) => {
      //   response.response.data.map((item) => {
      //     prevValue.push(item);
      //   });
      //   return [...prevValue];
      // });
      // SetNextPage(response.response.next_page);
    }
  }

  async function unavailable(e) {
    let pass_data = {};
    let arr = [];
    arr.push(e);

    pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        product_data_unavailable: arr,
      },
    };

    let response: any = await api(
      "/brand/update_brand_variant_available",
      pass_data
    );
    console.log("/brand/product_data_unavailable :", response);

    if (response.status_code == 200) {
      SetPageNo(1);
      get_products({
            

  
        page_no: 1,
      }, count, min_max_value, type, text, sort);
      toast.success("Product now unavailable");
      // SetProductData((prevValue) => {
      //   response.response.data.map((item) => {
      //     prevValue.push(item);
      //   });
      //   return [...prevValue];
      // });
      // SetNextPage(response.response.next_page);
    }
  }

  async function available(e) {
    let pass_data = {};
    let arr = [];
    arr.push(e);

    pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        product_data_available: arr,
      },
    };

    let response: any = await api(
      "/brand/update_brand_variant_available",
      pass_data
    );
    console.log("/brand/add_brand_variant :", response);

    if (response.status_code == 200) {
      SetPageNo(1);
      get_products({
    
  
        page_no: 1,
      }, count, min_max_value, type, text,sort);

      toast.success("Product now available");
      // SetProductData((prevValue) => {
      //   response.response.data.map((item) => {
      //     prevValue.push(item);
      //   });
      //   return [...prevValue];
      // });
      // SetNextPage(response.response.next_page);
    }
  }

  const handleCheckChange = (check) => {
    // SetCheckAvailable(check)
  };

  return (
    <div className="">
      {/* Header */}
      <div
        className={OutletContext ? "head-bar sticky-sm" : "head-bar sticky-sm"}
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="row align-items-center">
          <div className="col-6 mb-2">
            <h6 className="mb-0">Products</h6>
          </div>
          <div className="col-6 ps-0 text-end mb-2">
            <Link to={"/brand/" + id_value.brand_id + "/addproducts_new"}>
              <button className="btn bg-prime text-white btn-radius px-3 btn-sm">
                Add Products
              </button>
            </Link>
          </div>
        </div>
        <div className="py-2 row align-items-center">
          <div className="col-10 col-md-6 p-0 sm-mb-2">
            <div>
              {/* <AliceCarousel
                mouseTracking
                touchMoveDefaultEvents={false}
                // items={productsRestraunt}
                // paddingLeft={50}
                paddingRight={30}
                disableDotsControls
                responsive={responsive}
                disableButtonsControls
                controlsStrategy="alternate"
              > */}
                <div className="mt-1 d-flex align-items-center bg-fff ps-0 p-2 md-px-3 ">
                <div className="d-flex align-items-center scrollX">
                 {view_option.map((options, o_index) => (
                  // <div
                  //   key={o_index}
                  //   onDragStart={handleDragStart}
                  //   className={
                  //     type == options.value
                  //       ? "active-tab-view me-2 cursor"
                  //       : "tab-view me-2 cursor"
                  //   }
                   
                  // >
                   <button
                      type="button"
                      className={
                        type == options.value
                          ? "btn btn-text header-tap me-1 px-3 center"
                          : "btn btn-text text-black me-1 px-3 center"
                      }
                      onClick={() => {
                        setType(options.value);
                        SetPageNo(1);
                        get_products({
              
                          // load_more: true,
                    
                          page_no: 1,
                        }, count, min_max_value, options.value,text,sort);
                      }}
                       >
                      {options.label}
                    </button>
                ))}
              {/* </AliceCarousel> */}
            </div>
            </div>
            </div>
            {/* <div className="tabs overflowX p-0 sm-mb-2">
              <div
                className={type == 'all' ? 'active_tab tabs-head' : 'tabs-head'}
                onClick={() => {
                  setType('all')

                  get_products(1, count, min_max_value, '',text,sort)
                }}
              >
                <p className={type == 'all' ? "text-white px-2" : "px-2"}>All</p>
              </div>
              <div
                className={
                  type == 'active' ? 'active-tab-view  me-2 cursor ' : 'tab-view me-2 cursor'
                }
                onClick={() => {
                  setType('active')

                  get_products(1, count, min_max_value, 'active',text,sort)
                }}
              >
                <p className={type == 'active' ? "text-white px-2" : "px-2"}>Active</p>
              </div>
              <div
                className={
                  type == 'unavailable' ? 'active-tab-view me-2 cursor ' : 'tab-view me-2 cursor'
                }
                onClick={() => {
                  setType('unavailable')
                  get_products(1, count, min_max_value, 'unavailable',text,sort)
                }}
              >
                <p className={type == 'unavailable' ? "text-white px-2" : "px-2"}>unavailable </p>
              </div>
              <div
                className={
                  type == 'paused' ? 'active-tab-view  me-2 cursor' : 'tab-view me-2 cursor'
                }
                onClick={() => {
                  setType('paused')
                  get_products(1, count, min_max_value, 'paused',text,sort)
                }}
              >
                <p className={type == 'paused' ? "text-white px-2" : "px-2"}>paused </p>
              </div>
            </div> */}
          </div>
          <div className="col-2 d-sm-block d-md-none center p-0 ">
            <button
              className="btn btn-text sm-mb-2"
              onClick={() => {
                SetFilterOpen(true);
              }}
            >
              {/* <Ionicons name="md-options" size={20} color="black" /> */}
              <img  src={AppConfig.CDN_Image_url+"311ae78abd7e491e4b25539cfbf1a03f.png"} className="type-img-big mt-1" />
              <span className="position-absolute rounded-circle translate-middle badge rounded-pill bg-prime">
                2
              </span>
            </button>
          </div>
          <div className="col-md-6 row align-items-center">
            <div className="col-md-8 col-7 px-1">
              <div className="row px-md-2 md-none">
                <div className="input-icons form-group">
                  <input
                    type="text"
                    className="form-control search-bar p-0"
                    placeholder="Search products"
                    defaultValue={text}
                    onChange={(e) => {
                      onTextChanged(e);
                    }}
                  />
                  <div className="cursor ps-2 border-left">
                  <img src={AppConfig.CDN_Media_url+"c241785c497fb98f5f7d4e587b3857ba.svg"} className="w-20px" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 col-md-2 pe-0 ">
              <select
                className="number-select form-select btn-radius form-control pe-0 ps-2"
                defaultValue="10"
                onChange={(e) => {
                  countChange(e);
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>

                <option value="40">40</option>

                <option value="50">50</option>
              </select>
            </div>
            <div className="col-2 col-md-2 pe-0">
              <div
                className="pe-3 d-flex align-items-center cursor"
                onClick={() => {
                  dataSort()
                }}
              >
                <img src={AppConfig.CDN_Media_url+"1b59418519237ad4271be18c3bc9011f.svg"} className="w-16px" />
                {/* <MaterialCommunityIcons name="sort-reverse-variant" size={16} color="#555" /> */}
                <p className='text-darkgray ps-2 fw-bold'>Sort</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Body */}
      <div
        className={
          OutletContext ? "mt-3 px-md-3 px-sm-0 mb-5" : "mt-3 px-md-3 px-sm-0 mb-5"
        }
        style={OutletContext == false ? { width: width } : {}}
      >
              <InfiniteScroll
            dataLength={product_data.length}
            
            next={() => {
              let get_data = {
            
                load_more: true,
          
                page_no: page_no,
              };
              // console.log(pass_data)
              console.log(get_data);

              get_products(get_data, count, min_max_value, type, text, sort);

            }}
            hasMore={next_page}
            loader={
              <div className="center m-5">
                <h6>Loading...</h6>
              </div>
            }
          >
            {visible_content ? (

        <div className="row p-3">
          <div className="col-md-3 px-0 d-sm-none d-md-block">
            <div className="bg-fff radius p-3 overflowY-auto scrollw-none">
              <div className="d-flex ">
                <h6 className="fs-5 fw-500">Filter</h6>
                <div className="ms-auto cursor">
                  <small className="text-black">Clear</small>
                </div>
              </div>
              <div className="">
                <div className="my-3">
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name={"own products"}
                      // value={ele.id}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                    <p className="capitalize">{"own product"}</p>
                  </label>

                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name={"others products"}
                      // value={ele.id}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                    <p className="capitalize">{"other product"}</p>
                  </label>
                </div>
                <div className="">
                  <h6>Category</h6>
                </div>
                <div className="my-3">
                  {/* {Object.keys(option).length > 0
                  ? */}
                  {option?.product_categories?.map((ele: any) => (
                    <label className="form-label d-flex align-items-center mb-3 cursor">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        name={"filter_category"}
                        value={ele.id}
                        onChange={(event) => {
                          input_on_change(event);
                        }}
                      />
                      <p className="capitalize">{ele.name}</p>
                    </label>
                  ))}
                  {/* : null} */}
                </div>

                <div className="brand">
                  <Accordion className="px-0">
                    <AccordionSummary
                      expandIcon={
                        <img src={AppConfig.CDN_Media_url+"78f45bd73c51ade6a32e1185735e5439.svg"} className="w-10px" />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className="fs-7 fw-bold text-black">Brand</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="w-100">
                        <input
                          type="text"
                          placeholder="Search..."
                          className="form-control btn-radius"
                        />
                        <div className="p-2">
                          {/* {Object.keys(option).length > 0
                          ?  */}
                          {option.product_brands?.map((ele: any) => (
                            <label className="form-label mt-3 d-flex align-items-center">
                              <input
                                type="checkbox"
                                className="me-2 form-check-input"
                                name={"filter_brand"}
                                value={ele.id}
                                onChange={(event) => {
                                  input_on_change(event);
                                }}
                              />
                              {ele.name}
                            </label>
                          ))}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="mt-3">
                  <p className="text-black" id="range-slider">
                    Mininum Maximum Price
                  </p>
                  <div className="d-flex align-items-center">
                    {/* <p className="pe-3">Min</p> */}
                    <Slider
                      value={min_max_value}
                      onChange={handleChange}
                      step={20}
                      onClick={() => {
                        console.log("min max :", min_max_value);
                      }}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                      min={0}
                      max={1000000}
                    />
                    {/* <p className="px-3">Max</p> */}
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <label className="form-label">Min Price</label>
                      <input
                        type="text"
                        className="form-control"
                        name={"min_mrp"}
                        value={min_max_value[0]}
                        onChange={(event) => {
                          input_on_change(event);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-label">Max Price</label>
                      <input
                        type="text"
                        className="form-control"
                        name={"max_mrp"}
                        value={min_max_value[1]}
                        onChange={(event) => {
                          input_on_change(event);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-9 px-sm-0 px-md-2">
            <div className="">
              {add_product ? (
                <div>
                  {product_data.length > 0 ?
                    <div className="row">
                      {product_data.length == 0 ? (
                        <div className="center p-3 bg-fff">
                          <div>
                          <img src={AppConfig.CDN_Image_url+"e38cbe608dba40c81ec58d266c09055f.png"} className="empty-img"
                  />
                            <p className="text-center">No data found!</p>
                          </div>
                        </div>
                      ) : (
                        product_data.map((ele: any) => (
                          <div className="col-md-6 mb-2">
                            <div
                              className="card p-3 pb-0"
                              onClick={(event) => {
                                if (width < 768) {
                                  console.log("width", width);
                                  // SetSelectedData({
                                  //     value: data,
                                  //     i: u_index,
                                  //     type: "active",
                                  //   });
                                  // sample_fun('bottom', true, event)
                                }
                              }}
                            >
                              <div className="row">
                                <div className="col-3 pe-0 ps-2">
                                  {ele.images ? (
                                    <img
                                      src={AppHelper.imageSrc(
                                        data.image_base_url,
                                        ele.images[0]?.url,
                                        { format: "webp", width: 100 }
                                      )}
                                      className="img-prods mb-auto"
                                    />
                                  ) : (
                                    <img
                                      src={AppConfig.CDN_Image_url+"33c67a26ab58ada3ee31b91f7efb32bb.png"}
                                      alt="pic"
                                      className="prod-group-img"
                                    />
                                  )}
                                </div>
                                <div className="col-8">
                                  <h6>{ele.name}</h6>
                                </div>
                                <div className="col-1 ps-0">
                                  {/* <div className="dropdown menu cursor">
                                  <div className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <Entypo name="dots-three-vertical" size={18} color="#aaa" />
                                  </div>
  
                                  <ul className="dropdown-menu  dropdown-menu-start">
  
                                    <li className="cursor"
                                      onClick={() => {
                                        SetDeleteModal({
                                          is_open: true,
                                          id: ele.id,
                                        })
                                        console.log("Delete Clicked!");
                                      }}
                                    >
                                      <div className="dropdown-item d-flex align-items-center">
                                        <MaterialIcons name="delete-outline" size={18} color="#555" />
                                        <p className="ps-2 text-black">Delete</p>
                                      </div>
                                    </li>
  
                                  </ul>
                                </div> */}
                                </div>
                              </div>
                              <div className="py-2">
                                <p className="v-small">{ele.description}</p>
                              </div>

                              <div className="row align-items-center pb-2 border-bottom">
                                <div className="col-6">
                                  <label className="d-flex align-items-center">
                                    <input
                                      type="checkbox"
                                      className="form-check-input mt-0"
                                      checked={ele.checked}
                                      onClick={(e) => {
                                        if (e.target.checked) {
                                          Enable(ele.id);
                                          console.log("enable :", e.target.checked);
                                        } else {
                                          Pause(ele.id);
                                          console.log("Pause :", e.target.checked);
                                        }
                                      }}
                                    />
                                    <p className="ps-2 text-black">Enable</p>
                                  </label>
                                </div>
                                <div className="col-6">
                                  <label className="end ">
                                    <span className="pe-2 text-black">
                                      Available
                                    </span>
                                    <div className="form-check form-switch mb-0">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={ele.available}
                                        onChange={(e) => {
                                          console.log("switch :", ele.available);
                                          if (ele.available == true) {
                                            unavailable(ele.id);
                                          } else {
                                            available(ele.id);
                                          }
                                        }}
                                      />
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="prods-card ">
                                <Accordion className="px-0">
                                  <AccordionSummary
                                    expandIcon={
                                      <img src={AppConfig.CDN_Media_url+"78f45bd73c51ade6a32e1185735e5439.svg"} className="w-10px" />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <p className="fs-7 fw-bold text-black">
                                      Product Details
                                    </p>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div className="w-100">
                                      <div className="">
                                        <p className="text-dark fw-bold pb-2">
                                          Package Details
                                        </p>
                                        <div className="mt-2">
                                          <div className="d-flex align-items-center mb-2">
                                            <div className="">
                                              <p className="text-darkgray v-small">
                                                L × B × H :
                                              </p>
                                            </div>
                                            <div className="ps-2">
                                              {ele.additional_details
                                                .package_height &&
                                                ele.additional_details
                                                  .package_length &&
                                                ele.additional_details
                                                  .package_breadth ? (
                                                <p className="text-gray v-small fw-500">
                                                  {ele.additional_details
                                                    .package_length +
                                                    " " +
                                                    ele.additional_details
                                                      .package_length_unit_name}{" "}
                                                  × {" "}
                                                  {ele.additional_details
                                                    .package_breadth +
                                                    " " +
                                                    ele.additional_details
                                                      .package_breadth_unit_name}{" "}
                                                  × {" "}
                                                  {ele.additional_details
                                                    .package_height +
                                                    " " +
                                                    ele.additional_details
                                                      .package_height_unit_name}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>

                                          <div className="d-flex align-items-center mb-2">
                                            <div className="">
                                              <p className="text-darkgray v-small">Weight :</p>
                                            </div>
                                            <div className="ps-2">
                                              {ele.additional_details
                                                .package_weight ? (
                                                <p className="text-gray v-small fw-500">
                                                  {ele.additional_details
                                                    .package_weight +
                                                    " " +
                                                    ele.additional_details
                                                      .package_weight_unit_name}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="border-top pt-2">
                                          <p className="text-dark py-2 fw-bold">
                                            Tax Details
                                          </p>
                                          <div className="mt-2">
                                            <div className="d-flex align-items-center mb-2">
                                              <div className="">
                                                <p className="text-darkgray v-small">
                                                  Gst Percent :
                                                </p>
                                              </div>
                                              <div className="ps-2">
                                                <p className="text-gray v-small fw-500">
                                                  {
                                                    ele.additional_details
                                                      .gst_percent
                                                  }
                                                </p>
                                              </div>
                                            </div>

                                            <div className="d-flex align-items-center mb-2">
                                              <div className="">
                                                <p className="text-darkgray v-small">HSN :</p>
                                              </div>
                                              <div className="ps-2">
                                                <p className="text-gray v-small fw-500">
                                                  {ele.additional_details.hsn}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-end w-100 mt-2">
                                        <button
                                          className="btn btn-sm px-4 bg-prime btn-radius text-white fw-bold"
                                          onClick={() => {
                                            // console.log("add variants");
                                            // SetModalVariants({
                                            //   // prevValue.is_open = true;
                                            //   is_open: true,
                                            //   product_name: ele.name,
                                            //   product_id: ele.id,
                                            //   product_checked: ele.checked,

                                            //   data: ele.variants,
                                            //   type: "edit",

                                            //   // return { ...prevValue }
                                            // });

                                            console.log("SetEditUserModal :");

                                            SetEditUserModal((prevValue: any) => {
                                              prevValue.is_open = true;
                                              prevValue.type = "edit";
                                              prevValue.data =
                                                ele.additional_details;
                                              prevValue.units = units;
                                              prevValue.id = ele.id;

                                              return { ...prevValue };
                                            });
                                            console.log("Edit");
                                          }}
                                        >
                                          Edit
                                        </button>
                                      </div>
                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            </div>
                            {/* <div
                            className="card p-3 "
                            onClick={(event) => {
                              if (width < 768) {
                                console.log('width', width)
                                // SetSelectedData({
                                //     value: data,
                                //     i: u_index,
                                //     type: "active",
                                //   });
                                // sample_fun('bottom', true, event)
                              }
                            }}
                          >
                            <div className="row align-items-center mb-2">
                              <div className="col-10">
                                {/* <h6 className="mb-0">Oppo A9 2020</h6> 
  
                                <h6 className="mb-0">
                                  <input
                                    type="checkbox"
                                    className="me-2"
                                    checked={ele.checked}
                                    name="checked"
                                    // key= {index}
                                    onChange={(e) => {
                                      // check_product(e);
                                    }}
                                  />
                                  {ele.name}
                                </h6>
                              </div>
                              <div className="col-2 text-end">
                                <FontAwesome
                                  name="circle"
                                  size={14}
                                  color="#18b901"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3 col-3">
                                {/* <img src={require("../../../assets/img/mob.png")} className="w-100 product-img" /> *}
  
                                {ele.images ? (
                                  <img
                                    src={
                                      // Object.keys(option).length > 0
                                      //   ?
                                      data.image_base_url +
                                      data.sizes[0] +
                                      '/' +
                                      ele.images[0]?.url
                                      // : null
                                    }
                                    className="img-fluid mb-auto"
                                  />
                                ) : (
                                  <img src={require('../../../assets/img/noimage.png')}
                                   
                                    className="img-fluid mb-auto"
                                    />
  
                                
  
         
                                )}
  
                                <div className="mt-5">
                                  {ele.checked ? (
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        Pause(ele.id)
                                      }}
                                    >
                                      pause
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        Enable(ele.id)
                                      }}
                                    >
                                      enable
                                    </button>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-8 col-9 ps-0">
                                <p className="single_line">{ele.description}</p>
                                <div className="row">
                                  <div className="col-12 mb-2">
                                    Package Details:
                                  </div>
                                  <div className="col-6">
                                    <p>
                                      l x b x h :{' '}
                                      {ele.additional_details.package_height ? (
                                        <small className="text-dark">
                                          {/* 6 *}
                          <span className="text-dark">
                            {/* {ele.additional_details
                                            .package_breadth +
                                            " " +
                                            ele.additional_details
                                              .package_bread
                                              
                                              th_unit_name}
                                        </span>{" "} *}
                                            {ele.additional_details
                                              .package_length +
                                              ' ' +
                                              ele.additional_details
                                                .package_length_unit_name}{' '}
                                            x{' '}
                                            {ele.additional_details
                                              .package_breadth +
                                              ' ' +
                                              ele.additional_details
                                                .package_breadth_unit_name}{' '}
                                            x{' '}
                                            {ele.additional_details
                                              .package_height +
                                              ' ' +
                                              ele.additional_details
                                                .package_height_unit_name}
                                          </span>{' '}
                                        </small>
                                      ) : (
                                        '-'
                                      )}
                                    </p>
                                  </div>
                                  {/* <div className="col-6">
                                    <p>
                                      Height :{" "}
                                      <small className="text-dark">
                                        {" "}
                                        <span className="text-dark">
                                          {ele.additional_details.package_height +
                                            " " +
                                            ele.additional_details
                                              .package_height_unit_name}
                                        </span>
                                      </small>
                                    </p>
                                  </div>
  
                                  <div className="col-6">
                                    <p>
                                      Length :
                                      <small className="text-dark">
                                        {" "}
                                        <span className="text-dark">
                                          {ele.additional_details.package_length +
                                            " " +
                                            ele.additional_details
                                              .package_length_unit_name}
                                        </span>
                                      </small>
                                    </p>
                                  </div> *}
                                  <div className="col-6">
                                    <p>
                                      Weight :{' '}
                                      {ele.additional_details.package_weight ? (
                                        <small className="text-dark">
                                          {ele.additional_details.package_weight +
                                            ' ' +
                                            ele.additional_details
                                              .package_weight_unit_name}
                                        </small>
                                      ) : (
                                        '-'
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-12 mb-2">
                                    Tax Information:
                                  </div>
                                  <div className="col-6">
                                    <p>
                                      Gst Percent :{' '}
                                      <small className="text-dark">
                                        {ele.additional_details.gst_percent}
                                      </small>
                                    </p>
                                  </div>
                                  <div className="col-6">
                                    <p>
                                      Hsn :{' '}
                                      <small className="text-dark">
                                        {' '}
                                        {ele.additional_details.hsn}
                                      </small>
                                    </p>
                                  </div>
  
                                  <div className="col-12 mb-2 text-center">
                                    {ele.available ? (
                                      <button
                                        type="button"
                                        class="btn btn-primary "
                                        onClick={() => {
                                          console.log('add variants')
  
                                          unavailable(ele.id)
                                        }}
                                      >
                                        {' '}
                                        Unavailable
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        class="btn btn-primary "
                                        onClick={() => {
                                          console.log('add variants')
  
                                          available(ele.id)
                                        }}
                                      >
                                        Available
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1 md-ps-0 d-sm-none d-md-block">
                                {ele.available ? (
                                  <div
                                    className="cursor center my-3"
                                    onClick={() => {
                                      console.log('add variants')
  
                                      unavailable(ele.id)
                                    }}
                                  >
                                    <MaterialIcons
                                      name="do-not-disturb"
                                      size={16}
                                      color="gray"
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="cursor center my-3"
                                    onClick={() => {
                                      console.log('add variants')
  
                                      available(ele.id)
                                    }}
                                  >
                                    <MaterialIcons
                                      name="check-circle"
                                      size={16}
                                      color="gray"
                                    />
                                  </div>
                                )}
  
                                <div
                                  className="cursor center my-3"
                                  onClick={() => {
                                    SetDeleteModal({
                                      is_open: true,
                                      id: ele.id,
                                    })
                                  }}
                                >
                                  <MaterialIcons
                                    name="delete-outline"
                                    size={16}
                                    color="gray"
                                  />
                                </div>
  
                                <div
                                  className="cursor center my-3"
                                  onClick={() => {
                                    // console.log("add variants");
                                    // SetModalVariants({
                                    //   // prevValue.is_open = true;
                                    //   is_open: true,
                                    //   product_name: ele.name,
                                    //   product_id: ele.id,
                                    //   product_checked: ele.checked,
  
                                    //   data: ele.variants,
                                    //   type: "edit",
  
                                    //   // return { ...prevValue }
                                    // });
  
                                    console.log('SetEditUserModal :')
  
                                    SetEditUserModal((prevValue: any) => {
                                      prevValue.is_open = true
                                      prevValue.type = 'edit'
                                      prevValue.data = ele.additional_details
                                      prevValue.units = units
                                      prevValue.id = ele.id
  
                                      return { ...prevValue }
                                    })
                                    console.log('Edit')
                                  }}
                                >
                                  <Feather name="edit-3" size={16} color="gray" />
                                </div>
                              </div>
                            </div>
                          </div> */}
                          </div>
                        ))
                      )}
                    </div>
                    :
                    <div className="text-center card p-3">
                      <img src={AppConfig.CDN_Image_url+"e38cbe608dba40c81ec58d266c09055f.png"} className="empty-img mb-2"
                  />
                      <p className="v-small mb-0">No Data, Please Add Product</p>
                      <Link to={"/brand/" + id_value.brand_id + "/addproducts_new"}>

                        <button className="btn btn-custom-outline btn-radius px-3 btn-sm mt-2">Add Products</button>
                      </Link>
                    </div>


                  }
                </div>
              ) : null}
            </div>
            {/* {next_page ? (
              <p
                className="text-prime fw-bold text-center py-3 cursor fs-7"
                onClick={() => {
                  load_more();
                }}
              >
                Load More
              </p>
            ) : null} */}
          </div>
        </div>
                   ) : (
                      <div className="row p-3">
                       <div className="col-md-3 px-0 d-sm-none d-md-block">
      <div className="bg-fff radius p-3 overflowY-auto scrollw-none">
        <div className="d-flex">
          <Skeleton width={100} height={25} />
          <div className="ms-auto cursor">
            <Skeleton width={50} height={20} />
          </div>
        </div>
        <div className="my-3">
          <label className="form-label d-flex align-items-center mb-3 cursor">
            <Skeleton width={20} height={20} circle={true} />
            <p className="ms-2"><Skeleton width={80} /></p>
          </label>
          <label className="form-label d-flex align-items-center mb-3 cursor">
            <Skeleton width={20} height={20} circle={true} />
            <p className="ms-2"><Skeleton width={80} /></p>
          </label>
        </div>
        <div className="">
          <h6><Skeleton width={80} /></h6>
        </div>
        <div className="my-3">
          {[1, 2, 3].map((index) => (
            <label className="form-label d-flex align-items-center mb-3 cursor" key={index}>
              <Skeleton width={20} height={20} circle={true} />
              <p className="ms-2"><Skeleton width={100} /></p>
            </label>
          ))}
        </div>
        <div className="brand">
          <Accordion className="px-0">
            <AccordionSummary expandIcon={<Skeleton width={20} height={20} />}>
              <p className="fs-7 fw-bold text-black"><Skeleton width={50} /></p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-100">
                <Skeleton width="100%" height={30} />
                <div className="p-2">
                  {[1, 2, 3].map((index) => (
                    <label className="form-label mt-3 d-flex align-items-center" key={index}>
                      <Skeleton width={20} height={20} circle={true} />
                      <p className="ms-2"><Skeleton width={100} /></p>
                    </label>
                  ))}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="mt-3">
          <p className="text-black"><Skeleton width={200} /></p>
          <div className="d-flex align-items-center">
            <Skeleton width="100%" height={30} />
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <Skeleton width="100%" height={40} />
            </div>
            <div className="col-6">
              <Skeleton width="100%" height={40} />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-9 mb-2" >
    <div className="row">
   { [1, 2, 3, 4].map(() => (
      <div className="col-md-6 mb-2">
                        <div className="card p-3 pb-0">
                          <div className="row">
                            <div className="col-3 pe-0 ps-2">
                              <Skeleton width={100} height={100} />
                            </div>
                            <div className="col-8">
                              <h6><Skeleton width={200} /></h6>
                            </div>
                            <div className="col-1 ps-0">
                              {/* Dropdown menu skeleton */}
                            </div>
                          </div>
                          <div className="py-2">
                            <p><Skeleton count={2} /></p>
                          </div>
                          {/* Package details skeleton */}
                          <div className="row align-items-center pb-2 border-bottom">
                       
                          </div>
                          {/* Accordion skeleton */}
                          <div className="prods-card">
                            <Accordion className="px-0">
                              <AccordionSummary expandIcon={<Skeleton width={20} height={20} />}>
                                <p className="fs-7 fw-bold text-black"><Skeleton width={150} /></p>
                              </AccordionSummary>
                              <AccordionDetails>
                                {/* Accordion content skeleton */}
                                <div className="w-100">
                                  {/* Package and tax details skeleton */}
                                  <p><Skeleton count={4} /></p>
                                  <div className="text-end w-100 mt-2">
                                    <button className="btn btn-sm px-4 bg-prime btn-radius text-white fw-bold"><Skeleton width={50} /></button>
                                  </div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                        </div>

                    ))}
                      </div>

                      </div>
                      </div>

                 
                  
              
                  )}
        </InfiniteScroll>
      </div>

      {/* PR */}

      {/* <ModalVariants
          open={modal_variants.is_open}
          close={(data: any) => {
              console.log("Variants modl on close :", data)
              SetModalVariants((prevValue: any) => {
                  prevValue.is_open = false;
                  return { ...prevValue }
              })
          }}
      /> */}

      {["left", "right", "top", "bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={(event) => {
              sample_fun(anchor, false, event);
            }}
            onOpen={(event) => {
              sample_fun(anchor, true, event);
            }}
          >
            <div className="mt-3">
              <div>
                <div className="d-flex justify-content-center">
                  <div className="w-25 px-4">
                    <hr className="h-3" />
                  </div>
                </div>
                <ul>
                  <li
                    className="p-2 pb-3 cursor align-center"
                    onClick={(event) => {
                      console.log("Inactive");
                      sample_fun(anchor, false, event);
                    }}
                  >
                    <MaterialIcons
                      name="do-not-disturb"
                      size={16}
                      color="gray"
                    />
                    &nbsp; Inactive
                  </li>
                  {/* Monthly Salary */}

                  <li
                    className="p-2 pb-3 cursor align-center text-dark"
                    onClick={(event) => {
                      console.log("Remove");
                      sample_fun(anchor, false, event);
                    }}
                  >
                    <MaterialIcons
                      name="delete-outline"
                      size={16}
                      color="gray"
                    />
                    &nbsp; Remove
                  </li>
                  {/* Work Salary */}
                  <li
                    className="p-2 pb-3 cursor align-center text-dark"
                    onClick={(event) => {
                      let set_data = {
                        // value: ele,
                        // index: ele.i,
                      };
                      console.log("SetEditUserModal :");

                      SetEditUserModal((prevValue: any) => {
                        prevValue.is_open = true;
                        prevValue.type = "edit";
                        prevValue.data = set_data;
                        return { ...prevValue };
                      });
                      console.log("Edit");
                      // sample_fun(anchor, false, event);
                    }}
                  >
                     <img src={AppConfig.CDN_Media_url+"5d643b0b50ef1705bf7437de3338d7df.svg"} className="w-16px"  />
                    {/* <Feather name="edit-3" size={16} color="gray" /> */}
                    &nbsp; Edit
                  </li>
                </ul>
              </div>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}

      <ModalEditAdditional
        open={edit_user_modal.is_open}
        data={edit_user_modal.data}
        units={edit_user_modal.units}
        id={edit_user_modal.id}
        type={edit_user_modal.type}
        index={edit_user_modal.index}
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          SetEditUserModal({
            is_open: false,
            data: {},
            units: {},
            id: "",
            type: "",
            index: "",
          });
          if (data.action == "edit") {
              SetPageNo(1);
            get_products({
            
         
        
              page_no: 1,
            }, count, min_max_value, type, text, sort);
          }
        }}
      />

      <ModalDeleteVarient
        open={deleteModal.is_open}
        id={deleteModal.id}
        close={(data: any) => {
          console.log("Franchise modl on close :", data);
          SetDeleteModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            get_products(1, count, min_max_value, type, text, sort);
          }
        }}
      />

      {width < 786 ? (
        <SwipeableBottomSheet
          overflowHeight={0}
          style={{ zIndex: 1300 }}
          open={filter_open}
          onClose={() => {
            SetFilterOpen(false);
          }}
          onChange={() => {
            SetFilterOpen(false);
          }}
        >
          <div
            className="bg-fff radius-top"
            style={{ minHeight: "500px", maxHeight: "90vh" }}
          >
            <div className=" drawer-fixed">
              <div className="w-100 center">
                <hr className="hr-drawer" />
              </div>
              <div className="row align-items-center pt-2 px-3">
                <div className="col-9">
                  <h6 className="fs-5 fw-500">Filter</h6>
                </div>
                <div className="col-3 text-end cursor">
                  <small className="text-black">Clear</small>
                </div>
              </div>
            </div>
            <div className="p-3 pt-5">
              <div className="mb-3 mt-5">
                <label className="form-label d-flex align-items-center mb-3 cursor">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    name={"own products"}
                    // value={ele.id}
                    onChange={(event) => {
                      input_on_change(event);
                    }}
                  />
                  <p className="capitalize">{"own product"}</p>
                </label>

                <label className="form-label d-flex align-items-center mb-3 cursor">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    name={"others products"}
                    // value={ele.id}
                    onChange={(event) => {
                      input_on_change(event);
                    }}
                  />
                  <p className="capitalize">{"other product"}</p>
                </label>
              </div>
              <div className="d-flex ">
                <h6>Category</h6>
              </div>
              <div className="my-3">
                {/* {Object.keys(option).length > 0
                  ? */}
                {option?.product_categories?.map((ele: any) => (
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name={"filter_category"}
                      value={ele.id}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                    <p className="capitalize">{ele.name}</p>
                  </label>
                ))}
                {/* : null} */}
              </div>

              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <div className="accordion-header" id="acc-head-id">
                    <button
                      className="accordion-button px-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acc-id"
                      aria-expanded="false"
                      aria-controls="acc-id"
                    >
                      Brand
                    </button>
                  </div>
                  <div
                    id="acc-id"
                    className="accordion-collapse collapse "
                    aria-labelledby="acc-head-id"
                  >
                    <div className="accordion-body">
                      <input
                        type="text"
                        placeholder="Search..."
                        className="form-control"
                      />
                      <div className="p-2">
                        {/* {Object.keys(option).length > 0
                          ?  */}
                        {option.product_brands?.map((ele: any) => (
                          <label className="form-label mt-3 d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="me-2"
                              name={"filter_brand"}
                              value={ele.id}
                              onChange={(event) => {
                                input_on_change(event);
                              }}
                            />
                            {ele.name}
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <p className="text-black" id="range-slider" gutterBottom>
                  Mininum Maximum Price
                </p>
                <div className="d-flex align-items-center">
                  {/* <p className="pe-3">Min</p> */}
                  <Slider
                    value={min_max_value}
                    onChange={handleChange}
                    step={20}
                    onClick={() => {
                      console.log("min max :", min_max_value);
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                    min={10}
                    max={1000}
                  />
                  {/* <p className="px-3">Max</p> */}
                </div>

                <div className="row">
                  <div className="col-6">
                    <label className="form-label">Min Price</label>
                    <input
                      type="text"
                      className="form-control"
                      name={"min_mrp"}
                      value={min_max_value[0]}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">Max Price</label>
                    <input
                      type="text"
                      className="form-control"
                      name={"max_mrp"}
                      value={min_max_value[1]}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwipeableBottomSheet>
      ) : null}
    </div>
  );
}
