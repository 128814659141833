import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../../utils/Api";

let myModal: any = {};

export default function ModalCallReminderUserMobile(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [user_data, SetUserData] = useState([]);
  const [selected_user, SetSelectedUser] = useState({});
  const [formData, setFormData]:any = useState({
    dial_code: "+91",
    mobile: ""
  });

  useEffect(() => {
    console.log("ModalCallReminderUser On mount :", props);
    let myModal1: any = document.getElementById("OutletCallReminderAddMobile");
    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModalCallReminderUser On mount :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("OutletCallReminderAddMobile"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);

  useEffect(() => {
    if (Object.keys(selected_user).length > 0) {
      add_user(selected_user);
    }
  }, [selected_user]);



  async function add_user() {
    if(formData?.dial_code && formData?.mobile && formData?.mobile?.length == 10){
      setFormData((prev: any) => { prev.error = null; return { ...prev }; });
      
      let priority_data = props.users_priority_data;
      priority_data.push(formData?.dial_code+""+""+formData?.mobile);
      let pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: id_value.outlet_id,
        },
        post: {
          dial_code: formData?.dial_code,
          mobile: formData?.mobile,
          priority: priority_data,
        },
      };
      let data_res: any = await api(
        "/brand/add_order_call_reminder_mobile_number",
        pass_data
      );
      if (data_res.status_code == 200) {
        let item: any = close_data;
        item.action = "save";
        item.value = data_res.response;
        console.log("Close data : ", item);
        SetCloseData(item);
        myModal.hide();
      }
    } else {
      setFormData((prev: any) => { prev.error = 'Kindly enter valid mobile number'; return { ...prev }; })
    }
  }

  return (
    <div
      className="modal fade"
      id="OutletCallReminderAddMobile"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered p-2">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Add Mobile Number
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="input-group mb-3 mobile-input">
              <input type="text" className="form-control" value={formData.dial_code} onChange={(e) => { setFormData((prev: any) => { prev.dial_code = e.target.value; return { ...prev }; }) }} />
              <input type="text" className="form-control" value={formData.mobile} onChange={(e) => { setFormData((prev: any) => { prev.mobile = e.target.value; return { ...prev }; }) }} />
            </div>
            {formData?.error && (
              <div className="fs-12 text-danger">{formData?.error}</div>
            )}
          </div>
          <div className="modal-footer">
            <div>
              <button
                type="button"
                className="btn btn-outline-secondary px-3 btn-sm"
                onClick={(event) => {
                  console.log("Close modal", event);
                  myModal.hide();
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary ms-2 px-3 btn-sm"
                onClick={(event) => {
                  add_user();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
