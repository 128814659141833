import React, { useState, useEffect, useContext, useRef } from "react";
import OrdersList from "./OrdersList";
import { api } from "../../../utils/Api";
import AliceCarousel from "react-alice-carousel";
import DataContext from "../../../utils/Context/DataContext";
import { AppConfig } from "../../../config";

import {
  Link,
  useParams,
  useOutletContext,
  useLocation,
  useNavigate,
} from "react-router-dom";
import OrdersListUi from "./OrdersListUi";

export default function Orders(props: any) {
  const OutletContext = useOutletContext();
  const [open, setOpen] = React.useState(false);
  // const anchorRef = React.useRef(null);
  const context: any = useContext(DataContext);
  const [grid_view, SetGridView] = useState(false);
  const [list_view, SetListView] = useState(true);
  const [id_value, SetIdValue] = useState(useParams());
  const [response_data, SetResponseData] = useState({});
  const [type, setType] = useState("all");

  const [tab_filter, SertTabFilter] = useState([
    { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "New", value: "new" },
    { label: "Finished", value: "finished" },
    { label: "Cancelled", value: "cancelled" },
  ]);
  const [width, SetWidth] = useState(innerWidth);
  const [have_new_order, SetHaveNewOrder] = useState(false);
  // const [is_loading, SetIsLoading] = useState(false);
  const [view_type, SetViewType] = useState(props?.type);
  const [active_tab, SetActiveTab] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (props) {
      SetViewType(props?.type);
      // get_order(props?.type);
      console.log(id_value);
    }
  }, [props]);

  async function get_order(view: any) {
    SetViewType(view);
    SetIsLoading(true);
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
    };

    let response;

    if (view) {
      pass_data.get.view = view;
      pass_data.get.page = 1;

      response = await api("/outlet/orders", pass_data);
    }

    if (response?.status_code == 200) {
      SetResponseData(response?.response);
      console.log(response?.response);
    }
    SetIsLoading(false);
  }

  return (
    <div className="mb-5">
      <div
        className="head-bar sm-pb-0"
        style={width < 780 ? { width: width } : {}}
      >
        <div className="">
          <h6 className="mb-1">Orders</h6>
        </div>

        <div className="mt-1 d-flex align-items-center bg-fff p-2 sm-px-0 md-px-3 sm-pb-0">
          <div className="d-flex align-items-center scrollX">
            {tab_filter.map((tab, tab_index) => (
              <Link
                key={tab_index}
                type="button"
                className={
                  props?.type == tab.value
                    ? "btn btn-text header-tap me-1 px-3 pb-2 center"
                    : "btn btn-text text-darkgray me-1 px-3 pb-2 "
                }
                to={
                  id_value.hasOwnProperty("brand_id")
                    ? "/outlet/" +
                      id_value.brand_id +
                      "/" +
                      id_value.outlet_id +
                      "/orders/" +
                      tab.value
                    : "/orders/" + tab.value
                }
              >
                {tab.label}
              </Link>
            ))}
          </div>
        </div>
      </div>

      <div className="px-1 sm-px-0 my-3">
        <OrdersList
          //  OrdersList

          // data={response_data}
          view={props?.type}
          // have_new_order={have_new_order}
          // is_loading={is_loading}
          on_new_order={(data: any) => {
            SetHaveNewOrder(data);
          }}
        />
      </div>
      {/* You got new order! */}
      <div className="">
        {context.app_data.application.platform == "web" && have_new_order && (
          //    id_value.hasOwnProperty("brand_id")?
          <Link
            className="new-order-alert"
            to={
              id_value.hasOwnProperty("brand_id")
                ? "/outlet/" +
                  id_value.brand_id +
                  "/" +
                  id_value.outlet_id +
                  "/orders/new"
                : "/orders/new"
            }
          >
            <div
              className="row cursor "
              style={width < 548 ? { width: width } : {}}
            >
              <div className="col-12 d-flex align-items-center text-white">
                <div>
                  <h6 className="mb-0 text-white">You got new order!</h6>
                </div>
                <div className=" ms-auto">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "df28bba3aea2a6c2838fd46005ec8f71.svg"
                    }
                    className="img-fluid"
                    width={30}
                  />
                </div>
              </div>
            </div>
          </Link>
          //     :  <Link className="new-order-alert"

          //     to={

          //         "/orders/new"

          //     } >

          //     <div
          //         className="row cursor "
          //         style={width < 548 ? { width: width } : {}}
          //     >
          //         <div className="col-12 d-flex align-items-center text-white">
          //             <div>
          //                 <h6 className="mb-0 text-white">You got new order!</h6>
          //             </div>
          //             <div className=" ms-auto">
          //                 <img
          //                     src={
          //                         AppConfig.CDN_Media_url +
          //                         "df28bba3aea2a6c2838fd46005ec8f71.svg"
          //                     }
          //                     className="img-fluid"
          //                     width={30}
          //                 />
          //             </div>
          //         </div>
          //     </div>
          // </Link>
        )}
      </div>
    </div>
  );
}
