import React, { useState, useEffect ,useContext} from "react";
import { api } from "../../../utils/Api";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import ModalAddGallery from "../Gallery/ModalAddGallery";
import toast from "react-hot-toast";
import { AppHelper } from "../../../utils/Helper";
import { AppConfig } from "../../../config";
import DataContext from "../../../utils/Context/DataContext";






const setting_initial_value = {
  default_country_id: "",
  default_state_id: "",
  default_language_id: "",
  default_currency_id: "",
  delivery_type_id: "",

  image: "",
  image_id: "",


  banner_image: "",
  banner_image_id: "",


};
export default function BrandSettings(props: any) {
  const context:any = useContext(DataContext);

 

  const OutletContext = useOutletContext()
  const [id_value, SerIdValue] = useState(useParams());
  const [default_data, SetDefaultData] = useState([]);
  const [countries, SetCountries] = useState([]);
  const [languages, SetLanguages] = useState([]);
  const [setting_data, SetSettingData] = useState(setting_initial_value);
  const [width, SetWidth] = useState(innerWidth);
  const [data, SetData] = useState([]);


  const [currencies, SetCurrencies] = useState([]);

  const [delivery_type, SetDeliverytype] = useState([]);
  const [states, SetStates] = useState([]);
  const [modal_image, SetModalImage] = useState({
    is_open: false,
    selected_images: [],

  });

  const [modal_banner, SetModalBanner] = useState({
    is_open: false,
    selected_banner: [],

  });


  useEffect(() => {
    console.log("Account Settings on mount:", props);
    get_account();
    get_states();
   
  }, []);

  async function get_account() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let response = await api("/brand/get_settings", pass_data);
    console.log("/brand/settings response :", response.response);
    if (response.status_code == 200) {
      SetDefaultData(response.response.data);
      SetData(response.response);


      let set_data = {
        brand_name: response.response.data.brand_name?
        response.response.data.brand_name : "",
        default_country_id:
        response.response.data.default_country_id?
        response.response.data.default_country_id : "",
        default_state_id:
         response.response.data.default_state_id?
         response.response.data.default_state_id :"",
        default_language_id:
         response.response.data.default_language_id?
         response.response.data.default_language_id:"",
        default_currency_id: 
        response.response.data.default_currency_id ?
        response.response.data.default_currency_id : "",
        delivery_type_id: 
        response.response.data.delivery_type_id?
        response.response.data.delivery_type_id : "",

        image : response.response.data.image?
        response.response.data.image:"",
        image_id: response.response.data.image_id?
        response.response.data.image_id:"",
        banner_image : response.response.data.banner_image?
        response.response.data.banner_image:"",
        banner_image_id : response.response.data.banner_image_id?
        response.response.data.banner_image_id:"",

      };
      console.log("On edit franchise data :", set_data);
      SetSettingData(set_data);

      SetCountries(response.response.options.countries);
      SetLanguages(response.response.options.languages);
      SetCurrencies(response.response.options.currencies);
      SetDeliverytype(response.response.options.delivery_type);
    }

  }

  async function get_states() {
    let pass_data = {
      get: {
        country_id: 99,
      },
    };
    let response = await api("/init/get_states", pass_data);
    console.log("/brand/settings response :", response);
    if (response.status_code == 200) {
      SetStates(response.response.date);

    }

  }

  async function SubmitForm() {

    console.log("outlet_data", outlet_data);

    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        channel_data: outlet_data,
      }
    };
    let response = await api("/brand/save_outlet_channels", pass_data);
    console.log("/brand/settings response :", response.response);
    if (response.status_code == 200) {



      let new_response = await api("/brand/outlet_channels_status", pass_data);
      console.log("/brand/settings response :", new_response.response);
      if (response.status_code == 200) {

        SetOutlettData(new_response.response.data);

      }
    }
  }

  async function check_pause(event: any, index: any, i: any) {
    console.log(index);


    let key_name = event.target.name;

    SetOutlettData((prevValue) => {

      console.log(prevValue[index]);
      console.log(prevValue[index].channels[i].paused);


      // {
      //   type == "checked"
      //     &&
      (prevValue[index].channels[i].paused =
        !prevValue[index].channels[i].paused)
      //  }
      {
        prevValue[index].channels[i].paused == false &&
          (
            prevValue[index].channels[i].checked = true)
      }



      return [...prevValue];
    });

    // SubmitForm();

  }

  async function check_channel(event: any, index: any, i: any) {
    console.log(index);


    let key_name = event.target.name;

    SetOutlettData((prevValue) => {

      console.log(prevValue[index]);
      console.log(prevValue[index].channels[i].checked);


      // {
      //   type == "checked"
      //     &&
      (prevValue[index].channels[i].checked =
        !prevValue[index].channels[i].checked)
      //  }


      return [...prevValue];
    });

    // SubmitForm();



  }

  async function check_availibility(event: any, index: any, i: any) {
    console.log(index);


    let key_name = event.target.name;

    SetOutlettData((prevValue) => {

      console.log(prevValue[index]);
      console.log(prevValue[index].channels[i].checked);


      // {
      //   type == "checked"
      //     &&
      (prevValue[index].channels[i].unavailable =
        !prevValue[index].channels[i].unavailable)
      //  }


      return [...prevValue];
    });




    // SubmitForm();



  }

  function settings_value_on_change(name: any, value: any) {
    SetSettingData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  { console.log(setting_data) }


  async function update_setting() {
    console.log("update_user :", setting_data);
    let post_value;
    let pass_data
    if(!setting_data.brand_name){
    
      toast.error("brand name should'nt be empty");

    
    
  }else{

    post_value = {
      brand_name: setting_data.brand_name,
      default_country_id: setting_data.default_country_id,
      default_state_id: setting_data.default_state_id,
      default_language_id: setting_data.default_language_id,
      default_currency_id: setting_data.default_currency_id,
      delivery_type_id: setting_data.delivery_type_id,
      image: setting_data.image_id,
      banner_image: setting_data.banner_image_id,
    }

     pass_data = {
      get: {
        brand: id_value.brand_id,
        // franchise: id_value.franchise_id,
      },
      post: post_value,
    };
    console.log("pass_data :", pass_data);

    let data_res = await api("/brand/save_settings", pass_data);
    console.log("edit_franchise_user response :", data_res.response);
    if (data_res.status_code == 200) {

      console.log(data_res.response?.data?.image)
      if(!data_res.response?.data?.image ){
      context.SetAppData((prevValue:any) => ({
        ...prevValue,
        current_brand: {
          // brand_profile : data_res.response?.image_base_url + data_res.response?.data?.image,
          brand_name : data_res.response?.data?.brand_name,
          
        }
      }));
    }else{
      context.SetAppData((prevValue:any) => ({
        ...prevValue,
        current_brand: {
          brand_profile : data_res.response?.image_base_url + data_res.response?.data?.image,
          brand_name : data_res.response?.data?.brand_name,
          
        }
      }));
    }
      toast.success("settings saved");
    }
  
  }
  }

  function onImgSelect() {
    SetModalImage((prevValue: any) => {
      prevValue.is_open = !prevValue.is_open;
      // prevValue.selected_images = data.selected_img;
      return { ...prevValue };
    });
  }


  function onBnrSelect() {
    SetModalBanner((prevValue: any) => {
      prevValue.is_open = !prevValue.is_open;
      // prevValue.selected_images = data.selected_img;
      return { ...prevValue };
    });
  }

  return (

    <div className="mb-5">
      <div className={OutletContext ? "head-bar sticky-sm" : "head-bar  sticky-sm"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="pb-2">
          <h6 className="mb-0">Details</h6>
        </div>
      </div>
      <div className="mt-3 px-md-3 px-sm-0"
        style={width < 786 ? (
          OutletContext == false ? { width: width } : {}
        ) : (
          OutletContext == false ? { width: width, paddingTop: "3rem" } : {}
        )} >
        <div className="bg-fff p-3 radius">
          <div className="row px-sm-2 px-md-1">
          <div className="col-md-6 col-lg-4 md-ps-0 sm-p-0">
              <label className="form-label">Brand Name</label>
              <br/>
<input className="form-input mb-3" value={setting_data.brand_name}  onChange={(e) => {
                settings_value_on_change("brand_name", e.target.value);}}/>
             
            </div>
            <div className="col-md-6 col-lg-4 md-ps-0 sm-p-0">
              <label className="form-label">Countries</label>

              <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" onChange={(e) => {
                settings_value_on_change("default_country_id", e.target.value);
              }}>
                <option selected>{default_data.default_country_name}</option>
                {countries.map((ele: any) => (
                  <option value={ele.id}>{ele.nicename}</option>
                ))}
              </select>
            </div>

            {setting_data.default_country_id == "99" &&
              <div className="col-md-6 col-lg-4 md-ps-0 sm-p-0">
                <label className="form-label">States</label>

                <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" onChange={(e) => {
                  settings_value_on_change("default_state_id", e.target.value);
                }}>
                  <option selected>{default_data.default_state_name}</option>
                  {states.map((ele: any) => (
                    <option value={ele.id} >{ele.nicename}</option>
                  ))}
                </select>

              </div>
            }

            <div className="col-md-6 col-lg-4 md-ps-0 sm-p-0">
              <label className="form-label">Languages</label>
              <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                onChange={(e) => {
                  settings_value_on_change("default_language_id", e.target.value);
                }}
              >
                <option selected>{default_data.default_language_name}</option>
                {languages.map((ele: any) => (
                  <option value={ele.id}>{ele.name}</option>
                ))}
              </select>
            </div>

            <div className="col-md-6 md-ps-0 sm-p-0">
              <label className="form-label">Currencies</label>

              <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                onChange={(e) => {
                  settings_value_on_change("default_currency_id", e.target.value);
                }}
              >
                <option selected>{default_data.currency_name}</option>
                {currencies.map((ele: any) => (
                  <option value={ele.id}>{ele.name}</option>
                ))}
              </select>
            </div>

            <div className="col-md-6 md-ps-0 sm-p-0">
              <label className="form-label">Delivery type</label>

              <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                onChange={(e) => {
                  settings_value_on_change("delivery_type_id", e.target.value);
                }}
              >
                <option selected>{default_data.delivery_type}</option>
                {delivery_type.map((ele: any) => (
                  <option value={ele.id}>{ele.name}</option>
                ))}
              </select>
            </div>

          </div>
          <div className="row">
            {/* Add Image */}
            <div className="col-12 col-md-6 mt-2">
              <label className="form-label"> Brand Logo </label>

              {/* Image */}
              <div
          
              >
                {setting_data.image ? (
                  <div className=" p-1">

                    {console.log("hi")}

                   
                    <div
                      className=" p-1"
                    // key={img_index}
                    >
                      <div className="card">
                        <img

                          src={AppHelper.imageSrc(data.image_base_url, setting_data.image, { format: "webp", width: 100 })}

                          className="cover_img radius"
                        />
                      </div>
                    </div>
                    {/* )
                            )} */}
                    {/* </div> */}
                    <div className="row">
                    <div className="col-6">
                    <div
                      className="center cursor p-2 bg-fff border mt-3 radius"
                      onClick={() => {
                        onImgSelect(setting_data.image);
                      }}
                    >
                      <img src={AppConfig.CDN_Media_url+"52ce25657d85541bc672d3868acc2f00.svg"} className="w-14px" />
                    
                      <p className="ps-2 mb-0 text-darkgray fw-bold ">
                        Update Logo
                      </p>
                    </div>
                   

                  </div>
                  <div className="col-6">
                  <div
                      className="center cursor p-2 bg-danger border mt-3 radius"
                      onClick={() => {
                        settings_value_on_change("image", "");
                        settings_value_on_change("image_id", "");
                      }}
                    >
                     
                     
                      <p className="ps-2 mb-0  fw-bold " style={{ color: 'white' }}>
                        Remove
                      </p>
                    </div>
                  </div>
                  </div>
                  </div>

                ) : (
                  <div
                    className="center cursor py-2"
                    onClick={() => {
                      onImgSelect(modal_image.selected_images);
                    }}
                  >
                    <div className="border radius cursor p-5 center cover_img">
                      <div className="d-flex align-items-center">
                        
                      <img src={AppConfig.CDN_Media_url+"52ce25657d85541bc672d3868acc2f00.svg"} className="w-14px" />
                        
                        <p className="ps-2 mb-0 text-darkgray">
                          Add / Select Images
                        </p>
                      </div>
                    
                    </div>
                  </div>
                )}
              </div>

        
            </div>

            {/* Banner Image */}
            <div className="col-12 col-md-6 mt-2">
              <label className="form-label">Banner Image</label>

              {/* Image */}
              <div
           
              >
                {setting_data.banner_image?.length > 0 ? (
                  <div className=" p-1">
          
                    <div
                      className="p-1"
                    // key={img_index}
                    >
                      <div className="card">
                        <img


                          src={AppHelper.imageSrc(data.image_base_url, setting_data.banner_image, { format: "webp", width: 100 })}

                          className="cover_img radius"
                        />
                      </div>
                    </div>
                    {/* )
                            )} */}
                    {/* </div> */}
                    <div className="row">
                    <div className="col-6">
                    <div
                      className="center cursor p-2 bg-fff border mt-3 radius"
                      onClick={() => {
                        onBnrSelect(setting_data.selected_banner);
                      }}
                    >
                      <img src={AppConfig.CDN_Media_url+"52ce25657d85541bc672d3868acc2f00.svg"} className="w-14px" />

                      {/* <MaterialCommunityIcons
                        name="image-plus"
                        size={18}
                        color="gray"
                      /> */}
                      <p className="ps-2 mb-0 text-darkgray fw-bold ">
                        Update Banner 
                      </p>
                    </div>
                    </div>

                    <div className="col-6">
                  <div
                      className="center cursor p-2 bg-danger border mt-3 radius"
                      onClick={() => {
                        settings_value_on_change("banner_image", "");
                        settings_value_on_change("banner_image_id", "");
                      }}
                    >
                     
                      {/* <MaterialCommunityIcons
                        name="image-plus"
                        size={18}
                        color="gray"
                      /> */}
                      <p className="ps-2 mb-0  fw-bold " style={{ color: 'white' }}>
                        Remove
                      </p>
                    </div>
                  </div>
                  </div>

                  </div>
                ) : (
                  <div
                    className="center cursor py-2"
                    onClick={() => {
                      onBnrSelect(modal_banner.selected_banner);
                    }}
                  >
                    <div className="cover_img border radius cursor p-5 center">
                      <div className="d-flex align-items-center">
                     
                      <img src={AppConfig.CDN_Media_url+"52ce25657d85541bc672d3868acc2f00.svg"} className="w-14px" />
                        <p className="ps-2 mb-0 text-darkgray">
                          Add / Select Banners 
                        </p>
                      </div>
                    
                    </div>
                  </div>
                )}
              </div>
      
            </div>
          </div>
          <div className="text-end mt-4">
            <button type="button" className="btn bg-prime text-white btn-radius px-3 btn-sm"
              onClick={() => {
                update_setting();
              }}>Save</button>
          </div>
        </div>
      </div>


      {modal_image.is_open ? (
        <ModalAddGallery
          open={modal_image.is_open}
          type={modal_image.type}
          data={modal_image.data}

          selected={modal_image.selected_images}
          from={"create_outlet"}
          close={(data: any) => {
            console.log("ModalAddGallery on close :", data);
            SetModalImage((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });

            if (data.action == 'save') {
              SetSettingData((prevValue: any) => {
                prevValue.image = data.value[0].url;
                prevValue.image_id = data.value[0].id;


                return { ...prevValue }
              })
            }

          }}
        />
      ) : null}

      {modal_banner.is_open ? (
        <ModalAddGallery
          open={modal_banner.is_open}
          type={modal_banner.type}
          data={modal_banner.data}

          selected={modal_banner.selected_banner}
          from={"create_banner"}
          close={(data: any) => {
            console.log("ModalAddGallery on close :", data);
            SetModalBanner((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });

            if (data.action == 'save') {
              SetSettingData((prevValue: any) => {

                prevValue.banner_image = data.value[0].url;
                prevValue.banner_image_id = data.value[0].id;


                // prevValue.banner_image = data.value[0].id;
                return { ...prevValue }
              })
            }

          }}
        />
      ) : null}
    </div>






    

  );
}
