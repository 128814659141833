import React, { useRef, useState, useEffect, useContext } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

export default function Intro(props: any) {
  const handleDragStart = (e: any) => e.preventDefault();
  const carouselRef = useRef<AliceCarousel>(null);
  const [activeIndex, setActiveIndex]: any = useState(0);

  useEffect(() => {
    let body: any = document.querySelector("body");
    if (body) {
      body.style.background = "#ffffff";
    }
  }, []);

  const items = [
    <div className="" onDragStart={handleDragStart}>
      <img
        className="img-fluid mb-4"
        src={require("../../assets/img/onboarding.png")}
      />
      <h6 className="text-primary font-poppins fw-500">Create Products</h6>
      <div className="text-muted font-poppins">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </div>
    </div>,
    <div className="" onDragStart={handleDragStart}>
      <img
        className="img-fluid mb-4"
        src={require("../../assets/img/onboarding.png")}
      />
      <h6 className="text-primary font-poppins fw-500">Easy & Safe Payments</h6>
      <div className="text-muted font-poppins">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </div>
    </div>,
    <div className="" onDragStart={handleDragStart}>
      <img
        className="img-fluid mb-4"
        src={require("../../assets/img/onboarding.png")}
      />
      <h6 className="text-primary font-poppins fw-500">Track Your Orders</h6>
      <div className="text-muted font-poppins">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </div>
    </div>,
    <div className="" onDragStart={handleDragStart}>
      <img
        className="img-fluid mb-4"
        src={require("../../assets/img/onboarding.png")}
      />
      <h6 className="text-primary font-poppins fw-500">Fast Delivery</h6>
      <div className="text-muted font-poppins">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </div>
    </div>,
  ];

  const onSlideChanged = (e) => {
    setActiveIndex(e.item);
  };

  const Carousel = () => (
    <AliceCarousel
      key="carouselRef"
      activeIndex={activeIndex}
      ref={carouselRef}
      mouseTracking
      disableButtonsControls
      items={items}
      onSlideChanged={onSlideChanged}
    />
  );
  return (
    <div className="container py-5 h-100 intro-div">
      <div className="row h-100">
        <div className="col-lg-4 offset-lg-4 text-center h-100 d-flex flex-column">
          <h6 className="font-poppins fw-500 fs-20">Sellers</h6>
          <Carousel />
          <div className="text-center">
            {activeIndex < items.length - 1 && (
              <button
                type="button"
                className="btn btn-dark w-100 btn-rounded btn-outline-none"
                onClick={(e) => {
                  carouselRef?.current?.slideNext(e);
                }}
              >
                Next
              </button>
            )}
            {activeIndex >= items.length - 1 && (
              <button
                type="button"
                className="btn btn-dark w-100 btn-rounded btn-outline-none"
              >
                Get Started
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
