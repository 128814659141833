import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { api } from "../../../utils/Api";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { Map, TileLayer } from "react-leaflet";
import { AppHelper } from "../../../utils/Helper";

import ModalEditOutlet from "./ModalEditOutlet";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import DeleteAlert from "../../../components/Alert/DeleteAlert";
import ModalDeleteOutlet from "./ModalDeleteOutlet";
import {
  AntDesign,
  Entypo,
  Feather,
  FontAwesome,
  Fontisto,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  SimpleLineIcons,
} from "@expo/vector-icons";
import ModalCallReminderUser from "./ModalCallReminderUser";
import { AppConfig } from "../../../config";
import ModalCallReminderUserMobile from "./ModalCallReminderUserMobile";

export default function OutletCallReminder(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [id_value, SerIdValue] = useState(useParams());
  const [width, SetWidth] = useState(innerWidth);
  const [user_data, SetUserData] = useState([]);
  const [users_priority_data, SetUsersPriorityData] = useState([]);
  const [original_users_priority_data, SetOriginalUsersPriorityData] = useState(
    []
  );
  const [checked_user_data, SetCheckedUserData] = useState({});
  const [add_user_modal, SetAddUserModal] = useState({
    is_open: false,
  });
  const [add_user_number_modal, SetAddUserNumberModal] = useState({
    is_open: false,
  });
  const [is_edit, SetIsEdit] = useState(false);

  useEffect(() => {
    console.log("OutletCallReminder On mount :", props);
    console.log("OutletCallReminder context :", context);
    get_order_call_reminder_users();
  }, []);

  async function get_order_call_reminder_users() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
    };

    let response: any = await api(
      "/brand/order_call_reminder_users",
      pass_data
    );
    console.log("get_order_call_reminder_users response :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("users")) {
        SetUserData(response.response.users);
      }
      if (response.response.hasOwnProperty("users_priority")) {
        SetUsersPriorityData(response.response.users_priority);
        SetOriginalUsersPriorityData(response.response.users_priority);
      }
    }
  }

  function get_user_priority() {
    let return_data: any = [];
    users_priority_data.map((p_ele: any, p_index: any) => {
      return_data.push(user_data.find((u_ele: any) => (u_ele?.full_mobile)?u_ele.full_mobile == p_ele:u_ele.id == p_ele));
    });
    return return_data;
  }

  async function delete_user(get_data: any) {
    let priority_data = users_priority_data;
    let t:any = (get_data?.full_mobile)?get_data.full_mobile:get_data.id
    let index: any = priority_data.indexOf(t);
    if (index > -1) {
      priority_data.splice(index, 1);
    }
    let pass_data: any;
    pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
      post: {
        priority: priority_data,
      },
    };
    if(get_data?.full_mobile){
      pass_data.post.id = get_data.rid;
    } else {
      pass_data.post.outlet_user_id = get_data.id;
    }

    let response: any = await api(
      "/brand/delete_order_call_reminder_user",
      pass_data
    );
    console.log("delete_order_call_reminder_user response :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("users")) {
        SetUserData(response.response.users);
      }
      if (response.response.hasOwnProperty("users_priority")) {
        SetUsersPriorityData(response.response.users_priority);
        SetOriginalUsersPriorityData(response.response.users_priority);
      }
    }
  }

  function move_up() {
    SetCheckedUserData((c_prevalue: any) => {
      let checked_data = c_prevalue;
      SetUsersPriorityData((p_prevalue: any) => {
        let priority_data = p_prevalue;
        let index: any = priority_data.indexOf(checked_data?.full_mobile?checked_data?.full_mobile:checked_data.id);
        if (index > 0) {
          const newItems = [...priority_data];
          [newItems[index - 1], newItems[index]] = [
            newItems[index],
            newItems[index - 1],
          ];
          p_prevalue = newItems;
        }
        return [...p_prevalue];
      });
      return { ...c_prevalue };
    });
  }

  function move_down() {
    SetCheckedUserData((c_prevalue: any) => {
      let checked_data = c_prevalue;
      SetUsersPriorityData((p_prevalue: any) => {
        let priority_data = p_prevalue;
        let index: any = priority_data.indexOf(checked_data?.full_mobile?checked_data?.full_mobile:checked_data.id);
        if (index < priority_data.length - 1) {
          const newItems = [...priority_data];
          [newItems[index], newItems[index + 1]] = [
            newItems[index + 1],
            newItems[index],
          ];
          p_prevalue = newItems;
        }
        return [...p_prevalue];
      });
      return { ...c_prevalue };
    });
  }

  async function re_arrange() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
      post: {
        priority: users_priority_data,
      },
    };
    console.log("re_arrange pass_data :", pass_data);
    let response: any = await api(
      "/brand/change_order_call_reminder_user_priority",
      pass_data
    );
    SetCheckedUserData({});
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("users")) {
        SetUserData(response.response.users);
      }
      if (response.response.hasOwnProperty("users_priority")) {
        SetUsersPriorityData(response.response.users_priority);
        SetOriginalUsersPriorityData(response.response.users_priority);
      }
    }
  }

  return (
    <div>
      <div>
        <div
          className={
            props.have_header
              ? OutletContext
                ? "head-bar sticky-sm"
                : "head-bar  sticky-sm"
              : "p-3 border-bottom bg-fff"
          }
          style={
            props.have_header == true
              ? OutletContext == false
                ? { width: width }
                : {}
              : null
          }
        >
          <div className="row align-items-center">
            <div className="col-md-8 mb-sm-2 mb-md-0 d-flex align-items-center px-md-2 px-sm-0">
              <div
                className="cursor"
                onClick={() => {
                  window.history.back(-1);
                }}
              >
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "3760dcf7e8daefc2196a2b9f357fbec1.svg"
                  }
                  className="type-img"
                />
              </div>
              <h6 className=" px-2 mb-0">Call Reminder</h6>
            </div>
            {/* buttons */}
            <div className="col-md-4 end px-0">
              {is_edit ? (
                <>
                  {Object.keys(checked_user_data).length > 0 ? (
                    <>
                      <button
                        className="btn btn-white btn-radius btn-sm me-2"
                        onClick={() => {
                          console.log("Up");
                          move_up();
                        }}
                      >
                        {/* Up */}
                        <img
                          src={AppConfig.CDN_Media_url+"97107c2e91a53a7d0f0f48fd5ef3ae72.svg"}
                          className="w-14px"
                        />
                      </button>
                      <button
                        className="btn btn-white btn-radius btn-sm me-2"
                        onClick={() => {
                          console.log("Down");
                          move_down();
                        }}
                      >
                        {/* Down */}
                        <img
                          src={AppConfig.CDN_Media_url+"2e7dca010109a098afb7bcc4a2f66f28.svg"}
                          className="w-14px"
                        />
                      </button>
                      <button
                        className="btn btn-white btn-radius btn-sm me-2"
                        onClick={() => {
                          console.log("Done");
                          re_arrange();
                        }}
                      >
                        {/* Done */}
                        <img
                          src={AppConfig.CDN_Media_url+"40ac44cb9f339de8e376dcd25cab620d.svg"}
                          className="w-14px"
                        />
                      </button>
                    </>
                  ) : null}
                  <button
                    className="btn btn-white btn-radius btn-sm"
                    onClick={() => {
                      SetIsEdit(false);
                      SetCheckedUserData({});
                      SetUsersPriorityData(original_users_priority_data);
                    }}
                  >
                    {/* Cancel */}
                    <img
                      src={AppConfig.CDN_Media_url+"69f47f352d55b9560c79f946fc6cdfd5.svg"}
                      className="w-14px"
                    />
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-primary btn-sm me-2"
                    onClick={() => {
                      console.log("Add");
                      SetAddUserModal((prevValue: any) => {
                        prevValue.is_open = true;
                        return { ...prevValue };
                      });
                    }}
                  >
                    Add User
                  </button>
                  <button
                    className="btn btn-primary btn-sm me-2"
                    onClick={() => {
                      console.log("Add");
                      SetAddUserNumberModal((prevValue: any) => {
                        prevValue.is_open = true;
                        return { ...prevValue };
                      });
                    }}
                  >
                    Add Mobile Number
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      console.log("Add");
                      SetIsEdit(true);
                    }}
                  >
                    Change Priority
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="mt-3 px-md-3 px-sm-0">
          <div className="px-0">
            {get_user_priority()?.map((data: any, o_index: any) => (
              <div className="row">
                <div className="col-md-6 col-lg-6 px-md-1 mb-3">
                  <div className="card px-3 py-2">
                    <label className="form-label mb-0">
                      <div className="row align-items-center">
                        {is_edit ? (
                          <div className="col-1 ps-0 pe-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={
                                (data?.full_mobile)?
                                checked_user_data.full_mobile == data?.full_mobile ? true : false
                                : checked_user_data.id == data?.id ? true : false
                              }
                              onChange={(event) => {
                                console.log("input on change event :", event);
                                if (event.target.checked) {
                                  SetCheckedUserData(data);
                                } else {
                                  SetCheckedUserData({});
                                }
                              }}
                            />
                          </div>
                        ) : null}
                        <div
                          className={
                            is_edit
                              ? "col-9 d-flex align-items-center ps-0"
                              : "col-10 d-flex align-items-center ps-0"
                          }
                        >
                          <div className="pe-2">
                            {data?.profile != null && data?.profile != "" ? (
                              <img
                                src={data.profile}
                                className="w-30px img-radius"
                              />
                            ) : (
                              <img
                                src={AppConfig.CDN_Image_url+"beb6e59fdba49c15dfd0fd4f2a341ca0.png"}
                                className="w-30px img-radius"
                              />
                            )}
                          </div>
                          <p className="pop-font fw-bold text-dark ">
                            {(data?.full_mobile != null)?data.full_mobile:data?.name}
                          </p>
                        </div>

                        <div className="col-2">
                          <button
                            className="btn btn-text btn-sm"
                            onClick={() => {
                              console.log("Delete");
                              delete_user(data);
                            }}
                          >
                            <img
                              src={AppConfig.CDN_Media_url+"42db3e197b6a62fd0e26f3e3ad362049.svg"}
                              className="w-14px"
                            />
                          </button>
                        </div>
                      </div>

                      {/* <div className="row p-0 align-items-center">
                      

                      <div className="col-8">
                        <div className="d-flex">
                          <Feather name="user" size={14} color="#777" />
                          <img src={data.profile} className="w-20px" />
                          <p className="ps-2 text-black fw-bold">
                            {data?.name}
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-2 cursor"
                        onClick={() => {
                          console.log("Delete");
                          delete_user(data);
                        }}
                      >
                        <Feather name="trash-2" size={14} color="#777" />
                      </div>
                    </div> */}
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <ModalCallReminderUser
        open={add_user_modal.is_open}
        reminder_user={user_data}
        users_priority_data={users_priority_data}
        close={(data: any) => {
          console.log("add_user_modal on close :", data);
          SetAddUserModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            if (data.value.hasOwnProperty("users")) {
              SetUserData(data.value.users);
            }
            if (data.value.hasOwnProperty("users_priority")) {
              SetUsersPriorityData(data.value.users_priority);
              SetOriginalUsersPriorityData(data.value.users_priority);
            }
          }
        }}
      />
      <ModalCallReminderUserMobile
        open={add_user_number_modal.is_open}
        reminder_user={user_data}
        users_priority_data={users_priority_data}
        close={(data: any) => {
          SetAddUserNumberModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            if (data.value.hasOwnProperty("users")) {
              SetUserData(data.value.users);
            }
            if (data.value.hasOwnProperty("users_priority")) {
              SetUsersPriorityData(data.value.users_priority);
              SetOriginalUsersPriorityData(data.value.users_priority);
            }
          }
        }}
      />
    </div>
  );
}
