import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from 'react-router-dom';
import { AppHelper } from "../../../utils/Helper";
import Skeleton from "react-loading-skeleton";

import { api } from "../../../utils/Api";
import {
  MaterialCommunityIcons,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
// import ModalVariants from "./ModalVariants";
import { Accordion, Typography, Slider, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { AppConfig } from "../../../config";


// function valuetext(value) {
//   return `${value}°C`;
// }

export default function AddProductsNew(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    // product_name: "",
    product_id: "",
    // product_checked: "",
    // data: {},
  });
  const [text, setText] = useState("");
  const [id_value, SerIdValue] = useState(useParams());
  const [filter_open, SetFilterOpen] = useState(false);
  const [product_data, SetProductData] = useState([]);
  const [data, SetData] = useState([]);
  const [variant, SetVariant] = useState([]);
  const [filter, SetFilter] = useState([]);
  const [timer, SetTimer] = useState(null);

  const [sort_open, SetSortOpen] = useState(false);
  const [min_max_value, SetMinMaxValue] = React.useState(["", ""]);
  const [brand, SetBrand] = useState([]);
  const [category, SetCategory] = useState([]);
  const [f_products, SetFproducts] = useState({
    own_products: false,
    others_products: false,
  });

  const [sort, SetSort] = useState("");
  const [count, SetCount] = useState(10);
  const [width, SetWidth] = useState(innerWidth);
  const [search, SetSearch] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [is_loading, SetIsLoading] = useState(true);

  const [next_page, SetNextPage] = useState(false);
  const WAIT_INTERVAL = 700;
  const PRICE_WAIT_INTERVAL = 2000;

  // const [sliderWidth, SetSlideWidth] = useState(0);
  // const [offsetSliderWidht, SetOffsetSlideWidth] = useState(0);
  // const [min, SetMin] = useState(0);
  // const [max, SetMax] = useState(200);
  // const [minValueBetween, SetMinValueBetween] = useState(10);
  // const [currentMin, SetCurrentMin] = useState(55);
  // const [inputMin, SetInputMin] = useState(55);
  // const [currentMax, SetCurrentMax] = useState(100);
  // const [inputMax, SetInputMax] = useState(100);

  useEffect(() => {
    console.log("Add Product On mount :", props);
    console.log("Add Product context :", context);
    console.log("ProductAssign OutletContext :", OutletContext);
    get_add_products(page_no, count, min_max_value, text, sort);
    console.log(f_products);
  }, []);

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const search_text = e.target.value;
    setText(search_text);
    console.log("Text onchange: ", search_text);
    if (search_text == "") {
      let page_no_value = 1;
      SetPageNo(page_no_value);
      get_add_products(page_no_value, count, min_max_value, search_text, sort);
    } else {
      SetTimer(
        setTimeout(() => {
          get_add_products(1, count, min_max_value, search_text, sort);
        }, WAIT_INTERVAL)
      );
    }
  }

  function countChange(e: any) {
    console.log("countChange:", e.target.value);
    SetCount(e.target.value);
    get_add_products(1, e.target.value, min_max_value);
  }

  const handleChange = (event: any, newValue: any) => {
    clearTimeout(timer);
    SetMinMaxValue(newValue);
    console.log(newValue);

    if (newValue != "") {
      SetTimer(
        setTimeout(() => {
          get_add_products(1, count, newValue, text, sort);
        }, PRICE_WAIT_INTERVAL)
      );
    }
    // SetMinMaxValue(newValue);

    // get_add_products(1,count);
  };

  async function get_add_products(
    page_value: any,
    count_value: any,
    f_min_max_value: any,
    search_value: any,
    sort_value: any,
  ) {
     SetIsLoading(true);
    let pass_data = {};

    SetPageNo(page_value);

    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,
          search: search_value,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: f_min_max_value[0],
          filter_max_mrp: f_min_max_value[1],
          sort: sort_value,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          filter_count: count_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: f_min_max_value[0],
          filter_max_mrp: f_min_max_value[1],
          sort: sort_value,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          filter_count: count_value,
        },
      };
    }
    let response: any = await api("/brand/brand_variant_list", pass_data);
    console.log("/brand/brand_variant_list :", response);
    if (response.status_code == 200) {
      SetData(response.response);

      if (response.response.hasOwnProperty("data")) {
        SetProductData(response.response.data);
        SetFilter(response.response.filter);
        SetNextPage(response.response.next_page);
      } else {
        SetProductData([]);
      }
     SetIsLoading(false);

    }
  }

  async function input_on_change(event) {
    console.log("input_on_change value", event.target.name);

    console.log("input_on_change value", event.target.checked);
    console.log("input_on_change value", f_products.others_products);

    let arr = [];

    if (event.target.checked == true) {
      if (event.target.name == "own products") {
        f_products.own_products = true;
      }
    } else {
      if (event.target.name == "own products") {
        f_products.own_products = false;
      }
    }

    if (event.target.checked == true) {
      if (event.target.name == "others products") {
        f_products.others_products = true;
      }
    } else {
      if (event.target.name == "others products") {
        f_products.others_products = false;
      }
    }

    if (event.target.checked == true) {
      if (event.target.name == "filter_brand") {
        brand.push(event.target.value);
      }
    } else {
      if (event.target.name == "filter_brand") {
        brand.splice(brand.indexOf(event.target.value, 1));
      }
    }

    console.log(brand);
    SetBrand(brand);

    if (event.target.checked == true) {
      if (event.target.name == "filter_category") {
        category.push(event.target.value);
      }
    } else {
      if (event.target.name == "filter_category") {
        category.splice(brand.indexOf(event.target.value, 1));
      }
    }

    get_add_products(1, count, min_max_value, text, sort);
  }

  function dataSort() {
    if (sort == "asc") {
      SetSort("desc");
      get_add_products(1, count, min_max_value, text, "desc");
    } else {
      SetSort("asc");

      get_add_products(1, count, min_max_value, text, "asc");
    }
  }

  async function add_products(id) {
    var arr = [];

    // console.log(element);

    arr.push(id);
    console.log(arr);

    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        product_data: arr,
      },
    };
    let response: any = await api("/brand/add_brand_variant", pass_data);
    console.log("/brand/add_brand_variant :", response);
    if (response.status_code == 200) {
      get_add_products(1, count, min_max_value, text, sort);
    }
  }


  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          search: text,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_products: encodeURIComponent(JSON.stringify(f_products)),

          filter_min_mrp: min_max_value[0],
          filter_max_mrp: min_max_value[1],
          sort: sort,
          filter_count: count,


        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_products: encodeURIComponent(JSON.stringify(f_products)),

          filter_min_mrp: min_max_value[0],
          filter_max_mrp: min_max_value[1],
          sort: sort,
          filter_count: count,


        },
      };
    }
    let response: any = await api("/brand/brand_variant_list", pass_data);
    console.log("/brand/add_brand_product_list :", response);
    SetProductData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  return (
    <div>
      <div className={OutletContext ? "head-bar sticky-sm" : "head-bar sticky-sm"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="">
          <div className="d-flex align-items-center">
            <Link to={"/brand/" + id_value.brand_id + "/products"}>
              <MaterialCommunityIcons name="arrow-left" size={20} color="black" />
            </Link>
            <h6 className="mb-0 ms-1">Add Products</h6>
          </div>
        </div>
      </div>

      <div className={OutletContext ? "px-md-3 px-sm-0" : "px-md-3 px-sm-0"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="row p-3">
          <div className="col-md-3 ps-0 d-sm-none d-md-block">
            <div className="bg-fff radius p-3 sticky-md-50px overflowY-auto scrollw-none">
              <div className="d-flex align-items-center">
                <h6 className='fs-5 fw-500'>Filter</h6>
                <div className="ms-auto cursor">
                  <small className='text-black'>Clear</small>
                </div>
              </div>
              <div className="">
                <div className="my-3">
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name={"own products"}
                      // value={ele.id}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                    <p className='capitalize'>{"own product"}</p>
                  </label>

                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name={"others products"}
                      // value={ele.id}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                    <p className='capitalize'>{"other product"}</p>
                  </label>
                </div>
                <div className="">
                  <h6>Category</h6>
                </div>
                <div className="my-3">
                  {filter.product_categories?.map((ele: any) => (
                    <label className="form-label d-flex align-items-center mb-3 cursor">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        name={"filter_category"}
                        value={ele.id}
                        onChange={(event) => {
                          input_on_change(event);
                        }}
                      />
                      <p className='capitalize'>{ele.name}</p>
                    </label>
                  ))}
                </div>

                <div className="brand">
                  <Accordion className='px-0'>
                    <AccordionSummary
                      expandIcon={<MaterialIcons name="keyboard-arrow-down" size={20} color="black" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='fs-7 fw-bold text-black'>Brand</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='w-100'>
                        <input
                          type="text"
                          placeholder="Search..."
                          className="form-control btn-radius"
                        />
                        <div className="p-2">
                          {filter.product_brands?.map((ele: any) => (
                            <label className="form-label mt-3 d-flex align-items-center">
                              <input
                                type="checkbox"
                                className="me-2 form-check-input"
                                name={"filter_brand"}
                                value={ele.id}
                                onChange={(event) => {
                                  input_on_change(event);
                                }}
                              />
                              {ele.name}
                            </label>
                          ))}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="mt-3">
                  <p className='text-black' id="range-slider">
                    Mininum Maximum Price
                  </p>
                  <div className="d-flex align-items-center">
                    {/* <p className="pe-3">Min</p> */}
                    <Slider
                      value={min_max_value}
                      onChange={handleChange}
                      step={20}

                      onClick={() => {
                        console.log("min max :", min_max_value);
                      }}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      // getAriaValueText={valuetext}
                      min={10}
                      max={1000}
                    />
                    {/* <p className="px-3">Max</p> */}
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <label className="form-label">Min Price</label>
                      <input
                        type="text"
                        className="form-control"
                        name={"min_mrp"}
                        value={min_max_value[0]}
                        onChange={(event) => {
                          input_on_change(event);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-label">Max Price</label>
                      <input
                        type="text"
                        className="form-control"
                        name={"max_mrp"}
                        value={min_max_value[1]}
                        onChange={(event) => {
                          input_on_change(event);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 px-sm-0 px-md-2 bg-fff">
            <div className="row bg-fff align-items-center p-3 border-bottom">
              <div className="col-md-9 col-7 px-md-2">
                <div className="row px-md-2 md-none">
                  <div className="input-icons form-group">
                    <input
                      type="text"
                      className="form-control search-bar p-0"
                      placeholder="Search Products"
                      name="search"
                      defaultValue={text}
                      onChange={(e) => {
                        onTextChanged(e);
                      }}
                    />
                    <div className="cursor ps-2 border-left">
                    <img src={AppConfig.CDN_Media_url+"c241785c497fb98f5f7d4e587b3857ba.svg"} className="w-20px" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-5 text-end d-flex ps-0">
                <div className="col-6 px-1">
                  <select
                    className="number-select form-select btn-radius form-control pe-0 ps-2"
                    defaultValue="10"
                    onChange={(e) => {
                      countChange(e);
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>

                    <option value="40">40</option>

                    <option value="50">50</option>
                  </select>
                </div>

                <div className="end">
                  <div
                    className="px-2 end cursor"
                    onClick={() => {
                      dataSort()
                    }}
                  >
                    <img src={AppConfig.CDN_Media_url+"1b59418519237ad4271be18c3bc9011f.svg"} className="w-16px" />
                    <p className='text-darkgray ps-2 fw-bold'>Sort</p>
                  </div>
                </div>
                {/* <Link to={"/brand/" + id_value.brand_id + "/product_assign"}>
                    <button className="btn btn-blue-outline btn-sm ms-2">Next</button>
                  </Link> */}
              </div>
            </div>

            <div className="row pt-3 ">
              {/* {console.log(product_data)} */}
              {/* {product_data.length == 0 ? (
                <div className="center p-3 bg-fff">
                  <div>
                  <img src={AppConfig.CDN_Image_url+"e38cbe608dba40c81ec58d266c09055f.png"} className="empty-img"
                  />
                    <p className="text-center">No data found!</p>
                  </div>
                </div>
              ) : */}
               {is_loading ? ( [...Array(6)].map((_, index) => (
    <div key={index} className="col-md-6 px-2 mb-3">
      <div className="p-3 card radius">
        <div className="row">
          <div className="col-3 pe-0 ps-1">
            <Skeleton height={100} width={100} />
          </div>
          <div className="col-9">
            <p className="mb-1">
              <Skeleton width={`80%`} />
            </p>
            <div className="d-flex align-items-center">
              <Skeleton width={16} height={16} />
              <p className="ps-1">
                <Skeleton width={100} />
              </p>
            </div>
            <div className="mt-2">
              <Skeleton width={80} height={30} />
            </div>
          </div>
        </div>
        <p className="text-gray small fw-500">
          <Skeleton count={2} />
        </p>
      </div>
    </div>
  ))): product_data.length>0?
                (product_data.map((ele: any) => (
                  <div className="col-md-6 px-2 mb-3">
                    <div className="p-3 card radius ">
                      <div className="row">
                        <div className="col-3 pe-0 ps-1">
                          {ele.images ? (
                            <img

                              src={AppHelper.imageSrc(data.image_base_url, ele.images[0]?.url, { format: "webp", width: 100 })}

                              className="addimg-prods"
                            />
                          ) : (
                            <img
                            src={AppConfig.CDN_Image_url+"33c67a26ab58ada3ee31b91f7efb32bb.png"}
                              // "https://cpimg.tistatic.com/06747380/b/4/Used-Iphone-12.jpg"

                              alt="No Image"
                              className="addimg-prods"
                            />
                          )}
                        </div>
                        <div className="col-9">
                          <p className="mb-1 text-black fw-bold">{ele.name}</p>
                          <div className="d-flex align-items-center">
                            <MaterialIcons name="category" size={14} color="gray" />
                            <p className='ps-1'><small className="text-gray fw-500">
                              {ele.category}
                            </small></p>
                          </div>
                          <div className="mt-2">
                            <button
                              className="btn bg-prime text-white radius btn-sm fw-bold px-4 v-small"
                              onClick={() => {
                                console.log("add variants");
                                // SetModalVariants((prevValue: any) => {
                                // SetModalVariants({
                                //   // prevValue.is_open = true;
                                //   is_open: true,
                                //   // product_name: ele.name,
                                //   product_id: ele.id,
                                //   // product_checked: ele.checked,

                                //   // data: ele.variants,

                                //   // return { ...prevValue }
                                // });
                                add_products(ele.id);
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                        {/* <div className="col-3 text-end">
                        
                      </div> */}
                      </div>
                      <p className="text-gray small fw-500">{ele.description}</p>
                      {/* <div className="row">
                      <div className="col-3">
                        {ele.images ? (
                          <img
                            src={
                              data.image_base_url +
                              data.sizes[3] +
                              "/" +
                              ele.images[0].url
                            }
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={require('../../../assets/img/noimage.png')}
                            alt="pic"
                            className="img-fluid"
                          />
                        )}
                        <img
                          // src={require(ele.images[0].url)}
                          className="w-100"
                        />
                      </div>
                      <div className="col-9">
                        <small className="disc-overflow">
                          {ele.description}
                        </small>
                      </div>
                    </div> */}
                      {/* <div className="row pt-3  border-bottom">
                      <p> <small className="text-gray disc-overflow fw-500">
                        {ele.description}
                      </small></p>
                    </div>
                    {/* <div className="row pt-3  border-bottom">
                        <div className="col-6">
                          <p>
                            Variants :{" "}
                            <span className="text-dark">
                              {ele.total_variants +
                                "(" +
                                ele.total_active_variants +
                                ")"}
                            </span>{" "}
                          </p>
                        </div>
                        <div className="col-6">
                          <p>
                            Addons :{" "}
                            <span className="text-dark">
                              {ele.total_addon_groups +
                                "(" +
                                ele.total_active_addon_groups +
                                ")"}
                            </span>
                          </p>
                        </div>
                        <div className="col-6">
                          <p>
                            Addon Items :{" "}
                            <span className="text-dark">
                              {ele.total_addon_items +
                                "(" +
                                ele.total_active_addon_items +
                                ")"}
                            </span>
                          </p>
                        </div>
                        <div className="col-6">
                          <p>
                            Brand :{" "}
                            <span className="text-dark">{ele.product_brand}</span>
                          </p>
                        </div>
                        <div className="col-6">
                          <p>
                            Category :{" "}
                            <span className="text-dark">{ele.category}</span>
                          </p>
                        </div>
                        <div className="col-12">
                          <p>
                            MRP :{" "}
                            <span className="text-dark">
                              ₹{ele.min_mrp} - ₹{ele.max_mrp}{" "}
                            </span>
                          </p>
                        </div>
                      </div> */}

                      {/* <div className="row pt-3  border-bottom">
                      <div className="col-6">
                        <p>
                          Category :{" "}
                          <span className="text-dark">{ele.category}</span>{" "}
                        </p>
                      </div>
                    </div> */}
                    </div>

                  </div>
                ))) :  
                (  <div className="center p-3 bg-fff">
                <div>
                <img src={AppConfig.CDN_Image_url+"e38cbe608dba40c81ec58d266c09055f.png"} className="empty-img"
                />
                  <p className="text-center">No data found!</p>
                </div>
              </div>
                )
              }
            </div>

            {next_page ? (
              <div className="my-3">
                <p
                  className="text-prime fw-bold text-center cursor"
                  onClick={() => {
                    load_more();
                  }}
                >
                  Load More
                </p >
              </div>
            ) : null
            }
          </div>
        </div >




      </div >

      {/* <ModalVariants
                open={modal_variants.is_open}
                // product={modal_variants.product_name}
                id={modal_variants.product_id}
                // checked={modal_variants.product_checked}
                // data={modal_variants.data}
                close={(data: any) => {
                  console.log("Variants modl on close :", data);
                  // SetModalVariants((prevValue: any) => {
                  //     prevValue.is_open = false;
                  //     return { ...prevValue }
                  // })
                  SetModalVariants({
                    is_open: false,
                    // product_name: "",
                    product_id: "",
                    // product_checked: "",

                    // return { ...prevValue }
                    // data: {},
                  });

                  if (data.action == "add") {
                    get_add_products(1,count,min_max_value);
                  }
                }}
              /> */}
    </div >
  )
}
