import React, { useState, useEffect } from "react";

const DataContext = React.createContext();

function DataProvider(props) {
  const [sample_data, SetSampleData] = useState("");
  let html: any = document.querySelector("html"), body: any = document.querySelector("body");
  const [app_data, SetAppData]:any = useState({
    application: {  },
   
    brand_id: "",
    outlet_id: "",
    user_data: {},
    // selected_language: { label: "English", value: "en" },
    selected_language: {},
    language_data: {},
    language_list: [],
    country_data: [],
    
    notification: false,

    selected_account_data: { name: "" },
    scroll_locked: 0,
    current_brand: {},
    current_outlet: {},
    current_franchise: {},
    pages: {},
  });

  useEffect(() => {
    console.log("DataProvider On mount :");
  }, []);

  useEffect(() => {
    if (props.selected_language != {} && props.language_data != {}) {
      console.log("DataProvider On props change :");
      SetAppData((prevValue) => {
        prevValue.selected_language = props.selected_language;
        prevValue.language_data = props.language_data;
        prevValue.language_list = props.language_list;
        return { ...prevValue };
      });
    }
    if (props.application) {
      SetAppData((prevValue) => {
        prevValue.application = props.application;
        return { ...prevValue };
      });
    }
  }, [props]);

  useEffect(() => {
    // console.log('app_data.scroll_locked', app_data.scroll_locked);
    
    if(app_data.scroll_locked > 0){
      html.style.overflowY = "hidden";
      html.style.overscrollBehaviorY = "none";
      body.style.overflowY = "hidden";
      body.style.overscrollBehaviorY = "none";
    } else {
      html.style.overflowY = "";
      html.style.overscrollBehaviorY = "";
      body.style.overflowY = "";
      body.style.overscrollBehaviorY = "";
    }
  }, [app_data?.scroll_locked]);


  function t(value) {
    if (app_data.language_data.hasOwnProperty(value)) {
      return app_data.language_data[value];
    } else {
      return value;
    }
  }

  return (
    <DataContext.Provider
      value={{
        app_data,
        SetAppData,
        t,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}
export { DataProvider };

// export const DataProvider = DataContext.Provider;
export const DataConsumer = DataContext.Consumer;

export default DataContext;
