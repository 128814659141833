"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
var _exportNames = {
  useClassNames: true,
  inBrowserEnv: true,
  useInsertStyles: true
};
exports.useInsertStyles = exports.inBrowserEnv = exports.useClassNames = void 0;

var _prefix = require("./prefix");

Object.keys(_prefix).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _prefix[key]) return;
  exports[key] = _prefix[key];
});

var _useClassNames = _interopRequireDefault(require("./useClassNames"));

exports.useClassNames = _useClassNames["default"];

var _inBrowserEnv = _interopRequireDefault(require("./inBrowserEnv"));

exports.inBrowserEnv = _inBrowserEnv["default"];

var _useInsertStyles = _interopRequireDefault(require("./useInsertStyles"));

exports.useInsertStyles = _useInsertStyles["default"];