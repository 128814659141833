import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../../utils/Api";
import ModalDeleteUserRole from "./ModalDeleteUserRole";
import ShortNotification from "../../../components/Toast/ShortNotification";
// import Franchise from './../Franchise/Franchise';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';



export default function FranchiseUserRole(props: any) {
  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });



  const [openToast, SetOpenToast] = useState(false);
  const [toastData, SetToastData] = useState({ message: "", style: "" });

  const [id_value, SerIdValue] = useState(useParams());
  const [brand_active_roles, SetBrandActiveRoles] = useState([]);
  const [franchies_active_roles, SetFranchiesActiveRoles] = useState([]);
  const [outlet_active_roles, SetOutletActiveRoles] = useState([]);

  useEffect(() => {
    console.log("AdminUserRoles On mount :", props);
    get_custom_roles();
  }, []);

  function ModalOnClose(data : any) {
    SetopenModal({
      is_open: false,
      data: {},
    });
    // SetToastData({ message: "User Role Deleted", style: "" });
    // SetOpenToast(true);
    if (data.action == "delete") {
      console.log("on confirm delete :", data.value);
      delete_role (
        data.value.element,
        data.value.index,
        data.value.type,
        data.value.status
      );
    }
  }

  function ToastOnClose(data : any) {
    SetOpenToast(false);
  }
  //
  

  async function get_custom_roles() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        franchise : id_value.franchise_id,
      },
    };
    let data : any = await api("/brand/get_custom_roles", pass_data);
    console.log("get_custom_roles response :", data);
    if (data.status_code == 200) {
      data.response.roles.map((role_element : any, role_index : any) => {
        if (role_element.name == "Brand") {
          if (role_element.hasOwnProperty("roles")) {
            SetBrandActiveRoles(role_element.roles);
          } else {
            SetBrandActiveRoles([]);
          }
        } else if (role_element.name == "Outlet") {
          if (role_element.hasOwnProperty("roles")) {
            SetOutletActiveRoles(role_element.roles);
          } else {
            SetOutletActiveRoles([]);
          }
        } else if (role_element.name == "Franchise") {
          if (role_element.hasOwnProperty("roles")) {
            SetFranchiesActiveRoles(role_element.roles);
          } else {
            SetFranchiesActiveRoles([]);
          }
        }
      });
    }
  }

  // function view_role(ele, index, type, status) {
  //   console.log("View Role");
  //   console.log("View Role ele :", ele);
  //   console.log("View Role index :", index);
  //   console.log("View Role type :", type);
  //   console.log("View Role status :", status);
  // }
  // function edit_role(ele, index, type, status) {
  //   console.log("Edit Role");
  //   console.log("Edit Role ele :", ele);
  //   console.log("Edit Role index :", index);
  //   console.log("Edit Role type :", type);
  //   console.log("Edit Role status :", status);
  // }
  // async function delete_role(ele, index, type, status) {
  //   console.log("Delete Role");
  //   let pass_data = {
  //     get: {
  //       brand: id_value.brand_id,
  //     },
  //     post: {
  //       role_id: ele.id,
  //     },
  //   };
  //   let data = await api("/brand/delete_custom_user_role", pass_data);
  //   console.log("delete_custom_user_role response :", data);
  //   if (data.status_code == 200) {
  //     get_custom_roles();
  //   }
  // }

  return (
    <div>
      <div className="bg-fff py-3 px-4">
        <h6 className="mb-1 text-dark fw-bolder">User Roles</h6>
        <p className="mb-0 v-small">Brands & Outlets</p>
      </div>
      <div className="container-fuild mt-3 p-3">
        <div className="row">
          <div className="col-lg-4 mb-3">
            <div className="bg-fff box-3 p-3 br-3">
              <div className="d-flex align-items-center mb-3">
                <h6 className="mb-0">Brand User Roles</h6>
                <Link
                  to="add/brand/new"
                  className="btn btn-sm btn-outline-light text-primary border-0 d-flex align-items-center cursor py-1 px-2 ms-auto"
                >
                  <i className="fas fa-plus" ></i>
                </Link>
              </div>
              <div>

                {brand_active_roles.length > 0 ? (
                  brand_active_roles.map((ele: any, index: any) => (
                    
                    <div className="p-2 row list border-bottom">
                      <div className="col-6">
                        <div className="mb-1">{ele.name}</div>
                      </div>
                      <div className="col-6 d-flex justify-content-end">
                        <Link
                          className="cursor"
                          to={
                            "/brand/" +
                            id_value.brand_id +
                            "/userrole/view/brand/" +
                            ele.id
                          }
                        >
                          <img
                            src={require("../../../assets/icon/svg/eye.svg")}
                            className="action_menu_img cursor me-3"
                          />
                        </Link>

                        <Link
                          className="cursor"
                          to={
                            "/brand/" +
                            id_value.brand_id +
                            "/userrole/edit/brand/" +
                            ele.id
                          }
                        >
                          <img
                            src={require("../../../assets/icon/svg/edit1.svg")}
                            className="action_menu_img cursor me-3"
                          />

                        </Link>

                        <div
                          className="cursor"
                          onClick={() => {
                            SetopenModal({
                              is_open: true,
                              data: {
                                element: ele,
                                index: index,
                                type: "brand",
                                status: "active",
                              },
                            });
                          }}
                        >

                          <i className="fas fa-trash action_menu_img cursor "></i>

                        </div>
                      </div>
                    </div>
                   
                  
                  ))

                ) : (
                  <div className="text-center">
                    <img src={require("../../../assets/icon/empty.png")} className="w-30 pb-2" />
                    <p className="v-small mb-0">No Data, Please Add Some Roles</p>
                  </div>
                )}
              </div>
            </div>
          </div>


          <div className="col-lg-4 mb-3">
            <div className="bg-fff  box-3 p-3 br-3">
              <div className="d-flex align-items-center mb-3">
                <h6 className="mb-0">Franchise User Roles</h6>
                <Link
                  to="add/franchise/new"
                  className="btn btn-sm btn-outline-light text-primary border-0 d-flex align-items-center cursor py-1 px-2 ms-auto"
                >
                  <i className="fas fa-plus" ></i>
                </Link>
              </div>

              <div>
                {brand_active_roles.length > 0 ? (
                 
                 franchies_active_roles.map((ele: any, index: any) => (
                  <div className="p-2 row list border-bottom">

                    <div className="col-6">
                      <div className="mb-1">{ele.name}</div>
                    </div>

                    <div className="col-6 d-flex justify-content-end">
                      <Link
                        className="cursor"
                        to={
                          "/brand/" +
                          id_value.brand_id +
                          "/userrole/view/franchise/" +
                          ele.id
                        }
                      >
                        <img
                          src={require("../../../assets/icon/svg/eye.svg")}
                          className="action_menu_img cursor me-3"
                        />
                      </Link>

                      <Link
                        className="cursor"
                        to={
                          "/brand/" +
                          id_value.brand_id +
                          "/userrole/edit/franchise/" +
                          ele.id
                        }
                      >
                        <img
                          src={require("../../../assets/icon/svg/edit1.svg")}
                          className="action_menu_img cursor me-3"
                        />
                      </Link>

                      <div
                        className="cursor"
                        onClick={() => {
                          SetopenModal({
                            is_open: true,
                            data: {
                              element: ele,
                              index: index,
                              type: "franchise",
                              status: "active",
                            },
                          });
                        }}
                      >

                        <i className="fas fa-trash action_menu_img cursor "></i>

                      </div>
                    </div>
                  </div>
                ))
                 

                ) : (
                  <div className="text-center">
                    <img src={require("../../../assets/icon/empty.png")} className="w-30 pb-2" />
                    <p className="v-small mb-0">No Data, Please Add Some Roles</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-3">
            <div className="bg-fff box-3 p-3 br-3">
              <div className="d-flex align-items-center mb-3">
                <h6 className="mb-0">Outlet User Roles</h6>
                <Link
                  to="add/outlet/new"
                  className="btn btn-sm btn-outline-light text-primary border-0 d-flex align-items-center cursor py-1 px-2 ms-auto"
                >
                  <i className="fas fa-plus" ></i>
                </Link>
              </div>

              <div>
                {outlet_active_roles.length > 0 ? (
                  outlet_active_roles.map((ele: any, index: any) => (
                    <div className="p-2 row list border-bottom">
                      <div className="col-6">
                        <div className="mb-1">{ele.name}</div>
                      </div>
                      <div className="col-6 d-flex justify-content-end">
                        <Link
                          className="cursor"
                          to={
                            "/brand/" +
                            id_value.brand_id +
                            "/userrole/view/outlet/" +
                            ele.id
                          }
                        >
                          <img
                            src={require("../../../assets/icon/svg/eye.svg")}
                            className="action_menu_img cursor me-3"
                          />
                        </Link>

                        <Link
                          className="cursor"
                          to={
                            "/brand/" +
                            id_value.brand_id +
                            "/userrole/edit/outlet/" +
                            ele.id
                          }
                        >
                          <img
                            src={require("../../../assets/icon/svg/edit1.svg")}
                            className="action_menu_img cursor me-3"
                          />

                        </Link>

                        <div
                          className="cursor"
                          onClick={() => {
                            SetopenModal({
                              is_open: true,
                              data: {
                                element: ele,
                                index: index,
                                type: "outlet",
                                status: "active",
                              },
                            });
                          }}
                        >

                          <i className="fas fa-trash action_menu_img cursor "></i>

                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    <img src={require("../../../assets/icon/empty.png")} className="w-30 pb-2" />
                    <p className="v-small mb-0">No Data, Please Add Some Roles</p>
                  </div>
                )}
              </div>
            </div>
          </div>


          <ModalDeleteUserRole
            open={openModal.is_open}
            data={openModal.data}
            close={ModalOnClose}
          />
          <ShortNotification
            open={openToast}
            close={ToastOnClose}
            data={toastData}
          />
        </div>
      </div>
    </div>
  );
}
