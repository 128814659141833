import React, { useState, useEffect } from "react";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
// import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import { useParams } from "react-router-dom";

let myModal = {};

const userRoles = [
    { value: "admin", label: "Admin" },
    { value: "admin1", label: "Admin1" },
    { value: "admin2", label: "Admin2" },
]

export default function ModalCreateChannels(props: any) {
    const [close_data, SetCloseData] = useState({
        action: "close",
    });
    const [id_value, SerIdValue] = useState(useParams());
    const [userOpen, setUserOpen] = useState(false);

    useEffect(() => {
        if (props.open) {
            console.log("ModalCreateChannels On Open :", props);
            myModal = new bootstrap.Modal(
                document.getElementById("ChannelsModal"),
                {}
            );
            myModal.show();
        }
    });

    useEffect(() => {
        console.log("ModalCreateChannels On mount :", props);
        let myModal1 = document.getElementById("ChannelsModal");
        myModal1.addEventListener("hidden.bs.modal", function (event) {
            props.close(close_data);
        });
    }, []);



    return (
        <div className="modal" id="ChannelsModal" tabIndex={-1} aria-labelledby="ModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-fullscreen-sm-down">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="  modal-title" id="ModalLabel">
                            {props.type == "create" ? (
                                "Create Channels"
                            ) : (
                                props.type == "edit" ? (
                                    "Edit Channels"
                                ) : (
                                    props.type == "outlet" ? (
                                        "Outlet Channels"
                                    ) : (null)
                                )
                            )}
                        </h6>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                                let item = close_data;
                                item.action = "close";
                                SetCloseData(item);
                                myModal.hide();
                            }}
                        ></button>
                    </div>


                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-md-6 mt-2">
                                <label htmlFor="exampleFormControlInput1" className="form-label">User Name</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="User Name" />
                            </div>
                            <div className="col-12 col-md-6 mt-2">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Select Role</label>
                                <Select className="basic-multi-select" classNamePrefix="select Role" id="selected_roles" placeholder="Select Role" />
                            </div>
                            <div className="col-12 col-md-6 mt-2">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Custom Role</label>
                                <Select className="basic-multi-select" classNamePrefix="select Role" id="selected_roles" placeholder="Custom Role" />
                            </div>
                            <div className="col-12 col-md-6 mt-2">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Outlet Name</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Outlet Name" />
                            </div>
                            <div className="col-12 col-md-6 mt-2">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Address</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1"></textarea>
                            </div>
                            <div className="col-12 col-md-6 mt-2">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Mobile Number</label>
                                <input type="Number" className="form-control" id="exampleFormControlInput1" placeholder="Mobile Number" />
                            </div>
                            <div className="col-12 col-md-6 mt-2">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Whatsapp Number</label>
                                <input type="Number" className="form-control" id="exampleFormControlInput1" placeholder="Whatsapp Number" />
                            </div>
                            <div className="col-12 col-md-6 mt-2">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Email address</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="@example.com" />
                            </div>
                            <div className="col-12 col-md-6 mt-2">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Website</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Website" />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary-outline btn-radius btn-sm px-3"
                            onClick={() => {
                                let item = close_data;
                                item.action = "close";
                                SetCloseData(item);
                                // SetSaveData(initial_value);
                                myModal.hide();
                            }}
                        >
                            Close
                        </button>
                        <div className="ms-3">
                            <button
                                type="button"
                                className="btn bg-prime text-white btn-radius btn-sm px-3"
                                onClick={() => {
                                    console.log("save");
                                    // props.close();
                                    // SetSaveData(initial_value);
                                    myModal.hide();
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
