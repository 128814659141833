import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import ModalCreateFranchise from "./ModalCreateFranchise";
import Skeleton from "react-loading-skeleton";

import {
  Entypo,
  Feather,
  FontAwesome,
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
  SimpleLineIcons,
} from "@expo/vector-icons";
import toast from "react-hot-toast";
import { AppConfig } from "../../../config";

export default function Franchise(props: any) {
  const context = useContext(DataContext);
  const [modal_franchise, SetModalFranchise] = useState({
    is_open: false,
    data: "",
    type: "",
  });
  const [fran_data, SetFranData] = useState([]);

  const [grid_view, SetGridView] = useState(false);
  const [width, SetWidth] = useState(innerWidth);
  const [list_view, SetListView] = useState(true);
  const [id_value, SerIdValue] = useState(useParams());
  const OutletContext = useOutletContext();
  const [is_loading, SetIsLoading] = useState(false);

  

  useEffect(() => {
    console.log("Franchise On mount :", props);
    console.log("Franchise context :", context);
    console.log("ProductAssign OutletContext :", OutletContext);
    get_frans();
  }, []);

  async function get_frans() {
    SetIsLoading(true)
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let response: any = await api("/brand/franchise", pass_data);
    console.log("/brand/franchise response :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("franchise")) {
        SetFranData(response.response.franchise);
        SetIsLoading(false)
      } else {
        SetFranData([]);
      }
    }
  }

  async function delete_fran(data: any, index: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        franchise_id: data.id,
      },
    };
    let response: any = await api("/brand/delete_franchise", pass_data);
    console.log("/brand/delete_fran response :", response);
    if (response.status_code == 200) {
      toast.success("Franchise Deleted Successfully");
      let old_fran_data = fran_data;
      old_fran_data.splice(index, 1);
      SetFranData([...old_fran_data]);
      // get_fran();
    }
  }

  return (
    <div className="mb-5">
      <div className="sticky-sm sticky-md-50px row align-items-center head-bar">
        <div className="col-md-4 col-7 sm-mb-2">
          <h6 className="mb-0 ">Franchise</h6>
        </div>
        <div className="col-5 ps-0 d-sm-block d-md-none text-end sm-mb-2">
          <button
            className="btn bg-prime text-white btn-radius btn-sm fw-bold px-3"
            onClick={() => {
              console.log("Franchise new");
              SetModalFranchise((prevValue) => {
                prevValue.is_open = true;
                prevValue.type = "create";
                return { ...prevValue };
              });
            }}
          >
            Create New
          </button>
        </div>

        <div className="col-md-5 col-10 ">
          <input
            type="text"
            placeholder="Search..."
            className="form-control btn-radius"
          />
        </div>
        <div className="text-center col-md-1 col-2 p-0">
          <select className="form-control btn-radius" defaultValue="10">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        <div className="d-sm-none d-md-block text-center col-md-2">
          <button
            className="btn bg-prime text-white btn-radius fw-bold btn-sm px-3"
            onClick={() => {
              console.log("Franchise new");
              SetModalFranchise((prevValue: any) => {
                prevValue.is_open = true;
                prevValue.type = "create";
                return { ...prevValue };
              });
            }}
          >
            Create New
          </button>
        </div>
      </div>

      <div
        className={
          OutletContext ? "px-md-3 px-sm-0" : "table-headpage50 px-md-3 px-sm-0"
        }
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="pt-3 row px-2">
          {is_loading? Array.from({ length: 8 }).map((_, i) => (
        <div key={i} className="col-md-4 col-lg-3 col-6 mb-3">
          <div className="card p-3 text-center">
            <Skeleton circle={true} height={60} width={60} className="franchise-user-img" />
            <div className="mt-2">
              <h6 className="mb-1 oneline">
                <Skeleton width={100} />
              </h6>
              <p className="v-small oneline">
                <Skeleton width={80} />
              </p>
            </div>
          </div>
        </div>
      ))
          :fran_data.length > 0 ? (
            fran_data.map((iteminfo: any, f_index: any) => (
              <div className="col-md-4 col-lg-3 col-6 mb-3">
                <div className="">
                  <div className="card p-3 text-center">
                    <Link
                      to={
                        "/brand/" +
                        id_value.brand_id +
                        "/viewfranchise/" +
                        iteminfo.id +
                        "/details"
                      }
                      key={f_index}
                    >
                      {iteminfo.dp_url != null ? (
                        <img
                          src={iteminfo.dp_url}
                          className="franchise-user-img"
                        />
                      ) : (
                        <img
                          src={AppConfig.CDN_Image_url+"78a9cfd4dc78df18e9f3488646798286.png"}
                          className="franchise-user-img"
                        />
                      )}
                      <div className="mt-2">
                        <h6 className="mb-1 oneline">{iteminfo.name}</h6>
                        <p className="v-small oneline">@{iteminfo.user_name}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="bg-fff text-center p-3 ">
              <img
                 src={
                  AppConfig.CDN_Image_url +
                  "e38cbe608dba40c81ec58d266c09055f.png"
                }
                className="empty-img pb-2"
              />
              <p className="v-small mb-0">No Data, Please Add Franchise</p>
            </div>
          )}
        </div>
        {/* ---------------------------------------------------------------------------(Grid View) */}

        {grid_view ? (
          <div className="mt-4">
            <div className="row">
              {is_loading ?
            Array.from({ length: 6 }).map((_, i) => (
        <div key={i} className="col-md-4 col-sm-6 col-12">
          <div className="card p-3 mt-4">
            <div className="col-md-4">
              <Skeleton circle={true} height={60} width={60} />
            </div>
            <h6 className="mb-1">
              <Skeleton width={100} />
            </h6>
            <small>
              <Skeleton width={80} />
            </small>
            <p className="my-2">
              <Skeleton count={2} />
            </p>
            <small>
              <Skeleton width={120} />
            </small>
            <small>
              <Skeleton width={120} />
            </small>
          </div>
        </div>
      )) :
              fran_data.length > 0 ? (
                fran_data.map((itemdata: any) => (
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link to={"/brand/" + id_value.brand_id + "/viewfranchise"}>
                      <div className="card p-3 mt-4">
                        <div className="col-md-4">
                          {itemdata.dp_url != null ? (
                            <img
                              src={itemdata.dp_url}
                              className="br-100"
                              width="60"
                              alt="..."
                            />
                          ) : (
                            <img
                            src={AppConfig.CDN_Image_url+"78a9cfd4dc78df18e9f3488646798286.png"}
                              className="br-100"
                              width="60"
                              alt="..."
                            />
                          )}
                        </div>

                        <h6 className="mb-1">{itemdata.name}</h6>
                        <small>{`@${itemdata.user_name}`}</small>
                        <p className="my-2">{itemdata.address}</p>
                        <small>{`Mobile : ${itemdata.mobile}`}</small>
                        <small>{`Whatsapp : ${itemdata.whatsapp}`}</small>
                        {/* <p className="mb-0">9638527410</p> */}
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  {/* <img
                    src={require("../../../assets/icon/empty.png")}
                    className="w-30 pb-2"
                  /> */}
                  <p className="v-small mb-0">No Data, Please Add Users</p>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>

      <ModalCreateFranchise
        open={modal_franchise.is_open}
        type={modal_franchise.type}
        close={(data: any) => {
          console.log("Franchise modl on close :", data);
          SetModalFranchise((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            toast.success("New Franchise Created Successfully");
            get_frans();
          }
        }}
      />
    </div>
  );
}
