// import React, { useState, useEffect } from "react";
// import bootstrap from "bootstrap/dist/js/bootstrap";
// import { Link, useParams } from "react-router-dom";
// import { api } from "../../../utils/Api";




// export default function ModalDeleteShipment(props) {
//   const [close_data, SetCloseData] = useState({
//     action: "close",
//   });
//   let myModal : any;

  
//   const [id_value, SerIdValue] = useState(useParams());
//   const [delivery_id, SetDeliveryId] = useState("");


//   useEffect(() => {
//     console.log("ModelDeleteUserRoles On mount :", props);
//     let myModal1 = document.getElementById("CancelShipModal");
//     myModal1.addEventListener("hidden.bs.modal", function (event) {
//       console.log("event :", event);
//       props.close(close_data);
//     });
//   }, []);

//   useEffect(() => {
//     if (props.open) {
//       console.log("ModelDeleteUserRoles On props.open :", props);

//       SetDeliveryId(props.data.id)

//        myModal = new bootstrap.Modal(
//         document.getElementById("CancelShipModal"),
//         {}
//       );
//       myModal.show();
//     }
//   }, [props.open]);

//   async function delete_shipment() {
//     // props.close();

//     let pass_data = {
//       get: {
//         brand: id_value.brand_id,
//         outlet: id_value.outlet_id,
//         delivery_id: delivery_id,
//       },
//     }
//     let data_res = await api("/outlet/cancel_delivery", pass_data);
//     console.log("/outlet/cancel_delivery :", data_res.response);
//     if (data_res.status_code == 200) {
//       // let item = close_data;
//       // SetStatus(true);
//       // item.action = "edit";
//       // item.value = data_res.response.orders[0];
//       // item.index = props.data.index;
//       // console.log("Close data : ", item);
//       // SetCloseData(item);
//       // SetFranchiseData(franchise_initial_value);
//       props.close(close_data);
//       // console.log("gfrhjgf")
//       // props.close();
//     }
//   }

//   return (
//     <div
//       className="modal fade"
//       id="CancelShipModal"
//       tabIndex={-1}
//       aria-labelledby="ModalLabel"
//       aria-hidden="true"
//     >
//       <div className="modal-dialog">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h6 className="  modal-title" id="ModalLabel">
//               Delete Shipment
//             </h6>
//             <button
//               type="button"
//               className="btn-close"
//               data-bs-dismiss="modal"
//               aria-label="Close"
//             ></button>
//           </div>
//           <div className="modal-body">Are you sure want to delete Shipment?</div>
//           <div className="modal-footer">
//             <button
//               type="button"
//               className="btn btn-danger-outline"
//               onClick={delete_shipment}
//             >
//               Delete
//             </button>
//             <button
//               type="button"
//               className="btn btn-light-outline"
//               data-bs-dismiss="modal"
//             >
//               Cancel
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }















import React, { useState, useEffect } from "react";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap.min.js";

import { Link, useParams } from "react-router-dom";
import { api } from "../../../utils/Api";



let myModal : any;
export default function ModalDeleteShipment(props) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
 

  const [id_value, SerIdValue] = useState(useParams());
  const [delivery_id, SetDeliveryId] = useState("");
  const [status, SetStatus] = useState(false);

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("DeleteShipModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelDeleteUserRoles On props.open :", props);
      SetDeliveryId(props.data.id)

       myModal = new bootstrap.Modal(
        document.getElementById("DeleteShipModal"),
        {}
      );
      myModal.show();
    } else {
      console.log("hide on modal when changge false");

      myModal = new bootstrap.Modal(
        document.getElementById("DeleteShipModal"),
        {}
      );
      myModal.hide();
    }
  }, [props.open]);

  // function deleteUserRole() {
  //   let item : any = close_data;
  //   item.action = "delete";
  //   item.value = props.data;
  //   SetCloseData(item);
  //   myModal.hide();
  // }

  async function delete_shipment() {
    // props.close();

    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        delivery_id: delivery_id,
      },
    }
    let data_res = await api("/outlet/cancel_delivery", pass_data);
    console.log("/outlet/cancel_delivery :", data_res);
    if (data_res.status_code == 200) {
      let item = close_data;
      SetStatus(true);
      item.action = "delete";
   
      // item.index = props.data.index;
      // console.log("Close data : ", item);
      SetCloseData(item);
      myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="DeleteShipModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
            Delete Shipment
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">Are you sure want to delete Shipment?</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger-outline"
              onClick={delete_shipment}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-light-outline"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
