import React, { useState, useEffect } from "react";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import { omit } from "lodash";
import { useParams } from "react-router-dom";
import SelectUserName from "../../../components/SelectUserName/SelectUserName";


const animatedComponents = makeAnimated();

const initial_value = {
  user: "",
  username: "",
  user_id: "",
  email: "",
  mobile: "",
  whatsapp: "",
  address: "",
  roles: { roles: [], custom_roles: [] },
};

let myModal = {};

export default function ModelAddUsers(props: any) {
  const [status, setStatus] = React.useState(0);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [save_data, SetSaveData] = useState(initial_value);
  const [userOpen, setUserOpen] = useState(false);
  const [text, setText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  // const [brand_id, SetBrandId] = useState(7);
  // const [outlet_id, SetOutletId] = useState(13);
  const [id_value, SerIdValue] = useState(useParams());
  const [errors, setErrors] = useState({});
  const [userRole, SetUserRole] = useState({});
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);
  const [user_data, SetUserData] = useState([]);
  const [save_error, SetSaveError] = useState(false);

  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;

  useEffect(() => {
    console.log("ModelAddUsers On mount :", props);
    let myModal1 = document.getElementById("AddServiceModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      SetSaveData({
        ...initial_value,
        roles: {
          roles: [],
          custom_roles: []
        }
      });
      setErrors({});
      SetSaveError(false);
      setText("");
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelAddUsers On mount :", save_data);
      get_roles();
      get_users(page_no);

      myModal = new bootstrap.Modal(
        document.getElementById("AddServiceModal"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);

  async function save(event: any) {
    if (save_data.user !== "" && userRole !== "" && userRole.roles !== "") {
      if (event) event.preventDefault();

      if (
        Object.keys(errors).length === 0 &&
        Object.keys(save_data).length !== 0
      ) {
        console.log("Form Values :", save_data);
        let post_data = {
          user_id: save_data.user_id,
          mobile: save_data.mobile,
          whatsapp: save_data.whatsapp,
          email: save_data.email,
          address: save_data.address,
          roles: save_data.roles,
        };
        let pass_data = {
          get: {
            brand: id_value.brand_id,
            outlet: id_value.outlet_id,
          },
          post: post_data,
        };
        let response = await api("/outlet/add_user", pass_data);
        console.log("User post response :", response);
        let item = close_data;
        item.action = "save";
        item.value = response.response.outlet_users[0];
        console.log("Close data : ", item);
        SetSaveData({
          ...initial_value,
          roles: {
            roles: [],
            custom_roles: []
          }
        });
        SetCloseData(item);
     
        myModal.hide();
      } else {
        alert("There is an Error!");
      }
    } else {
      SetSaveError(true);
    }
  }

  function set(name: any, value: any, event: any) {
    // event.persist();
    validate(event, name, value);

    if (name == "system_roles") {
      let old_data = save_data;
      let role_data = [];
      value.map((e : any) => {
        role_data.push(e.value);
      });
      old_data.roles.roles = role_data;
      SetSaveData(old_data);
    } else if (name == "custom_roles") {
      let old_data = save_data;
      let role_data = [];
      value.map((e) => {
        role_data.push(e.value);
      });
      old_data.roles.custom_roles = role_data;
      SetSaveData(old_data);
    } else {
      SetSaveData((oldValues) => ({ ...oldValues, [name]: value }));
    }
  }

  function userOpenSelect() {
    setUserOpen(true);
  }

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const value = e.target.value;
    setText(value);
    console.log("Text onchange: ", value);
    if (value == "") {
      let page_no_value = 1;
      SetPageNo(page_no_value);
      get_users(page_no_value);
    } else {
      SetTimer(
        setTimeout(() => {
          get_users(1, value);
        }, WAIT_INTERVAL)
      );
    }
  }

  const validate = (event: any, name: any, value: any) => {
    //A function to validate each input values
    console.log("name :", name);
    console.log("value  :", value);
    switch (name) {
      case "username":
        if (value.length <= 2) {
          // we will set the error state

          setErrors({
            ...errors,
            username: "User name atleast have 3 letters",
          });
        } else {
          // set the error state empty or remove the error for username input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "username");
          setErrors(newObj);
        }
        break;

      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "Enter a valid email address",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      case "mobile":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            mobile: "mobile should contains 10 numbers",
          });
        } else {
          let newObj = omit(errors, "mobile");
          setErrors(newObj);
        }
        break;

      case "whatsapp":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            whatsapp: "whatsapp should contains 10 numbers",
          });
        } else {
          let newObj = omit(errors, "whatsapp");
          setErrors(newObj);
        }
        break;

      case "address":
        if (value.length <= 0) {
          setErrors({
            ...errors,
            address: "Address not vaild",
          });
        } else {
          let newObj = omit(errors, "address");
          setErrors(newObj);
        }
        break;

      case "system_roles":
        if (value.length <= 0 || value == {} || value == "") {
          setErrors({
            ...errors,
            system_roles: "System roles not vaild",
          });
        } else {
          let newObj = omit(errors, "system_roles");
          setErrors(newObj);
        }
        break;

      case "custom_roles":
        if (value.length <= 0 || value == {} || value == "") {
          setErrors({
            ...errors,
            custom_roles: "Custom roles not vaild",
          });
        } else {
          let newObj = omit(errors, "custom_roles");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  async function get_roles() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
    };
    let response = await api("/outlet/get_roles", pass_data);
    console.log("Users get user role response :", response);
    SetUserRole(response.response);
  }

  async function get_users(page_value: any, search_value = null) {
    let pass_data = {};
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: id_value.outlet_id,
          page: page_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: id_value.outlet_id,
          page: page_value,
        },
      };
    }
    // ------------------------------------------------------------------------------(/brand/get_users_add_outlet)
    let response = await api("/outlet/outlet_users", pass_data);
    console.log("Users get_users_add_outlet response :", response);
    if (response.response.data) {
      SetUserData(response.response.data);
    }
    SetNextPage(response.response.next_page);
  }

  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != "") {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: id_value.outlet_id,
          page: page_no_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: id_value.outlet_id,
          page: page_no_value,
        },
      };
    }
    let response = await api("/brand/get_users_add_outlet", pass_data);
    console.log("Users get_users_add_outlet response :", response);
    SetUserData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  const handleDataSelected = (userData) => {
    // Do something with the received data in the parent component
    setUserOpen(false)
    console.log("Selected user data in parent:", userData);
    SetSaveData((oldValues) => ({
                    ...oldValues,
                    user: userData.name,
                    username: userData.user_name,
                    user_id: userData.id,
                  }));
  };

  return (
    <div
      className="modal fade"
      id="AddServiceModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog  modal-fullscreen-sm-down">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              {userOpen ? (
                <div className="d-flex">
                  <img
                    src={require("../../../assets/icon/svg/arrow-left.svg")}
                    onClick={() => {
                      setUserOpen(false);
                    }}
                    className="px-2 cursor action-img"
                  />
                  Select User
                </div>
              ) : (
                "Add User"
              )}
            </h6>
            <button
              type="button"
              className="btn-close"
              // data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                SetSaveData(initial_value);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <form className="g-3">
              {/* onSubmit={onSubmit} */}

              {userOpen ? (
                // <div className="row">
                //   <div className="col-12">
                //     <input
                //       type="text"
                //       className="form-control"
                //       placeholder="Search users"
                //       defaultValue={text}
                //       required
                //       onChange={(e) => {
                //         onTextChanged(e), set("user", e, e);
                //       }}
                //     />
                //     {save_error == true ? (
                //       <small className="text-red">* Required fields</small>
                //     ) : null}
                //   </div>

                //   <h6 className="my-4 px-3">Available Users</h6>
                //   {/* {suggestions.map((item, sugIndex) => ( */}
                //   <div className="users_scroll px-4">
                //     {user_data.map((item, sugIndex) => (
                //       <div
                //         className="cursor"
                //         key={sugIndex}
                //         onClick={() => {
                //           if (!item.exists) {
                //             SetSaveData((oldValues) => ({
                //               ...oldValues,
                //               user: item.name,
                //               username: item.user_name,
                //               user_id: item.id,
                //             }));
                //             setUserOpen(false);
                //             console.log("Select item:", item);
                //           }
                //         }}
                //       >


                //         <div className="row align-items-center mb-3">
                //           <div className="col-2 col-sm-1 col-md-1 p-md-0">
                            
                //               <img
                //                 src={item.dp_url != null ? (
                //                   item.dp_url
                //                 ):(require("../../../assets/img/profile.png"))
                //                 }
                //                 className="img-radius w-100"
                //               />
                //           </div>
                //           <div className="col-6 col-md-7">

                //             <p
                //               className={
                //                 item.exists
                //                   ? "text-gray mb-0 fs-6 fw-bolder"
                //                   : "text-dark mb-0"
                //               }
                //             >
                //               {item.name}
                //             </p>
                //             <small>@{item.user_name}</small>
                //           </div>
                //           <div className="col-4 p-0 text-end ">
                //             {item.exists ? (
                //               <small className="text-red mb-0 fs-7">
                //                 User already exists
                //               </small>

                //             ) : (
                //               null
                //             )}
                //           </div>
                //         </div>

                //       </div>
                //     ))}
                //     {next_page ? (
                //       <p
                //         className="text-blue text-center my-2 cursor"
                //         onClick={() => {
                //           load_more();
                //         }}
                //       >
                //         Load More
                //       </p>
                //     ) : null}
                //   </div>
                // </div>
          
                <SelectUserName onDataSelected={handleDataSelected} type="outlet"/>
              
              ) : (
                <div className="row">
                  <div className="col-12 col-md-6 ">
                    <label htmlFor="userName" className="form-label">
                      User Name
                    </label>
                    <div onClick={userOpenSelect} className="cursor">
                    {  console.log(save_data)}
                      <input
                        type="text"
                        className="form-control"
                        id="userName"
                        placeholder="Select User Name"
                        value={save_data.user}
                        required
                      />
                      {save_error == true ? (
                        <small className="text-red">* Required fields</small>
                      ) : null}
                      {errors.username && (
                        <small className="text-red">{errors.username}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mt-3 mt-md-0">
                    <label className="form-label">Whatsapp Number</label>
                    <div className="input-group">
                      <span className="input-group-text text-darkgray">
                        <i className="fab fa-whatsapp"></i>
                      </span>
                      <input
                        type="number"
                        className="form-control"
                       value={save_data.whatsapp}
                        required
                        onChange={(e) => {
                          set("whatsapp", e.target.value, e);
                        }}
                      />
                    </div>
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                    {errors.whatsapp && (
                      <small className="text-red">{errors.whatsapp}</small>
                    )}
                  </div>

                  <div className="col-12 col-md-6 mt-3">
                    <label className="form-label">Mobile Number</label>
                    <div className="input-group">
                      <span className="input-group-text text-darkgray">
                        <i className="fas fa-phone-alt"></i>
                      </span>
                      <input

                        type="number"
                        className="form-control"
                        value={save_data.mobile}
                        required
                        onChange={(e) => {
                          set("mobile", e.target.value, e);
                        }}
                      />
                    </div>
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                    {errors.mobile && (
                      <small className="text-red">{errors.mobile}</small>
                    )}
                  </div>

                  <div className="col-12 col-md-6 mt-3">
                    <label className="form-label">Email Address</label>
                    <input
                      type="email"
                      className="form-control"
                      value={save_data.email}
                      required
                      onChange={(e) => {
                        set("email", e.target.value, e);
                      }}
                    />
                    {errors.email && (
                      <small className="text-red">{errors.email}</small>
                    )}
                  </div>

                  <div className="col-12 col-md-6 mt-3">
                    <label className="form-label">System roles</label>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      placeholder="Select User roles"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isMulti
                      options={userRole.roles}
                      onChange={(e) => {
                        set("system_roles", e, e);
                      }}
                    // onBlur={() => system_role.onBlur(input.value)}
                    // onChange={(e)=>{
                    //   set('roles', e);
                    // }}
                    />
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                    {errors.system_roles && (
                      <small className="text-red">
                        {errors.system_roles}
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <label className="form-label">Custom roles</label>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      placeholder="Select User roles"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isMulti
                      options={userRole.custom_roles}
                      onChange={(e) => {
                        set("custom_roles", e, e);
                      }}
                    // onBlur={() => system_role.onBlur(input.value)}
                    // onChange={(e)=>{
                    //   set('roles', e);
                    // }}
                    />
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                    {errors.custom_roles && (
                      <small className="text-red">
                        {errors.custom_roles}
                      </small>
                    )}
                  </div>

                  <div className="col-12 mt-3">
                    <label className="form-label">Address</label>
                    <textarea
                      className="form-control"
                      rows={2}
                      value={save_data.address}
                      required
                      onChange={(e) => {
                        set("address", e.target.value, e);
                      }}
                    />
                    {errors.address && (
                      <small className="text-red">{errors.address}</small>
                    )}
                  </div>

                  {/* <h6 className="mt-4">Salery Baciss</h6>
                          <div className="col-12 col-md-6 mt-2">
                            <label htmlFor="salaryAmount" className="form-label">Salery Amount</label>
                            <div className="input-group">
                              <span className="input-group-text">₹</span>
                              <input type="number" className="form-control" id="salaryAmount" defaultValue={save_data.salaryAmount} required onChange={(e) => {
                                set('salaryAmount', e.target.value, e)
                              }} />
                            </div>
                          </div>

                          <h6 className="mt-4">Work Baciss</h6>
                          <div className="col-12 mt-3">
                            <label htmlFor="userRole" className="form-label">Select Services</label>
                            <Select
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              placeholder="Select Services"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              isMulti
                              options={services}
                              onChange={(e) => {
                                serviceSelect(e)
                              }}
                            // onChange={(e)=>{
                            //   set('roles', e);
                            // }}
                            />
                          </div>
                          {save_data.workservices.map((service, index) => (
                            <div className="col-md-6 mt-2" key={index}>
                              {service.type == "Item wise" ? (
                                <div>
                                  <label htmlFor="amount" className="form-label"><b className="text-dark pr-2">{service.value}</b> <small className="text-gray">Item Wise Amount</small></label>
                                  <div className="input-group">
                                    <span className="input-group-text">₹</span>
                                    <input type="number" className="form-control" id="amount" defaultValue={service.amount} required onChange={(e) => {
                                      console.log("hggf :", e);
                                      // set('amount', e);
                                      let data = values;
                                      data.workservices[index].amount = e.target.value;
                                      setValues(data);

                                    }} />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {service.type == "Duration Wise" ? (
                                <div>
                                  <label htmlFor="amount" className="form-label"><b className="text-dark pr-2">{service.value}</b> <small className="text-gray">Duration Wise Amount</small></label>
                                  <div className="input-group">
                                    <span className="input-group-text">₹</span>
                                    <input type="number" className="form-control" id="amount" defaultValue={service.amount} required onChange={(e) => {
                                      console.log("hggf :", e);
                                      // set('amount', e);
                                      let data = values;
                                      data.workservices[index].amount = e.target.value;
                                      setValues(data);

                                    }} />
                                  </div>
                                </div>
                              ) : ("")}

                            </div>
                          ))} */}
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer w-100">
            <button
              type="button"
              className="btn btn-secondary-outline btn-radius btn-sm px-4"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                SetSaveData(initial_value);
                myModal.hide();
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn bg-prime text-white px-4 ms-auto btn-radius btn-sm"
              onClick={(e) => {
                save(e);
              }}
            // data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
