import React, { useState, useEffect, useMemo,useContext } from "react";
import { Link, useParams, useOutletContext } from "react-router-dom";
import ModelAddUsers from "./ModalAddUsers";
import ModelViewUsers from "./ModalViewUsers";
import { api } from "../../../utils/Api";
import { SwipeableDrawer } from "@material-ui/core";
import { Entypo, Feather, Foundation, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import ModalDeleteUser from "./ModalDeleteUser";
import ModalEditUsers from "./ModalEditUsers";
import Skeleton from "react-loading-skeleton";
import DataContext from "../../../utils/Context/DataContext";
import { useNavigate } from 'react-router-dom';


// import ModalSetOwnerUser from "./ModalSetOwnerUser";
// import ModalRemoveOwner from "./ModalRemoveOwner";
import DeleteAlert from "../../../components/Alert/DeleteAlert";
import Pagination from "../../../components/Pagination/Pagination";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { AppConfig } from "../../../config";

const table_header = [
  {
    name: "",
    width: "10",
  },
  {
    name: "Users",
    width: "30",
  },
  {
    name: "Mobile",
    width: "20",
  },
  {
    name: "Address",
    width: "30",
  },
  {
    name: "",
    width: "10",
  },
];

const delete_table_header = [
  {
    name: "",
    width: "10",
  },
  {
    name: "User",
    width: "30",
  },
  {
    name: "Deleted User",
    width: "30",
  },
  {
    name: "Deleted At",
    width: "20",
  },
  {
    name: "",
    width: "10",
  },
];

let PageSizeOption = [10, 20, 30, 40, 50];

export default function OutletUsers(props: any) {
  const context:any = useContext(DataContext);

  const [users_data, SetUsersData] = useState([]);
  const OutletContext = useOutletContext();
  const [deleted_user_data, SetDeletedUserData] = useState([]);
  const [openAddModal, SetopenAddModal] = useState(false);
  const [is_loading, SetIsLoading] = useState(false);

  const [ViewModal, SetViewModal] = useState({
    is_open: false,
    record: {},
    type: "view",
    record_index: "",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [selected_data, SetSelectedData] = useState({
    value: "",
    i: "",
    type: "",
  });
  const [user_modal, SetUserModal] = useState({
    is_open: false,
    type: "add",
    data: {},
  });
  const [owner, Setowner] = useState(false);
  const [edit_user_modal, SetEditUserModal] = useState({
    is_open: false,
    data: {},
    index: "",
    type: "",
  });
  const [deleteModal, SetDeleteModal] = useState({
    is_open: false,
    data: {},
  });
  const [removeOwnerModal, SetRemoveOwnerModal] = useState({
    is_open: false,
    data: {},
  });
  const [ownerModal, SetOwnerModal] = useState({
    is_open: false,
    data: {},
  });
  const [CurrentPage, SetCurrentPage] = useState(1);
  const [DeleteCurrentPage, SetDeleteCurrentPage] = useState(1);
  const [search_data, SetSearchData] = useState("");
  const [delete_search_data, SetDeleteSearchData] = useState("");
  const [PageSize, SetPageSize] = useState(10);
  const [DeletePageSize, SetDeletePageSize] = useState(10);
  const [active_table_data, SetActiveTableData] = useState([]);
  const [delete_table_data, SetDeleteTableData] = useState([]);
  const [width, SetWidth] = useState(innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("OutletUsers On mount :", props);
    if(context.app_data.pages.hasOwnProperty('25')){
      if(context.app_data.pages[25]){
    get_users();
  }else{
    console.log("hi")
    navigate("/no_access");

  }}else{
    SetIsLoading(true)
  }
  }, [context.app_data.pages[25]]);

  function sample_fun(anchor, open, event) {
    // console.log("sample_fun anchor :", anchor);
    // console.log("sample_fun open :", open);
    // console.log("sample_fun event :", event);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      // console.log("return");
      return;
    }
    // console.log("toggle else");

    setState({ ...state, [anchor]: open });
    // toggleDrawer("bottom", true);
  }

  const list = (anchor) => (
    <div className="mt-3">
      <div>
        <div className="d-flex justify-content-center">
          <div className="w-25 px-4">
            <hr className="h-3" />
          </div>
        </div>
        {selected_data.type == "active" ? (
          <ul>
            <li
              className="p-2 pb-3 cursor align-center"
              onClick={(event) => {
                SetViewModal({
                  is_open: true,
                  record: selected_data.value,
                  type: "view",
                  record_index: selected_data.i,
                });
                sample_fun(anchor, false, event);
                console.log("selected Data: ", selected_data);
              }}
            >
              <img src={AppConfig.CDN_Media_url+"899ba067909847f6cd6de5f56c7d3e29.svg"}
                className="me-2 action_menu_img cursor"
              />
              &nbsp; View
            </li>
            {context.app_data.pages[27] &&
            <li
              className="p-2 pb-3 cursor align-center"
              onClick={(event) => {
                SetViewModal({
                  is_open: true,
                  record: selected_data.value,
                  type: "edit",
                  record_index: selected_data.i,
                });
                sample_fun(anchor, false, event);
                console.log("selected Data: ", selected_data);
              }}
            >
              <img src={AppConfig.CDN_Media_url+"82e054804ff41ccba352c61302330d9d.svg"}
                className="me-2 action_menu_img cursor"
              />
              &nbsp; Edit
            </li>
}
{context.app_data.pages[28] &&
            <li
              className="p-2 pb-3 cursor align-center"
              onClick={(event) => {
                SetDeleteModal((prevValue) => {
                  prevValue.is_open = true;
                  prevValue.title = "User";
                  prevValue.record = selected_data.value;
                  prevValue.index = selected_data.i;
                  return { ...prevValue };
                });
                sample_fun(anchor, false, event);
              }}
            >
              <img src={AppConfig.CDN_Media_url+"472c3616430307f9f427931b500e2022.svg"}
                className="me-2 action_menu_img cursor"
              />
              &nbsp; Delete
            </li>
}
            <li className="p-2 pb-3 cursor align-center">
              <Link
                to={
                  "/outlet/" +
                  id_value.brand_id +
                  "/" +
                  id_value.outlet_id +
                  "/salarywork/" +
                  selected_data.value.id
                }
                className="text-dark"
              >
                <img src={AppConfig.CDN_Media_url+"cd5fb1a01ddc39aaa1e266dfbc7d9afb.svg"}
                  className="action_menu_img cursor me-2"
                />
                &nbsp; Salary Work
              </Link>
            </li>
            <li className="p-2 pb-3 cursor align-center">
              <Link
                to={
                  "/outlet/" +
                  id_value.brand_id +
                  "/" +
                  id_value.outlet_id +
                  "/monthlysalary/" +
                  selected_data.value.id
                }
                className="text-dark"
              >
                <img src={AppConfig.CDN_Media_url+"1f3b98e0db9a332e69402d487f767dd1.svg"}
                  className="action_menu_img cursor me-2"
                />
                &nbsp; Monthly Salary
              </Link>
            </li>
          </ul>
        ) : (
          <ul>
            <li
              className="p-2 pb-3 cursor align-center"
              onClick={(event) => {
                SetViewModal({
                  is_open: true,
                  record: selected_data.value,
                  type: "view",
                  record_index: selected_data.i,
                });
                sample_fun(anchor, false, event);
                console.log("selected Data: ", selected_data);
              }}
            >
              <img src={AppConfig.CDN_Media_url+"899ba067909847f6cd6de5f56c7d3e29.svg"}
                className="me-2 action_menu_img cursor"
              />
              &nbsp; View
            </li>
          </ul>
        )}
      </div>
    </div>
  );

  function AddModalOnClose(data: any) {
    console.log("Close Modal values: ", data);
    if (data.action == "save") {
      SetUsersData((prevValue) => {
        if (prevValue == undefined) {
          prevValue = [];
          prevValue.push(data.value);
        } else {
          prevValue.push(data.value);
        }
        return [...prevValue];
      });
    }
    SetopenAddModal(false);
  }

  function ViewModalOnClose(data: any) {
    console.log("ViewModalOnClose data :", data);
    SetViewModal({
      is_open: false,
      record: {},
      type: "view",
      record_index: "",
    });

    if (data.action == "edit") {
      // SetUsersData((prevValue) => {
      //   prevValue[data.index] = data.value;
      //   return [...prevValue];
      // });
      get_users();
    }
  }

  async function get_users() {
    SetIsLoading(true);
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
    };
    let response = await api("/outlet/outlet_users", pass_data);
    console.log("company get response :", response.response);
    if (response.response.hasOwnProperty("outlet_users")) {
      SetUsersData(response.response.outlet_users);
      SetIsLoading(false);
      const firstPageIndex = (CurrentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      SetActiveTableData(
        response.response.outlet_users.slice(firstPageIndex, lastPageIndex)
      );
    }


    if (response.response.hasOwnProperty("deleted_outlet_users")) {
      SetDeletedUserData(response.response.deleted_outlet_users);
      const DeletefirstPageIndex = (DeleteCurrentPage - 1) * DeletePageSize;
      const DeletelastPageIndex = DeletefirstPageIndex + DeletePageSize;
      SetDeleteTableData(
        response.response.deleted_outlet_users.slice(
          DeletefirstPageIndex,
          DeletelastPageIndex
        )
      );
    }
  }

  async function delete_user(i: any, record: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
      post: {
        user_id: record.id,
      },
    };
    let response = await api("/outlet/delete_user", pass_data);
    console.log("company delete response :", response);
    if (response.status_code == 200) {
      // let data = users_data;
      // console.log("users_data :", users_data);
      // console.log("i :", i);

      // console.log(users_data[i]);
      // data.splice(i, 1);
      // console.log("Sliced Data :", data);
      // SetUsersData([...data]);
      get_users();
    }
  }

  function search_on_change(e: any) {
    console.log("search_on_change : ", e.target.value);

    if (e.target.value == "") {
      const firstPageIndex = (CurrentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      SetActiveTableData(users_data.slice(firstPageIndex, lastPageIndex));
    } else {
      const filter_value = users_data.filter((obj) =>
        JSON.stringify(obj).toLowerCase().includes(e.target.value.toLowerCase())
      );
      console.log("filter_value : ", filter_value);
      SetActiveTableData(filter_value);
    }
  }

  function delete_search_on_change(e: any) {
    console.log("delete_search_on_change : ", e.target.value);

    if (e.target.value == "") {
      const firstPageIndex = (DeleteCurrentPage - 1) * DeletePageSize;
      const lastPageIndex = firstPageIndex + DeletePageSize;
      SetDeleteTableData(
        deleted_user_data.slice(firstPageIndex, lastPageIndex)
      );
    } else {
      const filter_value = deleted_user_data.filter((obj) =>
        JSON.stringify(obj).toLowerCase().includes(e.target.value.toLowerCase())
      );
      console.log("filter_value : ", filter_value);
      SetDeleteTableData(filter_value);
    }
  }

  return (

    <div>
      <div className={OutletContext ? "head-bar sticky-sm" : "head-bar  sticky-sm "}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="row align-items-center pb-2">
          <div className="col-10 sm-mb-2">
            <h6 className="mb-0">Users</h6>
          </div>
          <div className="col-2 p-0  text-end sm-mb-2">
          {context.app_data.pages[26]&&
            <button
              // className="btn bg-prime text-white btn-radius px-3 btn-sm w-75"
              className="btn bg-prime text-white btn-radius btn-sm px-3 "

              onClick={() => {
                SetUserModal({
                  is_open: true,
                  type: "add",
                  data: {},
                });
              }}
            >
              Add
            </button>
}
          </div>
            {/* <div className="row px-md-3">
              <div className="input-icons form-group">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search products"
                />
                <div className="cursor ps-2 border-left">
                  <Ionicons name="md-search" size={18} color="#663fc3" />
                </div>
              </div>
            </div> */}
          {/* <div className="text-center col-md-1 col-3 pe-0">
            <select className="number-select form-select btn-radius form-control pe-0 ps-2" defaultValue="10">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div> */}

          </div>
      </div>


      <div className={OutletContext ? "mt-3 px-md-3 px-sm-0" : "mt-3 mt-sm-3 mt-md-0 px-md-3 px-sm-0 mb-5"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="mt-2 p-sm-2 p-md-0">

        {is_loading ? (
        <div className="row">
          {[1, 2, 3, 4, 5, 6].map((index) => (
            <div className="col-md-4 col-lg-3 mb-3" key={index}>
              <div className="card radius px-2 pt-3 pb-0">
                <div className="position-absolute right-0">
                  <div className="dropdown menu ms-auto cursor px-2 user-dropdown">
                    <div className="dropdown-toggle">
                      <Skeleton circle={true} height={20} width={20} />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <Skeleton circle={true} height={100} width={100} />
                  <div className="pt-2">
                    <Skeleton width={100} />
                    <div className="center">
                      <Skeleton width={80} />
                    </div>
                    <div className="mt-1 mb-2">
                      <Skeleton width={50} />
                    </div>
                  </div>
                </div>
                <div className="border-top">
                  <Accordion className="px-0">
                    <AccordionSummary>
                      <Skeleton width={150} />
                    </AccordionSummary>
                    <AccordionDetails className="px-1">
                      <div>
                        <div className="d-flex align-items-center">
                          <Skeleton circle={true} height={16} width={16} />
                          <Skeleton width={200} className="ms-2" />
                        </div>
                        <div className="mt-3 d-flex align-items-center">
                          <Skeleton circle={true} height={16} width={16} />
                          <Skeleton width={200} className="ms-2" />
                        </div>
                        <div className="mt-3 d-flex align-items-center">
                          <Skeleton circle={true} height={16} width={16} />
                          <Skeleton width={200} className="ms-2" />
                        </div>
                        <div className="mt-3 d-flex align-items-center">
                          <Skeleton circle={true} height={16} width={16} />
                          <Skeleton width={200} className="ms-2" />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        users_data?.length > 0 ? (
            <div className=" row ">
              {users_data.map((ele: any, index: any) => (
                <div className="col-md-4 col-lg-3 mb-3">
                  <div className="card radius px-2 pt-3 pb-0">
                    <div className="position-absolute right-0 ">
                      <div className="dropdown menu ms-auto cursor px-2 user-dropdown">
                        <div
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={AppConfig.CDN_Media_url+"358cc79ee3ed14b245a3d3935e4b6e68.svg"} className="w-20px" />
                          {/* <Entypo name="dots-three-vertical" size={16} color="#777" /> */}
                        </div>

                        <ul className="dropdown-menu ms-auto dropdown-menu-start">
                          {owner == true &&
                            ele.loggedin_user == false &&
                            ele.admin == true ? (
                            <li
                              onClick={() => {
                                SetRemoveOwnerModal({
                                  is_open: true,
                                  data: {
                                    id: ele.id,

                                    type: "brand",
                                    status: "active",
                                  },
                                });
                              }}
                            >
                              <button className="dropdown-item py-2 mb-1 d-flex align-items-center" type="button">
                                {/* <Feather name="user-x" size={18} color="#666" /> */}
                                <img src={AppConfig.CDN_Media_url+"2ef3d0d83346bfe02ee9c1c296354aec.svg"} className="w-20px" />
                                <p className="ps-3 fs-7  text-black">Remove Owner</p>
                              </button>
                            </li>
                          ) : (
                            ""
                          )}
                          {owner == true &&
                            ele.loggedin_user == false &&
                            ele.admin == false ? (
                            <li
                              onClick={() => {
                                SetOwnerModal({
                                  is_open: true,
                                  data: {
                                    id: ele.id,

                                    type: "brand",
                                    status: "active",
                                  },
                                });
                              }}
                            >
                              <button className="dropdown-item py-2 mb-1 d-flex align-items-center" type="button">
                                {/* <Feather name="user-plus" size={18} color="#666" /> */}
                                <img src={AppConfig.CDN_Media_url+"11877862a6759a7ec8580443d1d62fc3.svg"} className="w-20px"  />
                                <p className="ps-3 fs-7  text-black">Set Owner</p>
                              </button>
                            </li>
                          ) : ("")}
                          <li

                            onClick={(event) => {
                              let set_data = {
                                value: ele,
                                index: ele.i,
                              };
                              SetEditUserModal({
                                is_open: true,
                                type: "view",
                                data: set_data,
                              });
                              console.log("Edit");
                              // sample_fun(anchor, false, event);
                            }}
                          >
                            <button className="dropdown-item py-2 mb-1 d-flex align-items-center" type="button"

                            >
                              {/* <MaterialCommunityIcons name="eye-outline" size={18} color="#666" /> */}
                                <img src={AppConfig.CDN_Media_url+"af780c8c71713ab2158e805e62c7ec9e.svg"} className="w-22px"  />
                              <p className="ps-3 fs-7 text-black">View</p>
                            </button>
                          </li>
                          {console.log(ele.custom_roles)}
                          <li
                            // onClick={() => {
                            //   console.log(ele)
                            //   SetEditUserModal({
                            //     is_open: true,
                            //     data: ele,
                            //     index: 0,
                            //   });
                            //   // console.log("Edit");
                            //   // sample_fun(anchor, false, event);
                            // }}

                            onClick={(event) => {
                              let set_data = {
                                value: ele,
                                index: ele.i,
                              };
                              SetEditUserModal({
                                is_open: true,
                                type: "edit",
                                data: set_data,
                              });
                              console.log("Edit");
                              // sample_fun(anchor, false, event);
                            }}
                          >
                            <button className="dropdown-item py-2 mb-1 d-flex align-items-center" type="button">
                              {/* <Feather name="edit" size={16} color="#666" /> */}
                                <img src={AppConfig.CDN_Media_url+"5d643b0b50ef1705bf7437de3338d7df.svg"} className="w-20px"  />
                              <p className="ps-3 fs-7  text-black">Edit</p>
                            </button>
                          </li>

                          <li
                            onClick={() => {
                              SetDeleteModal({
                                is_open: true,
                                data: {
                                  id: ele.id,

                                  type: "brand",
                                  status: "active",
                                },
                              });
                            }}
                          >
                            <button className="dropdown-item py-2 mb-1 d-flex align-items-center" type="button">
                              {/* <MaterialCommunityIcons name="delete-outline" size={18} color="#666" /> */}
                                <img src={AppConfig.CDN_Media_url+"78566607b3f770a7638c191d872e211b.svg"} className="w-20px"  />
                              <p className="ps-3 fs-7  text-black">Delete</p>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="text-center">
                   { ele.profile_picture?
                      <img src={ele.profile_picture} className="franchise-user-img" />
                      :

                      // <img src={require("../../../assets/img/profile.png")} className="franchise-user-img" />
                      <img src={AppConfig.CDN_Image_url+"beb6e59fdba49c15dfd0fd4f2a341ca0.png"} className="franchise-user-img"  />

                       
                   }


                      <div className="pt-2">
                        <div className="text-center">
                          <p className="text-black fw-bold oneline mt-1">{ele.user}</p>
                          <div className="center">
                            <p className="fw-500 text-white fw-bold extra-small mt-1 bg-prime btn-radius w-fit px-2">@{ele.username}</p>
                          </div>
                        </div>
                        <div className="mt-1 mb-2">
                          {ele.admin && <span className="badge bg-prime btn-radius extra-small">Owner</span>}
                        </div>

                      </div>
                    </div>


                    <div className="border-top">
                      <Accordion className='px-0'>
                        <AccordionSummary
                          expandIcon={<img src={AppConfig.CDN_Media_url+"78f45bd73c51ade6a32e1185735e5439.svg"} className="w-10px" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="px-1"
                        >
                          <p className="text-dark fw-bold small">Contact Details</p>
                        </AccordionSummary>
                        <AccordionDetails className="px-1">
                          <div>
                            <div className=" d-flex align-items-center">
                              <img src={AppConfig.CDN_Media_url+"68ea449942e40acb8d82122bf48d1003.svg"} className="w-16px" />
                              {/* <Ionicons name="md-location-outline" size={15} color="#444" /> */}
                              <p className="ms-2 v-small text-black">{ele.address}</p>
                            </div>
                            <div className="mt-3 d-flex align-items-center">
                              <img src={AppConfig.CDN_Media_url+"f2e8ccddeed4161d329a339f9a80a702.svg"} className="w-16px" />
                              {/* <Feather name="mail" size={13} color="#777" /> */}
                              <p className="ms-2 small">{ele.email}</p>
                            </div>

                            <div className="mt-3 d-flex align-items-center">
                              <img src={AppConfig.CDN_Media_url+"7473cb524f588caf0ffa5bca46abd090.svg"} className="w-16px" />
                              {/* <Feather name="phone-call" size={13} color="#777" /> */}
                              <p className="ms-2 small">{ele.mobile}</p>
                            </div>
                            <div className="mt-3 d-flex align-items-center">
                              <img src={AppConfig.CDN_Media_url+"0bb0fb443d87fcd7bb00b172e21b28ef.svg"} className="w-16px" />
                              {/* <Ionicons name="logo-whatsapp" size={13} color="#777" /> */}
                              <p className="ms-2 small">{ele.whatsapp}</p>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>
              ))}
            </div>

          ) : (
            <div className="bg-fff p-2 mt-5 radius">
              <div className="text-center">
              <img src={AppConfig.CDN_Image_url+"7ca4a2d2f4451242ca6e83a2173ad29e.png"} className="empty-img pb-2"
                  />
                <p className="v-small mb-0">No Data, Please Add Users</p>
              </div>
            </div>
          )
        )}
        </div>
      </div>


      {/* <ModelAddUsers
        open={openAddModal}
        close={(data) => {
          AddModalOnClose(data);
          if (data.action == "save") {
            get_users();
          }
        }}
      /> */}

      {/* <div className="p-2 row list border-bottom">
                    <div className="col-md-3">
                      <img
                        src={eleitem.profile_picture}
                        className="br-100"
                        width="60"
                        alt="..."
                      />


                      <div className="col-md-2">
                        <small className="text-dark">Username</small>
                        <p className="mb-0">{eleitem.username}</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <small className="text-dark">E-mail</small>
                      <p className="mb-0">{eleitem.email}</p>
                    </div>


                    <div className="col-md-1">
                      <small className="text-dark">MobileNO</small>
                      <p className="mb-0">{eleitem.mobile}</p>
                    </div>


                    <div className="col-md-1">
                      <small className="text-dark">whatsapp</small>
                      <p className="mb-0">{eleitem.whatsapp}</p>
                    </div>


                    <div className="col-md-2">
                      <small className="text-dark">Address</small>
                      <p className="mb-0">{eleitem.address}</p>
                    </div>


                    <div className="dropdown menu col-md-2">
                      <div className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <img
                          src={require("../../../assets/icon/svg/more-horizontal.svg")}
                          className="action_img cursor "
                        />
                      </div>

                      <ul className="dropdown-menu  dropdown-menu-start">
                        {owner == true &&
                          eleitem.loggedin_user == false &&
                          eleitem.admin == true ? (
                          <li
                            onClick={() => {
                              SetRemoveOwnerModal({
                                is_open: true,
                                data: {
                                  id: eleitem.id,

                                  type: "brand",
                                  status: "active",
                                },
                              });
                            }}
                          ><button className="dropdown-item" type="button"> <img
                            src={require("../../../assets/icon/svg/x1.svg")}
                            className="action_menu_img cursor"
                          />
                              &nbsp; Remove Owner</button></li>
                        ) : (
                          ""
                        )}



                        {owner == true &&
                          eleitem.loggedin_user == false &&
                          eleitem.admin == false ? (
                          <li
                            onClick={() => {
                              SetOwnerModal({
                                is_open: true,
                                data: {
                                  id: eleitem.id,

                                  type: "brand",
                                  status: "active",
                                },
                              });
                            }}
                          ><button className="dropdown-item" type="button">  <img
                            src={require("../../../assets/icon/svg/user2.svg")}
                            className="action_menu_img cursor"
                          />
                              &nbsp;
                              Set Owner</button></li>
                        ) : ("")}
                        <li
                          onClick={(event) => {
                            let set_data = {
                              value: eleitem,
                              index: eleitem.i,
                            };
                            SetEditUserModal({
                              is_open: true,
                              type: "view",
                              data: set_data,
                            });
                            console.log("Edit");
                            // sample_fun(anchor, false, event);
                          }}

                        ><button className="dropdown-item" type="button">  <img
                          src={require("../../../assets/icon/svg/eye.svg")}
                          className="action_menu_img cursor"
                        />
                            &nbsp; View</button></li>

                        <li


                          onClick={(event) => {
                            let set_data = {
                              value: eleitem,
                              index: eleitem.i,
                            };
                            SetEditUserModal({
                              is_open: true,
                              type: "edit",
                              data: set_data,
                            });
                            console.log("Edit");
                            // sample_fun(anchor, false, event);
                          }}

                        // onClick={() => {
                        //   console.log(eleitem)
                        //   SetEditUserModal({
                        //     is_open: true,
                        //     data: eleitem,
                        //     index: 0,
                        //   });
                        //   // console.log("Edit");
                        //   // sample_fun(anchor, false, event);
                        // }}
                        ><button className="dropdown-item" type="button">  <img

                          src={require("../../../assets/icon/svg/edit1.svg")}
                          className="action_menu_img cursor"
                        />
                            &nbsp; Edit</button></li>


                        <li
                          onClick={() => {
                            SetDeleteModal({
                              is_open: true,
                              data: {
                                id: eleitem.id,

                                type: "brand",
                                status: "active",
                              },
                            });
                          }}
                        ><button className="dropdown-item" type="button"> <img
                          src={require("../../../assets/icon/svg/trash1.svg")}
                          className="action_menu_img cursor"
                        />
                            &nbsp; Delete</button></li>

                      </ul>
                    </div>



                  </div> */}

      <ModelViewUsers
        data={ViewModal}
        close={(data) => {
          ViewModalOnClose(data);
        }}
      />

      {/* <DeleteAlert
        open={delete_modal.is_open}
        title={delete_modal.title}
        record={delete_modal.record}
        index={delete_modal.index}
        close={(data) => {
          SetDeleteModal((prevValue) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });

          if (data.action === "delete") {
            console.log("delete user : ", data);
            delete_user(data.index, data.value);
          }
        }}
      /> */}


      <ModelAddUsers
        open={user_modal.is_open}
        data={user_modal.data}
        type={user_modal.type}
        close={(data) => {
          console.log("Modal user on close :", data);
          SetUserModal({
            is_open: false,
            type: "add",
            data: {},
          });
          if (data.action == "save") {
            SetUsersData((prevValue) => {
              if (prevValue == undefined) {
                prevValue = [];
                prevValue.push(data.value);
              } else {
                prevValue.push(data.value);
              }
              return [...prevValue];
            });
          }
          if (data.action == "edit") {
            SetUsersData((prevValue) => {
              prevValue[data.index] = data.value;
              return [...prevValue];
            });
          }
        }}
      />

      <ModalEditUsers
        open={edit_user_modal.is_open}
        data={edit_user_modal.data}
        type={edit_user_modal.type}
        index={edit_user_modal.index}
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          SetEditUserModal({
            is_open: false,
            data: {},
            type: "",
            index: "",
          });
          if (data.action == "edit") {
            get_users();
            // SetUsersData((prevValue) => {
            //   prevValue[data.index] = data.value;
            //   return [...prevValue];
            // });
          }
        }}
      />

      <ModalDeleteUser
        open={deleteModal.is_open}
        data={deleteModal.data}
        close={(data: any) => {
          // console.log("Franchise modl on close :", data);
          SetDeleteModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            get_users();
          }
        }}
      />

      {
        ["left", "right", "top", "bottom"].map((anchor) => (
          <React.Fragment key={anchor}>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              // onClose={toggleDrawer(anchor, false)}
              // onOpen={toggleDrawer(anchor, true)}
              onClose={(event) => {
                sample_fun(anchor, false, event);
              }}
              onOpen={(event) => {
                sample_fun(anchor, true, event);
              }}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))
      }
    </div >
  );
}
