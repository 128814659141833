import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../utils/Context/DataContext";
import { Link, useParams } from "react-router-dom";
import { Table, Column, HeaderCell, Cell, ColumnGroup } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import Slider from "@material-ui/core/Slider";

import { api } from "../../utils/Api";

function valuetext(value) {
  return `${value}°C`;
}

const CheckCell = ({ rowData, checkedkeys, onChange, from, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div className="py-3">
      {from == "channel" ? (
        rowData.outlets[props.outlet_index].channels.length > 0 ? (
          rowData.outlets[props.outlet_index].channels[props.channel_index]
            .paused == true &&
          rowData.outlets[props.outlet_index].channels[props.channel_index]
            .checked == false ? (
            <p
              onClick={(event) => {
                onChange(rowData, props, true);
              }}
            >
              Pause Button
            </p>
          ) : (
            <input
              type="checkbox"
              checked={check_data(rowData, props, from)}
              onChange={(event) => {
                onChange(rowData, props, event.target.checked);
              }}
            />
          )
        ) : null
      ) : rowData.outlets[props.outlet_index].custom_channels.length > 0 ? (
        rowData.outlets[props.outlet_index].custom_channels[props.channel_index]
          .paused == true &&
        rowData.outlets[props.outlet_index].custom_channels[props.channel_index]
          .checked == false ? (
          <p
            onClick={(event) => {
              onChange(rowData, props, true);
            }}
          >
            Pause Button
          </p>
        ) : (
          <input
            type="checkbox"
            checked={check_data(rowData, props, from)}
            onChange={(event) => {
              onChange(rowData, props, event.target.checked);
            }}
          />
        )
      ) : null}

      {/* {(checkedkeys.some((item) => item == rowData.id)) ? (
                button
            ): null} */}

      {/* <IndeterminateInput value={0} onChange={(value)=>{
          // onChange;
          onChange(rowData, props, value);
        }} /> */}
    </div>
  </Cell>
);

function check_data(rowData: any, propsData: any, from: any) {
  let outlet_index, channel_index, checked;
  outlet_index = propsData.outlet_index;
  channel_index = propsData.channel_index;
  if (from == "channel") {
    checked = rowData.outlets[outlet_index].channels[channel_index].checked;
  } else {
    checked =
      rowData.outlets[outlet_index].custom_channels[channel_index].checked;
  }
  return checked;
}

export default function ProductAssigningComponent(props: any) {
  const context = useContext(DataContext);
  const [table_loading, SetTableLoading] = useState(false);
  const [checked, SetChecked] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [width, SetWidth] = useState(innerWidth);
  const [id_value, SetIdValue] = useState(useParams());
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [value, setValue] = useState(30);

  ////////////////////////////////////////////////////////////////    10/06/2022
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);

  const [view_option, SetViewOption] = useState([
    { label: "New", value: "new" },
    { label: "Active", value: "active" },
    { label: "Unavailable", value: "unavailable" },
    { label: "Paused", value: "paused" },
  ]);
  const [selected_view_option, SetSelectedViewOption] = useState({});
  const [count_option, SetCountOption] = useState([
    { label: "10", value: "10" },
    { label: "20", value: "30" },
    { label: "30", value: "30" },
  ]);
  const [selected_count_option, SetSelectedCountOption] = useState({});
  const [filter_products_value, SetFilterProductsValue] = useState({
    own_products: false,
    others_products: false,
  });
  const [search_value, SetSearchValue] = useState("");
  const [sort_option, SetSortOption] = useState([
    { label: "Ascending order", value: "asce" },
    { label: "Descending order", value: "desc" },
  ]);
  const [selected_sort_option, SetSelectedSortOption] = useState({});
  const [product_brands_option, SetProductBrandsOption] = useState([]);
  const [selected_brand, SetSelectedBrand] = useState([]);
  const [product_categories_option, SetProductCategoriesOption] = useState([]);
  const [selected_category, SetSelectedCategory] = useState([]);
  const [filter_mrp, SetFilterMrp] = useState({
    min_mrp: "",
    max_mrp: "",
  });

  const [product_data, SetProductData] = useState([]);
  const [outlets, SetOutlet] = useState([]);
  const [size_value, SetSizeValue] = useState([]);
  const [image_base_url, SetImageBaseUrl] = useState("");

  useEffect(() => {
    console.log("Add Product On mount :", props);
    get_products();
  }, []);

  async function get_products() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        page: page_no,
        view:
          Object.keys(selected_view_option).length > 0
            ? selected_view_option.value
            : "",
        filter_count:
          Object.keys(selected_count_option).length > 0
            ? selected_count_option.value
            : "10",
        filter_products: encodeURIComponent(
          JSON.stringify(filter_products_value)
        ),
        search: search_value,
        sort:
          Object.keys(selected_sort_option).length > 0
            ? selected_sort_option.value
            : "desc",
        filter_brand: encodeURIComponent(selected_brand),
        filter_min_mrp: filter_mrp.min_mrp,
        filter_max_mrp: filter_mrp.max_mrp,
        filter_categories: encodeURIComponent(selected_category),
      },
    };
    console.log("get_products pass_data :", pass_data);

    let response = await api("/brand/outlet_product_list", pass_data);
    console.log("/brand/outlet_product_list response :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("data")) {
        SetProductData(response.response.data);
      }
      if (response.response.hasOwnProperty("options")) {
        if (response.response.options.hasOwnProperty("outlets")) {
          let outlet_value = response.response.options.outlets;
          let product_data = response.response.data;
          set_outlet_value(product_data, outlet_value);
          // SetOutlet(outlet_value);
        }
        if (response.response.options.hasOwnProperty("sizes")) {
          SetSizeValue(response.response.options.sizes);
        }
        if (response.response.options.hasOwnProperty("image_base_url")) {
          SetImageBaseUrl(response.response.options.image_base_url);
        }
      }
      if (response.response.hasOwnProperty("filter")) {
        if (response.response.filter.hasOwnProperty("product_brands")) {
          SetProductBrandsOption(response.response.filter.product_brands);
        }
        if (response.response.filter.hasOwnProperty("product_categories")) {
          SetProductCategoriesOption(
            response.response.filter.product_categories
          );
        }
      }
      if (response.response.hasOwnProperty("next_page")) {
        if (response.response.next_page) {
          SetNextPage(true);
          // SetPageNo((prevValue: any) => {
          //   prevValue = prevValue + 1;
          //   return prevValue;
          // });
        } else {
          SetNextPage(false);
        }
      }
    }
  }

  function set_outlet_value(product_data, outlet_value) {
    product_data.map((ele: any, index: any) => {
      ele.outlets.map((o_ele: any, o_index: any) => {
        o_ele.channels.map((c_ele: any, c_index: any) => {
          if (c_ele.checked == true) {
            outlet_value[o_index].channels[c_index].checkedKeys.push(
              ele.unique_id
            );
          }
        });
        o_ele.custom_channels.map((cc_ele: any, cc_index: any) => {
          if (cc_ele.checked == true) {
            outlet_value[o_index].custom_channels[cc_index].checkedKeys.push(
              ele.unique_id
            );
          }
        });
      });
      if (ele.hasOwnProperty("children")) {
        ele.children.map((child_ele: any, child_index: any) => {
          child_ele.outlets.map((o_ele: any, o_index: any) => {
            o_ele.channels.map((c_ele: any, c_index: any) => {
              if (c_ele.checked == true) {
                outlet_value[o_index].channels[c_index].checkedKeys.push(
                  child_ele.unique_id
                );
              }
            });
            o_ele.custom_channels.map((cc_ele: any, cc_index: any) => {
              if (cc_ele.checked == true) {
                outlet_value[o_index].custom_channels[
                  cc_index
                ].checkedKeys.push(child_ele.unique_id);
              }
            });
          });
          if (child_ele.hasOwnProperty("children")) {
            child_ele.children.map((child_ele1: any, child_index1: any) => {
              child_ele1.outlets.map((o_ele: any, o_index: any) => {
                o_ele.channels.map((c_ele: any, c_index: any) => {
                  if (c_ele.checked == true) {
                    outlet_value[o_index].channels[c_index].checkedKeys.push(
                      child_ele1.unique_id
                    );
                  }
                });
                o_ele.custom_channels.map((cc_ele: any, cc_index: any) => {
                  if (cc_ele.checked == true) {
                    outlet_value[o_index].custom_channels[
                      cc_index
                    ].checkedKeys.push(child_ele1.unique_id);
                  }
                });
              });
              if (child_ele1.hasOwnProperty("children")) {
                child_ele1.children.map(
                  (child_ele2: any, child_index2: any) => {
                    child_ele2.outlets.map((o_ele: any, o_index: any) => {
                      o_ele.channels.map((c_ele: any, c_index: any) => {
                        if (c_ele.checked == true) {
                          outlet_value[o_index].channels[
                            c_index
                          ].checkedKeys.push(child_ele2.unique_id);
                        }
                      });
                      o_ele.custom_channels.map(
                        (cc_ele: any, cc_index: any) => {
                          if (cc_ele.checked == true) {
                            outlet_value[o_index].custom_channels[
                              cc_index
                            ].checkedKeys.push(child_ele2.unique_id);
                          }
                        }
                      );
                    });
                  }
                );
              }
            });
          }
        });
      }
    });
    SetOutlet(outlet_value);
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function getData() {
    // return products;
    return product_data;
  }

  function OnCheckChange(rowData: any, propsData: any, event: any, from: any) {
    console.log("OnCheckChange rowData :", rowData);
    console.log("OnCheckChange propsData :", propsData);
    console.log("OnCheckChange event :", event);
    console.log("OnCheckChange from :", from);

    if (from == "custom_channel") {
      if (event.target.checked) {
        // on check event
        let child_id = [];
        child_id.push(rowData.unique_id);
        if (have_parent(rowData)) {
          let parent_object = rowData[Object.getOwnPropertySymbols(rowData)[0]];
          // console.log("parent_object.id :", parent_object.id);
          child_id.push(parent_object.unique_id);
          if (have_parent(parent_object)) {
            let parent_object_1 =
              parent_object[Object.getOwnPropertySymbols(parent_object)[0]];
            // console.log("parent_object_1.id :", parent_object_1.id);
            child_id.push(parent_object_1.unique_id);
            if (have_parent(parent_object_1)) {
              let parent_object_2 =
                parent_object_1[
                  Object.getOwnPropertySymbols(parent_object_1)[0]
                ];
              // console.log("parent_object_2.id :", parent_object_2.id);
              child_id.push(parent_object_2.unique_id);
            }
          }
        }
        if (have_child(rowData)) {
          rowData.children.map((ele, index) => {
            child_id.push(ele.unique_id);
            if (have_child(ele)) {
              ele.children.map((child, c_index) => {
                child_id.push(child.unique_id);
                if (have_child(child)) {
                  child.children.map((c_ele, c_e_index) => {
                    child_id.push(c_ele.unique_id);
                  });
                }
              });
            }
          });
        }
        console.log("child_ids :", child_id);
        let old_outlets_value = outlets;
        let o_i = propsData.outlet_index;
        let c_i = propsData.channel_index;
        let v1_value = old_outlets_value[o_i].custom_channels[c_i].checkedKeys;
        child_id.map((child, c_index) => {
          let find_value = v1_value.some((item) => item == child);
          if (!find_value) {
            old_outlets_value[propsData.outlet_index].custom_channels[
              propsData.channel_index
            ].checkedKeys.push(child);
          }
        });
        SetOutlet((prevValue: any) => {
          prevValue = old_outlets_value;
          return [...prevValue];
        });
      } else {
        console.log("uncheck event row_data :", rowData);
        let child_id = [];
        let old_outlets_value = outlets;
        let o_i = propsData.outlet_index;
        let c_i = propsData.channel_index;
        let v1_value = old_outlets_value[o_i].custom_channels[c_i].checkedKeys;
        child_id.push(rowData.unique_id);
        if (have_parent(rowData)) {
          let v1 = [];
          let parent_object = rowData[Object.getOwnPropertySymbols(rowData)[0]];

          parent_object.children.map((ele, index) => {
            if (ele.unique_id != rowData.unique_id) {
              v1.push(ele.unique_id);
            }
          });
          v1.map((ele, index) => {
            v1[index] = v1_value.some((item) => item == ele);
          });
          if (v1.every((item) => item === false)) {
            child_id.push(parent_object.unique_id);
            if (have_parent(parent_object)) {
              let v1 = [];
              let parent_object_1 =
                parent_object[Object.getOwnPropertySymbols(parent_object)[0]];
              parent_object_1.children.map((ele, index) => {
                if (ele.unique_id != parent_object.unique_id) {
                  v1.push(ele.unique_id);
                }
              });
              v1.map((ele, index) => {
                v1[index] = v1_value.some((item) => item == ele);
              });
              if (v1.every((item) => item === false)) {
                child_id.push(parent_object_1.unique_id);
                if (have_parent(parent_object_1)) {
                  let parent_object_2 =
                    parent_object_1[
                      Object.getOwnPropertySymbols(parent_object_1)[0]
                    ];
                  child_id.push(parent_object_2.unique_id);
                }
              }
            }
          }
        }
        if (have_child(rowData)) {
          rowData.children.map((ele, index) => {
            child_id.push(ele.unique_id);
            if (have_child(ele)) {
              ele.children.map((child, c_index) => {
                child_id.push(child.unique_id);
                if (have_child(child)) {
                  child.children.map((c_ele, c_e_index) => {
                    child_id.push(c_ele.unique_id);
                  });
                }
              });
            }
          });
        }
        console.log("child_id :", child_id);
        let to_save_data = v1_value.filter((v1) => {
          return (
            child_id.filter((child) => {
              return v1 == child;
            }).length == 0
          );
        });
        console.log("to_save_data :", to_save_data);
        old_outlets_value[o_i].custom_channels[c_i].checkedKeys = to_save_data;
        SetOutlet((prevValue: any) => {
          prevValue = old_outlets_value;
          return [...prevValue];
        });
        console.log("outlets :", outlets);
      }
    }

    if (from == "channel") {
      if (event.target.checked) {
        // on check event
        let child_id = [];
        child_id.push(rowData.unique_id);
        if (have_parent(rowData)) {
          let parent_object = rowData[Object.getOwnPropertySymbols(rowData)[0]];
          child_id.push(parent_object.unique_id);
          if (have_parent(parent_object)) {
            let parent_object_1 =
              parent_object[Object.getOwnPropertySymbols(parent_object)[0]];
            child_id.push(parent_object_1.unique_id);
            if (have_parent(parent_object_1)) {
              let parent_object_2 =
                parent_object_1[
                  Object.getOwnPropertySymbols(parent_object_1)[0]
                ];
              child_id.push(parent_object_2.unique_id);
            }
          }
        }
        if (have_child(rowData)) {
          rowData.children.map((ele, index) => {
            child_id.push(ele.unique_id);
            if (have_child(ele)) {
              ele.children.map((child, c_index) => {
                child_id.push(child.unique_id);
                if (have_child(child)) {
                  child.children.map((c_ele, c_e_index) => {
                    child_id.push(c_ele.unique_id);
                  });
                }
              });
            }
          });
        }
        console.log("child_ids :", child_id);
        let old_outlets_value = outlets;
        let o_i = propsData.outlet_index;
        let c_i = propsData.channel_index;
        let v1_value = old_outlets_value[o_i].channels[c_i].checkedKeys;
        child_id.map((child, c_index) => {
          let find_value = v1_value.some((item) => item == child);
          if (!find_value) {
            old_outlets_value[propsData.outlet_index].channels[
              propsData.channel_index
            ].checkedKeys.push(child);
          }
        });
        SetOutlet((prevValue: any) => {
          prevValue = old_outlets_value;
          return [...prevValue];
        });
      } else {
        console.log("uncheck event row_data :", rowData);
        let child_id = [];
        let old_outlets_value = outlets;
        let o_i = propsData.outlet_index;
        let c_i = propsData.channel_index;
        let v1_value = old_outlets_value[o_i].channels[c_i].checkedKeys;
        child_id.push(rowData.unique_id);
        if (have_parent(rowData)) {
          let v1 = [];
          let parent_object = rowData[Object.getOwnPropertySymbols(rowData)[0]];

          parent_object.children.map((ele, index) => {
            if (ele.unique_id != rowData.unique_id) {
              v1.push(ele.unique_id);
            }
          });
          v1.map((ele, index) => {
            v1[index] = v1_value.some((item) => item == ele);
          });
          if (v1.every((item) => item === false)) {
            child_id.push(parent_object.unique_id);
            if (have_parent(parent_object)) {
              let v1 = [];
              let parent_object_1 =
                parent_object[Object.getOwnPropertySymbols(parent_object)[0]];
              parent_object_1.children.map((ele, index) => {
                if (ele.unique_id != parent_object.unique_id) {
                  v1.push(ele.unique_id);
                }
              });
              v1.map((ele, index) => {
                v1[index] = v1_value.some((item) => item == ele);
              });
              if (v1.every((item) => item === false)) {
                child_id.push(parent_object_1.unique_id);
                if (have_parent(parent_object_1)) {
                  let parent_object_2 =
                    parent_object_1[
                      Object.getOwnPropertySymbols(parent_object_1)[0]
                    ];
                  child_id.push(parent_object_2.unique_id);
                }
              }
            }
          }
        }
        if (have_child(rowData)) {
          rowData.children.map((ele, index) => {
            child_id.push(ele.unique_id);
            if (have_child(ele)) {
              ele.children.map((child, c_index) => {
                child_id.push(child.unique_id);
                if (have_child(child)) {
                  child.children.map((c_ele, c_e_index) => {
                    child_id.push(c_ele.unique_id);
                  });
                }
              });
            }
          });
        }
        console.log("child_id :", child_id);
        let to_save_data = v1_value.filter((v1) => {
          return (
            child_id.filter((child) => {
              return v1 == child;
            }).length == 0
          );
        });
        console.log("to_save_data :", to_save_data);
        old_outlets_value[o_i].channels[c_i].checkedKeys = to_save_data;
        SetOutlet((prevValue: any) => {
          prevValue = old_outlets_value;
          return [...prevValue];
        });
        console.log("outlets :", outlets);
      }
    }
  }

  function OnCheckChange1(rowData: any, propsData: any, event: any, from: any) {
    console.log("OnCheckChange1 rowData :", rowData);
    console.log("OnCheckChange1 propsData :", propsData);
    console.log("OnCheckChange1 event :", event);
    console.log("OnCheckChange1 from :", from);

    if (from == "custom_channel") {
      if (event) {
        // on check event
        let child_id = [];
        child_id.push(rowData.unique_id);
        if (have_parent(rowData)) {
          let parent_object = rowData[Object.getOwnPropertySymbols(rowData)[0]];
          // console.log("parent_object.id :", parent_object.id);
          child_id.push(parent_object.unique_id);
          if (have_parent(parent_object)) {
            let parent_object_1 =
              parent_object[Object.getOwnPropertySymbols(parent_object)[0]];
            // console.log("parent_object_1.id :", parent_object_1.id);
            child_id.push(parent_object_1.unique_id);
            if (have_parent(parent_object_1)) {
              let parent_object_2 =
                parent_object_1[
                  Object.getOwnPropertySymbols(parent_object_1)[0]
                ];
              // console.log("parent_object_2.id :", parent_object_2.id);
              child_id.push(parent_object_2.unique_id);
            }
          }
        }
        if (have_child(rowData)) {
          rowData.children.map((ele, index) => {
            child_id.push(ele.unique_id);
            if (have_child(ele)) {
              ele.children.map((child, c_index) => {
                child_id.push(child.unique_id);
                if (have_child(child)) {
                  child.children.map((c_ele, c_e_index) => {
                    child_id.push(c_ele.unique_id);
                  });
                }
              });
            }
          });
        }
        console.log("child_ids :", child_id);
        let old_outlets_value = outlets;
        let o_i = propsData.outlet_index;
        let c_i = propsData.channel_index;
        let v1_value = old_outlets_value[o_i].custom_channels[c_i].checkedKeys;
        child_id.map((child, c_index) => {
          let find_value = v1_value.some((item) => item == child);
          if (!find_value) {
            old_outlets_value[propsData.outlet_index].custom_channels[
              propsData.channel_index
            ].checkedKeys.push(child);
          }
        });
        SetOutlet((prevValue: any) => {
          prevValue = old_outlets_value;
          return [...prevValue];
        });

        SetProductData((prevValue: any) => {
          child_id.map((ele: any, index: any) => {
            for (var i = 0; i < prevValue.length; i++) {
              let result = dfs(prevValue[i], ele);
              console.log("result :", result);
              if (result) {
                if (prevValue[i].unique_id == result.unique_id) {
                  prevValue[i].outlets[o_i].checked = true;
                  prevValue[i].outlets[o_i].custom_channels[c_i].checked = true;
                } else if (prevValue[i].children) {
                  for (var j = 0; j < prevValue[i].children.length; j++) {
                    if (
                      prevValue[i].children[j].unique_id == result.unique_id
                    ) {
                      prevValue[i].children[j].outlets[o_i].checked = true;
                      prevValue[i].children[j].outlets[o_i].custom_channels[
                        c_i
                      ].checked = true;
                    } else if (prevValue[i].children[j].children) {
                      for (
                        var k = 0;
                        k < prevValue[i].children[j].children.length;
                        k++
                      ) {
                        if (
                          prevValue[i].children[j].children[k].unique_id ==
                          result.unique_id
                        ) {
                          prevValue[i].children[j].children[k].outlets[
                            o_i
                          ].checked = true;
                          prevValue[i].children[j].children[k].outlets[
                            o_i
                          ].custom_channels[c_i].checked = true;
                        } else if (
                          prevValue[i].children[j].children[k].children
                        ) {
                          for (
                            var l = 0;
                            l <
                            prevValue[i].children[j].children[k].children
                              .length;
                            l++
                          ) {
                            if (
                              prevValue[i].children[j].children[k].children[l]
                                .unique_id == result.unique_id
                            ) {
                              prevValue[i].children[j].children[k].children[
                                l
                              ].outlets[o_i].checked = true;
                              prevValue[i].children[j].children[k].children[
                                l
                              ].outlets[o_i].custom_channels[c_i].checked =
                                true;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          });
          return [...prevValue];
        });
      } else {
        console.log("uncheck event row_data :", rowData);
        let child_id = [];
        let old_outlets_value = outlets;
        let o_i = propsData.outlet_index;
        let c_i = propsData.channel_index;
        let v1_value = old_outlets_value[o_i].custom_channels[c_i].checkedKeys;
        child_id.push(rowData.unique_id);
        if (have_parent(rowData)) {
          let v1 = [];
          let parent_object = rowData[Object.getOwnPropertySymbols(rowData)[0]];

          parent_object.children.map((ele, index) => {
            if (ele.unique_id != rowData.unique_id) {
              v1.push(ele.unique_id);
            }
          });
          v1.map((ele, index) => {
            v1[index] = v1_value.some((item) => item == ele);
          });
          if (v1.every((item) => item === false)) {
            // child_id.push(parent_object.unique_id);
            let tree_depth = rowData[Object.getOwnPropertySymbols(rowData)[2]];
            console.log("tree_depth :", tree_depth);
            if (tree_depth == "1" || tree_depth == "3") {
              child_id.push(parent_object.unique_id);
            }
            if (have_parent(parent_object)) {
              let v1 = [];
              let parent_object_1 =
                parent_object[Object.getOwnPropertySymbols(parent_object)[0]];
              parent_object_1.children.map((ele, index) => {
                if (ele.unique_id != parent_object.unique_id) {
                  v1.push(ele.unique_id);
                }
              });
              v1.map((ele, index) => {
                v1[index] = v1_value.some((item) => item == ele);
              });
              if (v1.every((item) => item === false)) {
                child_id.push(parent_object_1.unique_id);
                if (have_parent(parent_object_1)) {
                  let parent_object_2 =
                    parent_object_1[
                      Object.getOwnPropertySymbols(parent_object_1)[0]
                    ];
                  console.log("parent_object_2:", parent_object_2);

                  // child_id.push(parent_object_2.unique_id);
                }
              }
            }
          }
        }
        if (have_child(rowData)) {
          rowData.children.map((ele, index) => {
            child_id.push(ele.unique_id);
            if (have_child(ele)) {
              ele.children.map((child, c_index) => {
                child_id.push(child.unique_id);
                if (have_child(child)) {
                  child.children.map((c_ele, c_e_index) => {
                    child_id.push(c_ele.unique_id);
                  });
                }
              });
            }
          });
        }
        console.log("child_id :", child_id);
        let to_save_data = v1_value.filter((v1) => {
          return (
            child_id.filter((child) => {
              return v1 == child;
            }).length == 0
          );
        });
        console.log("to_save_data :", to_save_data);

        old_outlets_value[o_i].custom_channels[c_i].checkedKeys = to_save_data;
        SetOutlet((prevValue: any) => {
          prevValue = old_outlets_value;
          return [...prevValue];
        });
        console.log("outlets :", outlets);
        SetProductData((prevValue: any) => {
          child_id.map((ele: any, index: any) => {
            for (var i = 0; i < prevValue.length; i++) {
              let result = dfs(prevValue[i], ele);
              console.log("result :", result);
              if (result) {
                if (prevValue[i].unique_id == result.unique_id) {
                  prevValue[i].outlets[o_i].custom_channels[c_i].checked =
                    false;
                  prevValue[i].outlets[o_i].custom_channels[c_i].paused = true;
                } else if (prevValue[i].children) {
                  for (var j = 0; j < prevValue[i].children.length; j++) {
                    if (
                      prevValue[i].children[j].unique_id == result.unique_id
                    ) {
                      prevValue[i].children[j].outlets[o_i].custom_channels[
                        c_i
                      ].checked = false;
                      prevValue[i].children[j].outlets[o_i].custom_channels[
                        c_i
                      ].paused = true;
                    } else if (prevValue[i].children[j].children) {
                      for (
                        var k = 0;
                        k < prevValue[i].children[j].children.length;
                        k++
                      ) {
                        if (
                          prevValue[i].children[j].children[k].unique_id ==
                          result.unique_id
                        ) {
                          prevValue[i].children[j].children[k].outlets[
                            o_i
                          ].custom_channels[c_i].checked = false;
                          prevValue[i].children[j].children[k].outlets[
                            o_i
                          ].custom_channels[c_i].paused = true;
                        } else if (
                          prevValue[i].children[j].children[k].children
                        ) {
                          for (
                            var l = 0;
                            l <
                            prevValue[i].children[j].children[k].children
                              .length;
                            l++
                          ) {
                            if (
                              prevValue[i].children[j].children[k].children[l]
                                .unique_id == result.unique_id
                            ) {
                              prevValue[i].children[j].children[k].children[
                                l
                              ].outlets[o_i].custom_channels[c_i].checked =
                                false;
                              prevValue[i].children[j].children[k].children[
                                l
                              ].outlets[o_i].custom_channels[c_i].paused = true;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          });
          console.log("SetProductData prevValue :", prevValue);
          return [...prevValue];
        });
      }
    }

    if (from == "channel") {
      if (event) {
        // on check event
        let child_id = [];
        child_id.push(rowData.unique_id);
        if (have_parent(rowData)) {
          let parent_object = rowData[Object.getOwnPropertySymbols(rowData)[0]];
          child_id.push(parent_object.unique_id);
          if (have_parent(parent_object)) {
            let parent_object_1 =
              parent_object[Object.getOwnPropertySymbols(parent_object)[0]];
            child_id.push(parent_object_1.unique_id);
            if (have_parent(parent_object_1)) {
              let parent_object_2 =
                parent_object_1[
                  Object.getOwnPropertySymbols(parent_object_1)[0]
                ];
              child_id.push(parent_object_2.unique_id);
            }
          }
        }
        if (have_child(rowData)) {
          rowData.children.map((ele, index) => {
            child_id.push(ele.unique_id);
            if (have_child(ele)) {
              ele.children.map((child, c_index) => {
                child_id.push(child.unique_id);
                if (have_child(child)) {
                  child.children.map((c_ele, c_e_index) => {
                    child_id.push(c_ele.unique_id);
                  });
                }
              });
            }
          });
        }
        console.log("child_ids :", child_id);
        let old_outlets_value = outlets;
        let o_i = propsData.outlet_index;
        let c_i = propsData.channel_index;
        let v1_value = old_outlets_value[o_i].channels[c_i].checkedKeys;
        child_id.map((child, c_index) => {
          let find_value = v1_value.some((item) => item == child);
          if (!find_value) {
            old_outlets_value[propsData.outlet_index].channels[
              propsData.channel_index
            ].checkedKeys.push(child);
          }
        });
        SetOutlet((prevValue: any) => {
          prevValue = old_outlets_value;
          return [...prevValue];
        });

        SetProductData((prevValue: any) => {
          child_id.map((ele: any, index: any) => {
            for (var i = 0; i < prevValue.length; i++) {
              let result = dfs(prevValue[i], ele);
              // console.log("result :", result);
              if (result) {
                if (prevValue[i].unique_id == result.unique_id) {
                  prevValue[i].outlets[o_i].checked = true;
                  prevValue[i].outlets[o_i].channels[c_i].checked = true;
                } else if (prevValue[i].children) {
                  for (var j = 0; j < prevValue[i].children.length; j++) {
                    if (
                      prevValue[i].children[j].unique_id == result.unique_id
                    ) {
                      prevValue[i].children[j].outlets[o_i].checked = true;
                      prevValue[i].children[j].outlets[o_i].channels[
                        c_i
                      ].checked = true;
                    } else if (prevValue[i].children[j].children) {
                      for (
                        var k = 0;
                        k < prevValue[i].children[j].children.length;
                        k++
                      ) {
                        if (
                          prevValue[i].children[j].children[k].unique_id ==
                          result.unique_id
                        ) {
                          prevValue[i].children[j].children[k].outlets[
                            o_i
                          ].checked = true;
                          prevValue[i].children[j].children[k].outlets[
                            o_i
                          ].channels[c_i].checked = true;
                        } else if (
                          prevValue[i].children[j].children[k].children
                        ) {
                          for (
                            var l = 0;
                            l <
                            prevValue[i].children[j].children[k].children
                              .length;
                            l++
                          ) {
                            if (
                              prevValue[i].children[j].children[k].children[l]
                                .unique_id == result.unique_id
                            ) {
                              prevValue[i].children[j].children[k].children[
                                l
                              ].outlets[o_i].checked = true;
                              prevValue[i].children[j].children[k].children[
                                l
                              ].outlets[o_i].channels[c_i].checked = true;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          });
          return [...prevValue];
        });
      } else {
        console.log("uncheck event row_data channel:");
        let child_id = [];
        let old_outlets_value = outlets;
        let o_i = propsData.outlet_index;
        let c_i = propsData.channel_index;
        let v1_value = old_outlets_value[o_i].channels[c_i].checkedKeys;
        child_id.push(rowData.unique_id);
        if (have_parent(rowData)) {
          console.log("product varriant");
          let v1 = [];
          let parent_object = rowData[Object.getOwnPropertySymbols(rowData)[0]];
          console.log("parent_object :", parent_object);
          parent_object.children.map((ele, index) => {
            if (ele.unique_id != rowData.unique_id) {
              v1.push(ele.unique_id);
            }
          });
          v1.map((ele, index) => {
            v1[index] = v1_value.some((item) => item == ele);
          });
          console.log("v1 :", v1);
          if (v1.every((item) => item === false)) {
            console.log(
              "parent_object.unique_id push :",
              parent_object.unique_id
            );
            let tree_depth = rowData[Object.getOwnPropertySymbols(rowData)[2]];
            console.log("tree_depth :", tree_depth);
            if (tree_depth == "1" || tree_depth == "3") {
              child_id.push(parent_object.unique_id);
            }
            // child_id.push(parent_object.unique_id);
            if (have_parent(parent_object)) {
              console.log("addon group");

              let v2 = [];
              let parent_object_1 =
                parent_object[Object.getOwnPropertySymbols(parent_object)[0]];
              console.log("parent_object_1 :", parent_object_1);
              parent_object_1.children.map((ele, index) => {
                if (ele.unique_id != parent_object.unique_id) {
                  v2.push(ele.unique_id);
                }
              });
              v2.map((ele, index) => {
                v2[index] = v1_value.some((item) => item == ele);
              });
              console.log("v2 :", v2);
              if (v2.every((item) => item === false)) {
                // child_id.push(parent_object_1.unique_id);
                if (have_parent(parent_object_1)) {
                  console.log("addon item");

                  let parent_object_2 =
                    parent_object_1[
                      Object.getOwnPropertySymbols(parent_object_1)[0]
                    ];
                  console.log("parent_object_2:", parent_object_2);
                  // child_id.push(parent_object_2.unique_id);
                }
              }
            }
          }
        }
        if (have_child(rowData)) {
          rowData.children.map((ele, index) => {
            child_id.push(ele.unique_id);
            if (have_child(ele)) {
              ele.children.map((child, c_index) => {
                child_id.push(child.unique_id);
                if (have_child(child)) {
                  child.children.map((c_ele, c_e_index) => {
                    child_id.push(c_ele.unique_id);
                  });
                }
              });
            }
          });
        }
        console.log("child_id :", child_id);
        console.log("v1_value :", v1_value);

        let to_save_data = v1_value.filter((v1) => {
          return (
            child_id.filter((child) => {
              return v1 == child;
            }).length == 0
          );
        });
        console.log("to_save_data :", to_save_data);
        old_outlets_value[o_i].channels[c_i].checkedKeys = to_save_data;
        SetOutlet((prevValue: any) => {
          prevValue = old_outlets_value;
          return [...prevValue];
        });
        console.log("outlets :", outlets);
        SetProductData((prevValue: any) => {
          child_id.map((ele: any, index: any) => {
            for (var i = 0; i < prevValue.length; i++) {
              let result = dfs(prevValue[i], ele);
              console.log("result :", result);
              if (result) {
                if (prevValue[i].unique_id == result.unique_id) {
                  prevValue[i].outlets[o_i].channels[c_i].checked = false;
                  prevValue[i].outlets[o_i].channels[c_i].paused = true;
                } else if (prevValue[i].children) {
                  for (var j = 0; j < prevValue[i].children.length; j++) {
                    if (
                      prevValue[i].children[j].unique_id == result.unique_id
                    ) {
                      prevValue[i].children[j].outlets[o_i].channels[
                        c_i
                      ].checked = false;
                      prevValue[i].children[j].outlets[o_i].channels[
                        c_i
                      ].paused = true;
                    } else if (prevValue[i].children[j].children) {
                      for (
                        var k = 0;
                        k < prevValue[i].children[j].children.length;
                        k++
                      ) {
                        if (
                          prevValue[i].children[j].children[k].unique_id ==
                          result.unique_id
                        ) {
                          prevValue[i].children[j].children[k].outlets[
                            o_i
                          ].channels[c_i].checked = false;
                          prevValue[i].children[j].children[k].outlets[
                            o_i
                          ].channels[c_i].paused = true;
                        } else if (
                          prevValue[i].children[j].children[k].children
                        ) {
                          for (
                            var l = 0;
                            l <
                            prevValue[i].children[j].children[k].children
                              .length;
                            l++
                          ) {
                            if (
                              prevValue[i].children[j].children[k].children[l]
                                .unique_id == result.unique_id
                            ) {
                              prevValue[i].children[j].children[k].children[
                                l
                              ].outlets[o_i].channels[c_i].checked = false;
                              prevValue[i].children[j].children[k].children[
                                l
                              ].outlets[o_i].channels[c_i].paused = true;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          });
          console.log("SetProductData prevValue :", prevValue);
          return [...prevValue];
        });
      }
    }
  }

  function dfs(obj, targetId) {
    if (obj.unique_id === targetId) {
      return obj;
    }
    if (obj.children) {
      for (let item of obj.children) {
        let check = dfs(item, targetId);
        if (check) {
          return check;
        }
      }
    }
    return null;
  }

  function have_child(data: any) {
    if (data.hasOwnProperty("children")) {
      return true;
    } else {
      return false;
    }
  }

  function have_parent(data: any) {
    let parent_object = data[Object.getOwnPropertySymbols(data)[0]];
    if (parent_object != null) {
      if (Object.keys(parent_object).length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function sample_fun(anchor: any, open: any, event: any) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  }

  async function submit_product_data() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        data: product_data,
      },
    };
    console.log("pass_data :", pass_data);
    let response = await api(
      "/brand/save_outlet_products",
      pass_data,
      false,
      false,
      "raw_data"
    );
    console.log("/brand/save_outlet_products response :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("data")) {
        get_products();
      }
    }
  }

  return (
    <div className="">
      <div className="p-3 ">
        <div className="bg-fff p-3">
          <div className="d-flex mb-3">
            <div
              className="d-flex align-items-center cursor"
              onClick={(event) => {
                if (width < 768) {
                  sample_fun("bottom", true, event);
                } else {
                  sample_fun("right", true, event);
                }
              }}
            >
              <Ionicons name="filter" size={18} color="black" />
              <h6 className=" mb-0 ps-2">Filter</h6>
            </div>
            <div className="ms-auto">
              <button
                className="btn btn-primary"
                onClick={() => {
                  console.log("product data :", product_data);
                  submit_product_data();
                }}
              >
                Submit
              </button>
            </div>
          </div>

          <Table
            bordered
            cellBordered
            headerHeight={80}
            data={getData()}
            loading={table_loading}
            height={800}
            rowKey="unique_id"
            isTree
            // defaultExpandAllRows
            // onExpandChange={(isOpen, rowData) => {
            //   console.log("onExpandChange :", isOpen, rowData);
            //   // console.log(
            //   //   "rowData.parent :",
            //   //   rowData[Object.getOwnPropertySymbols(rowData)[0]]
            //   // );
            // }}
            // renderTreeToggle={(icon, rowData) => {
            //   if (rowData.children && rowData.children.length === 0) {
            //     return <Spinner spin />;
            //   }
            //   return icon;
            // }}
            onRowClick={(data) => {
              // console.log("onRowClick data :", data);
            }}
            shouldUpdateScroll={false}
          >
            <Column width={300} align="left">
              <HeaderCell align="center">Products</HeaderCell>
              <Cell dataKey="name" />
            </Column>

            {outlets.map((ele, index) => (
              <ColumnGroup header={ele.name} align="center" key={index}>
                <>
                  {
                    ele.channels.length > 0
                      ? ele.channels.map((c_ele, c_index) => (
                          <Column
                            width={200}
                            align="center"
                            key={c_index}
                            outlet_index={index}
                            channel_index={c_index}
                            from="channel"
                          >
                            <HeaderCell>
                              <div style={{ lineHeight: "40px" }}>
                                {c_ele.name}
                              </div>
                            </HeaderCell>
                            <CheckCell
                              checkedkeys={c_ele.checkedKeys}
                              from="channel"
                              onChange={(
                                rowData: any,
                                propsData: any,
                                event: any
                              ) => {
                                OnCheckChange1(
                                  rowData,
                                  propsData,
                                  event,
                                  "channel"
                                );
                              }}
                            />
                          </Column>
                        ))
                      : null
                    // <Column width={200}>
                    //   <HeaderCell>
                    //     <div style={{ lineHeight: "40px" }}>No Channels</div>
                    //   </HeaderCell>
                    //   <Cell></Cell>
                    // </Column>
                  }
                  {
                    ele.custom_channels.length > 0
                      ? ele.custom_channels.map((cc_ele, cc_index) => (
                          <Column
                            width={200}
                            align="center"
                            key={cc_index}
                            outlet_index={index}
                            channel_index={cc_index}
                            from="custom_channel"
                          >
                            <HeaderCell>
                              <div style={{ lineHeight: "40px" }}>
                                {cc_ele.name}
                              </div>
                            </HeaderCell>
                            <CheckCell
                              checkedkeys={cc_ele.checkedKeys}
                              from="custom_channel"
                              onChange={(
                                rowData: any,
                                propsData: any,
                                event: any
                              ) => {
                                OnCheckChange1(
                                  rowData,
                                  propsData,
                                  event,
                                  "custom_channel"
                                );
                              }}
                            />
                          </Column>
                        ))
                      : null
                    // <Column width={200}>
                    //   <HeaderCell>
                    //     <div style={{ lineHeight: "40px" }}>
                    //       No Custom Channels
                    //     </div>
                    //   </HeaderCell>
                    //   <Cell></Cell>
                    // </Column>
                  }
                </>
              </ColumnGroup>
            ))}
          </Table>
        </div>
      </div>

      {/* Filter */}
      {["left", "right", "top", "bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={(event) => {
              sample_fun(anchor, false, event);
            }}
            onOpen={(event) => {
              sample_fun(anchor, true, event);
            }}
          >
            <div className="bg-fff p-3">
              <div className="d-flex ">
                <h6>Filter</h6>
                <div
                  className="ms-auto cursor"
                  onClick={() => {
                    sample_fun(anchor, false, event);
                  }}
                >
                  <small>Clear</small>
                </div>
              </div>
              <div className="my-3">
                <label className="form-label d-flex align-items-center">
                  <input type="checkbox" className="form-check me-2" />
                  <small>Own Brand Products</small>
                </label>
                <label className="form-label d-flex align-items-center">
                  <input type="checkbox" className="form-check me-2" />
                  <small>Other Brand Products</small>
                </label>
              </div>

              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="acc-head-id">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acc-id"
                      aria-expanded="false"
                      aria-controls="acc-id"
                    >
                      Brand
                    </button>
                  </h2>
                  <div
                    id="acc-id"
                    className="accordion-collapse collapse "
                    aria-labelledby="acc-head-id"
                  >
                    <div className="accordion-body">
                      <input
                        type="text"
                        placeholder="Search..."
                        className="form-control"
                      />
                      <div className="p-2">
                        <label className="form-label mt-3 d-flex align-items-center">
                          <input type="checkbox" className="me-2" />
                          Polo
                        </label>
                        <label className="form-label mt-3 d-flex align-items-center">
                          <input type="checkbox" className="me-2" />
                          Polo
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <Typography id="range-slider" gutterBottom>
                  Mininum Maximum Price
                </Typography>
                <div className="d-flex align-items-center">
                  {/* <p className="pe-3">Min</p> */}
                  <Slider
                    value={value}
                    onChange={handleChange}
                    onClick={() => {
                      console.log("min max :", value);
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                  />
                  {/* <p className="px-3">Max</p> */}
                </div>

                <div className="row">
                  <div className="col-6">
                    <label className="form-label">Min Price</label>
                    <input
                      type="text"
                      className="form-control"
                      value={value[0]}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">Max Price</label>
                    <input
                      type="text"
                      className="form-control"
                      value={value[1]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5 mt-2">
              <div
                className="text-center p-3 cursor bottom bg-primary text-white"
                onClick={(event) => {
                  sample_fun(anchor, false, event);
                }}
              >
                Save
              </div>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
