import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams } from "react-router-dom";
import ProductAssigningComponent from "../../../components/ProductAssigningComponent/ProductAssigningComponent";

export default function OutletProductAssign(props: any) {
    const context = useContext(DataContext);
    const [id_value, SerIdValue] = useState(useParams());

    useEffect(() => {
        console.log("Add Product On mount :", props);
        console.log("Add Product context :", context);
    }, []);

    return (
        <div>
            <div className="sm-mb-2 d-flex align-items-center head-bar">
                <Link to={"/outlet/" + id_value.brand_id + "/" + id_value.outlet_id +"/addproducts"}>
                    <img src={require("../../../assets/icon/svg/arrow-left.svg")} className="cursor pe-2" />
                </Link>
                <h6 className="mb-0">Products Assigning</h6>
            </div>

            <div className="mt-1">
                <ProductAssigningComponent from="outlet" />
            </div>
        </div>
    );
}