import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import "./OutletSideMenu.css";
import DataContext from "../../utils/Context/DataContext";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { AppConfig } from "../../config";

const menu_initial_value = [
  {
    id: 1,
    name: "Dashboard",
    path: "dashboard",
    img: require("../../assets/icon/svg/grid.svg"),
  },
  {
    id: 2,
    name: "Orders",
    path: "orders/active",
    img: require("../../assets/icon/svg/users.svg"),
  },
  {
    id: 6,
    name: "Manage Products",
    path: "manage_products",
    img: require("../../assets/icon/svg/users.svg"),
  },
  {
    id: 3,
    name: "Inventory",
    img: require("../../assets/icon/svg/grid.svg"),
    is_open: true,
    children: [
      {
        id: 3.1,
        name: "Products",
        path: "products",
        img: require("../../assets/icon/svg/grid.svg"),
      },
      {
        id: 3.2,
        name: "Addons",
        path: "addons",
      },
    ],
  },
  {
    id: 4,
    name: "Available Status",
    is_open: true,
    children: [
      {
        id: 4.1,
        name: "Product Groups",
        path: "product_group_available",
      },
      {
        id: 4.2,
        name: "Products",
        path: "product_available",
      },
      {
        id: 4.3,
        name: "Addon Groups",
        path: "addon_group_available",
      },
      {
        id: 4.4,
        name: "Addon Items",
        path: "addon_item_list",
      },
    ],
  },
  // {
  //   id: 4,
  //   name: "Channels",
  //   path: "channels",
  //   img: require("../../assets/icon/svg/user-check.svg"),
  // },
  
  // {
  //   id: 6,
  //   name: "Setting",
  //   path: "setting",
  //   img: require("../../assets/icon/svg/pie-chart.svg"),
  // },
  {
    id: 6,
    name: "Settlement",
    path: "outlet_settlement",
    img: require("../../assets/icon/svg/calendar.svg"),
  },
  {
    id: 5,
    name: "Users",
    path: "users",
    img: require("../../assets/icon/svg/calendar.svg"),
  },
  // {
  //   id: 8,
  //   name: "Collection",
  //   path: "collection",
  //   img: require("../../assets/icon/svg/settings.svg"),
  // },
  // {
  //   id: 9,
  //   name: "Users",
  //   path: "users",
  //   img: require("../../assets/icon/svg/users.svg"),
  // },
  // {
  //   id: 6,
  //   name: "SAMPLE DROPDOWN",
  //   is_open: true,
  //   children: [
  //     {
  //       id: 6.1,
  //       name: "DROPDOWN1",
  //       path: "dp1",
  //     },
  //     {
  //       id: 6.2,
  //       name: "DROPDOWN2",
  //       path: "dp2",
  //     },
  //   ],
  // },
];

export default function BrandSideMenu(props: any) {
  const context:any = useContext(DataContext);
  const [width, SetWidth] = useState(innerWidth);
  const [is_active, SetIsActive] = useState(props.active);
  const [menu_items, SetMenuItems]:any = useState(menu_initial_value);
  const [user_data, SetUserData] = useState({});
  const [id_value, SerIdValue] = useState(useParams());
  const [view_data, SetViewData] = useState({});
  const [current_outlet, SetCurrentOutlet]:any = useState({});

  useEffect(() => {
    console.log("AdminSideMenu On mount :", props);
    console.log("AdminSideMenu On id_value :", localStorage );

    console.log("BrandSideMenu On id_value :", localStorage.view_data
    );
    let view_value = localStorage.getItem("view_data");
    SetViewData(JSON.parse(view_value));
    return () => {
      // SetMenuItems([...menu_initial_value]);
      // console.log("menu_initial_value On mount :", menu_items);
    }
  }, []);

  useEffect(() => {
    console.log("AdminSideMenu On props.active :", props);
    SetIsActive(props.active);
  }, [props.active]);

  useEffect(() => {
    if(context.app_data?.current_outlet?.outlet_id){
      SetCurrentOutlet(context.app_data.current_outlet);
    }
  }, [context.app_data.current_outlet]);

  function dropdown_close() {
    let items = menu_items;
    items.map((item) => {
      if (item.hasOwnProperty("is_open")) {
        item.is_open = false;
      }
    });
    SetMenuItems([...items]);
  }

  function dropdown_action(m_item, m_index) {
    // let items = menu_items;
    // items.map((item) => {
    //   if (item.hasOwnProperty("is_open")) {
    //     item.is_open = false;
    //   }
    // });
    // items[m_index].is_open = !m_item.is_open;
    // SetMenuItems([...items]);

    // SetMenuItems((items:any) => {
    //   items.map((item, i) => {
    //     if(i != m_index){
    //       if (item.hasOwnProperty("is_open")) {
    //         item.is_open = false;
    //       }
    //     }
    //   });
    //   items[m_index].is_open = !m_item.is_open;
    //   return [...items];
    let items = menu_items;
    items[m_index].is_open = !m_item.is_open;
    SetMenuItems([...items]);
    }
  

  return (
    <div className="pb-5">
      <div className={is_active ? "sidebar active" : "sidebar"}>
        <div className={is_active ? "sidebar-w" : ""}>
          <div className="navbar-profile pt-sm-0 pt-md-2 py-3">
            <div className="d-sm-block d-md-none ">
              <div className="d-flex align-items-center">
                {/* <div className="p-2 cursor">
                  <AntDesign name="logout" size={17} color="#777" />
                </div> */}
                {/* <div
                  className=" p-2 cursor ms-auto"
                  onClick={() => {
                    if (width < 786) {
                      props.menu_click(false);
                    }
                  }}
                >
                  <Ionicons name="ios-close" size={20} color="#777" />
                </div> */}
              </div>
            </div>
            <div className="p-2 img-radius">
              {current_outlet?.outlet_profile ? (
                <img
                  src={current_outlet.outlet_profile}
                  className="prof-img"
                />
              ) : current_outlet?.brand_profile && (
                <img
                  src={current_outlet.brand_profile}
                  className="prof-img"
                />
              )}
            </div>
            <h6 className="mb-0 pt-2">{current_outlet?.brand_name}</h6>
            <p className="pt-2 text-darkgray v-small">{current_outlet?.outlet_name}</p>
            <p className="pt-2 v-small">Version {AppConfig.version_number}</p>

          </div>
          {/* <div className="d-lg-none d-flex">
            <div
              onClick={() => {
                props.menu_click(false);
              }}
            >
              <img
                src={require("../../assets/icon/svg/x.svg")}
                className="p-2 cursor"
              />
            </div>
            <div className="ms-auto">
              <img
                src={require("../../assets/icon/svg/log-out.svg")}
                className="p-2 cursor"
              />
            </div>
          </div> */}

          {/* <div className="fs-5 fw-bolder pt-2">{user_data.name}</div> */}

          <div className="sidebar-sticky pb-5 bg-fff">
            <ul>
              {menu_items.map((m_item, m_index) =>
                m_item.hasOwnProperty("children") ? (
                  <li className="sidebar-dropdown" key={m_index}>
                    <a
                      className={
                        m_item.is_open
                          ? "sidebar-dropdown-btn active"
                          : "sidebar-dropdown-btn"
                      }
                      role="button"
                      onClick={() => {
                        dropdown_action(m_item, m_index);
                      }}
                    >
                      {/* <i className="fas fa-cogs"></i>  */}
                      {m_item.name}
                      <i className={
                        m_item.is_open
                          ? "fas fa-chevron-up sidebar-dropdown-arrow"
                          : "fas fa-chevron-down sidebar-dropdown-arrow"
                      }></i>
                    </a>
                    <div className="sidebar-dropdown-menu">
                      <ul>
                        {m_item.children.map((m_c_item, m_c_index) => (
                          <li key={m_c_index}>
                            <NavLink
                              className="link-loader"
                              activeclassname="link-active"
                              to={m_c_item.path}
                              onClick={() => {
                                if (width < 786) {
                                  props.menu_click(false);
                                }
                                // dropdown_close();
                              }}
                            >
                              {/* <i className="fas fa-gifts"></i> */}
                               {m_c_item.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ) : (
                  <li key={m_index}>
                    <NavLink
                      className="link-loader"
                      activeclassname="link-active"
                      to={m_item.path}
                      onClick={() => {
                        // dropdown_close();
                        if (width < 786) {
                          props.menu_click(false);
                        }
                      }}
                    >
                      {/* <img src={m_item.img} className="icon-img" /> */}
                      {m_item.name}
                    </NavLink>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
