import React, { useState, useEffect, useContext } from "react";
import { Outlet, Link, useLocation, useParams } from "react-router-dom";
import Footer from "../Footer/Footer";

import DataContext from "../../utils/Context/DataContext";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "../../utils/Loader";
import { Toaster } from "react-hot-toast";
import { api } from "../../utils/Api";
import BrandSideMenu from "../BrandSideMenu/BrandSideMenu";
import Header from "../Header/Header";
import SideMenu from "../SideMenu/SideMenu";

export default function AdminLayout(props: any) {
  const context = useContext(DataContext);
  const location = useLocation();
  const [id_value, SetIdValue] = useState(useParams());
  const [width, SetWidth] = useState(innerWidth);
  const [is_menu_open, SetIsMenuOpen] = useState(width < 786 ? false : true);
  const [is_mobile_menu_open, SetIsMobileMenuOpen] = useState(false);
  
  useEffect(() => {
    console.log("AdminLayout onmount context :", context);
    get_init();
    // get_locales();
  }, []);

  async function get_locales() {
    let pass_data = {
      get: { lang: context.app_data.selected_language.value },
    };
    let response = await api("/locales", pass_data);
    console.log("locales :", response);

    if (response.response.hasOwnProperty("data")) {
      context.SetAppData((prevValue) => {
        prevValue.language_data = response.response.data;
        return { ...prevValue };
      });
    }
  }

  async function get_init() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let response = await api("/brand/init", pass_data);
    console.log("/brand/init :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("countries")) {
        context.SetAppData((prevValue: any) => {
          prevValue.current_brand = response.response.accounts.active;
          prevValue.country_data = response.response.countries;
          return { ...prevValue };
        });
      }
    }
  }

  // useEffect(() => {
  //   context.SetSampleData("hai");
  // }, [location]);

  function on_menu_click(data: any) {
    console.log("BrandLayout on menu/back click :", data);
    SetIsMenuOpen(data);
  }

  function footer_menu_on_click(data: any) {
    SetIsMobileMenuOpen(data);
  }

  return (
    <>
      {/* <Loader promiseTracker={usePromiseTracker} /> */}
      <div className="page-body">
        {/* {width > 720 ? ( */}
          {is_menu_open ? (
            <BrandSideMenu
              active={is_menu_open}
              menu_click={(data: any) => {
                on_menu_click(data);
              }}
            />
          ) : null}
        {/* ) : null} */}
        <div className={is_menu_open ? "page-content" : ""}>
          <Header
            is_menu_open={is_menu_open}
            menu_click={(data: any) => {
              console.log("Header menu_click data :", data);
              on_menu_click(data);
            }}
          />
          <div
            className={is_menu_open == false && width > 786 ? "page-main" : ""}
          >
            <Outlet context={is_menu_open} />
            <Toaster
              position="top-center"
              reverseOrder={false}
              toastOptions={{
                className: "",
                style: {
                  border: "1px solid #171546",
                  padding: "10px",
                },
                duration: 1500,
              }}
            />
          </div>
        </div>
      </div>
      <Footer
        is_menu_open = {is_menu_open}
        menu_click={(data: any) => {
          on_menu_click(data);
          // footer_menu_on_click(data);
        }}
        from="brand"
      />
    </>
  );
}
