import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import {
  MaterialCommunityIcons,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import ModalVariants from "../../Brands/Products/ModalVariants";
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'


function valuetext(value) {
  return `${value}°C`;
}

export default function OutletAddProducts(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext()
  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    product_name: "",
    product_id: "",
    product_checked: "",
    data: {},
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [filter_open, SetFilterOpen] = useState(false);
  const [sort_open, SetSortOpen] = useState(false);
  const [value, setValue] = React.useState([20, 37]);
  const [product_data, SetProductData] = useState([]);
  const [data, SetData] = useState([]);
  const [width, SetWidth] = useState(innerWidth);

  // const [sliderWidth, SetSlideWidth] = useState(0);
  // const [offsetSliderWidht, SetOffsetSlideWidth] = useState(0);
  // const [min, SetMin] = useState(0);
  // const [max, SetMax] = useState(200);
  // const [minValueBetween, SetMinValueBetween] = useState(10);
  // const [currentMin, SetCurrentMin] = useState(55);
  // const [inputMin, SetInputMin] = useState(55);
  // const [currentMax, SetCurrentMax] = useState(100);
  // const [inputMax, SetInputMax] = useState(100);

  useEffect(() => {
    console.log("OutletAddProducts On mount :", props);
    console.log("OutletAddProducts context :", context);
    get_add_products();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function get_add_products() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        page: 1,
      },
    };
    let response: any = await api("/brand/add_brand_product_list", pass_data);
    console.log("/brand/add_brand_product_list :", response);
    if (response.status_code == 200) {
      SetData(response.response);

      if (response.response.hasOwnProperty("data")) {
        SetProductData(response.response.data);
      } else {
        SetProductData([]);
      }
    }
  }

  return (
    <div>
      <div className={OutletContext ? "head-bar sticky-sm" : "head-bar  sticky-sm"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className=" row align-items-center">
          <div className="col-7 sm-mb-2 d-flex align-items-center">
            <Link
              to={
                "/brand/" +
                id_value.brand_id +
                "/" +
                id_value.outlet_id +
                "/products"
              }
            >
              <MaterialCommunityIcons name="arrow-left" size={20} color="black" />
            </Link>
            <h6 className="mb-0 ms-2">Add Products</h6>
          </div>
          <div className="col-5 ps-0 text-end sm-mb-2">
            <button className="btn btn-custom-outline  btn-sm">Create Products</button>
          </div>
        </div>
      </div>

      <div className="mt-3 px-md-3 px-sm-0"

        style={width < 786 ? (
          OutletContext == false ? { width: width } : {}
        ) : (
          OutletContext == false ? { width: width} : {}
        )} >
        <div className="row p-3">
          <div className="col-md-4 md-ps-0 sm-p-0">
            <div className="bg-fff p-3 sticky-md-50px">
              <div className="d-flex ">
                <h6>Filter</h6>
                <div className="ms-auto cursor">
                  <small className='text-black'>Clear</small>
                </div>
              </div>
              <div className="my-3">
                <label className="form-label d-flex align-items-center">
                  <input type="checkbox" className="form-check-input me-2" />
                 <p className='capitalize'>Own Brand Products</p>
                </label>
                <label className="form-label d-flex align-items-center">
                  <input type="checkbox" className="form-check-input me-2" />
                 <p className='capitalize'>Other Brand Products</p>
                </label>
              </div>

              <div className="mt-1">
                <Accordion className='px-0'>
                  <AccordionSummary
                    expandIcon={<MaterialIcons name="keyboard-arrow-down" size={20} color="black" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p className='fs-7 fw-bold text-black'>Brand</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='w-100'>
                      <input
                        type="text"
                        placeholder="Search..."
                        className="form-control btn-radius"
                      />
                      <div className="p-2">
                        <label className="form-label mt-3 d-flex align-items-center">
                          <input type="checkbox" className="me-2" />
                          Polo
                        </label>
                        <label className="form-label mt-3 d-flex align-items-center">
                          <input type="checkbox" className="me-2" />
                          Polo
                        </label>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div className="mt-3">
                <p className='text-black' id="range-slider">
                  Mininum Maximum Price
                </p>
                <div className="d-flex align-items-center">
                  {/* <p className="pe-3">Min</p> */}
                  <Slider
                    value={value}
                    onChange={handleChange}
                    onClick={() => {
                      console.log("min max :", value);
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                  />
                  {/* <p className="px-3">Max</p> */}
                </div>
                <div className="row pt-2">
                  <div className="col-6">
                    <label className="form-label mb-1 cursor">Min Price</label>
                    <input
                      type="text"
                      className="form-control"
                      name={'min_mrp'}
                      // value={min_max_value[0]}
                      onChange={(event) => {
                        // input_on_change(event)
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label mb-1 cursor">Max Price</label>
                    <input
                      type="text"
                      className="form-control"
                      name={'max_mrp'}
                      // value={min_max_value[1]}
                      onChange={(event) => {
                        // input_on_change(event)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

{
  /* 

  useEffect(() => {
    console.log("Add Product On mount :", props);
    console.log("Add Product context :", context);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className=" row align-items-center head-bar">
        <div className="col-7 sm-mb-2 d-flex align-items-center">
          <Link
            to={
              "/outlet/" +
              id_value.brand_id +
              "/" +
              id_value.outlet_id +
              "/products"
            }
          >
            <img
              src={require("../../../assets/icon/svg/arrow-left.svg")}
              className="cursor pe-2"
            />
          </Link>
          <h6 className="  mb-1">Add Products</h6>
        </div>
        <div className="col-5 ps-0 text-end sm-mb-2">
          <button className="btn btn-primary btn-sm">Create Products</button>
        </div>
      </div>

      <div className="p-3">
        <div className="row p-3">
          <div className="col-md-3 md-ps-0 sm-p-0">
            <div className="bg-fff p-3">
              <div className="d-flex ">
                <h6>Filter</h6>
                <div className="ms-auto">
                  <small>Clear</small>
                </div>
              </div>
              <div className="my-3">
                <label className="form-label d-flex align-items-center">
                  <input type="checkbox" className="form-check me-2" />
                  <small>Own Brand Products</small>
                </label>
                <label className="form-label d-flex align-items-center">
                  <input type="checkbox" className="form-check me-2" />
                  <small>Other Brand Products</small>
                </label>
              </div>

              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="acc-head-id">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acc-id"
                      aria-expanded="false"
                      aria-controls="acc-id"
                    >
                      Brand
                    </button>
                  </h2>
                  <div
                    id="acc-id"
                    className="accordion-collapse collapse "
                    aria-labelledby="acc-head-id"
                  >
                    <div className="accordion-body">
                      <input
                        type="text"
                        placeholder="Search..."
                        className="form-control"
                      />
                      <div className="p-2">
                        <label className="form-label mt-3 d-flex align-items-center">
                          <input type="checkbox" className="me-2" />
                          Polo
                        </label>
                        <label className="form-label mt-3 d-flex align-items-center">
                          <input type="checkbox" className="me-2" />
                          Polo
                        </label>
                      </div>
                    </div>
                    <div className="col-md-9 sm-mt-3">
                        <div className="row bg-fff  align-items-center p-3 border-bottom">
                            <div className="col-md-6">
                                <input type="text" className="form-control" placeholder="Search Products" />
                            </div>
                            <div className="col-md-3 col-6">
                                <label className="form-label mb-0">
                                    <input type="checkbox" className="me-2" />
                                    Select All
                                </label>
                            </div>
                            <div className="col-md-3 col-6 text-end">
                                <Link to={"/outlet/" + id_value.brand_id + "/" + id_value.outlet_id + "/product_assign"}>
                                    <button className="btn btn-blue-outline btn-sm" >Next</button>
                                </Link>
                            </div>
                        </div>
                        <div className="row bg-fff  align-items-center p-3 border-bottom">
                        {product_data.map((ele: any) => (
                <div className="col-md-6 p-0">
                  <div className="p-3 mb-2">
                    <div className="row align-items-center mb-2">
                      <div className="col-8">
                        <h6 className="mb-3">{ele.name}</h6>
                      </div>
                      <div className="col-4 text-end">
                        <button
                          className="btn btn-secondary btn-sm"
                          onClick={() => {
                            console.log("add variants");
                            // SetModalVariants((prevValue: any) => {
                            SetModalVariants({
                              // prevValue.is_open = true;
                              is_open: true,
                              product_name: ele.name,
                              product_id: ele.id,
                              product_checked: ele.checked,

                              data: ele.variants,

                              // return { ...prevValue }
                            });
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        {ele.images ? (
                          <img
                            src={
                              data.image_base_url +
                              data.sizes[3] +
                              "/" +
                              ele.images[0].url
                            }
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={
                              "https://cpimg.tistatic.com/06747380/b/4/Used-Iphone-12.jpg"
                            }
                            alt="pic"
                            className="img-fluid"
                          />
                        )}
                        <img
                          // src={require(ele.images[0].url)}
                          className="w-100"
                        />
                      </div>
                      <div className="col-9">
                        <small className="disc-overflow">
                          {ele.description}
                        </small>
                      </div>
                    </div>
                    <div className="row pt-3  border-bottom">
                      <div className="col-6">
                        <p>
                          Variants :{" "}
                          <span className="text-dark">
                            {ele.total_variants +
                              "(" +
                              ele.total_active_variants +
                              ")"}
                          </span>{" "}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          Addons :{" "}
                          <span className="text-dark">
                            {ele.total_addon_groups +
                              "(" +
                              ele.total_active_addon_groups +
                              ")"}
                          </span>
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          Addon Items :{" "}
                          <span className="text-dark">
                            {ele.total_addon_items +
                              "(" +
                              ele.total_active_addon_items +
                              ")"}
                          </span>
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          Brand :{" "}
                          <span className="text-dark">{ele.product_brand}</span>
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          Category :{" "}
                          <span className="text-dark">{ele.category}</span>
                        </p>
                      </div>
                      <div className="col-12">
                        <p>
                          MRP :{" "}
                          <span className="text-dark">
                            ₹{ele.min_mrp} - ₹{ele.max_mrp}{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
                        
                        </div>
                    </div>
                    <div className="col-4 text-end">
                      <button
                        className="btn btn-secondary btn-sm"
                        onClick={() => {
                          console.log("add variants");
                          SetModalVariants((prevValue: any) => {
                            prevValue.is_open = true;
                            return { ...prevValue };
                          });
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <img
                        src={require("../../../assets/img/mob.png")}
                        className="w-100"
                      />
                    </div>
                    <div className="col-9">
                      <small className="disc-overflow">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Pellentesque dignissim lorem ac lectus condimentum
                        posuere. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit
                      </small>
                    </div>
                  </div>
                  <div className="row pt-3  border-bottom">
                    <div className="col-6">
                      <p>
                        Variants : <span className="text-dark">6</span>{" "}
                      </p>
                    </div>
                    <div className="col-6">
                      <p>
                        Addons : <span className="text-dark">6</span>
                      </p>
                    </div>
                    <div className="col-6">
                      <p>
                        Brand : <span className="text-dark">Oppo</span>
                      </p>
                    </div>
                    <div className="col-6">
                      <p>
                        Category : <span className="text-dark">Mobile</span>
                      </p>
                    </div>
                    <div className="col-12">
                      <p>
                        MRP : <span className="text-dark">₹18000 - ₹20000</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ModalVariants
        open={modal_variants.is_open}
        product={modal_variants.product_name}
        id={modal_variants.product_id}
        checked={modal_variants.product_checked}
        data={modal_variants.data}
        close={(data: any) => {
          console.log("Variants modl on close :", data);
          // SetModalVariants((prevValue: any) => {
          //     prevValue.is_open = false;
          //     return { ...prevValue }
          // })
          SetModalVariants({
            is_open: false,
            product_name: "",
            product_id: "",
            product_checked: "",

            // return { ...prevValue }
            data: {},
          });

          if (data.action == "add") {
            get_add_products() 

          }
        }}
      />

        </div>
      </div>

      <ModalVariants
        open={modal_variants.is_open}
        close={(data: any) => {
          console.log("Variants modl on close :", data);
          SetModalVariants((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
        }}
      />
    </div>
  ); */
}
